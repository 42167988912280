import cn from 'classnames';
import { FC } from 'react';

interface IOptionsTitleProps {
	title: string;
	multi?: boolean;
}

export const OptionsTitle: FC<IOptionsTitleProps> = ({ multi, title }) => (
	<div
		className={cn('price-options', {
			multi,
		})}
	>
		<h4 className="price-options-title">{title}</h4>
	</div>
);
