import { useState } from 'react';

interface IPasswordInputResult {
	inputType: string;
	toggleValue: string;
	handleShowValue: () => void;
}

export const usePasswordInput = (): IPasswordInputResult => {
	const [showValue, setShowValue] = useState(false);

	const handleShowValue = () => {
		setShowValue((prev) => !prev);
	};

	const inputType = showValue ? 'text' : 'password';
	const toggleValue = showValue ? 'Hide' : 'Show';

	return { inputType, toggleValue, handleShowValue };
};
