import { object, string } from 'yup';

import { requiredFieldMessage } from 'constants/general/validation/generalMessages';

export const updateCardScheme = object({
	cardType: string().required(requiredFieldMessage),
	cardNumber: string().required(requiredFieldMessage),
	expiration: string().required(requiredFieldMessage),
	verificationCode: string().required(requiredFieldMessage),
	billingZip: string().required(requiredFieldMessage),
});
