import { CellProps, Column } from 'react-table';
import { Link } from 'react-router-dom';
import { FC } from 'react';

import { priceListRouteByFulfillment } from 'constants/priceLists/priceListRouteByFulfillment';
import { priceListsActionMenuList } from 'constants/priceLists/priceListsActionMenuList';
import { PriceListsSortLabels } from 'constants/priceLists/priceListsSortLabels';

import { ColumnLabelContainer } from 'components/Table/ColumnLabelContainer';
import { CellActionMenu } from 'components/Table/CellActionMenu';
import { Loader } from 'components/Loader';
import { Table } from 'components/Table';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useDefineDevice } from 'hooks/useDefineDevice';
import { usePagination } from 'hooks/usePagination';
import { useActionMenu } from 'hooks/useActionMenu';

import { isPaginatedPriceListsPendingSelector } from 'store/priceLists';
import { IPriceList } from 'api/models/responses/priceLists/priceList';
import { normalizeDate } from 'utils/ui/normalizeDate';
import { ISearchResult } from 'types/searchParams';

import { IActionMenuPayload } from '../..';
import { getPriceListSelectOptionKey } from 'utils/priceLists/getPriceListSelectOptionKey';

interface ISearchPriceListsProps
	extends Omit<
		ISearchResult<PriceListsSortLabels, IPriceList[]>,
		'isPending' | 'handleSearch'
	> {
	handleActionMenuClick: (payload: IActionMenuPayload) => void;
}

export const PriceListsList: FC<ISearchPriceListsProps> = ({
	orderBy,
	results,
	setSortParams,
	orderDirection,
	setPaginatedEntity,
	handleActionMenuClick,
}) => {
	const isPaginatedPriceListsPending = useAppSelector(
		isPaginatedPriceListsPendingSelector
	);

	const { actionMenuId, setActionMenuId } = useActionMenu();

	const ref = usePagination(setPaginatedEntity);

	const { isMobile } = useDefineDevice();

	const columns: Column<IPriceList>[] = [
		{
			id: 'name',
			Header: (
				<ColumnLabelContainer
					label="Name"
					orderBy={orderBy}
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={PriceListsSortLabels.Name}
				/>
			),
			Cell: ({ row }: CellProps<IPriceList>) => {
				const { key, name, fulfillment } = row.original;

				const path = `${priceListRouteByFulfillment[fulfillment]}/${key}`;

				return (
					<Link to={path} className="price-table-name">
						{name}
					</Link>
				);
			},
		},
		{
			Header: (
				<ColumnLabelContainer
					orderBy={orderBy}
					label="Fulfillment"
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={PriceListsSortLabels.Fulfillment}
				/>
			),
			accessor: 'fulfillment',
		},
		{
			Header: (
				<ColumnLabelContainer
					orderBy={orderBy}
					label="# of Products"
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={PriceListsSortLabels.ProductsCount}
				/>
			),
			accessor: 'productsCount',
		},
		{
			Header: (
				<ColumnLabelContainer
					orderBy={orderBy}
					label="# of Active Projects"
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={PriceListsSortLabels.ActiveProjectsCount}
				/>
			),
			accessor: 'activeProjectsCount',
			Cell: ({
				value,
			}: CellProps<IPriceList, IPriceList['activeProjectsCount']>) => {
				return value ? (
					<Link to="/" className="price-table-link">
						{value}
					</Link>
				) : (
					<>{value}</>
				);
			},
		},
		{
			Header: (
				<ColumnLabelContainer
					orderBy={orderBy}
					label="Last Modified"
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={PriceListsSortLabels.LastModifyDate}
				/>
			),
			accessor: 'lastModifyDate',
			Cell: ({
				value,
			}: CellProps<IPriceList, IPriceList['lastModifyDate']>) => (
				<>{normalizeDate(value)}</>
			),
		},
		{
			id: 'actionMenu',
			Cell: ({ row }: CellProps<IPriceList>) => {
				const { key, name, fulfillment } = row.original;

				const priceListKey = getPriceListSelectOptionKey(key, fulfillment);
				const showActionMenu = actionMenuId === priceListKey;

				const setActiveActionMenu = () => setActionMenuId(priceListKey);

				return (
					<CellActionMenu
						showActionMenu={showActionMenu}
						menuList={priceListsActionMenuList}
						setActiveActionMenu={setActiveActionMenu}
						handleMenuItemClick={(value) =>
							handleActionMenuClick({ key, name, fulfillment, menuItem: value })
						}
					/>
				);
			},
		},
	];

	const mobileColumns = columns.slice(0, 2);

	const processedColumns = isMobile ? mobileColumns : columns;

	return (
		<div className="price-table">
			<div className="org-project-search-container">
				<Table<IPriceList>
					data={results}
					columns={processedColumns}
					className="table-action-menu"
				/>
				<div ref={ref} style={{ height: '1px' }} />
				{isPaginatedPriceListsPending && <Loader isFillContent />}
			</div>
		</div>
	);
};
