import { v4 as uuidv4 } from 'uuid';

import { subjectFormQuestionInitialError } from 'constants/projects/subjectFormQuestions/subjectFormQuestionInitialError';
import { ISubjectFormQuestionUI } from 'components/SubjectFormQuestions/types';
import {
	ISubjectFormQuestion,
	ISubjectFormQuestionUniq,
} from 'api/models/responses/projects/subjectFormQuestion';

export const getSubjectFormQuestionsUI = (
	subjectFormQuestions: ISubjectFormQuestionUniq[] | ISubjectFormQuestion[]
): ISubjectFormQuestionUI[] =>
	subjectFormQuestions.map((subjectFormQuestion) => ({
		...subjectFormQuestion,
		localKey: uuidv4(),
		errors: subjectFormQuestionInitialError,
	}));
