import { FC } from 'react';

import { TableRowNames } from 'pages/RegistrationSteps/SelectPlan/components/TableRowLabels';
import { TableColumn } from 'pages/RegistrationSteps/SelectPlan/components/TableColumn';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';

import { Button } from 'components/FormControls/Button';
import {
	subscriptionsSelector,
	setActiveSubscriptionKey,
} from 'store/subscriptions';

interface IUpgradeYourPlanProps {
	hideUpgradePlanModal: () => void;
	showConfirmUpgradePlanModal: () => void;
}

export const UpgradeYourPlan: FC<IUpgradeYourPlanProps> = ({
	hideUpgradePlanModal,
	showConfirmUpgradePlanModal,
}) => {
	const subscriptions = useAppSelector(subscriptionsSelector);

	const dispatch = useAppDispatch();

	const selectSubscription = (key: number) => {
		dispatch(setActiveSubscriptionKey(key));
	};

	const handleChoosePlan = () => {
		showConfirmUpgradePlanModal();
		hideUpgradePlanModal();
	};

	const TableColumnsList = subscriptions.map((subscription) => (
		<TableColumn
			{...subscription}
			key={subscription.subscriptionLevelKey}
			selectSubscription={selectSubscription}
		/>
	));

	return (
		<div className="plans">
			<div className="panel-plans">
				<TableRowNames />
				{TableColumnsList}
			</div>
			<div>
				<Button
					value="Upgrade"
					className="btn-primary"
					handleClick={handleChoosePlan}
				/>
				<Button
					value="Back"
					className="btn-secondary"
					handleClick={hideUpgradePlanModal}
				/>
			</div>
		</div>
	);
};
