import { Formik } from 'formik';
import { FC } from 'react';

import { IPaginationResponse } from 'api/models/responses/general/paginationResponse';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import { ImageTypes } from 'api/models/responses/photos/photo';
import PhotoService from 'api/services/PhotoService';

import { IPhotoUI, IUpdatePhotoValues } from '../../types';
import { UpdatePhoto } from './UpdatePhoto';

interface IUpdatePhotoFormProps {
	projectKey: number;
	photoKey: string | null;
	hideEditPhotoModal: () => void;
	photosUI: IPaginationResponse<IPhotoUI>;
	editPhotoInitial: IUpdatePhotoValues | null;
	setPhotosUI: (photosUI: IPaginationResponse<IPhotoUI>) => void;
}

export const UpdatePhotoForm: FC<IUpdatePhotoFormProps> = ({
	photoKey,
	photosUI,
	projectKey,
	setPhotosUI,
	editPhotoInitial,
	hideEditPhotoModal,
}) => {
	const initialValues: IUpdatePhotoValues = {
		password: editPhotoInitial?.password || '',
		imageType: editPhotoInitial?.imageType || null,
		freeDownload: !!editPhotoInitial?.freeDownload,
	};

	const onSubmit = async (values: IUpdatePhotoValues) => {
		if (!photoKey || !projectKey) return;

		const updatedPasswordField: IPatchBody = {
			op: 'replace',
			path: 'accessCode',
			value: values.password || '',
		};

		const updatedImageTypeField: IPatchBody = {
			op: 'replace',
			path: 'imageType',
			value: values.imageType as ImageTypes,
		};

		const updatedHiResDownload: IPatchBody = {
			op: 'replace',
			path: 'hiResDownload',
			value: values.freeDownload,
		};

		const body: IPatchBody[] = [
			updatedPasswordField,
			updatedImageTypeField,
			updatedHiResDownload,
		];

		try {
			const data = await PhotoService.partialPhotoUpdate(
				photoKey,
				projectKey,
				body
			);

			const updatedPhotosUI = photosUI.results.map((photoUI) => {
				if (photoUI.id === data.id) {
					return {
						...data,
						smallThumbnailUrl: photoUI.smallThumbnailUrl,
					};
				}

				return photoUI;
			});

			setPhotosUI({ ...photosUI, results: updatedPhotosUI });
			hideEditPhotoModal();
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Formik
			enableReinitialize
			onSubmit={onSubmit}
			initialValues={initialValues}
		>
			<UpdatePhoto />
		</Formik>
	);
};
