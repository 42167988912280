import { FC, useState } from 'react';

import { useAsyncOptimizedCheckbox } from 'hooks/formControls/useAsyncOptimizedCheckbox';
import { useLazyDollarInput } from 'hooks/formControls/useLazyDollarInput';

import { Checkbox } from 'components/FormControls/Checkbox';
import { InputField } from 'components/InputField';

import { IPatchBody } from 'api/models/requests/general/patchBody';

import { OrderOptionsProps } from '..';

export const OrderOption: FC<OrderOptionsProps> = ({
	updatePriceListImago,
	isFreeShippingEnabled,
	freeShippingOnOrdersAbove,
}) => {
	const [priceError, setPriceError] = useState('');

	const updateIsFreeShippingEnabled = async (value: boolean) => {
		try {
			const updatedField: IPatchBody = {
				value,
				op: 'replace',
				path: 'isFreeShippingEnabled',
			};

			const body: IPatchBody[] = [updatedField];

			await updatePriceListImago(body);
		} catch (error) {
			console.log(error);
		}
	};

	const updatePrice = async (value: number, validationMessage: string) => {
		if (validationMessage) {
			return setPriceError(validationMessage);
		}

		try {
			const updatedField: IPatchBody = {
				value,
				op: 'replace',
				path: 'freeShippingOnOrdersAbove',
			};

			const body: IPatchBody[] = [updatedField];

			await updatePriceListImago(body);
		} catch (error) {
			console.log(error);
		}
	};

	const clearPriceError = () => setPriceError('');

	const { checked, isPending, handleChange } = useAsyncOptimizedCheckbox(
		isFreeShippingEnabled,
		updateIsFreeShippingEnabled
	);

	const {
		value,
		handleBlur,
		handleKeyDown,
		handleChange: handlePriceChange,
	} = useLazyDollarInput({
		handleUpdate: updatePrice,
		clearError: clearPriceError,
		initialValue: freeShippingOnOrdersAbove ?? 0,
	});

	return (
		<tbody className="price-option-tbody">
			<tr>
				<td className="price-option-td"></td>
				<td className="price-option-td">
					<Checkbox
						checked={checked}
						id="isFreeShippingEnabled"
						handleChange={handleChange}
						label="Free Shipping on orders above"
						disabled={isPending || !freeShippingOnOrdersAbove}
					/>
				</td>
				<td className="price-option-td">
					<InputField
						touched
						value={value}
						error={priceError}
						placeholder="Price"
						handleBlur={handleBlur}
						handleKeyDown={handleKeyDown}
						handleChange={handlePriceChange}
					/>
				</td>
				<td className="price-option-td">
					<p className="price-delivery-text">
						Your account will be charged for the least expensive shipping
						option. If the consumer chooses an upgraded shipping method they
						will pay the difference.
					</p>
				</td>
			</tr>
		</tbody>
	);
};
