import { MarketingPage } from 'pages/MarketingPage';
import { Dashboard } from 'pages/Dashboard';
import { ROUTES } from 'constants/ROUTES';

import { watermarkMediaRoutesList } from './watermarkMediaRoutesList';
import { journeyPresetRoutesList } from './journeyPresetRoutesList';
import { organizationRoutesList } from './organizationRoutesList';
import { promotionsRoutesList } from './promotionsRoutesList';
import { priceListRoutesList } from './priceListRoutesList';
import { campaignsRoutesList } from './campaignsRoutesList';
import { projectRoutesList } from './projectRoutesList';
import { Accounting } from 'pages/Accounting';

export const mainLayoutRouterList = [
	{
		path: ROUTES.DASHBOARD,
		element: <Dashboard />,
	},
	{
		path: ROUTES.MARKETING,
		element: <MarketingPage />,
	},
	{
		path: ROUTES.ACCOUNTING,
		element: <Accounting />,
	},
	...organizationRoutesList,
	...projectRoutesList,
	...journeyPresetRoutesList,
	...priceListRoutesList,
	...campaignsRoutesList,
	...watermarkMediaRoutesList,
	...promotionsRoutesList,
];
