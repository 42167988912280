import {
	maxPoints,
	minPoints,
} from 'constants/general/validation/pointsValidation';

export const validatePoints = (
	points: number,
	isRequired?: boolean
): string => {
	if (isNaN(points)) {
		return 'Points must be a number.';
	}

	if (!Number.isInteger(points)) {
		return 'Points must be an integer number.';
	}

	if (points > maxPoints) {
		return `Points must be less than ${maxPoints}.`;
	}

	if (points < minPoints) {
		return 'Points must be a positive number.';
	}

	if (isRequired && points === 0) {
		return 'Points must be greater than 0.';
	}

	return '';
};
