import { DragEvent, FC } from 'react';

import { BurgerColumn } from 'pages/PriceLists/components/BurgerColumn';
import {
	IPriceListFormQuestionUI,
	IClearFormQuestionErrorPayload,
	IDeletePriceListFormQuestionPayload,
	IUpdatePriceListFormQuestionPayload,
} from 'pages/PriceLists/types/studio/priceListFormQuestionUI';

import { FormQuestionDataFieldType } from 'api/models/responses/general/generalFormQuestion';
import { IChangeSequencePayload, useDragAndDrop } from 'hooks/useDragAndDrop';

import { ResponseOptionsColumn } from './components/ResponseOptionsColumn';
import { DataFiledTypeColumn } from './components/DataFieldColumn';
import { QuestionColumn } from './components/QuestionColumn';

interface IPriceListFormQuestionProps {
	formQuestionUI: IPriceListFormQuestionUI;
	updateFormQuestion: (
		payload: IUpdatePriceListFormQuestionPayload
	) => Promise<void>;
	deleteFormQuestion: (
		payload: IDeletePriceListFormQuestionPayload
	) => Promise<void>;
	changeFormQuestionSequence: (payload: IChangeSequencePayload) => void;
	clearFormQuestionError: (payload: IClearFormQuestionErrorPayload) => void;
}

export const PriceListFormQuestion: FC<IPriceListFormQuestionProps> = ({
	formQuestionUI,
	updateFormQuestion,
	deleteFormQuestion,
	clearFormQuestionError,
	changeFormQuestionSequence,
}) => {
	const {
		errors,
		sequence,
		formQuestion,
		dataFieldType,
		formQuestionKey,
		isAnswerRequired,
	} = formQuestionUI;

	const { formQuestion: formQuestionError } = errors;

	const formQuestionKeyString = formQuestionKey?.toString() || '';

	const {
		draggable,
		handleDrop,
		handleDragEnd,
		handleDragOver,
		handleDragStart,
		handleMouseDown,
	} = useDragAndDrop([], changeFormQuestionSequence);

	const onDragStart = (e: DragEvent<HTMLTableSectionElement>) => {
		if (!formQuestionKeyString) return;

		handleDragStart(e, formQuestionKeyString);
	};

	const handleDelete = () => {
		void deleteFormQuestion({
			sequence,
			formQuestionKey,
		});
	};

	const updateFormQuestionField = (
		value: string,
		validationMessage: string
	) => {
		void updateFormQuestion({
			value,
			sequence,
			formQuestionKey,
			validationMessage,
			fieldKey: 'formQuestion',
		});
	};

	const clearFormQuestionFieldError = () => {
		if (!formQuestionError) return;

		clearFormQuestionError({
			sequence,
			fieldKey: 'formQuestion',
		});
	};

	const updateDataFieldType = (value: FormQuestionDataFieldType) => {
		void updateFormQuestion({
			value,
			sequence,
			formQuestionKey,
			fieldKey: 'dataFieldType',
		});
	};

	const updateIsAnswerRequired = async (value: boolean) => {
		await updateFormQuestion({
			value,
			sequence,
			formQuestionKey,
			fieldKey: 'isAnswerRequired',
		});
	};

	const unsavedFormQuestion = !formQuestionKey;

	return (
		<tbody
			onDrop={handleDrop}
			draggable={draggable}
			onDragStart={onDragStart}
			onDragEnd={handleDragEnd}
			id={formQuestionKeyString}
			onDragOver={handleDragOver}
			data-drag-sequence={sequence}
		>
			<tr>
				<BurgerColumn
					colSpan={1}
					showBurger={!unsavedFormQuestion}
					handleMouseDown={handleMouseDown}
				/>
				<QuestionColumn
					question={formQuestion}
					error={formQuestionError}
					clearError={clearFormQuestionFieldError}
					updateQuestion={updateFormQuestionField}
				/>
				<DataFiledTypeColumn
					dataFieldType={dataFieldType}
					updateDataFieldType={updateDataFieldType}
				/>
				<ResponseOptionsColumn
					sequence={sequence}
					handleDelete={handleDelete}
					isAnswerRequired={isAnswerRequired}
					updateIsAnswerRequired={updateIsAnswerRequired}
				/>
			</tr>
		</tbody>
	);
};
