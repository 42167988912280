import { FC, SyntheticEvent } from 'react';

import { validateFormQuestion } from 'utils/validations/general/formQuestionsValidation';
import { LazyInput } from 'components/FormControls/LazyInput';

interface IQuestionColumnProps {
	error: string;
	question: string;
	clearError: () => void;
	updateQuestion: (question: string, validationMessage: string) => void;
}

export const QuestionColumn: FC<IQuestionColumnProps> = ({
	error,
	question,
	clearError,
	updateQuestion,
}) => {
	const handleChangeQuestion = (e: SyntheticEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;

		const trimmedValue = value.trim();

		const validationMessage = validateFormQuestion(trimmedValue);

		updateQuestion(trimmedValue, validationMessage);
	};

	return (
		<td className="price-option-td">
			<LazyInput
				error={error}
				placeholder="Question"
				defaultValue={question}
				clearError={clearError}
				className="input exp-wrapper"
				handleChange={handleChangeQuestion}
			/>
		</td>
	);
};
