export const minMaxUses = 1;
export const minMaxUsesMessage = 'Max Uses must be at least 1';

export const maxMaxUses = 2147483647;
export const maxMaxUsesMessage = 'Max Uses must be at most 2147483647';

export const minOrder = 0;
export const maxOrder = 999;
export const minOrderErrorMessage = 'Min order must be 999 or less.';

export const priceInDecimalRangeRegex = /^(9999|[1-9][0-9]{0,3})(\.\d{1,2})?$/;
export const priceValueMessage = 'Price must be between 1 and 9999.99';
export const pricePercentValueMessage = 'Price must be between 1 and 100';

export const promoCodeMaxLength = 25;
export const promoCodesMessage =
	'Each promo code must be at most 25 characters and contain only alphanumeric characters without spaces.';
export const duplicatePromoCodeMessage = 'Promo codes must be unique';
