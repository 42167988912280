import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GENERAL_SELECT_OPTION } from 'constants/projects/selectOptions/generalFilterSelectOption';
import { JourneyFilterSelectOption } from 'types/projects/selectOptions/journeyFilterSelectOption';
import { ICampaign } from 'api/models/responses/campaigns/campaign';

import { ICampaignsState, TargetFilterSelectOption } from './';
import { ICampaignDeletePayload } from 'api/models/responses/campaigns/campaignDeleteReject';

const initialState: ICampaignsState = {
	campaigns: [],
	usedInPresets: [],
	usedInProjects: [],
	isCopySuccess: false,
	copiedCampaign: null,
	isCampaignsPending: false,
	isDeleteCampaignReject: false,
	isDeleteCampaignSuccess: false,
	targetFilterSelectOption: GENERAL_SELECT_OPTION,
	journeyFilterSelectOption: GENERAL_SELECT_OPTION,
};

const campaignsSlice = createSlice({
	name: 'campaigns',
	initialState,
	reducers: {
		setCampaigns: (state, { payload }: PayloadAction<ICampaign[]>) => {
			state.campaigns = payload;
		},

		setCopiedCampaign: (state, { payload }: PayloadAction<ICampaign>) => {
			state.copiedCampaign = payload;
			state.campaigns.push(payload);
		},

		setIsCopySuccess: (state, { payload }: PayloadAction<boolean>) => {
			state.isCopySuccess = payload;
		},

		deleteCampaign: (state, { payload }: PayloadAction<number>) => {
			state.campaigns = state.campaigns.filter(
				(campaign) => campaign.campaignKey !== payload
			);
		},

		setIsDeleteCampaignReject: (state, { payload }: PayloadAction<boolean>) => {
			state.isDeleteCampaignReject = payload;
		},

		setIsDeleteCampaignSuccess: (
			state,
			{ payload }: PayloadAction<boolean>
		) => {
			state.isDeleteCampaignSuccess = payload;
		},

		setUsedInItems: (
			state,
			{ payload }: PayloadAction<ICampaignDeletePayload>
		) => {
			state.usedInPresets = payload.presets;
			state.usedInProjects = payload.projects;
		},

		setTargetFilterSelectOption: (
			state,
			{ payload }: PayloadAction<TargetFilterSelectOption>
		) => {
			state.targetFilterSelectOption = payload;
		},

		setJourneyFilterSelectOption: (
			state,
			{ payload }: PayloadAction<JourneyFilterSelectOption>
		) => {
			state.journeyFilterSelectOption = payload;
		},

		setIsCampaignsPending: (state, { payload }: PayloadAction<boolean>) => {
			state.isCampaignsPending = payload;
		},
	},
});

export const campaignsReducer = campaignsSlice.reducer;
export const {
	setCampaigns,
	setUsedInItems,
	deleteCampaign,
	setIsCopySuccess,
	setCopiedCampaign,
	setIsCampaignsPending,
	setIsDeleteCampaignReject,
	setIsDeleteCampaignSuccess,
	setTargetFilterSelectOption,
	setJourneyFilterSelectOption,
} = campaignsSlice.actions;
