import { Link } from 'react-router-dom';
import { FC } from 'react';

import { ROUTES } from 'constants/ROUTES';

interface Props {
	path?: string;
	title: string;
	paragraph: string;
	iconType: string;
}

export const Info: FC<Props> = ({ path, title, paragraph, iconType }) => (
	<li className="card-info-item">
		<div className={`card-info-icon ${iconType}`}></div>
		<div className="card-info-content">
			<h4 className="card-info-title">
				<Link to={path ?? ROUTES.DASHBOARD}>{title}</Link>
			</h4>
			<p>{paragraph}</p>
		</div>
	</li>
);
