import { FC, SyntheticEvent } from 'react';

import { LazyInput } from 'components/FormControls/LazyInput';
import { Checkbox } from 'components/FormControls/Checkbox';

import { validateReferenceCode } from 'utils/validations/priceLists/validateReferenceCode';
import { useAsyncOptimizedCheckbox } from 'hooks/formControls/useAsyncOptimizedCheckbox';

import { IGeneralColumnProps } from '../../';

interface IReferenceCodeColumnProps extends IGeneralColumnProps {
	referenceCode: string;
	referenceCodeError: string;
	isCollectingMailingAddress: boolean;
}

export const ReferenceCodeColumn: FC<IReferenceCodeColumnProps> = ({
	sequence,
	referenceCode,
	shippingOptionKey,
	referenceCodeError,
	updateShippingOption,
	clearShippingOptionErrors,
	isCollectingMailingAddress,
}) => {
	const updateCollectShippingAddress = async (value: boolean) => {
		await updateShippingOption({
			value,
			sequence,
			shippingOptionKey,
			fieldKey: 'isCollectingMailingAddress',
		});
	};

	const { checked, isPending, handleChange } = useAsyncOptimizedCheckbox(
		isCollectingMailingAddress,
		updateCollectShippingAddress
	);

	const handleChangeReferenceCode = (e: SyntheticEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;

		const validationMessage = validateReferenceCode(value);

		void updateShippingOption({
			value,
			sequence,
			shippingOptionKey,
			validationMessage,
			fieldKey: 'referenceCode',
		});
	};

	const clearReferenceCodeError = () => {
		if (!referenceCodeError) return;

		clearShippingOptionErrors({ sequence, fieldKey: 'referenceCode' });
	};

	return (
		<td className="price-option-td">
			<div className="price-table-container labels">
				<LazyInput
					label="Charge"
					error={referenceCodeError}
					defaultValue={referenceCode}
					placeholder="Reference Code"
					className="input exp-wrapper"
					clearError={clearReferenceCodeError}
					handleChange={handleChangeReferenceCode}
				/>
				<Checkbox
					checked={checked}
					disabled={isPending}
					handleChange={handleChange}
					label="Collect shipping address"
					id={`"collectShippingAddress"-${sequence}`}
				/>
			</div>
		</td>
	);
};
