import { FC } from 'react';

import { Info } from 'components/CardContent/Info';
import { Card } from 'components/Card';

import { ROUTES } from 'constants/ROUTES';

interface IInfo {
	path: string;
	title: string;
	iconType: string;
	paragraph: string;
}

const infoLinks: IInfo[] = [
	{
		title: 'Organization',
		path: ROUTES.ORGANIZATIONS.ACTIVE_ORGANIZATIONS,
		paragraph: `Create a place to host several projects that share a common asso-
					ciation (like school, district, or league).`,
		iconType: 'picture-add',
	},
	{
		title: 'Price List',
		path: ROUTES.PRICE_LISTS.PRICE_LISTS,
		paragraph:
			'Launch a new collection of packages and products to offer to your customers.',
		iconType: 'picture-move',
	},
	{
		title: 'Promotion',
		path: ROUTES.PROMOTIONS.PROMOTIONS,
		paragraph:
			'Generate a coupon or offer to help your customers and generate sales.',
		iconType: 'picture-static',
	},
];

export const CreateNew: FC = () => {
	const InfoLinksList = infoLinks.map(
		({ path, title, iconType, paragraph }) => (
			<Info
				key={path}
				title={title}
				path={path}
				paragraph={paragraph}
				iconType={iconType}
			/>
		)
	);

	return (
		<Card title="Create a New" subtitle="">
			<div className="card-content-info">
				<ul className="card-info-list">{InfoLinksList}</ul>
			</div>
		</Card>
	);
};
