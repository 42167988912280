import Select, { ActionMeta, OnChangeValue } from 'react-select';
import cn from 'classnames';

import { ISelectOption } from 'types/ui/select';

interface SelectProps<T> {
	id?: string;
	name?: string;
	label?: string;
	error?: string;
	touched?: boolean;
	disabled?: boolean;
	required?: boolean;
	readonly?: boolean;
	className?: string;
	isSearchable?: boolean;
	handleFocus?: () => void;
	selectPlaceholder?: string;
	value: string | number | null;
	selectOptions: ISelectOption<T>[];
	onChange?: (
		newValue: OnChangeValue<ISelectOption<T>, false>,
		actionMeta?: ActionMeta<ISelectOption<T>>
	) => void;
}

export const SelectComponent = <T,>({
	id,
	name,
	label,
	value,
	error,
	touched,
	onChange,
	disabled,
	readonly,
	required,
	className,
	handleFocus,
	isSearchable,
	selectOptions,
	selectPlaceholder,
}: SelectProps<T>) => {
	const processedValue =
		selectOptions.find((option) => option.value === value) || null;

	const showError = error && touched;

	return (
		<>
			{label && (
				<label className={`label ${className || ''}`}>
					{label} {required && <i className="grid-required">*</i>}
				</label>
			)}
			<Select
				id={id}
				name={name}
				onChange={onChange}
				onFocus={handleFocus}
				isDisabled={disabled}
				value={processedValue}
				options={selectOptions}
				classNamePrefix="select"
				menuPortalTarget={document.body}
				isSearchable={isSearchable}
				placeholder={selectPlaceholder}
				menuShouldScrollIntoView={false}
				className={cn('select-custom', {
					readonly,
					'select-error': showError,
				})}
				styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
			/>
			{showError && <span className="input-error">{error}</span>}
		</>
	);
};

SelectComponent.defaultProps = {
	disabled: false,
	readonly: false,
	required: false,
	isSearchable: false,
};
