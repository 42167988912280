interface IValidateImageFile {
	type: string;
	validatePng?: boolean;
	validateJpg?: boolean;
}

export const pngType = 'image/png';
export const jpgType = 'image/jpg';
export const jpegType = 'image/jpeg';

const allowedTypes = [pngType, jpgType, jpegType];

export const validateImageFile = ({
	type,
	validateJpg,
	validatePng,
}: IValidateImageFile): string => {
	const validateOnlyPng = validatePng && !validateJpg;
	const validateOnlyJpg = validateJpg && !validatePng;

	const invalidJpg = validateOnlyJpg && type !== jpgType && type !== jpegType;
	const invalidPng = validateOnlyPng && type !== pngType;

	if (invalidPng) {
		return 'Please upload a PNG image file.';
	}

	if (invalidJpg) {
		return 'Please upload a JPG or JPEG image file.';
	}

	if (!allowedTypes.includes(type)) {
		return 'Please upload a PNG, JPG, or JPEG image file.';
	}

	return '';
};
