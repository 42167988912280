import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getInitialPaginationResponse } from 'constants/general/pagination/getInitialPaginationResponse';
import { PaginatedPriceLists } from 'api/models/responses/priceLists/priceList';

import { IPriceListsState } from './';

const initialState: IPriceListsState = {
	isPriceListsPending: false,
	isPaginatedPriceListsPending: false,
	priceLists: getInitialPaginationResponse(),
};

const priceListsSlice = createSlice({
	name: 'priceLists',
	initialState,
	reducers: {
		setPriceLists: (state, { payload }: PayloadAction<PaginatedPriceLists>) => {
			state.priceLists = payload;
		},

		setPaginatedPriceLists: (
			state,
			{ payload }: PayloadAction<PaginatedPriceLists>
		) => {
			state.priceLists.totalCount = payload.totalCount;
			state.priceLists.results = [
				...state.priceLists.results,
				...payload.results,
			];
		},

		setIsPriceListsPending: (state, { payload }: PayloadAction<boolean>) => {
			state.isPriceListsPending = payload;
		},

		setIsPaginatedPriceListsPending: (
			state,
			{ payload }: PayloadAction<boolean>
		) => {
			state.isPaginatedPriceListsPending = payload;
		},

		deletePriceList: (state, { payload }: PayloadAction<number>) => {
			state.priceLists.results = state.priceLists.results.filter(
				(priceList) => priceList.key !== payload
			);
		},
	},
});

export const priceListsReducer = priceListsSlice.reducer;
export const {
	setPriceLists,
	deletePriceList,
	setIsPriceListsPending,
	setPaginatedPriceLists,
	setIsPaginatedPriceListsPending,
} = priceListsSlice.actions;
