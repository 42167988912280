import { CreateImagoDefaultBackgroundOption } from 'api/models/requests/priceLists/imago/priceListOptions/createDefaultBackgroundOption';
import { CreateImagoBackgroundOption } from 'api/models/requests/priceLists/imago/priceListOptions/createBackgroundOption';
import { IImagoBackgroundOption } from 'api/models/responses/priceLists/imago/priceListOptions/backgroundOptions';
import { IPatchBody } from 'api/models/requests/general/patchBody';

import { multipartHeaders } from 'constants/images/multipartHeaders';
import { priceListsUrls } from 'constants/apiUrls/priceListsUrls';

import { api } from 'config/api';

class ImagoBackgroundOptionsService {
	async getImagoBackgroundOptions(
		priceListKey: number
	): Promise<IImagoBackgroundOption[]> {
		const { data } = await api.get<IImagoBackgroundOption[]>(
			priceListsUrls.imagoFulfilled.backgroundOptions.getBackgroundOptions(
				priceListKey
			)
		);

		return data;
	}

	async createImagoBackgroundOption(
		priceListKey: number,
		{ name, sequence, backgroundImage }: CreateImagoBackgroundOption
	): Promise<IImagoBackgroundOption> {
		const formData = new FormData();

		formData.set('Name', name);
		formData.set('Sequence', sequence.toString());
		formData.set('BackgroundImage', backgroundImage);

		const { data } = await api.post<IImagoBackgroundOption>(
			priceListsUrls.imagoFulfilled.backgroundOptions.createBackgroundOption(
				priceListKey
			),
			formData,
			{ ...multipartHeaders }
		);

		return data;
	}

	async createDefaultBackgroundOption(
		priceListKey: number,
		body: CreateImagoDefaultBackgroundOption
	): Promise<IImagoBackgroundOption> {
		const { data } = await api.post<IImagoBackgroundOption>(
			priceListsUrls.imagoFulfilled.backgroundOptions.createDefaultBackgroundOption(
				priceListKey
			),
			body
		);

		return data;
	}

	async updateBackgroundOption(
		backgroundOptionKey: number,
		body: IPatchBody[]
	): Promise<IImagoBackgroundOption> {
		const { data } = await api.patch<IImagoBackgroundOption>(
			priceListsUrls.imagoFulfilled.backgroundOptions.getBackgroundOptionUrl(
				backgroundOptionKey
			),
			body
		);

		return data;
	}

	async deleteBackgroundOption(backgroundOptionKey: number): Promise<void> {
		await api.delete<string>(
			priceListsUrls.imagoFulfilled.backgroundOptions.getBackgroundOptionUrl(
				backgroundOptionKey
			)
		);
	}

	async reorderBackgroundOptions(
		priceListKey: number,
		body: Record<number, number>
	): Promise<void> {
		await api.put<string>(
			priceListsUrls.imagoFulfilled.backgroundOptions.reorderBackgroundOptions(
				priceListKey
			),
			body
		);
	}
}

export default new ImagoBackgroundOptionsService();
