import { Link } from 'react-router-dom';
import { FC } from 'react';

import { ROUTES } from 'constants/ROUTES';

interface INavigationProps {
	handleLogout: () => void;
}

export const Navigation: FC<INavigationProps> = ({ handleLogout }) => {
	return (
		<div className="aside-section">
			<div className="aside-content">
				<ul className="aside-menu">
					<li className="aside-menu-item">
						<Link to={ROUTES.ACC_USER} className="aside-menu-link">
							My Profile
						</Link>
					</li>
					<li className="aside-menu-item">
						<a
							className="aside-menu-link"
							href="https://support.hhcolorlab.com/hc/en-us/categories/26158397472532-Imago"
						>
							Help
						</a>
					</li>
					<li className="aside-menu-item">
						<button className="aside-menu-link" onClick={handleLogout}>
							Log Out
						</button>
					</li>
				</ul>
			</div>
		</div>
	);
};
