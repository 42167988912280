import { FC, useCallback, useEffect } from 'react';

import { ISubjectFormQuestionUI } from 'components/SubjectFormQuestions/types';
import { SubjectFormQuestions } from 'components/SubjectFormQuestions';
import { Loader } from 'components/Loader';

import { useSubjectFormQuestionCRUD } from 'hooks/subjectFormQuestions/useSubjectFormQuestionCRUD';
import { getSubjectFormQuestionsUI } from 'utils/subjectFormQuestions/getSubjectFormQuestionsUI';
import SubjectFormQuestionsService from 'api/services/SubjectFormQuestionsService';
import { projectsUrls } from 'constants/apiUrls/projectsUrls';
import { IHandleUpdateParams } from 'pages/Projects/types';

import { ToggleFormSectionContainerProps } from '..';

interface IFormSectionContainerProps extends ToggleFormSectionContainerProps {
	isPending: boolean;
	setIsPending: (isPending: boolean) => void;
	subjectFormQuestions: ISubjectFormQuestionUI[];
	setSubjectFormQuestions: (
		subjectFormQuestions: ISubjectFormQuestionUI[]
	) => void;
}

export const ProjectFormSection: FC<IFormSectionContainerProps> = ({
	isPending,
	projectKey,
	setIsPending,
	subjectFormQuestions,
	setSubjectFormQuestions,
}) => {
	const {
		isReordered,
		disabledFieldId,
		reorderSubjectFormQuestions,
		handleSaveSubjectFormQuestion,
		handleCreateSubjectFormQuestion,
		handleUpdateSubjectFromQuestion,
		handleDeleteSubjectFormQuestion,
		clearSubjectFormQuestionFieldError,
		handleChangeSubjectFormQuestionsSequence,
	} = useSubjectFormQuestionCRUD(subjectFormQuestions, setSubjectFormQuestions);

	const getProjectSubjectFormQuestions = useCallback(async () => {
		if (subjectFormQuestions.length || !projectKey) return;

		setIsPending(true);

		try {
			const data = await SubjectFormQuestionsService.getSubjectFormQuestions(
				projectsUrls.getProjectFormQuestions(projectKey)
			);

			const subjectFormQuestionsUI = getSubjectFormQuestionsUI(data);

			setSubjectFormQuestions(subjectFormQuestionsUI);
		} catch (error) {
			console.log(error);
		}

		setIsPending(false);
	}, [projectKey]);

	const handleSave = () => {
		if (!projectKey) return;

		void handleSaveSubjectFormQuestion(
			projectsUrls.createProjectFormQuestion(projectKey)
		);
	};

	const handleUpdate = (updateParams: IHandleUpdateParams) => {
		void handleUpdateSubjectFromQuestion(
			projectsUrls.getProjectFormQuestionUrl,
			updateParams
		);
	};

	const handleDelete = (localKey: string, subjectFormQuestionKey?: number) => {
		void handleDeleteSubjectFormQuestion(
			projectsUrls.getProjectFormQuestionUrl,
			localKey,
			subjectFormQuestionKey
		);
	};

	useEffect(() => {
		void getProjectSubjectFormQuestions();
	}, [getProjectSubjectFormQuestions]);

	useEffect(() => {
		if (!isReordered || !projectKey) return;

		void reorderSubjectFormQuestions(
			projectsUrls.reorderProjectFormQuestions(projectKey)
		);
	}, [projectKey, isReordered]);

	if (isPending) return <Loader />;

	return (
		<SubjectFormQuestions
			hideBurger
			handleSave={handleSave}
			disableMultipleCreation
			handleUpdate={handleUpdate}
			deleteFormRow={handleDelete}
			disabledFieldId={disabledFieldId}
			subjectFormQuestions={subjectFormQuestions}
			handleCreate={handleCreateSubjectFormQuestion}
			clearError={clearSubjectFormQuestionFieldError}
			changeSequence={handleChangeSubjectFormQuestionsSequence}
		/>
	);
};
