import { ChangeEvent, FC, FocusEvent, SyntheticEvent } from 'react';
import cn from 'classnames';

import { LazyTextArea } from 'components/FormControls/LazyTextArea';
import { IInputProps } from 'types/ui/inputProps';

interface ITextAreaProps
	extends Omit<IInputProps, 'handleChange' | 'value' | 'type'> {
	label?: string;
	error?: string;
	isLazy?: boolean;
	subText?: string;
	touched?: boolean;
	placeholder?: string;
	value?: string | null;
	subTextClassName?: string;
	defaultValue?: string | null;
	clearError?: (id: string) => void;
	handleFocus?: (e: FocusEvent<HTMLTextAreaElement>) => void;
	handleChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
	handleLazyChange?: (e: SyntheticEvent<HTMLTextAreaElement>) => void;
}

export const TextArea: FC<ITextAreaProps> = ({
	id,
	name,
	label,
	error,
	value,
	isLazy,
	touched,
	subText,
	required,
	disabled,
	className,
	clearError,
	placeholder,
	handleFocus,
	defaultValue,
	handleChange,
	handleLazyChange,
	subTextClassName,
}) => {
	const generalProps = {
		id,
		name,
		label,
		disabled,
		placeholder,
		className: className || 'project-textarea',
	};

	const showError = error && touched;

	return (
		<label
			className={cn('project-textarea-label', {
				'is-error': showError,
			})}
		>
			<span className="label">{label}</span>
			{!isLazy ? (
				<textarea
					{...generalProps}
					value={value || ''}
					onChange={handleChange}
				/>
			) : (
				<LazyTextArea
					{...generalProps}
					clearError={clearError}
					handleFocus={handleFocus}
					handleChange={handleLazyChange}
					defaultValue={defaultValue || ''}
				/>
			)}
			{showError && <span className="input-error">{error}</span>}
			{subText && (
				<span className={`project-textarea-info ${subTextClassName || ''}`}>
					{subText}
				</span>
			)}
		</label>
	);
};
