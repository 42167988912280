import { Column } from 'react-table';
import { FC } from 'react';

import { NoItemsFound } from 'components/NoItemsFound';
import { Loader } from 'components/Loader';

import { IBasePreset } from 'api/models/responses/presets/presets';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import {
	journeyPresetsSelector,
	isJourneyPresetsPendingSelector,
} from 'store/journeyPresets';

import { JourneyPresetsList } from './JourneyPresetsList';

export interface IJourneyPresetsListProps {
	columns: Column<IBasePreset>[];
	getPaginatedJourneyPresets: () => void;
}

export const JourneyPresetsListContainer: FC<IJourneyPresetsListProps> = ({
	columns,
	getPaginatedJourneyPresets,
}) => {
	const journeyPresets = useAppSelector(journeyPresetsSelector);
	const isJourneyPresetsPending = useAppSelector(
		isJourneyPresetsPendingSelector
	);

	const showPresetsTable =
		!isJourneyPresetsPending && !!journeyPresets.results.length;

	const showNoItemsFound =
		!isJourneyPresetsPending && !journeyPresets.results.length;

	if (isJourneyPresetsPending) {
		return <Loader />;
	}

	if (showNoItemsFound) {
		return (
			<div className="page-loader-container is-section">
				<NoItemsFound title="journey presets" />
			</div>
		);
	}

	if (showPresetsTable) {
		return (
			<JourneyPresetsList
				columns={columns}
				data={journeyPresets.results}
				getPaginatedJourneyPresets={getPaginatedJourneyPresets}
			/>
		);
	}

	return null;
};
