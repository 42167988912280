import { FC, useCallback, useEffect } from 'react';
import { CellProps, Column } from 'react-table';
import { Link } from 'react-router-dom';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';

import { Table } from 'components/Table';
import { Card } from 'components/Card';

import { INewOrder } from 'api/models/responses/orders/newOrder';
import OrderService from 'api/services/OrderService';

import { normalizeDigit } from 'utils/ui/normalizeDigit';
import { normalizeDate } from 'utils/ui/normalizeDate';

import { newOrdersSelector, setNewOrders } from 'store/dashboard';

export const NewOrders: FC = () => {
	const newOrders = useAppSelector(newOrdersSelector);

	const dispatch = useAppDispatch();

	const getNewOrders = useCallback(async () => {
		try {
			const data = await OrderService.getNewOrders();

			dispatch(setNewOrders(data));
		} catch (error) {
			console.log(error);
		}
	}, []);

	useEffect(() => {
		void getNewOrders();
	}, [getNewOrders]);

	const columns: Column<INewOrder>[] = [
		{
			Header: 'Date',
			accessor: 'orderDate',
			Cell: ({ value }: CellProps<INewOrder, INewOrder['orderDate']>) => (
				<>{normalizeDate(value)}</>
			),
		},
		{
			Header: 'Project Name',
			accessor: 'projectName',
		},
		{
			Header: 'Customer Name',
			accessor: 'customerName',
		},
		{
			Header: 'Order#',
			accessor: 'orderNumber',
			Cell: ({ value }: CellProps<INewOrder, INewOrder['orderNumber']>) => (
				// TODO: add correct route to order
				<Link to={`/${value}`} className="card-table-link" title={value}>
					{value}
				</Link>
			),
		},
		{
			Header: 'Revenue',
			accessor: 'revenue',
			Cell: ({ value }: CellProps<INewOrder, INewOrder['revenue']>) => (
				<>{normalizeDigit({ value })}</>
			),
		},
	];

	return (
		<Card
			bodyClassName="card-table"
			columnFit="col-2"
			title="New Orders"
			subtitle="(10 most recent orders) "
		>
			<Table<INewOrder> columns={columns} data={newOrders} />
		</Card>
	);
};
