import {
	maxOrderConfirmationMessageLength,
	orderConfirmationMessageErrorMessage,
} from 'constants/projects/validation/generalProjectFields';
import {
	emailRegEx,
	emailErrorMessage,
} from 'constants/general/validation/emailValidation';

export const validateOrderNotificationEmail = (
	orderNotificationEmail: string
): string => {
	if (orderNotificationEmail.length > maxOrderConfirmationMessageLength) {
		return orderConfirmationMessageErrorMessage;
	}

	if (!emailRegEx.test(orderNotificationEmail)) {
		return emailErrorMessage;
	}

	return '';
};
