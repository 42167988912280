import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PackageOptionTypes } from 'constants/priceLists/studio/packageOptionTypes';
import {
	IPackageOptionsState,
	GroupedPackageOptions,
	AddedPackageOptionTypes,
	ISetGroupedPackageOptionsPayload,
} from './types';

const initialGroupedPackageOptions: GroupedPackageOptions = {
	[PackageOptionTypes.BuildYourOwn]: [],
};

const initialAddedPackageOptionTypes: AddedPackageOptionTypes = {
	[PackageOptionTypes.BuildYourOwn]: false,
};

const initialState: IPackageOptionsState = {
	groupedPackageOptions: initialGroupedPackageOptions,
	addedPackageOptionTypes: initialAddedPackageOptionTypes,
};

const priceListPackageOptionsSlice = createSlice({
	name: 'studioPriceListPackageOptions',
	initialState,
	reducers: {
		setGroupedPackageOptions: (
			state,
			{ payload }: PayloadAction<ISetGroupedPackageOptionsPayload>
		) => {
			state.groupedPackageOptions[payload.optionType] = payload.options;
		},

		addPackageOptionType: (
			state,
			{ payload }: PayloadAction<PackageOptionTypes>
		) => {
			state.addedPackageOptionTypes[payload] = true;
		},

		deletePackageOptionsSection: (
			state,
			{ payload }: PayloadAction<PackageOptionTypes>
		) => {
			state.groupedPackageOptions[payload] = [];
			state.addedPackageOptionTypes[payload] = false;
		},

		resetAddedPackageOptionTypes: (state) => {
			state.addedPackageOptionTypes = initialAddedPackageOptionTypes;
		},
	},
});

export const priceListPackageOptionsReducer =
	priceListPackageOptionsSlice.reducer;

export const {
	addPackageOptionType,
	setGroupedPackageOptions,
	deletePackageOptionsSection,
	resetAddedPackageOptionTypes,
} = priceListPackageOptionsSlice.actions;
