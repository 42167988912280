import { ChangeEvent, FC } from 'react';

import { usePasswordInput } from 'hooks/formControls/usePasswordInput';
import { InputField } from 'components/InputField';

interface IFieldProps {
	id: string;
	label: string;
	value: string;
	error?: string;
	touched?: boolean;
	placeholder: string;
	handleValidateNumber: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const Field: FC<IFieldProps> = ({
	id,
	label,
	error,
	value,
	touched,
	placeholder,
	handleValidateNumber,
}) => {
	const { inputType, toggleValue, handleShowValue } = usePasswordInput();

	return (
		<div className="acc-toggle-section" key={id}>
			<InputField
				id={id}
				type={inputType}
				label={label}
				error={error}
				touched={touched}
				placeholder={placeholder}
				value={value}
				wrapperClassName="form-container"
				handleChange={handleValidateNumber}
			/>
			<span className="acc-toggle-btn" onClick={handleShowValue}>
				{toggleValue}
			</span>
		</div>
	);
};
