import { Link } from 'react-router-dom';
import { FC, useState } from 'react';
import moment, { utc } from 'moment';
import cn from 'classnames';

import { salesNotAvailableSelectOption } from 'constants/priceLists/selectOptions/salesNotAvailableSelectOption';
import { PriceListFulfillment } from 'constants/priceLists/priceListFulfillment';
import { IPriceListSale } from 'api/models/responses/priceLists/priceListSale';
import { ROUTES } from 'constants/ROUTES';

import { removeTags } from 'utils/general/removeHTMLTags';

interface TimelineSectionProps {
	icon: string;
	saleEnds: string;
	saleColor: string;
	saleBegins: string;
	datePreview: string;
	title: string | null;
	saleColorLine: string;
	projectGreeting: string;
	dateIsEstimate: boolean;
	handleEditSale: () => void;
	isEndDateEstimated?: boolean;
	isStartDateEstimated?: boolean;
	priceList: IPriceListSale | null;
	orderConfirmationMessage: string;
	dateIsEstimateComment: string | null;
}

export const TimelineSection: FC<TimelineSectionProps> = ({
	icon,
	title,
	saleEnds,
	priceList,
	saleColor,
	saleBegins,
	datePreview,
	saleColorLine,
	handleEditSale,
	dateIsEstimate,
	projectGreeting,
	isEndDateEstimated,
	isStartDateEstimated,
	dateIsEstimateComment,
	orderConfirmationMessage,
}) => {
	const [isSectionOpen, setSectionOpen] = useState(false);

	const handleSection = () => {
		setSectionOpen(!isSectionOpen);
	};

	const saleBeginsParsed = isStartDateEstimated
		? 'Images uploaded'
		: moment(utc(saleBegins).toDate()).format('lll');

	const saleEndsParsed = isEndDateEstimated
		? 'Images uploaded'
		: moment(utc(saleEnds).toDate()).format('lll');

	const priceListName =
		priceList?.priceListName || salesNotAvailableSelectOption.label;

	const priceListFulfillment =
		priceList?.fulfillmentType || PriceListFulfillment.NA;

	const priceListKey = priceList?.priceListKey;

	const priceListDetailsPath =
		priceListFulfillment === PriceListFulfillment.Studio
			? ROUTES.PRICE_LISTS.PRICE_LISTS_STUDIO
			: ROUTES.PRICE_LISTS.PRICE_LISTS_IMAGO;

	const PriceListName = priceListKey ? (
		<Link to={`${priceListDetailsPath}/${priceListKey}`}>{priceListName}</Link>
	) : (
		priceListName
	);

	return (
		<li className={`timeline-item ${saleColorLine}`}>
			<span
				className={cn('timeline-date', {
					'timeline-date-estimate': dateIsEstimate,
				})}
			>
				{datePreview}
			</span>
			<div
				className={cn(`timeline-road is-toggled ${saleColor}`, {
					'is-open': isSectionOpen,
				})}
			>
				<div className="timeline-road-indent">
					<i className={`timeline-indent-icon ${icon}`} />
				</div>
				<div className="timeline-roadmap">
					<span className="timeline-roadmap-header" onClick={handleSection}>
						{title}
						{dateIsEstimateComment && (
							<span className="timeline-roadmap-subtitle">
								{dateIsEstimateComment}
							</span>
						)}
					</span>
					<button className="timeline-edit" onClick={handleEditSale}>
						...
					</button>
					{isSectionOpen && (
						<>
							<div className="timeline-roadmap-col">
								<span className="timeline-roadmap-list">
									<b>Sale Begins:</b>
									{saleBeginsParsed}
								</span>
								<span className="timeline-roadmap-list">
									<b>Price List:</b>
									{PriceListName}
								</span>
								<span className="timeline-roadmap-list">
									<b>Project Greeting:</b>
								</span>
								<span className="timeline-roadmap-list timeline-roadmap-name">
									{removeTags(projectGreeting)}
								</span>
							</div>
							<div className="timeline-roadmap-col">
								<span className="timeline-roadmap-list">
									<b>Sale Ends:</b>
									{saleEndsParsed}
								</span>
								<span className="timeline-roadmap-list">
									<b>Fulfillment:</b> {priceListFulfillment}
								</span>
								<span className="timeline-roadmap-list">
									<b>Order Confirmation Message:</b>
								</span>
								<span className="timeline-roadmap-list timeline-roadmap-name">
									{orderConfirmationMessage}
								</span>
							</div>
						</>
					)}
				</div>
			</div>
		</li>
	);
};
