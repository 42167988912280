import { ISubscription } from 'api/models/responses/subscriptions/subscription';
import { subscriptionsUrls } from 'constants/apiUrls/subscriptionsUrls';
import { api } from 'config/api';

class SubscriptionsService {
	async getSubscription(): Promise<ISubscription> {
		const { data } = await api.get<ISubscription>(
			subscriptionsUrls.subscription
		);

		return data;
	}

	async getSubscriptionsList(): Promise<ISubscription[]> {
		const { data } = await api.get<ISubscription[]>(
			subscriptionsUrls.subscriptions
		);

		return data;
	}

	async setStudioSubscription(
		subscriptionLevelKey: number,
		studioKey: number
	): Promise<void> {
		await api.put<void>(subscriptionsUrls.setSubscription, {
			studioKey,
			subscriptionLevelKey,
		});
	}
}

export default new SubscriptionsService();
