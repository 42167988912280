import { IShippingOption } from 'api/models/responses/priceLists/studio/priceListOptions/shippingOption';
import { ICreateShippingOption } from 'api/models/requests/priceLists/studio/priceListOptions/createShippingOption';
import { IPatchBody } from 'api/models/requests/general/patchBody';

import { priceListsUrls } from 'constants/apiUrls/priceListsUrls';
import { api } from 'config/api';

class ShippingOptionsService {
	async createShippingOption(
		priceListKey: number,
		body: ICreateShippingOption
	): Promise<IShippingOption> {
		const { data } = await api.post<IShippingOption>(
			priceListsUrls.studioFulfilled.shippingOptions.createShippingOption(
				priceListKey
			),
			body
		);

		return data;
	}

	async updateShippingOption(
		shippingOptionKey: number,
		body: IPatchBody[]
	): Promise<IShippingOption> {
		const { data } = await api.patch<IShippingOption>(
			priceListsUrls.studioFulfilled.shippingOptions.shippingOptionUrl(
				shippingOptionKey
			),
			body
		);

		return data;
	}

	async deleteShippingOption(shippingOptionKey: number): Promise<void> {
		await api.delete<string>(
			priceListsUrls.studioFulfilled.shippingOptions.shippingOptionUrl(
				shippingOptionKey
			)
		);
	}

	async reorderShippingOptions(
		priceListKey: number,
		body: Record<number, number>
	): Promise<void> {
		await api.put<string>(
			priceListsUrls.studioFulfilled.shippingOptions.reorderShippingOptions(
				priceListKey
			),
			body
		);
	}

	async setDefaultShippingOption(
		priceListKey: number,
		shippingOptionKey: number
	): Promise<void> {
		await api.put<string>(
			priceListsUrls.studioFulfilled.shippingOptions.setDefaultShippingOption(
				priceListKey,
				shippingOptionKey
			),
			{}
		);
	}
}

export default new ShippingOptionsService();
