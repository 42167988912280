import { ISendSpecialBroadcastBody } from 'api/models/requests/broadcast/sendSpecialBroadcast';
import { ISendSpecialBroadcastInfo } from 'api/models/responses/projects/sendSpecialBroadcast';
import { ISendTestBroadcastBody } from 'api/models/requests/broadcast/sendTestBroadcast';
import { IUpdatedBroadcast } from 'api/models/responses/broadcasts/updatedBroadcast';
import { ICreateBroadcastBody } from 'api/models/requests/broadcast/createBroadcast';
import { IBroadcastInfo } from 'api/models/responses/broadcasts/broadcastInfo';
import { IBroadcast } from 'api/models/responses/broadcasts/broadcast';

import { broadcastUrls } from 'constants/apiUrls/broadcastUrls';
import { api } from 'config/api';

class BroadcastService {
	async getBroadcast(broadcastKey: number): Promise<IBroadcast> {
		const { data } = await api.get<IBroadcast>(
			broadcastUrls.getBroadcast(broadcastKey)
		);

		return data;
	}

	async createBroadcast(
		campaignKey: number,
		body: ICreateBroadcastBody
	): Promise<IUpdatedBroadcast> {
		const { data } = await api.post<IUpdatedBroadcast>(
			broadcastUrls.createBroadcast(campaignKey),
			body
		);

		return data;
	}

	async updateBroadcast(
		broadcastKey: number,
		body: ICreateBroadcastBody
	): Promise<IUpdatedBroadcast> {
		const { data } = await api.put<IUpdatedBroadcast>(
			broadcastUrls.getBroadcastUrl(broadcastKey),
			body
		);

		return data;
	}

	async deleteBroadcast(broadcastKey: number): Promise<void> {
		await api.delete(broadcastUrls.getBroadcastUrl(broadcastKey));
	}

	async sendSpecialBroadcast(
		body: ISendSpecialBroadcastBody
	): Promise<ISendSpecialBroadcastInfo> {
		const { data } = await api.put<ISendSpecialBroadcastInfo>(
			broadcastUrls.sendSpecialBroadcast,
			body
		);

		return data;
	}

	async getBroadcastInfo(projectKey: number): Promise<IBroadcastInfo> {
		const { data } = await api.get<IBroadcastInfo>(
			broadcastUrls.getBroadcastInfo(projectKey)
		);

		return data;
	}

	async sendTestBroadcast(body: ISendTestBroadcastBody): Promise<void> {
		await api.put(broadcastUrls.sendTestBroadcast, body);
	}
}

export default new BroadcastService();
