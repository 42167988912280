import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FC, useCallback, useEffect, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';

import { PromotionTypes } from 'api/models/responses/promotions/promotion';
import { UnitTypes } from 'api/models/responses/general/unitTypes';
import PromotionsService from 'api/services/PromotionsService';
import {
	IPromotionDetails,
	PromoApplyToTypes,
} from 'api/models/responses/promotions/promotionDetails';
import {
	ICreatePromotion,
	IPromoCodePayload,
} from 'api/models/requests/promotions/createPromotion';
import {
	ErrorTypes,
	IErrorResponse,
} from 'api/models/responses/general/errorResponse';

import { prepareDate } from 'utils/dates/setCurrentTimeToDate';
import { getInitialDate } from 'utils/dates/getInitialDate';
import { normalizeDigit } from 'utils/ui/normalizeDigit';

import { maxMaxUses } from 'constants/promotions/validation';
import { ROUTES } from 'constants/ROUTES';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useToggleSection } from 'hooks/useToggleSection';
import { useToastify } from 'hooks/useToastify';

import { IBreadcrumbs } from 'store/breadcrumbs/types';
import {
	setCopiedPromotionKey,
	copiedPromotionKeySelector,
} from 'store/promotions';

import { usePromotionsBreadcrumbs } from 'pages/Promotions/hooks/usePromotionsBreadcrumbs';
import { ToggleSection } from 'components/ToggleSection';

import { PromotionControls } from './components/PromotionControls';
import { PromotionForm } from './components/PromotionForm';
import { promotionValidationSchema } from './validation';

export interface IPromotionValues
	extends Omit<
		ICreatePromotion,
		| 'value'
		| 'applyTo'
		| 'maxUses'
		| 'promoCodes'
		| 'minOrderValue'
		| 'expirationDate'
	> {
	maxUses: string;
	promoCodes: string;
	priceValue: string;
	percentValue: string;
	minOrderValue: string;
	expirationDate: Date | null;
	applyTo: PromoApplyToTypes | null;
}

export const PromotionDetails: FC = () => {
	const [promotionDetails, setPromotionDetails] =
		useState<IPromotionDetails | null>(null);

	const copiedPromotionKey = useAppSelector(copiedPromotionKeySelector);

	const { showSuccessToastify } = useToastify();
	const { promotionId } = useParams();
	const { pathname } = useLocation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const isCreatePromotion = !promotionId;

	const promotionDetailsBreadcrumbs: IBreadcrumbs = {
		isActive: true,
		path: pathname,
		title: promotionDetails?.name || '',
	};

	const promotionDetailsBreadcrumbsParam = isCreatePromotion
		? null
		: promotionDetailsBreadcrumbs;

	usePromotionsBreadcrumbs(promotionDetailsBreadcrumbsParam);

	const { isOpen, handleToggle } = useToggleSection(true);

	const promotionKey = (promotionId && +promotionId) || copiedPromotionKey;

	const getPromotionDetails = useCallback(async () => {
		if (!promotionKey) return;

		try {
			const data = await PromotionsService.getPromotionDetails(promotionKey);

			setPromotionDetails(data);
		} catch (error) {
			console.log(error);
		}
	}, [promotionKey]);

	const onSubmit = async (
		{
			name,
			type,
			maxUses,
			applyTo,
			unitType,
			promoCodes,
			priceValue,
			percentValue,
			minOrderValue,
			expirationDate,
		}: IPromotionValues,
		{ setErrors }: FormikHelpers<IPromotionValues>
	) => {
		const parsedPromoCodes: IPromoCodePayload[] = promoCodes
			.split(',')
			.map((code) => ({ code }));

		if (!applyTo || !expirationDate) return;

		const expirationDateUtc = prepareDate(expirationDate);

		const isPercentUnitType = unitType === UnitTypes.Percent;

		const body: ICreatePromotion = {
			name,
			type,
			applyTo,
			unitType,
			promoCodes: parsedPromoCodes,
			minOrderValue: +minOrderValue,
			expirationDate: expirationDateUtc,
			maxUses: maxUses ? +maxUses : maxMaxUses,
			value: isPercentUnitType ? +percentValue : +priceValue,
		};

		try {
			if (isCreatePromotion) {
				await PromotionsService.createPromotion(body);

				dispatch(setCopiedPromotionKey(null));
				showSuccessToastify({ title: 'Promotion created' });
			} else {
				if (!promotionKey) return;

				await PromotionsService.updatePromotion(promotionKey, body);
				showSuccessToastify({ title: 'Promotion updated' });
			}

			navigate(ROUTES.PROMOTIONS.PROMOTIONS);
		} catch (error) {
			const { type: errorType, errors } = error as IErrorResponse;

			if (errorType === ErrorTypes.BusinessError) {
				const nameError = errors.promotion[0] || '';
				const promoCodesError = errors.promoCode[0] || '';

				setErrors({
					name: nameError,
					promoCodes: promoCodesError,
				});
			}
		}
	};

	useEffect(() => {
		void getPromotionDetails();
	}, [getPromotionDetails]);

	const initialUnitType = promotionDetails?.unitType || UnitTypes.Dollar;
	const isInitialPercentUnitType = initialUnitType === UnitTypes.Percent;

	const initialPriceValue = normalizeDigit({
		minimumFractionDigits: 2,
		value: promotionDetails?.value,
	});
	const initialPercentValue =
		isInitialPercentUnitType && promotionDetails
			? promotionDetails.value.toString()
			: '0';
	const initialMinOrderValue = normalizeDigit({
		minimumFractionDigits: 2,
		value: promotionDetails?.minOrderValue,
	});
	const initialExpirationDate = getInitialDate(
		promotionDetails?.expirationDate
	);
	const initialName = promotionDetails?.name || '';
	const initialApplyTo = promotionDetails?.applyTo || null;
	const initialMaxUses = promotionDetails?.maxUses.toString() || '';
	const initialType = promotionDetails?.type || PromotionTypes.Coupon;
	const initialPromoCodes =
		promotionDetails?.promoCodes.map((code) => code.code).join(',') || '';

	const initialValues: IPromotionValues = {
		name: initialName,
		type: initialType,
		applyTo: initialApplyTo,
		maxUses: initialMaxUses,
		unitType: initialUnitType,
		priceValue: initialPriceValue,
		promoCodes: initialPromoCodes,
		percentValue: initialPercentValue,
		minOrderValue: initialMinOrderValue,
		expirationDate: initialExpirationDate,
	};

	return (
		<div className="promotion-details-container">
			<ToggleSection
				isOpen={isOpen}
				title="Promotion Details"
				handleToggle={handleToggle}
				className="org-details-header"
			>
				<Formik
					enableReinitialize
					onSubmit={onSubmit}
					initialValues={initialValues}
					validationSchema={promotionValidationSchema}
				>
					<>
						<div className="promotion-details-wrapper">
							<PromotionForm />
						</div>
						<PromotionControls isCreatePromotion={isCreatePromotion} />
					</>
				</Formik>
			</ToggleSection>
		</div>
	);
};
