import { ChangeEvent, FC } from 'react';
import { SingleValue } from 'react-select';
import { useFormikContext } from 'formik';

import { validateExpirationDate } from 'utils/validations/general/validateExpirationDate';
import { validateStringNumber } from 'utils/validations/general/validateStringNumber';

import { paymentsSelectOptions } from 'constants/payments/paymentsSelectOptions';
import { VALID_FIELDS_LENGTH } from 'constants/auth/validation';

import { ISelectOption } from 'types/ui/select';
import { Payments } from 'types/ui/payments';
import { ButtonTypes } from 'types/ui/buttonTypes';

import { SelectComponent } from 'components/FormControls/Select';
import { InputGrid } from 'components/FormControls/InputGrid';
import { Button } from 'components/FormControls/Button';

interface ICloseModal {
	closeModal: () => void;
}

export interface IUpdateCardForm {
	cardType: Payments;
	cardNumber: string;
	expiration: string;
	verificationCode: string;
	billingZip: string;
}

export const UpdateCardDetails: FC<ICloseModal> = ({ closeModal }) => {
	const { values, errors, touched, handleChange, handleSubmit, setFieldValue } =
		useFormikContext<IUpdateCardForm>();

	const handleExpirationChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;
		const prevValue = values.expiration;

		const validatedExpirationDate = validateExpirationDate(prevValue, value);

		void setFieldValue('expiration', validatedExpirationDate);
	};

	const handleCardTypeChange = (
		option: SingleValue<ISelectOption<Payments>>
	) => {
		if (!option) return;

		void setFieldValue('cardType', option.value);
	};

	const handleCvvChange = (e: ChangeEvent<HTMLInputElement>) => {
		const isValidCvv = validateStringNumber(
			e.target.value,
			VALID_FIELDS_LENGTH.CVV
		);

		if (!isValidCvv) return;

		handleChange(e);
	};

	const handleZipChange = (e: ChangeEvent<HTMLInputElement>) => {
		const isValidZip = validateStringNumber(
			e.target.value,
			VALID_FIELDS_LENGTH.ZIP
		);

		if (!isValidZip) return;

		handleChange(e);
	};

	const handleCardNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
		const isValidCardNumber = validateStringNumber(
			e.target.value,
			VALID_FIELDS_LENGTH.CARD_NUMBER
		);

		if (!isValidCardNumber) return;

		handleChange(e);
	};

	return (
		<div className="modal-body accing-modal">
			<div className="accing-modal-form">
				<div className="accing-modal-input">
					<SelectComponent
						id="cardType"
						label="Card Type"
						value={values.cardType}
						error={errors.cardType}
						touched={!!touched.cardType}
						selectPlaceholder="Card Type"
						onChange={handleCardTypeChange}
						selectOptions={paymentsSelectOptions}
					/>
				</div>
				<div className="accing-modal-input">
					<InputGrid
						id="cardNumber"
						label="Card Number"
						value={values.cardNumber}
						placeholder="Card Number"
						touched={!!touched.cardNumber}
						error={errors.cardNumber}
						handleChange={handleCardNumberChange}
					/>
				</div>
				<InputGrid
					id="expiration"
					label="Expiration"
					placeholder="MM/YY"
					value={values.expiration}
					touched={!!touched.expiration}
					error={errors.expiration}
					handleChange={handleExpirationChange}
				/>
				<InputGrid
					label="CVV"
					type="text"
					id="verificationCode"
					placeholder="CVV Code"
					handleChange={handleCvvChange}
					value={values.verificationCode}
					touched={!!touched.verificationCode}
					error={errors.verificationCode}
				/>
				<div className="accing-modal-input">
					<InputGrid
						id="billingZip"
						label="Zipcode"
						placeholder="Zipcode"
						value={values.billingZip}
						handleChange={handleZipChange}
						touched={!!touched.billingZip}
						error={errors.billingZip}
					/>
				</div>
			</div>
			<div className="accing-modal-btns">
				<Button
					className="btn-primary"
					value="Update Card On File"
					type={ButtonTypes.SUBMIT}
					handleClick={handleSubmit}
				/>
				<Button
					className="btn-secondary"
					value="Back"
					handleClick={closeModal}
				/>
			</div>
		</div>
	);
};
