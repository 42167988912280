import { CellProps, Column } from 'react-table';
import { Link } from 'react-router-dom';
import { FC } from 'react';

import { IActivePriceListChildItems } from 'api/models/responses/priceLists/rejectDeletePriceList';
import { IProjectItem } from 'api/models/responses/projects/projectItem';
import { IPresetItem } from 'api/models/responses/presets/presetItem';

import { ColumnLabelContainer } from 'components/Table/ColumnLabelContainer';
import { Button } from 'components/FormControls/Button';
import { Table } from 'components/Table';

import { DataOrder } from 'constants/general/dataOrder';
import { ROUTES } from 'constants/ROUTES';

import { useSortParams } from 'hooks/useSortParams';

enum ItemsSortLabels {
	Name = 'Name',
}

interface IRejectDeleteProps {
	hideModal: () => void;
	activeChildItems: IActivePriceListChildItems;
}

export const RejectDeletePriceList: FC<IRejectDeleteProps> = ({
	hideModal,
	activeChildItems,
}) => {
	const projectsSortControls = useSortParams({
		defaultOrderBy: ItemsSortLabels.Name,
	});

	const presetsSortControls = useSortParams({
		defaultOrderBy: ItemsSortLabels.Name,
	});

	const sortedProjectItems = [...activeChildItems.projects].sort((a, b) => {
		const currentName = a.projectName;
		const nextName = b.projectName;

		return projectsSortControls.orderDirection === DataOrder.ASC
			? currentName.localeCompare(nextName)
			: nextName.localeCompare(currentName);
	});

	const sortedPresetItems = [...activeChildItems.presets].sort((a, b) => {
		const currentName = a.presetName;
		const nextName = b.presetName;

		return presetsSortControls.orderDirection === DataOrder.ASC
			? currentName.localeCompare(nextName)
			: nextName.localeCompare(currentName);
	});

	const projectsTableColumns: Column<IProjectItem>[] = [
		{
			Header: (
				<ColumnLabelContainer
					sortLabel={ItemsSortLabels.Name}
					label="Projects using this price list"
					orderBy={projectsSortControls.orderBy}
					setSortParams={projectsSortControls.setSortParams}
					orderDirection={projectsSortControls.orderDirection}
				/>
			),
			accessor: 'projectName',
			Cell: ({ row }: CellProps<IProjectItem>) => {
				const { projectName, projectKey } = row.original;

				const path = `${ROUTES.PROJECTS.PROJECTS}/${projectKey}`;

				return (
					<Link to={path} title={projectName} className="card-table-link">
						{projectName}
					</Link>
				);
			},
		},
	];

	const presetsTableColumns: Column<IPresetItem>[] = [
		{
			Header: (
				<ColumnLabelContainer
					sortLabel={ItemsSortLabels.Name}
					label="Presets using this price list"
					orderBy={presetsSortControls.orderBy}
					setSortParams={presetsSortControls.setSortParams}
					orderDirection={presetsSortControls.orderDirection}
				/>
			),
			accessor: 'presetName',
			Cell: ({ row }: CellProps<IPresetItem>) => {
				const { presetName, presetKey } = row.original;

				const path = `${ROUTES.PRESETS.PRESETS}/${presetKey}`;

				return (
					<Link to={path} title={presetName} className="card-table-link">
						{presetName}
					</Link>
				);
			},
		},
	];

	return (
		<div className="org-delete-modal">
			<div className="price-table modal-table-container">
				<Table columns={projectsTableColumns} data={sortedProjectItems} />
			</div>
			<div className="price-table modal-table-container">
				<Table columns={presetsTableColumns} data={sortedPresetItems} />
			</div>
			<Button
				value="OK"
				handleClick={hideModal}
				className="org-modal-btn btn-primary"
			/>
		</div>
	);
};
