import { date, number, object, string } from 'yup';

import { requiredFieldMessage } from 'constants/general/validation/generalMessages';
import { nameValidationMessage } from 'constants/organizations/validation';
import {
	validBaseNameRegex,
	numberInIntegerRangeRegex,
	alphaNumericWithoutSpacesRegex,
} from 'constants/general/validation/generalRegEx';
import {
	maxOrder,
	minOrder,
	maxMaxUses,
	minMaxUses,
	maxMaxUsesMessage,
	promoCodesMessage,
	priceValueMessage,
	minMaxUsesMessage,
	promoCodeMaxLength,
	minOrderErrorMessage,
	priceInDecimalRangeRegex,
	pricePercentValueMessage,
	duplicatePromoCodeMessage,
} from 'constants/promotions/validation';

import { UnitTypes } from 'api/models/responses/general/unitTypes';

export const promotionValidationSchema = object().shape({
	name: string()
		.required(requiredFieldMessage)
		.matches(validBaseNameRegex, nameValidationMessage)
		.min(3, nameValidationMessage)
		.max(100, nameValidationMessage),

	applyTo: string().required(requiredFieldMessage),

	maxUses: number()
		.notRequired()
		.min(minMaxUses, minMaxUsesMessage)
		.max(maxMaxUses, maxMaxUsesMessage),

	priceValue: string().when('unitType', (unitType: UnitTypes[]) =>
		unitType[0] === UnitTypes.Dollar
			? string().matches(priceInDecimalRangeRegex, priceValueMessage)
			: string().notRequired()
	),

	percentValue: string().when('unitType', (unitType: UnitTypes[]) =>
		unitType[0] === UnitTypes.Percent
			? string().matches(numberInIntegerRangeRegex, pricePercentValueMessage)
			: string().notRequired()
	),

	promoCodes: string()
		.required(requiredFieldMessage)
		.test('', '', (value, context) => {
			if (typeof value !== 'string') return true;

			const codes = value.split(',');

			const isValid = codes.every(
				(code) =>
					code.length <= promoCodeMaxLength &&
					alphaNumericWithoutSpacesRegex.test(code)
			);

			if (!isValid) {
				return context.createError({ message: promoCodesMessage });
			}

			const isDuplicatedCodes = new Set(codes).size !== codes.length;

			if (isDuplicatedCodes) {
				return context.createError({ message: duplicatePromoCodeMessage });
			}

			return true;
		}),

	minOrderValue: number()
		.min(minOrder, minOrderErrorMessage)
		.max(maxOrder, minOrderErrorMessage),

	expirationDate: date()
		.min(
			new Date(new Date().setHours(0, 0, 0, 0)),
			'Expiration date cannot be in the past'
		)
		.required(requiredFieldMessage),
});
