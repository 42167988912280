import { ICreateAlaCarteBody } from 'api/models/requests/priceLists/studio/priceListAlaCarte/createAlaCarteBody';
import { IAlaCarteFormQuestion } from 'api/models/responses/priceLists/studio/alaCarteFormQuestion';
import { IGeneralFormQuestion } from 'api/models/responses/general/generalFormQuestion';
import { IAlaCarte } from 'api/models/responses/priceLists/studio/priceListAlaCarte';
import { IPatchBody } from 'api/models/requests/general/patchBody';

import { multipartHeaders } from 'constants/images/multipartHeaders';
import { priceListsUrls } from 'constants/apiUrls/priceListsUrls';

import { api } from 'config/api';

class AlaCarteService {
	async getAlaCartes(priceListKey: number): Promise<IAlaCarte[]> {
		const { data } = await api.get<IAlaCarte[]>(
			priceListsUrls.studioFulfilled.alaCarte.getAlaCarteUrlByPriceListKey(
				priceListKey
			)
		);

		return data;
	}

	async createAlaCarte(
		priceListKey: number,
		{
			name,
			sequence,
			description,
			retailPrice,
			availableFor,
			referenceCode,
			previewImageFile,
			requiredPoseOptions,
			isWaiveShippingCharge,
			isIncludeImagesDownload,
			isBackgroundOptionRequired,
		}: ICreateAlaCarteBody
	): Promise<IAlaCarte> {
		const formData = new FormData();

		formData.set('Name', name);
		formData.set(
			'IsBackgroundOptionRequired',
			isBackgroundOptionRequired.toString()
		);
		formData.set('Description', description);
		formData.set('AvailableFor', availableFor);
		formData.set('ReferenceCode', referenceCode);
		formData.set('Sequence', sequence.toString());
		formData.set('RetailPrice', retailPrice.toString());
		formData.set('PreviewImage', previewImageFile ?? '');
		formData.set('RequiredPoseOptions', requiredPoseOptions);
		formData.set('IsWaiveShippingCharge', isWaiveShippingCharge.toString());
		formData.set('IsIncludeImagesDownload', isIncludeImagesDownload.toString());

		const { data } = await api.post<IAlaCarte>(
			priceListsUrls.studioFulfilled.alaCarte.getAlaCarteUrlByPriceListKey(
				priceListKey
			),
			formData
		);

		return data;
	}

	async updateAlaCarte(
		alaCarteKey: number,
		body: IPatchBody[]
	): Promise<IAlaCarte> {
		const { data } = await api.patch<IAlaCarte>(
			priceListsUrls.studioFulfilled.alaCarte.getAlaCarteUrlByAlaCarteKey(
				alaCarteKey
			),
			body
		);

		return data;
	}

	async deleteAlaCarte(alaCarteKey: number): Promise<void> {
		await api.delete<string>(
			priceListsUrls.studioFulfilled.alaCarte.getAlaCarteUrlByAlaCarteKey(
				alaCarteKey
			)
		);
	}

	async reorderAlaCartes(
		priceListKey: number,
		body: Record<number, number>
	): Promise<void> {
		await api.put<string>(
			priceListsUrls.studioFulfilled.alaCarte.reorderAlaCartes(priceListKey),
			body
		);
	}

	async uploadAlaCartePreviewImage(
		alaCarteKey: number,
		imageFile: File
	): Promise<string> {
		const formData = new FormData();

		formData.append('imageFile', imageFile);

		const { data } = await api.post<string>(
			priceListsUrls.studioFulfilled.alaCarte.getAlaCartePreviewImageUrl(
				alaCarteKey
			),
			formData,
			{ ...multipartHeaders }
		);

		return data;
	}

	async deleteAlaCartePreviewImage(alaCarteKey: number): Promise<void> {
		await api.delete<string>(
			priceListsUrls.studioFulfilled.alaCarte.getAlaCartePreviewImageUrl(
				alaCarteKey
			)
		);
	}

	async createAlaCarteFormQuestion(
		alaCarteKey: number,
		body: IGeneralFormQuestion
	): Promise<IAlaCarteFormQuestion> {
		const { data } = await api.post<IAlaCarteFormQuestion>(
			priceListsUrls.studioFulfilled.alaCarte.createAlaCarteFormQuestion(
				alaCarteKey
			),
			body
		);

		return data;
	}

	async updateAlaCarteFormQuestion(
		formQuestionKey: number,
		body: IPatchBody[]
	): Promise<IAlaCarteFormQuestion> {
		const { data } = await api.patch<IAlaCarteFormQuestion>(
			priceListsUrls.studioFulfilled.alaCarte.getAlaCarteFormQuestionsUrl(
				formQuestionKey
			),
			body
		);

		return data;
	}

	async deleteAlaCarteFormQuestion(formQuestionKey: number): Promise<void> {
		await api.delete<string>(
			priceListsUrls.studioFulfilled.alaCarte.getAlaCarteFormQuestionsUrl(
				formQuestionKey
			)
		);
	}

	async reorderAlaCarteFormQuestions(
		alaCarteKey: number,
		body: Record<number, number>
	): Promise<void> {
		await api.put<string>(
			priceListsUrls.studioFulfilled.alaCarte.reorderAlaCarteFormQuestions(
				alaCarteKey
			),
			body
		);
	}
}

export default new AlaCarteService();
