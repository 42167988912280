import { ToastContainer } from 'react-toastify';
import { FC } from 'react';

import { Portal } from 'components/Portal';

import 'react-toastify/dist/ReactToastify.css';

export const Notification: FC = () => {
	return (
		<Portal>
			<ToastContainer hideProgressBar />
		</Portal>
	);
};
