import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IBankAccountUpdateResponse } from 'api/models/responses/studioBilling/bankAccountUpdate';
import { IBankAccountResponse } from 'api/models/responses/studioBilling/bankAccount';
import { IBillingInfo } from 'api/models/responses/studioBilling/billingInfo';
import { ICardInfo } from 'api/models/responses/studioBilling/cardInfo';

import { IStudioBillingState } from './types';

const initialState: IStudioBillingState = {
	bankACH: null,
	cardInfo: null,
	studioBilling: null,
	bankAccUpdate: null,
	isBankAccUpdate: false,
	isBankACHFulfilled: false,
	iStudioBillingFulfilled: false,
};

const studioBillingSlice = createSlice({
	name: 'studioBilling',
	initialState,
	reducers: {
		setBankACH: (state, { payload }: PayloadAction<IBankAccountResponse>) => {
			state.bankACH = payload;
			state.isBankACHFulfilled = true;
		},

		setBankAccUpdate: (
			state,
			{ payload }: PayloadAction<IBankAccountUpdateResponse>
		) => {
			state.bankAccUpdate = payload;
			state.isBankAccUpdate = true;
		},

		setStudioBilling: (state, { payload }: PayloadAction<IBillingInfo>) => {
			state.studioBilling = payload;
			state.iStudioBillingFulfilled = true;
		},

		setCardInfo: (state, { payload }: PayloadAction<ICardInfo>) => {
			state.cardInfo = payload;
		},
	},
});

export const studioBillingReducer = studioBillingSlice.reducer;
export const { setBankACH, setCardInfo, setBankAccUpdate, setStudioBilling } =
	studioBillingSlice.actions;
