export enum ErrorTypes {
	NotFound = 'NotFound',
	Exception = 'Exception',
	BusinessError = 'BusinessError',
	ForbiddenError = 'ForbiddenError',
	ValidationError = 'ValidationError',
	IntegrationError = 'IntegrationError',
}

export interface IErrorResponse {
	type: ErrorTypes;
	traceId?: string;
	errors: Record<string, string[]>;
}
