import { FC, useCallback, useEffect, useState } from 'react';

import { IGetImageUploadSasUrl } from 'api/models/requests/albums/imageUploadSasUrl';
import AlbumService from 'api/services/AlbumService';

import { getPhotoBlobUrl } from 'utils/photos/getPhotoBlobUrl';
import { fileToBinary } from 'utils/general/fileToBinary';

import { uploadPhotosBlobUrl } from 'constants/images/blobUrls';
import { Button } from 'components/FormControls/Button';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import {
	watermarkTypeSelector,
	customWatermarkImageGuidByTypeSelector,
	defaultWatermarkImageGuidByTypeSelector,
} from 'store/studio';

import { IUploadImagesProgressParams } from '../../types';

interface IUploadImagesProgressProps {
	images: File[];
	resetSearchPhotosParams: () => void;
	hideUploadImagesProgressModal: () => void;
	setUpdateAlbums: (update: boolean) => void;
	setUpdatePhotos: (update: boolean) => void;
	uploadImagesProgressParams: IUploadImagesProgressParams | null;
}

export const UploadImagesProgress: FC<IUploadImagesProgressProps> = ({
	images,
	setUpdateAlbums,
	setUpdatePhotos,
	resetSearchPhotosParams,
	uploadImagesProgressParams,
	hideUploadImagesProgressModal,
}) => {
	const [uploadedImagesCount, setUploadedImagesCount] = useState(0);
	const [fileNames, setFileNames] = useState<string[]>([]);
	const [isPending, setIsPending] = useState(false);

	const watermarkType = useAppSelector(watermarkTypeSelector);
	const customWatermarkImageGuidByType = useAppSelector(
		customWatermarkImageGuidByTypeSelector
	);
	const defaultWatermarkImageGuidByType = useAppSelector(
		defaultWatermarkImageGuidByTypeSelector
	);

	const handleUploadImage = useCallback(async () => {
		if (!uploadImagesProgressParams) return;

		setIsPending(true);

		const promises = images.map(async (imageFile) => {
			try {
				const watermarkImageId =
					customWatermarkImageGuidByType[watermarkType] ||
					defaultWatermarkImageGuidByType[watermarkType];

				const body: IGetImageUploadSasUrl = {
					...uploadImagesProgressParams,
					watermarkImageId,
					hiResDownload: false,
					autoThumbnails: true,
					colorCorrectLevel: false,
					watermarkMethod: watermarkType,
					hasTransparentAlphaLayer: false,
					originalFileName: imageFile.name,
				};

				const data = await AlbumService.getImageUploadSasUrl(body);

				if (!data.Success) {
					setFileNames([...fileNames, imageFile.name]);
					return;
				}

				const blobUrl = getPhotoBlobUrl(uploadPhotosBlobUrl, data.Message);

				const binary = await fileToBinary(imageFile);

				if (!binary) return;

				await AlbumService.uploadImageToSasUrl(blobUrl, imageFile.type, binary);

				setUploadedImagesCount((prev) => prev + 1);
				return;
			} catch (error) {
				console.log(error);

				setFileNames([...fileNames, imageFile.name]);
				return;
			}
		});

		await Promise.all(promises);

		setIsPending(false);
	}, []);

	useEffect(
		() => () => {
			setUpdatePhotos(true);
			setUpdateAlbums(true);
			resetSearchPhotosParams();
		},
		[]
	);

	useEffect(() => {
		void handleUploadImage();
	}, [handleUploadImage]);

	const uploadProgress = (uploadedImagesCount / images.length) * 100;

	return (
		<>
			<span className="modal-upload">
				<b>{uploadedImagesCount}</b>
				of
				<b>{images.length}</b>
				images uploaded <span>(may take a few minutes)</span>
			</span>
			<div className="modal-upload-progress">
				<div
					className="modal-upload-bar"
					style={{ width: `${uploadProgress}%` }}
				/>
			</div>
			<Button
				value="OK"
				disabled={isPending}
				className="btn-primary org-modal-btn"
				handleClick={hideUploadImagesProgressModal}
			/>
			{!!fileNames.length && (
				<Button
					value="View Report"
					disabled={isPending}
					className="btn-primary org-modal-btn"
					handleClick={() => {
						hideUploadImagesProgressModal();
					}}
				/>
			)}
		</>
	);
};
