import { IGetImageUploadSasUrl } from 'api/models/requests/albums/imageUploadSasUrl';
import { ICreateAlbumBody } from 'api/models/requests/albums/createAlbum';
import { IImageUpload } from 'api/models/responses/albums/imageUpload';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import { IAlbum } from 'api/models/responses/albums/album';

import { multipartHeaders } from 'constants/images/multipartHeaders';
import { getBlobHeaders } from 'constants/images/getBlobHeaders';
import { albumsUrls } from 'constants/apiUrls/albumsUrls';

import { api } from 'config/api';

class AlbumService {
	async getAlbums(projectKey: number): Promise<IAlbum[]> {
		const { data } = await api.get<IAlbum[]>(albumsUrls.getAlbums(projectKey));

		return data;
	}

	async updateAlbum(
		albumKey: string,
		projectKey: number,
		body: IPatchBody[]
	): Promise<IAlbum> {
		const { data } = await api.patch<IAlbum>(
			albumsUrls.getAlbumUrl(albumKey, projectKey),
			body
		);

		return data;
	}

	async getAlbum(albumKey: string, projectKey: number): Promise<IAlbum> {
		const { data } = await api.get<IAlbum>(
			albumsUrls.getAlbumUrl(albumKey, projectKey)
		);

		return data;
	}

	async createAlbum(body: ICreateAlbumBody): Promise<IAlbum> {
		const { data } = await api.post<IAlbum>(albumsUrls.createAlbum, body);

		return data;
	}

	async deleteAlbum(albumKey: string, projectKey: number): Promise<void> {
		await api.delete<string>(albumsUrls.getAlbumUrl(albumKey, projectKey));
	}

	async getImageUploadSasUrl({
		albumGuid,
		studioKey,
		projectKey,
		hiResDownload,
		autoThumbnails,
		watermarkMethod,
		watermarkImageId,
		originalFileName,
		colorCorrectLevel,
		hasTransparentAlphaLayer,
	}: IGetImageUploadSasUrl): Promise<IImageUpload> {
		const formData = new FormData();

		formData.set('AlbumGuid', albumGuid);
		formData.set('EventKey', projectKey.toString());
		formData.set('WatermarkMethod', watermarkMethod);
		formData.set('WatermarkImageId', watermarkImageId);
		formData.set('OriginalFileName', originalFileName);
		formData.set('PhotographerKey', studioKey.toString());
		formData.set('HiResDownload', hiResDownload.toString());
		formData.set('AutoThumbnails', autoThumbnails.toString());
		formData.set('ColorCorrectLevel', colorCorrectLevel.toString());
		formData.set(
			'HasTransparentAlphaLaver',
			hasTransparentAlphaLayer.toString()
		);

		const { data } = await api.post<IImageUpload>(
			albumsUrls.getImageUploadSasUrl,
			formData,
			{
				...multipartHeaders,
			}
		);

		return data;
	}

	async uploadImageToSasUrl(
		url: string,
		contentType: string,
		arrayBuffer: ArrayBuffer | string
	): Promise<void> {
		await api.put(url, arrayBuffer, {
			...getBlobHeaders(contentType),
		});
	}
}

export default new AlbumService();
