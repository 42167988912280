export const allowedKeyWords = [
	'ProjectCoverImage',
	'PictureDay',
	'ProjectLink',
	'ProjectMessage',
	'ProjectName',
	'ProjectURL',
	'SaleEndDate',
	'GalleryPreview',
	'OrganizationName',
	'OrganizationURL',
	'AccessCode',
	'SingleAccessCode',
	'StudioContactInfo',
	'StudioName',
	'StudioLogo',
	'NotificationCreationDate',
	'UsingPasscode',
];
