import { FC } from 'react';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { studioSelector } from 'store/studio';

interface IHeaderControlProps {
	showAside: () => void;
}

export const HeaderControl: FC<IHeaderControlProps> = ({ showAside }) => {
	const studio = useAppSelector(studioSelector);

	return (
		<ul className="header-control" onClick={showAside}>
			<li className="header-user"></li>
			<li className="header-search"></li>
			<li className="header-profile">
				<div className="header-profile-thumb">
					<img
						className="header-profile-image"
						src="/image/ShawnCantrell.jpeg"
						alt="user"
					/>
				</div>
				<span className="header-profile-name">{studio?.studioName}</span>
			</li>
		</ul>
	);
};
