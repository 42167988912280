import { ICreateStudioBody } from 'api/models/requests/studio/createStudio';
import { IStudio } from 'api/models/responses/studio/studio';

import { studioUrls } from 'constants/apiUrls/studioUrls';
import { api } from 'config/api';

class StudioService {
	async createStudio(createStudioBody: ICreateStudioBody): Promise<IStudio> {
		const { data } = await api.post<IStudio>(
			studioUrls.studio,
			createStudioBody
		);

		return data;
	}

	async getStudio(): Promise<IStudio> {
		const { data } = await api.get<IStudio>(studioUrls.studio);

		return data;
	}

	async getAllStudios(): Promise<IStudio[]> {
		const { data } = await api.get<IStudio[]>(studioUrls.allStudiosForUser);

		return data;
	}

	async switchStudio(studioKey: number): Promise<IStudio> {
		const { data } = await api.put<IStudio>(studioUrls.switchStudio(studioKey));

		return data;
	}
}

export default new StudioService();
