import { useNavigate } from 'react-router-dom';
import { FC } from 'react';

import { setShowUpgradePlanModal } from 'store/subscriptions';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';

import { Button } from 'components/FormControls/Button';
import { ROUTES } from 'constants/ROUTES';

interface IUpgradePlanProps {
	hideModal: () => void;
}

export const UpgradePlan: FC<IUpgradePlanProps> = ({ hideModal }) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const handleChooseYourPlan = () => {
		dispatch(setShowUpgradePlanModal(true));

		navigate(ROUTES.ACCOUNTING);
	};

	return (
		<div className="modal-body campaign-modal-update">
			<Button
				value="Cancel"
				handleClick={hideModal}
				className="btn-secondary"
			/>
			<Button
				className="btn-primary"
				value="Choose Your Plan"
				handleClick={handleChooseYourPlan}
			/>
		</div>
	);
};
