import { FC } from 'react';

import { Button } from 'components/FormControls/Button';
import { Input } from 'components/FormControls/Input';

export const FindOrder: FC = () => {
	return (
		<div className="aside-section aside-mob-section">
			<h3 className="aside-section-title">FIND AN ORDER</h3>
			<div className="aside-content">
				<Input
					value=""
					type="text"
					id="order"
					placeholder="Participant Name"
					handleChange={() => {}}
				/>
				<span className="aside-menu-link">Find by other info</span>
				<Button className="btn-secondary" value="Search" />
			</div>
		</div>
	);
};
