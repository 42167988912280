import { Formik, FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';
import { FC } from 'react';

import PriceListsStudioService from 'api/services/PriceListsService/studio/PriceListsStudioService';
import PriceListsImagoService from 'api/services/PriceListsService/imago/PriceListsImagoService';
import {
	ErrorTypes,
	IErrorResponse,
} from 'api/models/responses/general/errorResponse';

import { PriceListFulfillment } from 'constants/priceLists/priceListFulfillment';
import { ROUTES } from 'constants/ROUTES';

import { createPriceListValidationScheme } from './NewPriceListForm/validation';
import { NewPriceListForm } from './NewPriceListForm';
import { ILimitErrorMessage } from '../..';

export interface ICreatePriceListValues {
	name: string;
	fulfillment: PriceListFulfillment | null;
}

export interface ICreatePriceListProps {
	isCopy?: boolean;
	hideCopyModal?: () => void;
	hideCreateModal?: () => void;
	priceListKey?: number | null;
	showSuccessCopyModal?: () => void;
	sortPriceListsAfterCopy?: () => void;
	initialFulfillment?: PriceListFulfillment | null;
	setPriceListName?: (priceListName: string) => void;
	setLimitErrorMessage: (payload: ILimitErrorMessage) => void;
}

export const CreateNewPriceList: FC<ICreatePriceListProps> = ({
	isCopy,
	priceListKey,
	hideCopyModal,
	hideCreateModal,
	setPriceListName,
	initialFulfillment,
	showSuccessCopyModal,
	setLimitErrorMessage,
	sortPriceListsAfterCopy,
}) => {
	const navigate = useNavigate();

	const initialValues: ICreatePriceListValues = {
		name: '',
		fulfillment: initialFulfillment ?? null,
	};

	const onCopySubmit = async (
		values: ICreatePriceListValues,
		{ setErrors }: FormikHelpers<ICreatePriceListValues>
	) => {
		if (
			!priceListKey ||
			!hideCopyModal ||
			!setPriceListName ||
			!showSuccessCopyModal ||
			!sortPriceListsAfterCopy
		)
			return;

		try {
			const { name, fulfillment } = values;

			if (fulfillment === PriceListFulfillment.Studio) {
				await PriceListsStudioService.copyPriceListStudio(priceListKey, name);
			}

			if (fulfillment === PriceListFulfillment.Imago) {
				await PriceListsImagoService.copyPriceListImago(priceListKey, name);
			}

			setPriceListName(name);
			sortPriceListsAfterCopy();

			hideCopyModal();
			showSuccessCopyModal();
		} catch (error) {
			const { type, errors } = error as IErrorResponse;

			if (type === ErrorTypes.BusinessError) {
				const limitErrorMessage = errors.maxPriceListCount;
				const nameError = errors.name;

				if (nameError) {
					setErrors({ ...errors, name: nameError[0] });
				}

				if (limitErrorMessage && hideCopyModal) {
					setLimitErrorMessage({ message: limitErrorMessage[0] });
					hideCopyModal();
				}
			}
		}
	};

	const onCreateSubmit = async (
		values: ICreatePriceListValues,
		{ setErrors }: FormikHelpers<ICreatePriceListValues>
	) => {
		try {
			const { name, fulfillment } = values;

			if (fulfillment === PriceListFulfillment.Studio) {
				const data = await PriceListsStudioService.createPriceListStudio({
					name,
				});

				navigate(
					`${ROUTES.PRICE_LISTS.PRICE_LISTS_STUDIO}/${data.priceListStudioFulfilledKey}`
				);
			}

			if (fulfillment === PriceListFulfillment.Imago) {
				const data = await PriceListsImagoService.createPriceListImago({
					name,
				});

				navigate(
					`${ROUTES.PRICE_LISTS.PRICE_LISTS_IMAGO}/${data.priceListImagoFulfilledKey}`
				);
			}
		} catch (error) {
			const { type, errors } = error as IErrorResponse;

			if (type === ErrorTypes.BusinessError) {
				const limitErrorMessage = errors.maxPriceListCount;
				const nameError = errors.name;

				if (nameError) {
					setErrors({ ...errors, name: nameError[0] });
				}

				if (limitErrorMessage && hideCreateModal) {
					setLimitErrorMessage({ message: limitErrorMessage[0] });
					hideCreateModal();
				}
			}
		}
	};

	const onSubmit = isCopy ? onCopySubmit : onCreateSubmit;

	return (
		<div className="modal-body">
			<Formik
				onSubmit={onSubmit}
				initialValues={initialValues}
				validationSchema={createPriceListValidationScheme}
			>
				<NewPriceListForm isCopy={isCopy} />
			</Formik>
		</div>
	);
};
