import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ISubscription } from 'api/models/responses/subscriptions/subscription';

import { ISubscriptionsState } from './types';

const initialState: ISubscriptionsState = {
	subscriptions: [],
	subscription: null,
	subscriptionLevelKey: 0,
	activeSubscriptionKey: 0,
	showUpgradePlanModal: false,
};

const subscriptionsSlice = createSlice({
	name: 'subscriptions',
	initialState,
	reducers: {
		setSubscription: (state, { payload }: PayloadAction<ISubscription>) => {
			state.subscription = payload;
			state.subscriptionLevelKey = payload.subscriptionLevelKey;
		},

		setSubscriptions: (state, { payload }: PayloadAction<ISubscription[]>) => {
			state.subscriptions = payload;
		},

		setSubscriptionLevelKey: (state, { payload }: PayloadAction<number>) => {
			state.subscriptionLevelKey = payload;
		},

		setActiveSubscriptionKey: (state, { payload }: PayloadAction<number>) => {
			state.activeSubscriptionKey = payload;
		},

		setShowUpgradePlanModal: (state, { payload }: PayloadAction<boolean>) => {
			state.showUpgradePlanModal = payload;
		},

		clearSelectedSubscriptionLevelKey: (state) => {
			state.subscriptionLevelKey = 0;
		},
	},
});

export const subscriptionsReducer = subscriptionsSlice.reducer;
export const {
	setSubscription,
	setSubscriptions,
	setShowUpgradePlanModal,
	setSubscriptionLevelKey,
	setActiveSubscriptionKey,
	clearSelectedSubscriptionLevelKey,
} = subscriptionsSlice.actions;
