import { FC, useCallback, useEffect, useState } from 'react';
import { SingleValue } from 'react-select';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';

import { dateRangeSelectOptions } from 'constants/dashboard/dateRangeSelectOptions';
import { getIntervalBoundariesByTerm } from 'utils/general/getIntervalBoundariesByTerm';
import { SelectComponent } from 'components/FormControls/Select';
import DashboardService from 'api/services/DashboardService';
import { Days, ISelectOption } from 'types/ui/select';
import {
	setDate,
	setStudioStatistic,
	selectedDateRangeSelector,
} from 'store/dashboard';

export const DateRangeSelect: FC = () => {
	const [selectedTerm, setSelectedTerm] = useState<string>(
		dateRangeSelectOptions[1].value
	);

	const selectedDateRange = useAppSelector(selectedDateRangeSelector);

	const dispatch = useAppDispatch();

	const handleChange = (value: SingleValue<ISelectOption<Days>>) => {
		if (!value) return;

		setSelectedTerm(value.value as string);
	};

	const getStudioStatistic = useCallback(async () => {
		if (!selectedDateRange) return;

		try {
			const data = await DashboardService.getStudioStatistic(
				selectedDateRange.dateFrom,
				selectedDateRange.dateTo
			);

			dispatch(setStudioStatistic(data));
		} catch (error) {
			console.log(error);
		}
	}, [selectedDateRange]);

	useEffect(() => {
		void getStudioStatistic();
	}, [getStudioStatistic]);

	useEffect(() => {
		const {
			dateTo,
			dateFrom,
			endDayIndex,
			endMonthName,
			startDayIndex,
			startMonthName,
		} = getIntervalBoundariesByTerm(selectedTerm);

		const dateParam = {
			dateTo,
			dateFrom,
			endDayIndex,
			endMonthName,
			startDayIndex,
			startMonthName,
			term: selectedTerm,
		};

		dispatch(setDate(dateParam));
	}, [selectedTerm]);

	return (
		<div className="data-control">
			<label className="data-control-label">Stats overview for</label>
			<SelectComponent
				id="dateRange"
				value={selectedTerm}
				onChange={handleChange}
				selectOptions={dateRangeSelectOptions}
			/>
		</div>
	);
};
