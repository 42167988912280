import { IGeneralPriceListOption } from '../../generalPriceListOption';

export enum StudioPoseOptionTypes {
	None = 'None',
	Pose = 'Pose',
	Style = 'Style',
	ColorFlavor = 'ColorFlavor',
	Backgrounds = 'Backgrounds',
}

export interface IPoseOption extends IGeneralPriceListOption {
	retailPrice: number;
	referenceCode: string;
	previewImageUrl: string | null;
	priceListStudioFulfilledKey: number;
	poseOptionType: StudioPoseOptionTypes;
	priceListStudioFulfilledPoseOptionKey: number;
}
