import { FC } from 'react';

import { useAppSelector } from 'hooks/redux/useAppSelector';

import { Button } from 'components/FormControls/Button';
import { isUploadImagesPendingSelector } from 'store/imageGallery';

interface IUploadProgressProps {
	filesCount: number;
	uploadedImagesCount: number;
	hideUploadImagesProgressModal: () => void;
}

export const UploadProgress: FC<IUploadProgressProps> = ({
	filesCount,
	uploadedImagesCount,
	hideUploadImagesProgressModal,
}) => {
	const isUploadImagesPending = useAppSelector(isUploadImagesPendingSelector);

	const uploadProgress = (uploadedImagesCount / filesCount) * 100;

	return (
		<>
			<span className="modal-upload">
				<b>{uploadedImagesCount}</b>
				of
				<b>{filesCount}</b>
				images uploaded <span>(may take a few minutes)</span>
			</span>
			<div className="modal-upload-progress">
				<div
					className="modal-upload-bar"
					style={{ width: `${uploadProgress}%` }}
				/>
			</div>
			<Button
				value="OK"
				disabled={isUploadImagesPending}
				className="btn-primary org-modal-btn"
				handleClick={hideUploadImagesProgressModal}
			/>
		</>
	);
};
