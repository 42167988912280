import { FC } from 'react';

import { OptionsTitle } from 'pages/PriceLists/components/OptionsTitle';
import {
	PriceTable,
	IHeaderConfig,
} from 'pages/PriceLists/components/PriceTable';

import { useToggleSection } from 'hooks/useToggleSection';
import { ToggleSection } from 'components/ToggleSection';

import { OrderOption } from './OrderOption';
import { IPriceOptionsProps } from '../..';

const deliveryOptionsHeaders: IHeaderConfig[] = [
	{ name: 'Name', required: true, left: true },
	{ name: 'Price', required: true, left: true },
];

export type OrderOptionsProps = Pick<
	IPriceOptionsProps,
	'updatePriceListImago' | 'isFreeShippingEnabled' | 'freeShippingOnOrdersAbove'
>;

export const OrderOptions: FC<OrderOptionsProps> = ({
	updatePriceListImago,
	isFreeShippingEnabled,
	freeShippingOnOrdersAbove,
}) => {
	const { isOpen, handleToggle } = useToggleSection(true);

	return (
		<ToggleSection
			isOpen={isOpen}
			title="Order Options"
			className="price-search-container price-sub-search"
			handleToggle={handleToggle}
		>
			<div className="price-container">
				<OptionsTitle title="Delivery Options" multi={false} />
				<PriceTable
					headers={deliveryOptionsHeaders}
					className="price-table-delivery"
				>
					<OrderOption
						updatePriceListImago={updatePriceListImago}
						isFreeShippingEnabled={isFreeShippingEnabled}
						freeShippingOnOrdersAbove={freeShippingOnOrdersAbove}
					/>
				</PriceTable>
			</div>
		</ToggleSection>
	);
};
