import { FC, useCallback, useEffect, useState } from 'react';

import { ControlBtnColumn } from 'pages/PriceLists/components/PriceListFormQuestions/PriceListFormQuestion/components/ControlBtnColumn';
import { PriceListFormQuestion } from 'pages/PriceLists/components/PriceListFormQuestions/PriceListFormQuestion';
import {
	IPriceListFormQuestionUI,
	CreatePriceListFormQuestion,
	SetPriceListFormQuestionsUI,
	UpdatePriceListFormQuestion,
	DeletePriceListFormQuestion,
	ReorderPriceListFormQuestions,
	IClearFormQuestionErrorPayload,
	IUpdatePriceListFormQuestionPayload,
	IDeletePriceListFormQuestionPayload,
} from 'pages/PriceLists/types/studio/priceListFormQuestionUI';
import {
	PriceTable,
	IHeaderConfig,
} from 'pages/PriceLists/components/PriceTable';

import { validateForUniquenessPriceFormQuestions } from 'utils/subjectFormQuestions/validateForUniquenessSubjectFormQuestions';
import { isValidPriceFormQuestions } from 'utils/subjectFormQuestions/isValidSubjectFormQuestions';
import { changeEntitiesSequence } from 'utils/dragAndDrop/changeEntitiesSequence';
import {
	validateFormQuestion,
	validateDataFieldType,
} from 'utils/validations/general/formQuestionsValidation';

import { IGeneralFormQuestion } from 'api/models/responses/general/generalFormQuestion';
import { IErrorResponse } from 'api/models/responses/general/errorResponse';
import { IPatchBody } from 'api/models/requests/general/patchBody';

import { initialPriceListFormQuestionErrors } from 'constants/priceLists/studio/priceListPackages/initialPackageFormQuestionErrors';
import { IChangeSequencePayload } from 'hooks/useDragAndDrop';

const headers: IHeaderConfig[] = [
	{
		name: 'Question',
		required: true,
		left: true,
	},
	{
		name: 'Data Type',
		required: true,
		left: true,
	},
	{
		name: 'Response options',
		left: true,
	},
];

interface IPriceListFormQuestionsProps {
	entityKey?: number;
	formQuestionsUI: IPriceListFormQuestionUI[];
	createFormQuestion: CreatePriceListFormQuestion;
	setFormQuestionsUI: SetPriceListFormQuestionsUI;
	updateFormQuestion: UpdatePriceListFormQuestion;
	deleteFormQuestion: DeletePriceListFormQuestion;
	reorderFormQuestions: ReorderPriceListFormQuestions;
}

export const PriceListFormQuestions: FC<IPriceListFormQuestionsProps> = ({
	entityKey,
	formQuestionsUI,
	updateFormQuestion,
	setFormQuestionsUI,
	createFormQuestion,
	deleteFormQuestion,
	reorderFormQuestions,
}) => {
	const [isReorder, setIsReorder] = useState(false);

	const lastFormQuestionUI = formQuestionsUI[formQuestionsUI.length - 1];

	const handleAddFormQuestion = () => {
		if (!entityKey) return;

		const newFormQuestionUI: IPriceListFormQuestionUI = {
			entityKey,
			formQuestion: '',
			dataFieldType: null,
			isAnswerRequired: false,
			sequence: formQuestionsUI.length,
			errors: initialPriceListFormQuestionErrors,
		};

		setFormQuestionsUI(entityKey, [...formQuestionsUI, newFormQuestionUI]);
	};

	const handleSaveFormQuestion = async () => {
		if (!entityKey) return;

		const { sequence, formQuestion, dataFieldType, isAnswerRequired } =
			lastFormQuestionUI;

		const slicedFormQuestionsUI = formQuestionsUI.slice(0, -1);

		const formQuestionValidationMessage = validateFormQuestion(formQuestion);
		const dataFieldTypeValidationMessage = validateDataFieldType(dataFieldType);

		const processedFormQuestionUI: IPriceListFormQuestionUI = {
			...lastFormQuestionUI,
			errors: {
				formQuestion: formQuestionValidationMessage,
				dataFieldType: dataFieldTypeValidationMessage,
			},
		};

		const processedFormQuestionsUI = [
			...slicedFormQuestionsUI,
			processedFormQuestionUI,
		];

		const isValid =
			!formQuestionValidationMessage && !dataFieldTypeValidationMessage;

		if (!isValid) {
			setFormQuestionsUI(entityKey, processedFormQuestionsUI);
			return;
		}

		const validatedFormQuestionsUI = validateForUniquenessPriceFormQuestions(
			processedFormQuestionsUI
		);

		const isUniqFormQuestions = isValidPriceFormQuestions(
			validatedFormQuestionsUI
		);

		if (!isUniqFormQuestions) {
			setFormQuestionsUI(entityKey, validatedFormQuestionsUI);
			return;
		}

		if (!dataFieldType) return;

		try {
			const body: IGeneralFormQuestion = {
				sequence,
				formQuestion,
				dataFieldType,
				isAnswerRequired,
			};

			const data = await createFormQuestion(entityKey, body);

			if (!data) return;

			const updatedFormQuestionUI: IPriceListFormQuestionUI = {
				...data,
				errors: initialPriceListFormQuestionErrors,
			};

			setFormQuestionsUI(entityKey, [
				...slicedFormQuestionsUI,
				updatedFormQuestionUI,
			]);
		} catch (error) {
			const { errors } = error as IErrorResponse;

			const updatedFormQuestionUI: IPriceListFormQuestionUI = {
				...lastFormQuestionUI,
				errors: {
					...initialPriceListFormQuestionErrors,
					...errors,
				},
			};

			setFormQuestionsUI(entityKey, [
				...slicedFormQuestionsUI,
				updatedFormQuestionUI,
			]);
		}
	};

	const updateFormQuestionUI = async ({
		value,
		fieldKey,
		sequence,
		formQuestionKey,
		validationMessage,
	}: IUpdatePriceListFormQuestionPayload) => {
		if (!entityKey) return;

		const processedFormQuestionsUI = formQuestionsUI.map((formQuestionUI) =>
			formQuestionUI.sequence === sequence
				? {
						...formQuestionUI,
						[fieldKey]: value,
						errors: {
							...formQuestionUI.errors,
							[fieldKey]: validationMessage,
						},
				  }
				: formQuestionUI
		);

		if (validationMessage) {
			setFormQuestionsUI(entityKey, processedFormQuestionsUI);
		}

		const validatedFormQuestionsUI = validateForUniquenessPriceFormQuestions(
			processedFormQuestionsUI
		);

		const isValid = isValidPriceFormQuestions(validatedFormQuestionsUI);

		if (!isValid || !formQuestionKey) {
			setFormQuestionsUI(entityKey, validatedFormQuestionsUI);
			return;
		}

		try {
			const updatedField: IPatchBody = {
				value,
				op: 'replace',
				path: fieldKey,
			};

			const body: IPatchBody[] = [updatedField];

			const data = await updateFormQuestion(formQuestionKey, body);

			if (!data) return;

			const updatedFormQuestionsUI = validatedFormQuestionsUI.map(
				(formQuestionUI) =>
					formQuestionUI.formQuestionKey === formQuestionKey
						? data
						: formQuestionUI
			);

			setFormQuestionsUI(entityKey, updatedFormQuestionsUI);
		} catch (error) {
			const { errors } = error as IErrorResponse;

			const updatedFormQuestionsUI = formQuestionsUI.map((formQuestionUI) =>
				formQuestionUI.sequence === sequence
					? {
							...formQuestionUI,
							errors: {
								...initialPriceListFormQuestionErrors,
								...errors,
							},
					  }
					: formQuestionUI
			);

			setFormQuestionsUI(entityKey, updatedFormQuestionsUI);
		}
	};

	const deleteFormQuestionUI = async ({
		sequence,
		formQuestionKey,
	}: IDeletePriceListFormQuestionPayload) => {
		if (!entityKey) return;

		const updatedFormQuestionsUI = formQuestionsUI.filter(
			(fromQuestionUI) => fromQuestionUI.sequence !== sequence
		);

		const validatedFormQuestionsUI = validateForUniquenessPriceFormQuestions(
			updatedFormQuestionsUI
		);

		setFormQuestionsUI(entityKey, validatedFormQuestionsUI);

		if (formQuestionKey) {
			try {
				await deleteFormQuestion(formQuestionKey);
			} catch (error) {
				console.log(error);
			}
		}
	};

	const clearFormQuestionError = ({
		fieldKey,
		sequence,
	}: IClearFormQuestionErrorPayload) => {
		if (!entityKey) return;

		const updatedFormQuestionsUI = formQuestionsUI.map((formQuestionUI) =>
			formQuestionUI.sequence === sequence
				? {
						...formQuestionUI,
						errors: {
							...formQuestionUI.errors,
							[fieldKey]: '',
						},
				  }
				: formQuestionUI
		);

		setFormQuestionsUI(entityKey, updatedFormQuestionsUI);
	};

	const changeFormQuestionsSequence = (payload: IChangeSequencePayload) => {
		if (!entityKey) return;

		const reorderedFormQuestionsUI = changeEntitiesSequence({
			...payload,
			entities: formQuestionsUI,
			fieldKey: 'formQuestionKey',
		});

		setFormQuestionsUI(entityKey, reorderedFormQuestionsUI);
		setIsReorder(true);
	};

	const reorderFormQuestionsUI = useCallback(async () => {
		if (!entityKey || !isReorder) return;

		const body = formQuestionsUI.reduce<Record<number, number>>(
			(acc, { formQuestionKey, sequence }) =>
				formQuestionKey
					? {
							...acc,
							[formQuestionKey]: sequence,
					  }
					: acc,
			{}
		);

		try {
			await reorderFormQuestions(entityKey, body);
		} catch (error) {
			console.log(error);
		}
	}, [isReorder, entityKey]);

	useEffect(() => {
		void reorderFormQuestionsUI();
	}, [reorderFormQuestionsUI]);

	const PriceListFormQuestionsList = formQuestionsUI.map((formQuestionUI) => (
		<PriceListFormQuestion
			formQuestionUI={formQuestionUI}
			updateFormQuestion={updateFormQuestionUI}
			deleteFormQuestion={deleteFormQuestionUI}
			clearFormQuestionError={clearFormQuestionError}
			changeFormQuestionSequence={changeFormQuestionsSequence}
			key={formQuestionUI.formQuestionKey || formQuestionUI.sequence}
		/>
	));

	const showSaveBtn =
		!!formQuestionsUI.length && !lastFormQuestionUI?.formQuestionKey;

	return (
		<>
			{!!formQuestionsUI.length && (
				<tr className="price-question-table">
					<td colSpan={1} />
					<td colSpan={5}>
						<PriceTable headers={headers}>
							{PriceListFormQuestionsList}
						</PriceTable>
					</td>
				</tr>
			)}
			<ControlBtnColumn
				disabled={!entityKey}
				showSaveBtn={showSaveBtn}
				handleAdd={handleAddFormQuestion}
				handleSave={handleSaveFormQuestion}
			/>
		</>
	);
};
