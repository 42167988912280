import { AnySchema, array, boolean, number, object, string } from 'yup';

import {
	maxAccessCodeMessageLength,
	maxSmsSpecialMessageLength,
	maxEmailSpecialMessageLength,
	smsSpecialMessageErrorMessage,
	accessCodeMessageErrorMessage,
	emailSpecialMessageErrorMessage,
	maxOrderNotificationEmailLength,
	maxOrderConfirmationMessageLength,
	orderNotificationEmailErrorMessage,
	orderConfirmationMessageErrorMessage,
} from 'constants/projects/validation/generalProjectFields';
import {
	numberErrorMessage,
	requiredFieldMessage,
} from 'constants/general/validation/generalMessages';
import {
	emailRegEx,
	emailErrorMessage,
} from 'constants/general/validation/emailValidation';
import {
	nameValidationMessage,
	organizationNameRegEx,
} from 'constants/organizations/validation';

import { IJourneyPresetValues } from './types';

const notRequiredFieldValidation = string().notRequired();

export const journeyPresetDetailsScheme = () => {
	const campaignKeyValidation = number().when(
		'isMarketingEnabled',
		(isMarketingEnabled: boolean[]) =>
			isMarketingEnabled[0]
				? number().required(requiredFieldMessage)
				: number().nullable()
	);

	return object().shape<Record<keyof IJourneyPresetValues, AnySchema>>({
		presetName: string()
			.required(requiredFieldMessage)
			.matches(organizationNameRegEx, nameValidationMessage),
		presetDescription: string().required(requiredFieldMessage),
		journey: string(),
		projectType: string().required(requiredFieldMessage),
		presetSales: array().of(
			object().shape({
				priceListKey: string().required(requiredFieldMessage),
				saleExpiresInDays: number()
					.typeError(numberErrorMessage)
					.required(requiredFieldMessage)
					.min(0, numberErrorMessage),
				orderNotificationEmail: string()
					.notRequired()
					.max(
						maxOrderNotificationEmailLength,
						orderNotificationEmailErrorMessage
					)
					.matches(emailRegEx, emailErrorMessage),
				orderConfirmationMessage: string()
					.notRequired()
					.max(
						maxOrderConfirmationMessageLength,
						orderConfirmationMessageErrorMessage
					),
			})
		),
		isMarketingEnabled: boolean(),
		prospectCampaignKey: campaignKeyValidation,
		leadCampaignKey: campaignKeyValidation,
		buyerCampaignKey: campaignKeyValidation,
		accessCodeMessage: string().when(
			'isMarketingEnabled',
			(isMarketingEnabled: boolean[]) =>
				isMarketingEnabled[0]
					? string()
							.notRequired()
							.max(maxAccessCodeMessageLength, accessCodeMessageErrorMessage)
					: notRequiredFieldValidation
		),
		smsSpecialMessage: string()
			.notRequired()
			.max(maxSmsSpecialMessageLength, smsSpecialMessageErrorMessage),
		emailSpecialMessage: string()
			.notRequired()
			.max(maxEmailSpecialMessageLength, emailSpecialMessageErrorMessage),
	});
};
