import { FC, SyntheticEvent, useState } from 'react';

import { IPatchBody } from 'api/models/requests/general/patchBody';
import ProjectsService from 'api/services/ProjectsService';

import { LazyTextArea } from 'components/FormControls/LazyTextArea';
import { InputGrid } from 'components/FormControls/InputGrid';
import { TextArea } from 'components/FormControls/TextArea';
import { ToggleSection } from 'components/ToggleSection';

import {
	maxAccessCodeMessageLength,
	maxSmsSpecialMessageLength,
	maxEmailSpecialMessageLength,
	smsSpecialMessageErrorMessage,
	accessCodeMessageErrorMessage,
	emailSpecialMessageErrorMessage,
} from 'constants/projects/validation/generalProjectFields';
import { useToggleSection } from 'hooks/useToggleSection';

import { IMarketingContainerProps } from '../../..';

interface IErrors {
	accessCodeMessage: string;
	smsSpecialMessage: string;
	emailSpecialMessage: string;
}

interface IChange {
	id: string;
	value: string;
	message: string;
	maxLength: number;
}

const initialErrors: IErrors = {
	accessCodeMessage: '',
	smsSpecialMessage: '',
	emailSpecialMessage: '',
};

type IProjectMessageProps = Omit<
	IMarketingContainerProps,
	'hasAnySentBroadcast' | 'journey' | 'setIsUpdateTimeline'
>;

export const ProjectMessage: FC<IProjectMessageProps> = ({
	projectKey,
	setProjectDetails,
	accessCodeMessage,
	smsSpecialMessage,
	isMarketingEnabled,
	emailSpecialMessage,
}) => {
	const [errors, setErrors] = useState<IErrors>(initialErrors);

	const { isOpen, handleToggle } = useToggleSection(true);

	const updateProject = async (changedValue: string, path: string) => {
		try {
			if (!projectKey) return;

			const updatedProjectFields: IPatchBody = {
				path,
				value: changedValue,
				op: 'replace',
			};

			const projectUpdateBody: IPatchBody[] = [updatedProjectFields];

			const data = await ProjectsService.partialProjectUpdate(
				projectKey,
				projectUpdateBody
			);

			setProjectDetails(data);
		} catch (error) {
			console.log(error);
		}
	};

	const handleChange = ({ id, value, maxLength, message }: IChange) => {
		if (value.length > maxLength) {
			return setErrors({ ...errors, [id]: message });
		}

		void updateProject(value, id);
	};

	const handleAccessCodeMessageChange = (
		e: SyntheticEvent<HTMLTextAreaElement>
	) => {
		const { id, value } = e.currentTarget;

		handleChange({
			id,
			value,
			maxLength: maxAccessCodeMessageLength,
			message: accessCodeMessageErrorMessage,
		});
	};

	const handleSmsSpecialMessageChange = (
		e: SyntheticEvent<HTMLInputElement>
	) => {
		const { id, value } = e.currentTarget;

		handleChange({
			id,
			value,
			maxLength: maxSmsSpecialMessageLength,
			message: smsSpecialMessageErrorMessage,
		});
	};

	const handleEmailSpecialMessageChange = (
		e: SyntheticEvent<HTMLTextAreaElement>
	) => {
		const { id, value } = e.currentTarget;

		handleChange({
			id,
			value,
			maxLength: maxEmailSpecialMessageLength,
			message: emailSpecialMessageErrorMessage,
		});
	};

	const clearError = (id: string) => {
		setErrors({ ...errors, [id]: '' });
	};

	return (
		<ToggleSection
			isOpen={isOpen}
			title="Project Messages"
			handleToggle={handleToggle}
			className="marketing-section"
		>
			<div className="marketing-table">
				<div className="project-message-section">
					<span className="project-message-title">
						Project Special Message
						<b>
							Will be inserted in marketing broadcasts when using keyword
							[SpecialMessage]
						</b>
					</span>
					<div className="project-textarea-section">
						<div className="project-message">
							<InputGrid
								isLazy
								touched
								required={false}
								className="autorow"
								id="smsSpecialMessage"
								clearError={clearError}
								label="SMS Special Message"
								disabled={!isMarketingEnabled}
								defaultValue={smsSpecialMessage}
								placeholder="SMS Special Message"
								error={errors.smsSpecialMessage}
								handleLazyChange={handleSmsSpecialMessageChange}
							/>
							<span className="project-textarea-info t-right">
								120 Characters Max
							</span>
						</div>
						<TextArea
							isLazy
							touched
							clearError={clearError}
							id="emailSpecialMessage"
							subTextClassName="t-right"
							subText="500 Characters Max"
							label="Email Special Message"
							disabled={!isMarketingEnabled}
							error={errors.emailSpecialMessage}
							defaultValue={emailSpecialMessage}
							placeholder="Email Special Message"
							handleLazyChange={handleEmailSpecialMessageChange}
						/>
					</div>
				</div>
				<div className="project-message-section">
					<span className="project-message-title is-pad">
						Access Code Message
						<b>
							Shown when consumer clicks “Need help finding your access code?”
							link.
						</b>
					</span>
					<div className="project-textarea-section">
						<LazyTextArea
							id="accessCodeMessage"
							clearError={clearError}
							className="project-textarea"
							defaultValue={accessCodeMessage}
							handleChange={handleAccessCodeMessageChange}
							placeholder="Your Gallery Access Code is your child’s student ID. If you have more than one child attending this school, you will need a separate access code for each child."
						/>
						{errors.accessCodeMessage && (
							<span className="input-error">{errors.accessCodeMessage}</span>
						)}
						<span className="project-textarea-info t-right">
							500 Characters Max
						</span>
					</div>
				</div>
			</div>
		</ToggleSection>
	);
};
