import { RefObject, useRef } from 'react';

import { ModalRef } from 'components/Modal';

interface IModalControls {
	showModal: () => void;
	hideModal: () => void;
	modalRef: RefObject<ModalRef>;
}

export const useModalControls = (): IModalControls => {
	const modalRef = useRef<ModalRef>(null);

	const showModal = () => {
		modalRef.current?.open();
	};

	const hideModal = () => {
		modalRef.current?.close();
	};

	return {
		modalRef,
		showModal,
		hideModal,
	};
};
