import { Formik, FormikHelpers, useFormikContext } from 'formik';
import { useNavigate } from 'react-router-dom';
import { FC, useEffect } from 'react';

import { testBroadcastSelectOption } from 'constants/broadcasts/selectOptions/testBroadcastSelectOption';
import { ROUTES } from 'constants/ROUTES';

import { ISendTestBroadcastBody } from 'api/models/requests/broadcast/sendTestBroadcast';
import BroadcastService from 'api/services/BroadcastService';

import { validateSendToEmails } from 'utils/validations/broadcasts/validateSendToEmails';
import { validateSendToPhones } from 'utils/validations/broadcasts/validateSendToPhones';

import { TextEditorRef } from 'components/TextEditor/types';
import { ToggleSection } from 'components/ToggleSection';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useToggleSection } from 'hooks/useToggleSection';
import { useToastify } from 'hooks/useToastify';

import { ISelectOption } from 'types/ui/select';

import { sendTestBroadcastValidationSchema } from './validation';
import { SendTestBroadcastForm } from './SendTestBroadcastForm';
import { IBroadcastValues } from '../../..';

import {
	activeStudioProjectsSelectOptionsSelector,
	getActiveStudioProjectsAsync,
} from 'store/projects';

export type SendTestBroadcastValues = Omit<
	ISendTestBroadcastBody,
	'isUsingTestData'
>;

interface ISendTestBroadcastProps {
	campaignKey?: number;
	editorRef: TextEditorRef;
	disableSendTest: boolean;
	setSendTestBroadcastSubmitted: (value: boolean) => void;
}

export const SendTestBroadcast: FC<ISendTestBroadcastProps> = ({
	editorRef,
	campaignKey,
	disableSendTest,
	setSendTestBroadcastSubmitted,
}) => {
	const { values, validateForm, setTouched } =
		useFormikContext<IBroadcastValues>();

	const activeStudioProjectsSelectOptions = useAppSelector(
		activeStudioProjectsSelectOptionsSelector
	);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { isOpen, handleToggle } = useToggleSection(false);
	const { showSuccessToastify } = useToastify();

	const emailMessage = editorRef.current?.editor?.data.get() || '';

	const initialValues: SendTestBroadcastValues = {
		emailMessage,
		sendToPhoneNumber: '',
		sendToEmailAddress: '',
		textMessage: values.textMessage,
		emailSubject: values.emailSubject,
		projectKey: testBroadcastSelectOption.value,
	};

	const onSubmit = async (
		sendTestValues: SendTestBroadcastValues,
		{ setErrors }: FormikHelpers<SendTestBroadcastValues>
	) => {
		const emailBody = editorRef.current?.editor?.data.get() ?? '';

		const updatedValues = {
			...values,
			emailMessage: emailBody,
		};

		const touched = Object.keys(updatedValues).reduce(
			(acc, key) => ({ ...acc, [key]: true }),
			{}
		);

		await setTouched(touched);

		const broadcastErrors = await validateForm(updatedValues);

		const isValid = !Object.keys(broadcastErrors).length;

		if (!isValid || !campaignKey) return;

		const sendToEmailAddressError = validateSendToEmails(
			sendTestValues.sendToEmailAddress
		);
		const sendToPhoneNumberError = validateSendToPhones(
			sendTestValues.sendToPhoneNumber
		);

		if (sendToPhoneNumberError || sendToEmailAddressError) {
			return setErrors({
				sendToPhoneNumber: sendToPhoneNumberError,
				sendToEmailAddress: sendToEmailAddressError,
			});
		}

		const isTestBroadcast =
			sendTestValues.projectKey === testBroadcastSelectOption.value;

		const sendTestBroadcastBody: ISendTestBroadcastBody = {
			...sendTestValues,
			emailMessage,
			isUsingTestData: isTestBroadcast,
			projectKey: isTestBroadcast ? null : sendTestValues.projectKey,
		};

		try {
			await BroadcastService.sendTestBroadcast(sendTestBroadcastBody);

			showSuccessToastify({ title: 'Test broadcast sent' });

			navigate(`${ROUTES.CAMPAIGNS.CAMPAIGNS}/${campaignKey}`);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		void dispatch(getActiveStudioProjectsAsync());
	}, []);

	const selectOptions: ISelectOption[] = [
		testBroadcastSelectOption,
		...activeStudioProjectsSelectOptions,
	];

	return (
		<div className="campaign-toggle-section">
			<ToggleSection
				isOpen={isOpen}
				title="Send a Test"
				handleToggle={handleToggle}
				className="campaign-section-header"
			>
				<Formik
					enableReinitialize
					onSubmit={onSubmit}
					initialValues={initialValues}
					validationSchema={sendTestBroadcastValidationSchema}
				>
					<SendTestBroadcastForm
						selectOptions={selectOptions}
						disableSendTest={disableSendTest}
						setSendTestBroadcastSubmitted={setSendTestBroadcastSubmitted}
					/>
				</Formik>
			</ToggleSection>
		</div>
	);
};
