import { CellProps, Column } from 'react-table';
import { Link } from 'react-router-dom';
import { FC } from 'react';

import { IProjectItem } from 'api/models/responses/projects/projectItem';
import { IPresetItem } from 'api/models/responses/presets/presetItem';

import { Button } from 'components/FormControls/Button';
import { Table } from 'components/Table';

import { usedInPresetsSelector, usedInProjectsSelector } from 'store/campaigns';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { ROUTES } from 'constants/ROUTES';

interface ICampaignDeleteRejectProps {
	hideModal: () => void;
}

export const CampaignDeleteReject: FC<ICampaignDeleteRejectProps> = ({
	hideModal,
}) => {
	const usedInProjects = useAppSelector(usedInProjectsSelector);
	const usedInPresets = useAppSelector(usedInPresetsSelector);

	const projectsColumns: Column<IProjectItem>[] = [
		{
			Header: <div className="tr-arrow">Projects using this campaign</div>,
			accessor: 'projectName',
			Cell: ({ row }: CellProps<IProjectItem>) => {
				const { projectKey, projectName } = row.original;

				const path = `${ROUTES.PROJECTS.PROJECTS}/${projectKey}`;

				return (
					<Link title={projectName} className="card-table-link" to={path}>
						{projectName}
					</Link>
				);
			},
		},
	];

	const presetsColumns: Column<IPresetItem>[] = [
		{
			Header: <div className="tr-arrow">Presets using this campaign</div>,
			accessor: 'presetName',
			Cell: ({ row }: CellProps<IPresetItem>) => {
				const { presetKey, presetName } = row.original;

				const path = `${ROUTES.PRESETS.PRESETS}/${presetKey}`;

				return (
					<Link title={presetName} className="card-table-link" to={path}>
						{presetName}
					</Link>
				);
			},
		},
	];

	return (
		<div className="modal-body campaign-modal-tables">
			<div className="campaign-modal-table">
				<Table<IProjectItem>
					data={usedInProjects}
					columns={projectsColumns}
					className="org-table table-action-menu"
				/>
			</div>
			<div className="campaign-modal-table">
				<Table<IPresetItem>
					data={usedInPresets}
					columns={presetsColumns}
					className="org-table table-action-menu"
				/>
			</div>
			<Button
				value="OK"
				handleClick={hideModal}
				className="btn-primary campaign-table-btn"
			/>
		</div>
	);
};
