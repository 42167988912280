import { date, number, string } from 'yup';

import { requiredFieldMessage } from 'constants/general/validation/generalMessages';
import { IDatesLabels } from 'constants/projects/timeline/journeyTimelines';
import {
	projectUrlValidationRegEx,
	projectUrlValidationMessage,
	estimatedNumberOfSubjectsUpLimit,
	estimatedNumberOfSubjectsBottomLimit,
	estimatedNumberOfSubjectsValidationMessage,
} from 'constants/projects/validation/generalProjectFields';
import {
	nameValidationMessage,
	organizationNameRegEx,
} from 'constants/organizations/validation';

import { getDateErrorMessage } from '../../validation';
import { TestContextExtended } from '../../types';
import { ICreateProjectFromPresetValues } from '.';

export const getProjectFromPrestValidationSchema = (
	datesLabels: IDatesLabels,
	validateLastPictureDate: boolean,
	validateFirstPictureDate: boolean
) => {
	const { publishDateLabel, lastPictureDateLabel, firstPictureDateLabel } =
		datesLabels;

	return {
		projectUrl: string()
			.required(requiredFieldMessage)
			.matches(projectUrlValidationRegEx, projectUrlValidationMessage),
		projectName: string()
			.required(requiredFieldMessage)
			.matches(organizationNameRegEx, nameValidationMessage),
		estimatedNumberOfSubjects: number()
			.typeError(estimatedNumberOfSubjectsValidationMessage)
			.required(requiredFieldMessage)
			.min(
				estimatedNumberOfSubjectsBottomLimit,
				estimatedNumberOfSubjectsValidationMessage
			)
			.max(
				estimatedNumberOfSubjectsUpLimit,
				estimatedNumberOfSubjectsValidationMessage
			),
		publishDate: date()
			.default(null)
			.required(requiredFieldMessage)
			.test('', '', (publishDate, context) => {
				const { parent } = context as TestContextExtended;
				const { firstPictureDate, lastPictureDate } =
					parent as ICreateProjectFromPresetValues;

				if (!publishDate) return true;

				if (validateFirstPictureDate && firstPictureDate) {
					return publishDate >= firstPictureDate
						? context.createError(
								getDateErrorMessage(firstPictureDateLabel, false)
						  )
						: true;
				}

				if (
					validateLastPictureDate &&
					lastPictureDate &&
					lastPictureDateLabel
				) {
					return publishDate > lastPictureDate
						? context.createError(getDateErrorMessage(lastPictureDateLabel))
						: true;
				}

				return true;
			}),
		firstPictureDate: date()
			.default(null)
			.required(requiredFieldMessage)
			.test('', '', (firstPictureDate, context) => {
				const { parent } = context as TestContextExtended;
				const { publishDate, lastPictureDate } =
					parent as ICreateProjectFromPresetValues;

				if (!firstPictureDate) return true;

				if (
					validateFirstPictureDate &&
					publishDate &&
					firstPictureDate <= publishDate
				) {
					return context.createError(getDateErrorMessage(publishDateLabel));
				}

				if (
					validateLastPictureDate &&
					lastPictureDate &&
					lastPictureDateLabel
				) {
					return firstPictureDate > lastPictureDate
						? context.createError(getDateErrorMessage(lastPictureDateLabel))
						: true;
				}

				return true;
			}),
		lastPictureDate: validateLastPictureDate
			? date()
					.default(null)
					.required(requiredFieldMessage)
					.test('', '', (lastPictureDate, context) => {
						const { parent } = context as TestContextExtended;
						const { publishDate, firstPictureDate } =
							parent as ICreateProjectFromPresetValues;

						if (!lastPictureDate) return true;

						if (
							(publishDate && lastPictureDate < publishDate) ||
							(firstPictureDate && lastPictureDate < firstPictureDate)
						) {
							return context.createError(
								getDateErrorMessage(
									`${publishDateLabel} and ${firstPictureDateLabel}`,
									false
								)
							);
						}

						return true;
					})
			: date().nullable(),
	};
};
