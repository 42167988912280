import { ICampaignDetails } from 'api/models/responses/campaigns/campaignDetails';
import { ICampaign } from 'api/models/responses/campaigns/campaign';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import { ICopyCampaignBody } from 'api/models/requests/campaigns';

import { campaignsUrls } from 'constants/apiUrls/campaigns';
import { api } from 'config/api';

class CampaignsService {
	async getCampaigns(): Promise<ICampaign[]> {
		const { data } = await api.get<ICampaign[]>(campaignsUrls.getCampaigns);

		return data;
	}

	async getCampaign(campaignKey: number): Promise<ICampaignDetails> {
		const { data } = await api.get<ICampaignDetails>(
			campaignsUrls.getCampaignUrl(campaignKey)
		);

		return data;
	}

	async updateCampaign(
		campaignKey: number,
		body: IPatchBody[]
	): Promise<ICampaignDetails> {
		const { data } = await api.patch<ICampaignDetails>(
			campaignsUrls.getCampaignUrl(campaignKey),
			body
		);

		return data;
	}

	async deleteCampaign(campaignKey: number): Promise<void> {
		await api.delete<string>(campaignsUrls.getCampaignUrl(campaignKey));
	}

	async copyCampaign(
		campaignKey: number,
		body: ICopyCampaignBody
	): Promise<ICampaign> {
		const { data } = await api.post<ICampaign>(
			campaignsUrls.copyCampaign(campaignKey),
			body
		);

		return data;
	}
}

export default new CampaignsService();
