import { Dispatch } from '@reduxjs/toolkit';

import StudioBillingService from 'api/services/StudioBillingService';

import {
	setBankACH,
	setCardInfo,
	setStudioBilling,
} from './studioBillingSlice';

export const getBankACHInfoAsync = () => async (dispatch: Dispatch) => {
	try {
		const data = await StudioBillingService.getBankACHInfo();

		dispatch(setBankACH(data));
	} catch (error) {
		console.log(error);
	}
};

export const getCardInfoAsync = () => async (dispatch: Dispatch) => {
	try {
		const data = await StudioBillingService.getCardInfo();

		dispatch(setCardInfo(data));
	} catch (error) {
		console.log(error);
	}
};

export const getBillingInfoAsync = () => async (dispatch: Dispatch) => {
	try {
		const data = await StudioBillingService.getBillingInfo();

		dispatch(setStudioBilling(data));
	} catch (error) {
		console.log(error);
	}
};
