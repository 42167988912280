import { SingleValue } from 'react-select';
import { FC } from 'react';

import { dataFieldTypeSelectOptions } from 'constants/general/selectOptions/dataFieldTypeSelectOptions';
import { FormQuestionDataFieldType } from 'api/models/responses/general/generalFormQuestion';
import { SelectComponent } from 'components/FormControls/Select';
import { ISelectOption } from 'types/ui/select';

interface IDataFiledTypeColumnProps {
	dataFieldType: FormQuestionDataFieldType | null;
	updateDataFieldType: (dataFieldType: FormQuestionDataFieldType) => void;
}

export const DataFiledTypeColumn: FC<IDataFiledTypeColumnProps> = ({
	dataFieldType,
	updateDataFieldType,
}) => {
	const handleChangeDataFieldType = (
		option: SingleValue<ISelectOption<FormQuestionDataFieldType>>
	) => {
		if (!option) return;

		updateDataFieldType(option.value);
	};

	return (
		<td className="price-option-td">
			<SelectComponent
				isSearchable
				value={dataFieldType}
				onChange={handleChangeDataFieldType}
				selectOptions={dataFieldTypeSelectOptions}
				selectPlaceholder="Select Data Field Type*"
			/>
		</td>
	);
};
