import { Column } from 'react-table';
import { FC } from 'react';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { usePagination } from 'hooks/usePagination';

import { Loader } from 'components/Loader';
import { Table } from 'components/Table';

import { isPaginatedJourneyPresetsPendingSelector } from 'store/journeyPresets';
import { IBasePreset } from 'api/models/responses/presets/presets';

interface IJourneyPresetsListProps {
	data: IBasePreset[];
	columns: Column<IBasePreset>[];
	getPaginatedJourneyPresets: () => void;
}

export const JourneyPresetsList: FC<IJourneyPresetsListProps> = ({
	data,
	columns,
	getPaginatedJourneyPresets,
}) => {
	const isPaginatedJourneyPresetsPending = useAppSelector(
		isPaginatedJourneyPresetsPendingSelector
	);

	const ref = usePagination(getPaginatedJourneyPresets);

	return (
		<div className="org-project-search-container">
			<Table
				data={data}
				columns={columns}
				className="org-table table-action-menu"
			/>
			<div ref={ref} style={{ height: '1px' }} />
			{isPaginatedJourneyPresetsPending && <Loader isFillContent />}
		</div>
	);
};
