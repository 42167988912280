import { PriceListFulfillment } from './priceListFulfillment';

export const priceListFulfillmentDescriptions: Record<
	PriceListFulfillment,
	string
> = {
	[PriceListFulfillment.NA]: '',
	[PriceListFulfillment.Imago]:
		'(Orders immediately sent to H&H Color Lab for production.)',
	[PriceListFulfillment.Studio]:
		'(Studio to download and submit orders for production.)',
};
