import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ImagoPoseOptionTypes } from 'api/models/responses/priceLists/imago/priceListOptions/poseOptionTypes';

import {
	IPoseOptionsState,
	GroupedPoseOptions,
	AddedPoseOptionTypes,
} from './';

const initialGroupedPoseOptions: GroupedPoseOptions = {
	[ImagoPoseOptionTypes.None]: [],
	[ImagoPoseOptionTypes.Backgrounds]: [],
};

const initialAddedPoseOptionTypes: AddedPoseOptionTypes = {
	[ImagoPoseOptionTypes.None]: false,
	[ImagoPoseOptionTypes.Backgrounds]: false,
};

const initialState: IPoseOptionsState = {
	groupedPoseOptions: initialGroupedPoseOptions,
	addedPoseOptionTypes: initialAddedPoseOptionTypes,
};

const priceListPoseOptionsSlice = createSlice({
	name: 'imagoPriceListPoseOptions',
	initialState,
	reducers: {
		addPoseOptionType: (
			state,
			{ payload }: PayloadAction<ImagoPoseOptionTypes>
		) => {
			state.addedPoseOptionTypes[payload] = true;
		},

		deletePoseOptionSection: (
			state,
			{ payload }: PayloadAction<ImagoPoseOptionTypes>
		) => {
			state.groupedPoseOptions[payload] = [];
			state.addedPoseOptionTypes[payload] = false;
		},
	},
});

export const priceListPoseOptionsReducer = priceListPoseOptionsSlice.reducer;
export const { addPoseOptionType, deletePoseOptionSection } =
	priceListPoseOptionsSlice.actions;
