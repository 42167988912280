import { Dispatch } from '@reduxjs/toolkit';

import { ICampaignDeleteReject } from 'api/models/responses/campaigns/campaignDeleteReject';
import { ICopyCampaignBody } from 'api/models/requests/campaigns';
import CampaignsService from 'api/services/CampaignsService';

import {
	setCampaigns,
	setUsedInItems,
	deleteCampaign,
	setIsCopySuccess,
	setCopiedCampaign,
	setIsCampaignsPending,
	setIsDeleteCampaignReject,
	setIsDeleteCampaignSuccess,
} from './campaignsSlice';

export const getCampaignsAsync = () => async (dispatch: Dispatch) => {
	dispatch(setIsCampaignsPending(true));

	try {
		const data = await CampaignsService.getCampaigns();

		dispatch(setCampaigns(data));
	} catch (error) {
		console.log(error);
	}

	dispatch(setIsCampaignsPending(false));
};

export const copyCampaignAsync =
	(campaignKey: number, body: ICopyCampaignBody) =>
	async (dispatch: Dispatch) => {
		try {
			const data = await CampaignsService.copyCampaign(campaignKey, body);

			dispatch(setCopiedCampaign(data));
			dispatch(setIsCopySuccess(true));
		} catch (error) {
			console.log(error);
		}
	};

export const deleteCampaignAsync =
	(campaignKey: number) => async (dispatch: Dispatch) => {
		try {
			await CampaignsService.deleteCampaign(campaignKey);

			dispatch(deleteCampaign(campaignKey));
			dispatch(setIsDeleteCampaignSuccess(true));
		} catch (error) {
			const { isDeleted, activeChildItems } = error as ICampaignDeleteReject;

			const { presets, projects } = activeChildItems[0];

			if (!isDeleted) {
				dispatch(setUsedInItems({ presets, projects }));
				dispatch(setIsDeleteCampaignReject(true));
			}
		}
	};
