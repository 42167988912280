import { Dispatch } from '@reduxjs/toolkit';

import OrganizationService from 'api/services/OrganizationService';

import {
	setActiveOrganizations,
	setArchivedOrganizations,
	setIsOrgPending,
} from './organizationSlice';

export const getActiveOrganizationsAsync = () => async (dispatch: Dispatch) => {
	dispatch(setIsOrgPending(true));

	try {
		const activeOrganizations =
			await OrganizationService.getActiveOrganizations();

		dispatch(setActiveOrganizations(activeOrganizations));
	} catch (error) {
		console.log(error);
	}

	dispatch(setIsOrgPending(false));
};

export const getArchivedOrganizationsAsync =
	() => async (dispatch: Dispatch) => {
		dispatch(setIsOrgPending(true));

		try {
			const data = await OrganizationService.getArchivedOrganizations();

			dispatch(setArchivedOrganizations(data));
		} catch (error) {
			console.log(error);
		}

		dispatch(setIsOrgPending(false));
	};
