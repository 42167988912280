import cn from 'classnames';
import { FC } from 'react';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';

import { isOpenSelector, setIsOpen as setIsOpenSidebar } from 'store/sidebar';
import { setIsOpen as setIsOpenAside } from 'store/aside';

import { useDefineDevice } from 'hooks/useDefineDevice';

import { HeaderBreadcrumbs } from './HeaderBreadcrumbs';
import { HeaderControl } from './HeaderControl';
import { HeaderMobile } from './HeaderMobile';

export const Header: FC = () => {
	const isOpen = useAppSelector(isOpenSelector);

	const dispatch = useAppDispatch();

	const { isMobile } = useDefineDevice();

	const showAside = () => {
		dispatch(setIsOpenAside(true));
	};

	const showSidebar = () => {
		dispatch(setIsOpenSidebar(true));
	};

	return (
		<>
			<header className="header">
				<div
					className={cn('header-content', {
						'header-thumb-open': isOpen,
					})}
				>
					<div
						className={cn('header-thumb', {
							'is-show': !isOpen,
						})}
					>
						{!isMobile && (
							<button className="header-burger" onClick={showSidebar}></button>
						)}
						<div className="heade-logo-mini"></div>
					</div>
					{!isMobile && <HeaderBreadcrumbs />}
				</div>
				<HeaderControl showAside={showAside} />
			</header>
			{isMobile && <HeaderMobile showSidebar={showSidebar} />}
		</>
	);
};
