import { FC } from 'react';

import { ToggleSection } from 'components/ToggleSection';
import { Loader } from 'components/Loader';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useToggleSection } from 'hooks/useToggleSection';

import { isPendingBaseOptionsSelector } from 'store/priceLists/studio/priceListBaseOptions';

import { AdditionalChargeOptions } from './components/AdditionalChargeOptions';
import { ShippingOptions } from './components/ShippingOptions';
import { PresaleOptions } from './components/PresaleOptions';

import { IPriceListOptionsProps } from '../..';

export interface IOrderOptionsContainerProps
	extends Omit<IPriceListOptionsProps, 'isPriceListPending' | 'setActiveTab'> {
	className: string;
	isPriceListPending: boolean;
}

export const OrderOptionsContainer: FC<IOrderOptionsContainerProps> = ({
	className,
	priceListStudio,
	isPriceListPending,
	updatePriceListStudio,
}) => {
	const isPendingBaseOptions = useAppSelector(isPendingBaseOptionsSelector);

	const { isOpen, handleToggle } = useToggleSection(false);

	const isAddBuddyPoseAtPresale = priceListStudio?.isAddBuddyPoseAtPresale;
	const priceListKey = priceListStudio?.priceListStudioFulfilledKey;
	const isChargeSalesTax = priceListStudio?.isChargeSalesTax;
	const salesTaxPercent = priceListStudio?.salesTaxPercent;

	const showLoader = isPriceListPending || isPendingBaseOptions;

	return (
		<ToggleSection
			isOpen={isOpen}
			className={className}
			title="Order Options"
			handleToggle={handleToggle}
		>
			{showLoader ? (
				<Loader />
			) : (
				<>
					<AdditionalChargeOptions
						priceListKey={priceListKey}
						salesTaxPercent={salesTaxPercent}
						isChargeSalesTax={isChargeSalesTax}
						updatePriceListStudio={updatePriceListStudio}
					/>
					<ShippingOptions priceListKey={priceListKey} />
					<PresaleOptions
						updatePriceListStudio={updatePriceListStudio}
						isAddBuddyPoseAtPresale={isAddBuddyPoseAtPresale}
					/>
				</>
			)}
		</ToggleSection>
	);
};
