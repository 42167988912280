import { FormikHandlers } from 'formik';
import cn from 'classnames';
import { FC } from 'react';

import { Input } from 'components/FormControls/Input';

interface IDomainProps {
	value: string;
	error?: string;
	touched: boolean;
	handleChange: FormikHandlers['handleChange'];
}

export const Domain: FC<IDomainProps> = ({
	value,
	error,
	touched,
	handleChange,
}) => {
	const showError = error && touched;

	return (
		<div
			className={cn('form-container', {
				'wrapper-error': showError,
			})}
		>
			<label htmlFor="domain" className="label">
				Domain
			</label>
			<div className="form-grid">
				<span className="form-sublabel">https://</span>
				<Input
					value={value}
					id="networkDomain"
					placeholder="Studio"
					handleChange={handleChange}
				/>
			</div>
			<span className="form-suplabel">.accessmyevent.com</span>
			{showError && <span className="input-error">{error}</span>}
		</div>
	);
};
