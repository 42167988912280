import { SingleValue } from 'react-select';
import { useFormikContext } from 'formik';
import { FC } from 'react';

import { ProjectSpecialMessage } from 'components/Marketing/ProjectSpecialMessage';
import { CampaignsFieldsList } from 'components/Marketing/CampaignsFieldsList';
import { AccessCodeMessage } from 'components/Marketing/AccessCodeMessage';
import { MarketingHeader } from 'components/Marketing/MarketingHeader';
import { SelectComponent } from 'components/FormControls/Select';
import { InputGrid } from 'components/FormControls/InputGrid';
import { TextArea } from 'components/FormControls/TextArea';

import { ICreateBlankProjectValues } from 'pages/Projects/pages/CreateBlankProject';
import { campaignsSelectOptionsSelector } from 'store/projects';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { ISelectOption } from 'types/ui/select';

export const MarketingFunnel: FC = () => {
	const { values, errors, touched, handleChange, setFieldValue } =
		useFormikContext<ICreateBlankProjectValues>();

	const campaignsSelectOptions = useAppSelector(campaignsSelectOptionsSelector);

	const disabledMarketing = !values.isMarketingEnabled;

	const CampaignsSelectList = campaignsSelectOptions.map(
		({ id, label, selectOptions }) => {
			const handleChangeCampaignKey = (option: SingleValue<ISelectOption>) => {
				if (!option) return;

				void setFieldValue(id, option.value);
			};

			return (
				<div className="project-section-wrapper-lg" key={id}>
					<SelectComponent
						label={label}
						value={values[id]}
						error={errors[id]}
						touched={touched[id]}
						selectPlaceholder={label}
						disabled={disabledMarketing}
						selectOptions={selectOptions}
						onChange={handleChangeCampaignKey}
					/>
				</div>
			);
		}
	);

	const SmsSpecialMessageField = (
		<InputGrid
			required={false}
			className="autorow"
			id="smsSpecialMessage"
			handleChange={handleChange}
			label="SMS Special Message"
			value={values.smsSpecialMessage}
			error={errors.smsSpecialMessage}
			placeholder="SMS Special Message"
			touched={touched.smsSpecialMessage}
			disabled={!values.isMarketingEnabled}
		/>
	);

	const EmailSpecialMessageField = (
		<TextArea
			id="emailSpecialMessage"
			subTextClassName="t-right"
			handleChange={handleChange}
			subText="500 Characters Max"
			label="Email Special Message"
			value={values.emailSpecialMessage}
			error={errors.emailSpecialMessage}
			placeholder="Email Special Message"
			touched={touched.emailSpecialMessage}
			disabled={!values.isMarketingEnabled}
		/>
	);

	return (
		<div className="project-section">
			<MarketingHeader
				handleChange={handleChange}
				isMarketingEnabled={values.isMarketingEnabled}
			/>
			<CampaignsFieldsList>{CampaignsSelectList}</CampaignsFieldsList>
			<ProjectSpecialMessage
				SmsSpecialMessageField={SmsSpecialMessageField}
				EmailSpecialMessageField={EmailSpecialMessageField}
				toggled={true}
			/>
			<AccessCodeMessage
				error={errors.accessCodeMessage}
				touched={touched.accessCodeMessage}
			>
				<textarea
					id="accessCodeMessage"
					onChange={handleChange}
					className="project-textarea"
					disabled={disabledMarketing}
					value={values.accessCodeMessage}
					placeholder="Access code message"
				/>
			</AccessCodeMessage>
		</div>
	);
};
