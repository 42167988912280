import { ISelectOption } from 'types/ui/select';

import { packageOptionTypesUI } from '../packageOptionTypesUI';
import { PackageOptionTypes } from '../packageOptionTypes';

export const packageOptionTypesSelectOptions: ISelectOption<PackageOptionTypes>[] =
	Object.values(PackageOptionTypes).map((value) => ({
		value,
		label: packageOptionTypesUI[value],
	}));
