import { FC } from 'react';

import { Button } from 'components/FormControls/Button';

interface IConfirmModalProps {
	confirmBtnValue?: string;
	hideConfirmModal: () => void;
	handleConfirm: () => Promise<void> | void;
}

export const Confirm: FC<IConfirmModalProps> = ({
	handleConfirm,
	confirmBtnValue,
	hideConfirmModal,
}) => (
	<div className="org-modal-confirm">
		<Button
			className="btn-primary"
			handleClick={handleConfirm}
			value={confirmBtnValue ?? 'Confirm'}
		/>
		<Button
			value="Cancel"
			className="btn-secondary"
			handleClick={hideConfirmModal}
		/>
	</div>
);
