import { FC, useState } from 'react';

import { useToggleSection } from 'hooks/useToggleSection';
import { useDefineDevice } from 'hooks/useDefineDevice';

import { ITimelineItem } from 'api/models/responses/projects/timeline';
import { ToggleSection } from 'components/ToggleSection';
import { IUpdateTimeline } from 'pages/Projects/types';

import { Timeline } from './Timeline';

export interface IToggleTimelineContainerProps extends IUpdateTimeline {
	projectKey?: number;
	setIsUpdateProject: (isUpdate: boolean) => void;
}

export const TimelineContainer: FC<IToggleTimelineContainerProps> = ({
	projectKey,
	isUpdateTimeline,
	setIsUpdateProject,
	setIsUpdateTimeline,
}) => {
	const [timeline, setTimeline] = useState<ITimelineItem[]>([]);
	const [isPending, setIsPending] = useState(false);

	const { isOpen, handleToggle } = useToggleSection(false);
	const { isMobile } = useDefineDevice();

	if (isMobile) return null;

	return (
		<ToggleSection
			isOpen={isOpen}
			title="Timeline"
			handleToggle={handleToggle}
			className="preset-details-header"
		>
			<Timeline
				timeline={timeline}
				isPending={isPending}
				projectKey={projectKey}
				setTimeline={setTimeline}
				setIsPending={setIsPending}
				isUpdateTimeline={isUpdateTimeline}
				setIsUpdateProject={setIsUpdateProject}
				setIsUpdateTimeline={setIsUpdateTimeline}
			/>
		</ToggleSection>
	);
};
