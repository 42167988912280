import { ITab } from 'types/tab';

import { StudioPriceListTabs } from './studioPriceListTabs';

export const studioPriceListTabsList: ITab<StudioPriceListTabs>[] = [
	{
		id: StudioPriceListTabs.PriceListOptions,
		title: 'Price List Options',
	},
	{
		id: StudioPriceListTabs.Packages,
		title: 'Packages',
	},
	{
		id: StudioPriceListTabs.ALaCarte,
		title: 'A la Carté',
	},
	{
		id: StudioPriceListTabs.AddOns,
		title: 'Add Ons (coming soon)',
		disabled: true,
	},
];
