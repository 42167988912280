import { createSelector } from 'reselect';

import { RootState } from 'store/store';

const journeyPresetsStateSelector = (state: RootState) => state.journeyPresets;

export const journeyPresetsSelector = createSelector(
	journeyPresetsStateSelector,
	({ journeyPresets }) => journeyPresets
);

export const isJourneyPresetsPendingSelector = createSelector(
	journeyPresetsStateSelector,
	({ isJourneyPresetsPending }) => isJourneyPresetsPending
);

export const isPaginatedJourneyPresetsPendingSelector = createSelector(
	journeyPresetsStateSelector,
	({ isPaginatedJourneyPresetsPending }) => isPaginatedJourneyPresetsPending
);

export const selectedJourneyPresetSelector = createSelector(
	journeyPresetsStateSelector,
	({ selectedJourneyPreset }) => selectedJourneyPreset
);

export const filterByJourneySelector = createSelector(
	journeyPresetsStateSelector,
	({ filterByJourney }) => filterByJourney
);

export const filterByProjectTypeSelector = createSelector(
	journeyPresetsStateSelector,
	({ filterByProjectType }) => filterByProjectType
);

export const copiedJourneyPresetKeySelector = createSelector(
	journeyPresetsStateSelector,
	({ copiedJourneyPresetKey }) => copiedJourneyPresetKey
);

export const createJourneyPresetValuesSelector = createSelector(
	journeyPresetsStateSelector,
	({ createJourneyPresetValues }) => createJourneyPresetValues
);
