import { FC, useEffect } from 'react';
import cn from 'classnames';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { usePagination } from 'hooks/usePagination';

import { Loader } from 'components/Loader';
import {
	journeyPresetsSelector,
	setSelectedJourneyPreset,
	selectedJourneyPresetSelector,
	isPaginatedJourneyPresetsPendingSelector,
} from 'store/journeyPresets';
import { IStep1Props } from '../../..';

export const JourneyPresets: FC<
	Pick<IStep1Props, 'getPaginatedJourneyPresets'>
> = ({ getPaginatedJourneyPresets }) => {
	const selectedJourneyPreset = useAppSelector(selectedJourneyPresetSelector);
	const journeyPresets = useAppSelector(journeyPresetsSelector);
	const isPaginatedJourneyPresetsPending = useAppSelector(
		isPaginatedJourneyPresetsPendingSelector
	);

	const dispatch = useAppDispatch();

	const ref = usePagination(getPaginatedJourneyPresets);

	const handleSelectPreset = (presetKey: number) => {
		const searchedPreset = journeyPresets.results.find(
			(preset) => preset.presetKey === presetKey
		);

		if (!searchedPreset) return;

		dispatch(setSelectedJourneyPreset(searchedPreset));
	};

	const isEmpty = !journeyPresets.results.length;

	const PresetsList = journeyPresets.results.map(
		({ presetKey, presetName }) => (
			<li
				key={presetKey}
				onClick={() => handleSelectPreset(presetKey)}
				className={cn('presets-journey-item', {
					'is-active': selectedJourneyPreset?.presetKey === presetKey,
				})}
			>
				{presetName}
			</li>
		)
	);

	useEffect(() => {
		if (selectedJourneyPreset) return;

		dispatch(setSelectedJourneyPreset(journeyPresets.results[0]));
	}, [journeyPresets, selectedJourneyPreset]);

	return (
		<>
			<span className="preset-title">Journey Presets</span>
			<ul
				className={cn('presets-journey-list', {
					'is-empty': isEmpty,
				})}
			>
				{PresetsList}
				{isEmpty && (
					<li className="presets-journey-item">No Journey Presets Found</li>
				)}
				{isPaginatedJourneyPresetsPending && <Loader className="loader-sm" />}
				<li>
					<div ref={ref} style={{ height: '0.1px' }} />
				</li>
			</ul>
		</>
	);
};
