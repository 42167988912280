import { FC, useRef } from 'react';

import { Button } from 'components/FormControls/Button';
import { Modal, ModalRef } from 'components/Modal';

import { useAccountingBreadcrumbs } from './hooks/useAccountingBreadcrumbs';
import { BillingInformation } from './components/BillingInformation';
import { BankInformation } from './components/BankInformation';
import { PlanInformation } from './components/PlanInformation';
import { WInformation } from './components/WInformation';
import { CloseAccount } from './components/CloseAccount';

export const Accounting: FC = () => {
	const closeAccRef = useRef<ModalRef>(null);

	useAccountingBreadcrumbs();

	const showCloseAccModal = () => {
		closeAccRef?.current?.open();
	};

	const hideCloseAccModal = () => {
		closeAccRef?.current?.close();
	};

	return (
		<div className="order-container">
			<PlanInformation />
			<BillingInformation />
			<BankInformation />
			<WInformation />
			<Button
				value="Close Account"
				handleClick={showCloseAccModal}
				className="btn-secondary accing-footer-btn"
			/>
			<Modal
				title="You are about to close your imago account."
				subtitle="This will delete your images, order history, projects, price lists, marketing, etc. You will no longer have access to this information. This action cannot be undone."
				ref={closeAccRef}
			>
				<CloseAccount />
			</Modal>
		</div>
	);
};
