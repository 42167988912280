import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { getInitialPaginationResponse } from 'constants/general/pagination/getInitialPaginationResponse';
import { SKIP, TAKE } from 'constants/general/generalGetDataParams';
import { ROUTES } from 'constants/ROUTES';

import { IOrganizationProject } from 'api/models/responses/projects/organizationProjects';
import { IPaginationResponse } from 'api/models/responses/general/paginationResponse';
import ProjectsService from 'api/services/ProjectsService';
import {
	GeneralProjectSortLabels,
	UnitedOrgDetailsProjectSortLabels,
} from 'api/models/requests/projects/projectsParams';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useToggleSection } from 'hooks/useToggleSection';
import { useSortParams } from 'hooks/useSortParams';

import { ToggleSection } from 'components/ToggleSection';
import { Button } from 'components/FormControls/Button';
import { NoItemsFound } from 'components/NoItemsFound';
import { TableLoader } from 'components/TableLoader';

import { setOrganizationKey } from 'store/projects';
import { GeneralTabs } from 'types/generalTabs';

import { ProjectList } from './ProjectList';

interface IOrganizationProjectsProps {
	organizationKey: number;
}

interface IProjectTab {
	title: string;
	projectType: GeneralTabs;
}

const projectsTabs: IProjectTab[] = [
	{ title: GeneralTabs.Active, projectType: GeneralTabs.Active },
	{
		title: `${GeneralTabs.Archived} > 60 Days`,
		projectType: GeneralTabs.Archived,
	},
];

const initialProjects = getInitialPaginationResponse<IOrganizationProject>();

export const OrganizationProjects: FC<IOrganizationProjectsProps> = ({
	organizationKey,
}) => {
	const [projectType, setProjectType] = useState<GeneralTabs>(
		GeneralTabs.Active
	);

	const [activeProjects, setActiveProjects] =
		useState<IPaginationResponse<IOrganizationProject>>(initialProjects);
	const [expiredProjects, setExpiredProjects] =
		useState<IPaginationResponse<IOrganizationProject>>(initialProjects);
	const [isPending, setIsPending] = useState(false);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { orderBy, orderDirection, setSortParams } =
		useSortParams<UnitedOrgDetailsProjectSortLabels>({
			defaultOrderBy: GeneralProjectSortLabels.ProjectName,
		});
	const { isOpen, handleToggle } = useToggleSection(true);

	const tableData =
		projectType === GeneralTabs.Active ? activeProjects : expiredProjects;

	const handleSelectTab = (projectsTypeParam: GeneralTabs) => {
		setProjectType(projectsTypeParam);
	};

	const handleCreateProject = () => {
		if (!organizationKey) return;

		dispatch(setOrganizationKey(organizationKey));
		navigate(ROUTES.PROJECTS.CREATE_PROJECT);
	};

	const setPaginatedProjects = async () => {
		const isPaginationLimit =
			tableData.totalCount === tableData.results.length &&
			tableData.totalCount >= 0;

		if (isPaginationLimit || isPending || !organizationKey) return;

		const projectParams = {
			orderBy,
			take: TAKE,
			orderDirection,
			skip: tableData.results.length,
		};

		setIsPending(true);

		if (projectType === GeneralTabs.Active) {
			try {
				const data = await ProjectsService.getActiveOrganizationProjects(
					organizationKey,
					projectParams
				);

				setActiveProjects({
					totalCount: data.totalCount,
					results: [...tableData.results, ...data.results],
				});
			} catch (error) {
				console.log(error);
			}
		}

		if (projectType === GeneralTabs.Archived) {
			try {
				const data = await ProjectsService.getExpiredOrganizationProjects(
					organizationKey,
					projectParams
				);

				setExpiredProjects({
					totalCount: data.totalCount,
					results: [...tableData.results, ...data.results],
				});
			} catch (error) {
				console.log(error);
			}
		}

		setIsPending(false);
	};

	const getOrganizationProjects = useCallback(async () => {
		if (!organizationKey || isPending) return;

		const projectParams = {
			orderBy,
			skip: SKIP,
			take: TAKE,
			orderDirection,
		};

		setIsPending(true);

		if (projectType === GeneralTabs.Active) {
			try {
				const data = await ProjectsService.getActiveOrganizationProjects(
					organizationKey,
					projectParams
				);

				setActiveProjects(data);
			} catch (error) {
				console.log(error);
			}
		}

		if (projectType === GeneralTabs.Archived) {
			try {
				const data = await ProjectsService.getExpiredOrganizationProjects(
					organizationKey,
					projectParams
				);

				setExpiredProjects(data);
			} catch (error) {
				console.log(error);
			}
		}

		setIsPending(false);
	}, [orderBy, projectType, orderDirection, organizationKey]);

	useEffect(() => {
		void getOrganizationProjects();
	}, [getOrganizationProjects]);

	const ProjectTabsList = projectsTabs.map((projectTab) => (
		<li
			key={projectTab.title}
			className={cn('price-header-item', {
				'is-active': projectType === projectTab.projectType,
			})}
			onClick={() => handleSelectTab(projectTab.projectType)}
		>
			{projectTab.title}
		</li>
	));

	return (
		<ToggleSection
			isOpen={isOpen}
			title="Projects"
			className="price-org-title"
			handleToggle={handleToggle}
			header={
				<Button
					value="+ New Project"
					handleClick={handleCreateProject}
					className="btn-primary org-details-btn"
				/>
			}
		>
			<section className="org-section-price">
				<div className="org-header-list-container">
					<ul className="org-header-list">{ProjectTabsList}</ul>
				</div>
				{!!tableData.results.length && (
					<ProjectList
						orderBy={orderBy}
						projects={tableData}
						className="price-table"
						setSortParams={setSortParams}
						orderDirection={orderDirection}
						setPaginatedProjects={setPaginatedProjects}
					/>
				)}
				{!tableData.results.length && !isPending && (
					<NoItemsFound title="projects" />
				)}
				{isPending && <TableLoader />}
			</section>
		</ToggleSection>
	);
};
