import { useNavigate } from 'react-router-dom';
import { FC, useState } from 'react';

import { ConfirmDelete } from 'components/Modal/components/ConfirmDelete';
import { Button } from 'components/FormControls/Button';
import { Modal } from 'components/Modal';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useModalControls } from 'hooks/useModalControls';

import ProjectsService from 'api/services/ProjectsService';
import {
	ErrorTypes,
	IErrorResponse,
} from 'api/models/responses/general/errorResponse';

import { breadcrumbsSelector } from 'store/breadcrumbs';
import { ROUTES } from 'constants/ROUTES';

import { CreatePreset } from '../CreatePreset';
import { SuccessCreate } from '../SuccessCreate';

interface IProjectControlsProps {
	projectKey?: number;
	projectName?: string;
}

export const ProjectControls: FC<IProjectControlsProps> = ({
	projectKey,
	projectName,
}) => {
	const [presetKey, setPresetKey] = useState<number | null>(null);

	const breadcrumbs = useAppSelector(breadcrumbsSelector);

	const navigate = useNavigate();

	const {
		modalRef: confirmDeleteModalRef,
		showModal: showConfirmDeleteModal,
		hideModal: hideConfirmDeleteModal,
	} = useModalControls();

	const {
		modalRef: rejectDeleteModalRef,
		showModal: showRejectDeleteModal,
		hideModal: hideRejectDeleteModal,
	} = useModalControls();

	const {
		modalRef: successDeleteModalRef,
		showModal: showSuccessDeleteModal,
		hideModal: hideSuccessDeleteModal,
	} = useModalControls();

	const {
		modalRef: createPresetModalRef,
		showModal: showCreatePresetModal,
		hideModal: hideCreatePresetModal,
	} = useModalControls();

	const {
		modalRef: successModalRef,
		showModal: showSuccessModal,
		hideModal: hideSuccessModal,
	} = useModalControls();

	const handleSuccessDeleteProject = () => {
		hideSuccessDeleteModal();

		const organizationPath = breadcrumbs[breadcrumbs.length - 2].path;

		navigate(organizationPath);
	};

	const handlePermanentlyDelete = async () => {
		if (!projectKey) return;

		try {
			await ProjectsService.deleteProject(projectKey);

			hideConfirmDeleteModal();
			showSuccessDeleteModal();
		} catch (error) {
			const { type, errors } = error as IErrorResponse;

			if (type === ErrorTypes.BusinessError && errors.project) {
				hideConfirmDeleteModal();
				showRejectDeleteModal();
			}
		}
	};

	const handleViewPreset = () => {
		if (!presetKey) return;

		navigate(`${ROUTES.PRESETS.PRESETS}/${presetKey}`);
	};

	const projectNameUI = projectName || '';

	return (
		<>
			<div className="project-details-btns">
				<Button
					value="Save as Preset"
					handleClick={showCreatePresetModal}
					className="btn-secondary project-details-save-preset"
				/>
				<Button
					value="Delete this Project"
					handleClick={showConfirmDeleteModal}
					className="btn-secondary project-details-delete"
				/>
			</div>
			<Modal
				ref={successModalRef}
				title="New Preset Created"
				subtitle="This preset has been created and will now be available for you to use when creating projects in the future."
			>
				<SuccessCreate
					handleView={handleViewPreset}
					handleClose={hideSuccessModal}
				/>
			</Modal>
			<Modal
				ref={createPresetModalRef}
				title="Create New Preset"
				subtitle="What would you like to call your new preset?"
			>
				<CreatePreset
					projectKey={projectKey}
					setPresetKey={setPresetKey}
					showSuccessModal={showSuccessModal}
					hideCreateModal={hideCreatePresetModal}
				/>
			</Modal>
			<Modal
				className="modal-info"
				title="Are you sure?"
				ref={confirmDeleteModalRef}
				subtitle={`Are you sure you would like to delete the project ${projectNameUI}? This action cannot be undone.`}
			>
				<ConfirmDelete
					hideConfirmDeleteModal={hideConfirmDeleteModal}
					handlePermanentlyDelete={handlePermanentlyDelete}
				/>
			</Modal>
			<Modal
				className="modal-info"
				title="Project Deleted"
				ref={successDeleteModalRef}
				subtitle={`The project ${projectNameUI} has been deleted.`}
			>
				<div className="org-modal-agreement">
					<Button
						value="OK"
						className="btn-primary"
						handleClick={handleSuccessDeleteProject}
					/>
				</div>
			</Modal>
			<Modal
				ref={rejectDeleteModalRef}
				title="Cannot Delete Project"
				subtitle="There are people with active shopping carts in this project. Please try again later. You may need to wait up to 3 hours for their checkouts to be completed and abandoned shopping carts removed. In the meantime we recommend expiring the project to avoid further waiting."
			>
				<Button
					value="OK"
					handleClick={hideRejectDeleteModal}
					className="org-modal-btn btn-primary"
				/>
			</Modal>
		</>
	);
};
