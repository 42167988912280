import { ISelectOption } from 'types/ui/select';

import { priceListFulfillmentDescriptions } from '../priceListFulfillmentDescriptions';
import { PriceListFulfillment } from '../priceListFulfillment';

export const priceListFulfillmentSelectOptions: ISelectOption<PriceListFulfillment>[] =
	Object.values(PriceListFulfillment)
		.filter((fulfillment) => fulfillment !== PriceListFulfillment.NA)
		.map((fulfillment) => ({
			label: `${fulfillment} ${priceListFulfillmentDescriptions[fulfillment]}`,
			value: fulfillment,
		}));
