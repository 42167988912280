interface ICreateProductBtnProps {
	optionName: string;
	showSaveBtn: boolean;
	handleAdd: () => void;
	handleSave: () => Promise<void> | void;
}

interface ICreateProductBtnResult {
	btnValue: string;
	btnClassName: string;
	btnClickHandler: () => void;
}

export const useCreatePriceListOptionBtn = ({
	handleAdd,
	optionName,
	handleSave,
	showSaveBtn,
}: ICreateProductBtnProps): ICreateProductBtnResult => {
	const createPackageBtnValue = showSaveBtn
		? `Save ${optionName}`
		: `+ Add ${optionName}`;

	const createPackageBtnClassName = showSaveBtn
		? 'btn-primary'
		: 'btn-secondary';

	const createPackageBtnHandleClick = showSaveBtn ? handleSave : handleAdd;

	return {
		btnValue: createPackageBtnValue,
		btnClassName: createPackageBtnClassName,
		btnClickHandler: createPackageBtnHandleClick,
	};
};
