import { IStudioMarketing } from 'api/models/responses/studio/studioMarketing';
import { IPatchBody } from 'api/models/requests/general/patchBody';

import { studioMarketingUrls } from 'constants/apiUrls/studioMarketingUrls';
import { api } from 'config/api';

class StudioMarketingService {
	async getStudioMarketing(): Promise<IStudioMarketing> {
		const { data } = await api.get<IStudioMarketing>(
			studioMarketingUrls.getStudioMarketing
		);

		return data;
	}

	async updateStudioMarketing(body: IPatchBody[]): Promise<IStudioMarketing> {
		const { data } = await api.patch<IStudioMarketing>(
			studioMarketingUrls.getStudioMarketing,
			body
		);

		return data;
	}

	async createPhoneNumber(): Promise<IStudioMarketing> {
		const { data } = await api.put<IStudioMarketing>(
			studioMarketingUrls.createPhoneNumber,
			{}
		);

		return data;
	}
}

export default new StudioMarketingService();
