import { ICreateStudioDefaultBackgroundOption } from 'api/models/requests/priceLists/studio/priceListOptions/createStudioDefaultBackgroundOption';
import { ICreateStudioBackgroundOption } from 'api/models/requests/priceLists/studio/priceListOptions/createStudioBackgroundOption';
import { IStudioBackgroundOption } from 'api/models/responses/priceLists/studio/priceListOptions/backgroundOptions';
import { IPatchBody } from 'api/models/requests/general/patchBody';

import { multipartHeaders } from 'constants/images/multipartHeaders';
import { priceListsUrls } from 'constants/apiUrls/priceListsUrls';

import { api } from 'config/api';

class StudioBackgroundOptionsService {
	async createStudioBackgroundOption(
		priceListKey: number,
		{
			name,
			sequence,
			retailPrice,
			referenceCode,
			backgroundImage,
		}: ICreateStudioBackgroundOption
	): Promise<IStudioBackgroundOption> {
		const formData = new FormData();

		formData.set('Name', name);
		formData.set('ReferenceCode', referenceCode);
		formData.set('Sequence', sequence.toString());
		formData.set('BackgroundImage', backgroundImage);
		formData.set('RetailPrice', retailPrice.toString());

		const { data } = await api.post<IStudioBackgroundOption>(
			priceListsUrls.studioFulfilled.backgroundOptions.createBackgroundOption(
				priceListKey
			),
			formData,
			{ ...multipartHeaders }
		);

		return data;
	}

	async createDefaultBackgroundOption(
		priceListKey: number,
		body: ICreateStudioDefaultBackgroundOption
	): Promise<IStudioBackgroundOption> {
		const { data } = await api.post<IStudioBackgroundOption>(
			priceListsUrls.studioFulfilled.backgroundOptions.createDefaultBackgroundOption(
				priceListKey
			),
			body
		);

		return data;
	}

	async updateBackgroundOption(
		backgroundOptionKey: number,
		body: IPatchBody[]
	): Promise<IStudioBackgroundOption> {
		const { data } = await api.patch<IStudioBackgroundOption>(
			priceListsUrls.studioFulfilled.backgroundOptions.getBackgroundOptionUrl(
				backgroundOptionKey
			),
			body
		);

		return data;
	}

	async deleteBackgroundOption(backgroundOptionKey: number): Promise<void> {
		await api.delete<string>(
			priceListsUrls.studioFulfilled.backgroundOptions.getBackgroundOptionUrl(
				backgroundOptionKey
			)
		);
	}

	async deleteBackgroundOptions(priceListKey: number): Promise<void> {
		await api.delete<string>(
			priceListsUrls.studioFulfilled.backgroundOptions.deleteBackgroundOptions(
				priceListKey
			)
		);
	}

	async reorderBackgroundOptions(
		priceListKey: number,
		body: Record<number, number>
	): Promise<void> {
		await api.put<string>(
			priceListsUrls.studioFulfilled.backgroundOptions.reorderBackgroundOptions(
				priceListKey
			),
			body
		);
	}
}

export default new StudioBackgroundOptionsService();
