import { FC } from 'react';

import { useAsyncOptimizedCheckbox } from 'hooks/formControls/useAsyncOptimizedCheckbox';
import { useAppSelector } from 'hooks/redux/useAppSelector';

import { StudioPoseOptionTypes } from 'api/models/responses/priceLists/studio/priceListOptions/poseOption';
import { Checkbox } from 'components/FormControls/Checkbox';
import {
	showPoseCheckboxSelector,
	showStyleCheckboxSelector,
	showColorFlavorCheckboxSelector,
} from 'store/priceLists/studio/priceListPoseOptions';
import { showStudioBackgroundCheckboxSelector } from 'store/priceLists/priceListBackgrounds';

interface IPoseOptionsCheckBoxesProps {
	sequence: number;
	requiredPoseOptions: string;
	isBackgroundOptionRequired: boolean;
	updateRequiredPoseOptions: (posesRequired: string) => Promise<void>;
	updateIsBackgroundOptionRequired: (value: boolean) => Promise<void>;
}

export const PoseOptionsCheckBoxes: FC<IPoseOptionsCheckBoxesProps> = ({
	sequence,
	requiredPoseOptions,
	updateRequiredPoseOptions,
	isBackgroundOptionRequired,
	updateIsBackgroundOptionRequired,
}) => {
	const showStyleCheckbox = useAppSelector(showStyleCheckboxSelector);
	const showPoseCheckbox = useAppSelector(showPoseCheckboxSelector);
	const showStudioBackgroundCheckbox = useAppSelector(
		showStudioBackgroundCheckboxSelector
	);
	const showColorFlavorCheckbox = useAppSelector(
		showColorFlavorCheckboxSelector
	);

	const backgroundOption = useAsyncOptimizedCheckbox(
		isBackgroundOptionRequired,
		updateIsBackgroundOptionRequired
	);

	const posesInitial = requiredPoseOptions.includes(StudioPoseOptionTypes.Pose);
	const stylesInitial = requiredPoseOptions.includes(
		StudioPoseOptionTypes.Style
	);
	const colorFlavorsInitial = requiredPoseOptions.includes(
		StudioPoseOptionTypes.ColorFlavor
	);

	const updateRequiredPoseOption = async (
		value: boolean,
		poseOptionType: string
	) => {
		if (value) {
			const updatedRequiredPoseOptions = `${requiredPoseOptions.replace(
				StudioPoseOptionTypes.None,
				''
			)}, ${poseOptionType}`
				.split(',')
				.map((option) => option.trim())
				.filter((option) => option)
				.join(', ');

			return updateRequiredPoseOptions(updatedRequiredPoseOptions);
		}

		const updatedRequiredPoseOptions = requiredPoseOptions
			.split(',')
			.map((option) => option.trim())
			.filter((option) => option && option !== poseOptionType)
			.join(', ');

		return updateRequiredPoseOptions(
			updatedRequiredPoseOptions || StudioPoseOptionTypes.None
		);
	};

	const updatePosesRequired = async (value: boolean) =>
		updateRequiredPoseOption(value, StudioPoseOptionTypes.Pose);

	const poseOptions = useAsyncOptimizedCheckbox(
		posesInitial,
		updatePosesRequired
	);

	const updateStylesRequired = async (value: boolean) =>
		updateRequiredPoseOption(value, StudioPoseOptionTypes.Style);

	const styleOptions = useAsyncOptimizedCheckbox(
		stylesInitial,
		updateStylesRequired
	);

	const updateColorFlavorsRequired = async (value: boolean) =>
		updateRequiredPoseOption(value, StudioPoseOptionTypes.ColorFlavor);

	const colorFlavorOptions = useAsyncOptimizedCheckbox(
		colorFlavorsInitial,
		updateColorFlavorsRequired
	);

	return (
		<>
			{showStudioBackgroundCheckbox && (
				<Checkbox
					label="Background"
					id={`background-${sequence}`}
					checked={backgroundOption.checked}
					disabled={backgroundOption.isPending}
					handleChange={backgroundOption.handleChange}
				/>
			)}
			{showPoseCheckbox && (
				<Checkbox
					label="Poses"
					id={`poses-${sequence}`}
					checked={poseOptions.checked}
					disabled={poseOptions.isPending}
					handleChange={poseOptions.handleChange}
				/>
			)}
			{showStyleCheckbox && (
				<Checkbox
					label="Styles"
					id={`styles-${sequence}`}
					checked={styleOptions.checked}
					disabled={styleOptions.isPending}
					handleChange={styleOptions.handleChange}
				/>
			)}
			{showColorFlavorCheckbox && (
				<Checkbox
					label="Color Flavors"
					id={`colorFlavors-${sequence}`}
					checked={colorFlavorOptions.checked}
					disabled={colorFlavorOptions.isPending}
					handleChange={colorFlavorOptions.handleChange}
				/>
			)}
		</>
	);
};
