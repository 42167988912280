import { Dispatch } from '@reduxjs/toolkit';

import { IPaginatedPresetsParams } from 'api/models/requests/presets/paginatedPresetsParams';
import PresetsService from 'api/services/PresetsService';

import {
	setJourneyPresets,
	deleteJourneyPreset,
	setIsJourneyPresetsPending,
	setIsPaginatedJourneyPresetsPending,
	setPaginatedJourneyPresets,
} from './journeyPresetsSlice';

export const getJourneyPresetsAsync =
	(params: IPaginatedPresetsParams) => async (dispatch: Dispatch) => {
		const isPaginated = params.skip > 0;

		if (isPaginated) {
			dispatch(setIsPaginatedJourneyPresetsPending(true));
		} else {
			dispatch(setIsJourneyPresetsPending(true));
		}

		try {
			const presets = await PresetsService.getPresets(params);

			if (isPaginated) {
				dispatch(setPaginatedJourneyPresets(presets));
			} else {
				dispatch(setJourneyPresets(presets));
			}
		} catch (error) {
			console.log(error);
		}

		dispatch(setIsJourneyPresetsPending(false));
		dispatch(setIsPaginatedJourneyPresetsPending(false));
	};

export const deleteJourneyPresetAsync =
	(presetKey: number) => async (dispatch: Dispatch) => {
		dispatch(deleteJourneyPreset(presetKey));

		try {
			await PresetsService.deletePreset(presetKey);
		} catch (error) {
			console.log(error);
		}
	};
