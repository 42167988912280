import { FC } from 'react';

import { projectTypesSelectOptions } from 'constants/projects/selectOptions/projectTypesSelectOptions';
import { journeyTimelines } from 'constants/projects/timeline/journeyTimelines';

import { ProjectJourneys } from 'api/models/requests/projects/projectJourneys';
import { ProjectTypes } from 'api/models/requests/projects/projectTypes';

import { JourneyTimeline } from 'components/Journey/JourneyTimeline';
import { SelectComponent } from 'components/FormControls/Select';

import { useAsyncOptimizedSelect } from 'hooks/formControls/useAsyncOptimizedSelect';

import { UpdatePresetHandler } from '../../types';
import { InputGrid } from 'components/FormControls/InputGrid';
import { getJourneyType } from 'utils/journeyTimeline/getJourneyType';

interface IJourneyPresetTimelineProps {
	journey: ProjectJourneys;
	projectType: ProjectTypes;
	updatePreset: UpdatePresetHandler;
}

export const JourneyPresetTimeline: FC<IJourneyPresetTimelineProps> = ({
	journey,
	projectType,
	updatePreset,
}) => {
	const updateProjectType = async (value: ProjectTypes) => {
		await updatePreset({
			value,
			key: 'projectType',
		});
	};

	const { value, isPending, handleChange } = useAsyncOptimizedSelect({
		initialValue: projectType,
		updateSelectValue: updateProjectType,
	});

	const journeyType = getJourneyType(journeyTimelines[journey]);

	const {
		journeyText,
		journeyTitle,
		journeySubText,
		journeyBlankProjectImage: journeyImage,
	} = journeyTimelines[journey];

	return (
		<>
			{/* TODO: add styles for placing project type and journey in row */}
			<div className="preset-section-wrapper preset-wrapper-header pres-details">
				<SelectComponent
					value={value}
					disabled={isPending}
					label="Project Type"
					onChange={handleChange}
					className="select-required"
					selectOptions={projectTypesSelectOptions}
				/>
				<InputGrid
					disabled
					readOnly
					value={journeyType}
					label="Journey Type"
					className="autorow preset-header-input"
				/>
			</div>
			<div className="preset-journey">
				<JourneyTimeline
					journeyText={journeyText}
					journeyTitle={journeyTitle}
					journeyImage={journeyImage}
					journeySubText={journeySubText}
				/>
			</div>
		</>
	);
};
