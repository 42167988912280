import { IPatchBody } from 'api/models/requests/general/patchBody';
import {
	ISubjectFormQuestion,
	ISubjectFormQuestionUniq,
} from 'api/models/responses/projects/subjectFormQuestion';

import { api } from 'config/api';

class SubjectFormQuestionsService {
	async getSubjectFormQuestions(
		url: string
	): Promise<ISubjectFormQuestionUniq[]> {
		const { data } = await api.get<ISubjectFormQuestionUniq[]>(url);

		return data;
	}

	async createSubjectFormQuestion(
		url: string,
		body: ISubjectFormQuestion
	): Promise<ISubjectFormQuestionUniq> {
		const { data } = await api.post<ISubjectFormQuestionUniq>(url, body);

		return data;
	}

	async updateSubjectFormQuestion(
		url: string,
		body: IPatchBody[]
	): Promise<ISubjectFormQuestionUniq> {
		const { data } = await api.patch<ISubjectFormQuestionUniq>(url, body);

		return data;
	}

	async deleteSubjectFormQuestion(url: string): Promise<void> {
		await api.delete<boolean>(url);
	}

	async reorderSubjectFormQuestions(
		url: string,
		body: Record<number, number>
	): Promise<void> {
		await api.put<string>(url, body);
	}
}

export default new SubjectFormQuestionsService();
