import { number, object, Schema, string } from 'yup';

import { requiredFieldMessage } from 'constants/general/validation/generalMessages';
import {
	emailRegEx,
	maxEmailLength,
	emailErrorMessage,
	maxEmailLengthMessage,
} from 'constants/general/validation/emailValidation';

import { ISendLinkBody } from 'api/models/requests/dashboard/sendLink';

const emailAndPhoneMessage =
	'Please provide either an email address or a mobile phone number.';

export const sendLinkValidationSchema = object().shape<
	Partial<Record<keyof ISendLinkBody, Schema>>
>(
	{
		projectKey: number().required(requiredFieldMessage),
		emailAddress: string().when('mobilePhoneNumber', {
			is: (value: string) => !value?.length,
			then: () =>
				string()
					.required(emailAndPhoneMessage)
					.max(maxEmailLength, maxEmailLengthMessage)
					.matches(emailRegEx, emailErrorMessage),
		}),
		mobilePhoneNumber: string().when('emailAddress', {
			is: (value: string) => !value?.length,
			then: () => string().required(emailAndPhoneMessage),
		}),
	},
	[['emailAddress', 'mobilePhoneNumber']]
);
