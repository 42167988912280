import { FC } from 'react';

import { DeleteBtn } from 'components/Controls/DeleteBtn';

interface IImagoBackgroundsHeaderProps {
	handleDeleteBackgroundOptions: () => void;
}

export const ImagoBackgroundsHeader: FC<IImagoBackgroundsHeaderProps> = ({
	handleDeleteBackgroundOptions,
}) => (
	// TODO: add styles for the parent div
	<div style={{ display: 'flex', alignItems: 'center' }}>
		<span className="price-container-subtitle">Background Options</span>
		<DeleteBtn handleClick={handleDeleteBackgroundOptions} />
	</div>
);
