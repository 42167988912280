import { object, string, ref } from 'yup';

import { requiredFieldMessage } from 'constants/general/validation/generalMessages';
import {
	maxRoutingLength,
	maxAccountingLength,
	maxAccountLengthError,
	maxRoutingLengthError,
	minAccountingLength,
	minAccountingLengthError,
} from 'constants/studioBilling/validation/bankACH';

const requiredFieldValidation = string().required(requiredFieldMessage);

const billingPhoneFieldValidation = object().shape({
	firstPartPhone: requiredFieldValidation,
	secondPartPhone: requiredFieldValidation,
	thirdPartPhone: requiredFieldValidation,
});

const ssnFieldValidation = object().shape({
	firstPartSsn: string(),
	secondPartSsn: string(),
	thirdPartSsn: string(),
});

const einFieldValidation = object().shape({
	firstPartEin: string(),
	secondPartEin: string(),
});

const tinFieldValidation = object()
	.shape({
		ssn: ssnFieldValidation,
		ein: einFieldValidation,
	})
	.test('tin', '', (values, context) => {
		const { firstPartSsn, secondPartSsn, thirdPartSsn } = values.ssn;
		const { firstPartEin, secondPartEin } = values.ein;

		const isSsnEmpty = !firstPartSsn && !secondPartSsn && !thirdPartSsn;
		const isEinEmpty = !firstPartEin && !secondPartEin;

		if (isSsnEmpty && isEinEmpty) {
			return context.createError({
				path: 'tin.ssn.firstPartSsn',
				message: 'Employer identification number is required',
			});
		}

		return true;
	});

const confirmBankAccountNumberFieldValidation = string()
	.required(requiredFieldMessage)
	.equals(
		[ref<string>('bankAccountNumber')],
		'This field should be equal to Account #'
	);

const confirmBankRoutingNumberFieldValidation = string()
	.required(requiredFieldMessage)
	.equals(
		[ref<string>('bankRoutingNumber')],
		'This field should be equal to Routing #'
	);

export const bankACHValidationSchema = {
	bankAccountName: requiredFieldValidation,

	bankAccountNumber: requiredFieldValidation
		.min(minAccountingLength, minAccountingLengthError)
		.max(maxAccountingLength, maxAccountLengthError),

	confirmBankAccountNumber: confirmBankAccountNumberFieldValidation,

	bankRoutingNumber: requiredFieldValidation.max(
		maxRoutingLength,
		maxRoutingLengthError
	),

	confirmBankRoutingNumber: confirmBankRoutingNumberFieldValidation,
};

export const getPaidFormValidationScheme = object().shape({
	...bankACHValidationSchema,
	billingFirstName: requiredFieldValidation,
	billingLastName: requiredFieldValidation,
	billingBusinessName: requiredFieldValidation,
	billingStreet: requiredFieldValidation,
	billingCity: requiredFieldValidation,
	billingState: requiredFieldValidation,
	billingPostalCode: requiredFieldValidation,
	billingPhone: billingPhoneFieldValidation,
	billingEmail: requiredFieldValidation,
	billingEntityType: requiredFieldValidation,
	tin: tinFieldValidation,
	billingW9Initials: requiredFieldValidation,
});
