import { createSelector } from 'reselect';

import { RootState } from 'store/store';

const studioBillingSelector = (state: RootState) => state.studioBilling;

export const bankACHSelector = createSelector(
	studioBillingSelector,
	({ bankACH }) => bankACH
);

export const isBankACHFulfilledSelector = createSelector(
	studioBillingSelector,
	({ isBankACHFulfilled }) => isBankACHFulfilled
);

export const billingInfoSelector = createSelector(
	studioBillingSelector,
	({ studioBilling }) => studioBilling
);

export const isStudioBillingFulfilledSelector = createSelector(
	studioBillingSelector,
	({ iStudioBillingFulfilled }) => iStudioBillingFulfilled
);

export const cardInfoSelector = createSelector(
	studioBillingSelector,
	({ cardInfo }) => cardInfo
);
