import { useEffect, useRef } from 'react';

import { usePasswordInput } from 'hooks/formControls/usePasswordInput';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useToggleSection } from 'hooks/useToggleSection';

import { InputGrid } from 'components/FormControls/InputGrid';
import { ToggleSection } from 'components/ToggleSection';
import { Button } from 'components/FormControls/Button';
import { Modal, ModalRef } from 'components/Modal';

import { bankACHSelector, getBankACHInfoAsync } from 'store/studioBilling';

import { UpdateBankAccount } from '../UpdateBankAccount';

export const BankInformation = () => {
	const bankACH = useAppSelector(bankACHSelector);

	const updateBankAccRef = useRef<ModalRef>(null);

	const dispatch = useAppDispatch();

	const { isOpen, handleToggle } = useToggleSection(true);

	const routingInputControls = usePasswordInput();
	const accountInputControls = usePasswordInput();

	const showUpdateBankAccModal = () => {
		updateBankAccRef?.current?.open();
	};

	const hideUpdateBankAccModal = () => {
		updateBankAccRef?.current?.close();
	};

	useEffect(() => {
		void dispatch(getBankACHInfoAsync());
	}, []);

	return (
		<div className="accing-toggle-section">
			<ToggleSection
				title="Bank ACH Information"
				isOpen={isOpen}
				className="accing-toggle-header"
				handleToggle={handleToggle}
			>
				<div className="accing-wrapper">
					<span className="accing-title">
						This is the account we will payout your profits to.
					</span>
					<div className="accing-list-wrapper">
						<ul className="accing-field-list">
							<li className="accing-field-item">
								<InputGrid
									row
									isLazy
									readOnly
									required={false}
									label="Routing #"
									type={routingInputControls.inputType}
									defaultValue={bankACH?.bankRoutingNumber}
								/>
								<span
									className="acc-toggle-btn"
									onClick={routingInputControls.handleShowValue}
								>
									{routingInputControls.toggleValue}
								</span>
							</li>
							<li className="accing-field-item">
								<InputGrid
									row
									isLazy
									readOnly
									required={false}
									label="Account #"
									type={accountInputControls.inputType}
									defaultValue={bankACH?.bankAccountNumberLastFour}
								/>
								<span
									className="acc-toggle-btn"
									onClick={accountInputControls.handleShowValue}
								>
									{accountInputControls.toggleValue}
								</span>
							</li>
						</ul>
						<Button
							className="btn-secondary accing-btn"
							value="Update Bank Account"
							handleClick={showUpdateBankAccModal}
						/>
					</div>
				</div>
			</ToggleSection>
			<Modal
				title="Update Bank Account ACH Details"
				subtitle="This account will be used for all profit payouts for this studio."
				ref={updateBankAccRef}
			>
				<UpdateBankAccount closeModal={hideUpdateBankAccModal} />
			</Modal>
		</div>
	);
};
