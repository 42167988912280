import { imageGalleryUrls } from 'constants/apiUrls/imageGalleryUrls';
import { IGalleryImage } from 'api/models/responses/imageGallery';
import { api } from 'config/api';

class ImageGalleryService {
	async getGalleryImages(): Promise<IGalleryImage[]> {
		const { data } = await api.get<IGalleryImage[]>(
			imageGalleryUrls.imageGalleryUrl
		);

		return data;
	}

	async uploadImage(imageFile: File): Promise<IGalleryImage> {
		const formData = new FormData();

		formData.append('image', imageFile);

		const { data } = await api.post<IGalleryImage>(
			imageGalleryUrls.imageGalleryUrl,
			formData
		);

		return data;
	}

	async deleteGalleryImage(galleryImageKey: number): Promise<void> {
		await api.delete<string>(
			imageGalleryUrls.deleteGalleryImage(galleryImageKey)
		);
	}
}

export default new ImageGalleryService();
