import { StudioPoseOptionTypes } from 'api/models/responses/priceLists/studio/priceListOptions/poseOption';

interface IPoseOptionTypesUI {
	singleLabel: string;
	multiLabel: string;
}

export const studioPoseOptionTypesUI: Record<
	StudioPoseOptionTypes,
	IPoseOptionTypesUI
> = {
	[StudioPoseOptionTypes.None]: {
		singleLabel: 'None',
		multiLabel: 'None',
	},
	[StudioPoseOptionTypes.Pose]: {
		singleLabel: 'Pose',
		multiLabel: 'Poses',
	},
	[StudioPoseOptionTypes.Style]: {
		singleLabel: 'Style',
		multiLabel: 'Styles',
	},
	[StudioPoseOptionTypes.ColorFlavor]: {
		singleLabel: 'Color Flavor',
		multiLabel: 'Color Flavors',
	},
	[StudioPoseOptionTypes.Backgrounds]: {
		singleLabel: 'Background',
		multiLabel: 'Backgrounds',
	},
};
