import { allowedKeyWords } from 'constants/broadcasts/allowedKeyWords';

interface IValidationResult {
	isValidKeyWords: boolean;
	invalidKeyWords: string[];
}

export const validateKeyWords = (keyWords: string[]): IValidationResult => {
	const invalidKeyWords = keyWords.reduce<string[]>((acc, keyWord) => {
		const isValidKeyWord = allowedKeyWords.includes(keyWord);

		if (!isValidKeyWord) {
			acc.push(keyWord);
		}

		return acc;
	}, []);

	return {
		invalidKeyWords,
		isValidKeyWords: !invalidKeyWords.length,
	};
};
