import { FC } from 'react';

import { GiftAndAccessoriesProductCategoryTypes } from 'api/models/responses/catalogProducts/giftAndAccessoriesCatalogProduct';
import {
	ICatalogProduct,
	CatalogProductCategoryTypes,
} from 'api/models/responses/catalogProducts/catalogProducts';

import { giftAndAccessoriesCatalogProductTypesUI } from 'constants/priceLists/imago/giftAndAccessoriesCatalogProductTypesUI';
import { imagoTableHeaders } from 'constants/priceLists/imago/imagoTableHeaders';

import { useCreatePriceListOptionBtn } from 'pages/PriceLists/hooks/useCreatePriceListOptionBtn';
import { IProductUI } from 'pages/PriceLists/types/imago/productUI';
import { PriceTable } from 'pages/PriceLists/components/PriceTable';

import { ToggleSection } from 'components/ToggleSection';
import { Button } from 'components/FormControls/Button';
import { NoItemsFound } from 'components/NoItemsFound';

import { useToggleSection } from 'hooks/useToggleSection';

import { useImagoProducts } from '../../../hooks/useImagoProducts';
import { Product } from '../../../components/Product';

export type SetGiftsAndAccessoriesProductsUI = (
	productsUI: IProductUI[]
) => void;

interface IProductContainerProps {
	priceListKey?: number;
	catalogProducts: ICatalogProduct[];
	giftsAndAccessoriesProductsUI: IProductUI[];
	gifProductCategoryType: GiftAndAccessoriesProductCategoryTypes;
	setGiftsAndAccessoriesProductsUI: SetGiftsAndAccessoriesProductsUI;
}

const headers = imagoTableHeaders();

export const ProductContainer: FC<IProductContainerProps> = ({
	priceListKey,
	catalogProducts,
	gifProductCategoryType,
	giftsAndAccessoriesProductsUI,
	setGiftsAndAccessoriesProductsUI,
}) => {
	const initialIsOpen = !!giftsAndAccessoriesProductsUI.filter(
		({ key }) => key === gifProductCategoryType
	).length;

	const { isOpen, handleToggle } = useToggleSection(initialIsOpen);

	const {
		updateProduct,
		deleteProduct,
		lastProductUI,
		previewImageFile,
		handleAddProduct,
		handleSaveProduct,
		clearProductError,
		uploadProductImage,
		deleteProductImage,
		updateCatalogProduct,
		changeProductSequence,
		catalogProductsSelectOptions,
	} = useImagoProducts({
		priceListKey,
		gifProductCategoryType,
		setGiftsAndAccessoriesProductsUI,
		specialCatalogProducts: catalogProducts,
		specialProductsUI: giftsAndAccessoriesProductsUI,
		catalogProductCategoryType: CatalogProductCategoryTypes.GiftsAndAccessories,
	});

	const productsUI = giftsAndAccessoriesProductsUI.filter(
		(product) => product.key === gifProductCategoryType
	);

	const ProductsList = productsUI.map((productUI) => (
		<Product
			productUI={productUI}
			updateProduct={updateProduct}
			deleteProduct={deleteProduct}
			previewImageFile={previewImageFile}
			clearProductError={clearProductError}
			uploadProductImage={uploadProductImage}
			deleteProductImage={deleteProductImage}
			productSelectPlaceholder="Select Product"
			updateCatalogProduct={updateCatalogProduct}
			changeProductSequence={changeProductSequence}
			catalogProductsSelectOptions={catalogProductsSelectOptions}
			key={productUI.priceListImagoFulfilledProductKey || productUI.sequence}
		/>
	));

	const showSaveBtn =
		!!productsUI.length &&
		lastProductUI?.key === gifProductCategoryType &&
		!lastProductUI?.priceListImagoFulfilledProductKey;

	const gifProductCategoryTypeUI =
		giftAndAccessoriesCatalogProductTypesUI[gifProductCategoryType];

	const { btnValue, btnClassName, btnClickHandler } =
		useCreatePriceListOptionBtn({
			showSaveBtn,
			handleAdd: handleAddProduct,
			handleSave: handleSaveProduct,
			optionName: gifProductCategoryTypeUI,
		});

	return (
		<ToggleSection
			isOpen={isOpen}
			handleToggle={handleToggle}
			title={gifProductCategoryTypeUI}
			className="price-search-container"
		>
			<div className="price-container price-imago-toggle">
				{productsUI.length ? (
					<PriceTable className="price-imago-package" headers={headers}>
						{ProductsList}
					</PriceTable>
				) : (
					<NoItemsFound title={gifProductCategoryTypeUI.toLowerCase()} />
				)}
				<Button
					value={btnValue}
					handleClick={btnClickHandler}
					className={`price-add-back ${btnClassName}`}
				/>
			</div>
		</ToggleSection>
	);
};
