/* eslint-disable no-useless-catch */
import { FC } from 'react';

import { IProject } from 'api/models/responses/projects/projectDetails';
import ProjectsService from 'api/services/ProjectsService';

import { CoverImageContainer } from 'pages/Organizations/pages/OrganizationDetailsPage/components/OrganizationDetails/CoverImageContainer';

import { FormFields } from './components/FormFields';
import { ProjectInfo } from './components/ProjectInfo';

export interface IProjectDetailsProps {
	projectDetails: IProject;
	setProjectDetails: (projectDetails: IProject) => void;
}

export const ProjectDetails: FC<IProjectDetailsProps> = ({
	projectDetails,
	setProjectDetails,
}) => {
	const {
		projectKey,
		projectUrl,
		projectName,
		projectType,
		shortUrlCode,
		shortUrlDomain,
		projectUniqueId,
		estimatedNumberOfSubjects,
		projectCoverImagePreviewUrl,
	} = projectDetails;

	const handleUploadProjectCoverImage = async (file: File) => {
		try {
			const data = await ProjectsService.setCoverImage(
				projectDetails.projectKey,
				file
			);

			setProjectDetails({
				...projectDetails,
				projectCoverImagePreviewUrl: data,
			});
		} catch (error) {
			throw error;
		}
	};

	const handleDeleteProjectCoverImage = async () => {
		try {
			await ProjectsService.deleteCoverImage(projectDetails.projectKey);

			setProjectDetails({
				...projectDetails,
				projectCoverImagePreviewUrl: '',
			});
		} catch (error) {
			throw error;
		}
	};

	return (
		<section className="prj-details-section">
			<div className="project-details-section">
				<FormFields
					projectKey={projectKey}
					projectUrl={projectUrl}
					projectName={projectName}
					projectType={projectType}
					setProjectDetails={setProjectDetails}
					estimatedNumberOfSubject={estimatedNumberOfSubjects}
				/>
				<ProjectInfo
					projectName={projectName}
					shortUrlCode={shortUrlCode}
					shortUrlDomain={shortUrlDomain}
					projectUniqueId={projectUniqueId}
				/>
			</div>
			<CoverImageContainer
				label="Project Cover Image"
				handleDeleteImage={handleDeleteProjectCoverImage}
				handleUploadImage={handleUploadProjectCoverImage}
				coverImagePreviewRelativePath={projectCoverImagePreviewUrl}
			/>
		</section>
	);
};
