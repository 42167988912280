import { FC } from 'react';

export const PriceListPoseOptionsPrompt: FC = () => (
	<div className="price-prompt">
		<span className="price-prompt-label">
			Yearbook Pose Options <i>(coming soon)</i>
		</span>
		<span className="price-prompt-info">
			Prompt parents to confirm the selected yearbook pose
		</span>
	</div>
);
