import { SingleValue } from 'react-select';
import { useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import { ChangeEvent, FC } from 'react';

import { getProjectDateTypesSelectOptions } from 'constants/broadcasts/selectOptions/projectDateTypesSelectOptions';
import { saleConditionSelectOptions } from 'constants/projects/selectOptions/saleConditionSelectOptions';
import { maxDaysNumber, minDaysNumber } from 'constants/broadcasts/validation';
import { maxTimeH, minTimeH } from 'constants/broadcasts/time';
import { TimeZonesUI } from 'constants/studio/timeZonesUI';
import {
	classicDatePickerConfig,
	getOnlyTimeDatePickerConfig,
} from 'constants/general/datePickerConfigs';

import { ProjectDateTypes } from 'api/models/responses/broadcasts/broadcast';
import { SaleCondition } from 'api/models/requests/presets/createPresets';
import { CampaignsTarget } from 'api/models/responses/campaigns/campaign';

import { SelectComponent } from 'components/FormControls/Select';
import { InputGrid } from 'components/FormControls/InputGrid';
import { ToggleSection } from 'components/ToggleSection';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useToggleSection } from 'hooks/useToggleSection';

import { ISelectOption } from 'types/ui/select';
import { timezoneSelector } from 'store/studio';

import { IBroadcastValues } from '../../..';

const onlyTimeDatePickerConfig = getOnlyTimeDatePickerConfig(
	minTimeH,
	maxTimeH
);

interface IBroadcastSettingsProps {
	disabledEdit: boolean;
	target?: CampaignsTarget;
}

export const BroadcastSettings: FC<IBroadcastSettingsProps> = ({
	target,
	disabledEdit,
}) => {
	const { values, errors, touched, handleChange, setFieldValue } =
		useFormikContext<IBroadcastValues>();

	const timeZone = useAppSelector(timezoneSelector);

	const { isOpen, handleToggle } = useToggleSection(true);

	const handleChangeDays = (e: ChangeEvent<HTMLInputElement>) => {
		const { id, value } = e.target;

		const valueNumber = +value;

		if (!value) {
			void setFieldValue(id, '');
			return;
		}

		if (isNaN(valueNumber)) return;

		if (valueNumber > maxDaysNumber) {
			void setFieldValue(id, maxDaysNumber);
			return;
		}

		if (valueNumber < minDaysNumber) {
			void setFieldValue(id, minDaysNumber);
			return;
		}

		void setFieldValue(id, valueNumber);
	};

	const handleChangeCondition = (
		option: SingleValue<ISelectOption<SaleCondition>>
	) => {
		if (!option) return;

		void setFieldValue('condition', option.value);
	};

	const handleChangeProjectDateType = (
		option: SingleValue<ISelectOption<ProjectDateTypes>>
	) => {
		if (!option) return;

		void setFieldValue('projectDateType', option.value);
	};

	const handleChangeTime = (date: Date) => {
		void setFieldValue('time', date);
	};

	const handleChangeSpecialDate = (date: Date) => {
		void setFieldValue('specialDate', date);
	};

	const projectDateTypesSelectOptions = target
		? getProjectDateTypesSelectOptions(target)
		: [];

	const isSpecialDate = values.projectDateType === ProjectDateTypes.SpecialDate;
	const showSpecialDateError = !!(touched.specialDate && errors.specialDate);

	return (
		<div className="campaign-toggle-section">
			<ToggleSection
				isOpen={isOpen}
				title="Broadcast Settings"
				handleToggle={handleToggle}
				className="campaign-section-header"
			>
				<div className="campaign-section-wrapper campaign-section-fields">
					<label className="label">Broadcast Name</label>
					<InputGrid
						row
						required
						id="broadcastName"
						label="Broadcast Name"
						disabled={disabledEdit}
						handleChange={handleChange}
						error={errors.broadcastName}
						value={values.broadcastName}
						placeholder="Broadcast Name"
						touched={touched.broadcastName}
						className="autorow campaign-input"
					/>
					<label className="label">Trigger Broadcast</label>
					<div className="campaign-section-grid">
						<InputGrid
							row
							id="days"
							required={false}
							placeholder="Days"
							className="autorow"
							disabled={disabledEdit}
							label="Trigger Broadcast"
							value={values.days.toString()}
							handleChange={handleChangeDays}
						/>
						<span>Days</span>
						<SelectComponent
							disabled={disabledEdit}
							value={values.condition}
							onChange={handleChangeCondition}
							selectOptions={saleConditionSelectOptions}
						/>
						<SelectComponent
							disabled={disabledEdit}
							value={values.projectDateType}
							onChange={handleChangeProjectDateType}
							selectOptions={projectDateTypesSelectOptions}
						/>
					</div>
					{isSpecialDate && (
						<div className="campaign-section-form">
							<label className="label">Special Date</label>
							<DatePicker
								{...classicDatePickerConfig}
								id="specialDate"
								disabled={disabledEdit}
								selected={values.specialDate}
								onChange={handleChangeSpecialDate}
							/>
							{/* TODO: add styles for error */}
							{showSpecialDateError && <span>{errors.specialDate}</span>}
						</div>
					)}
					<div className="campaign-section-form">
						<label className="label">Send After</label>
						<div>
							<DatePicker
								{...onlyTimeDatePickerConfig}
								selected={values.time}
								disabled={disabledEdit}
								onChange={handleChangeTime}
							/>
						</div>
						<span>{timeZone && TimeZonesUI[timeZone]}</span>
					</div>
				</div>
			</ToggleSection>
		</div>
	);
};
