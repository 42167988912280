import { IPriceListFormQuestionUI } from 'pages/PriceLists/types/studio/priceListFormQuestionUI';
import { ISubjectFormQuestionUI } from 'components/SubjectFormQuestions/types';

export const isValidSubjectFormQuestions = (
	subjectFormQuestions: ISubjectFormQuestionUI[]
) =>
	subjectFormQuestions.every(({ errors }) =>
		Object.values(errors).every((value) => !value)
	);

export const isValidPriceFormQuestions = (
	subjectFormQuestions: IPriceListFormQuestionUI[]
) =>
	subjectFormQuestions.every(({ errors }) =>
		Object.values(errors).every((value) => !value)
	);
