import { useEffect } from 'react';

import { CUSTOM_BACKGROUND_OPTION_KEY } from 'constants/priceLists/selectOptions/backgroundFileCustomSelectOption';
import { PackageOptionTypes } from 'constants/priceLists/studio/packageOptionTypes';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';

import {
	deletePackageOptionsSection,
	emptyOwnPackageOptionsSelector,
	resetAddedPackageOptionTypes,
} from 'store/priceLists/studio/priceListPackageOptions';
import {
	deletePoseOptionSection,
	emptyPoseOptionsSelector,
	emptyStyleOptionsSelector,
	emptyStudioBackgroundsSelector,
	emptyColorFlavorOptionsSelector,
	resetAddedPoseOptionTypes,
} from 'store/priceLists/studio/priceListPoseOptions';
import {
	setBackgroundFileKey,
	setCustomBackgroundFile,
} from 'store/priceLists/priceListBackgrounds';

import { StudioPoseOptionTypes } from 'api/models/responses/priceLists/studio/priceListOptions/poseOption';

export const useClearEmptyStudioPriceListOptions = () => {
	const emptyOwnPackageOptions = useAppSelector(emptyOwnPackageOptionsSelector);
	const emptyStudioBackgrounds = useAppSelector(emptyStudioBackgroundsSelector);
	const emptyStyleOptions = useAppSelector(emptyStyleOptionsSelector);
	const emptyPoseOptions = useAppSelector(emptyPoseOptionsSelector);
	const emptyColorFlavorOptions = useAppSelector(
		emptyColorFlavorOptionsSelector
	);

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(resetAddedPoseOptionTypes());
		dispatch(resetAddedPackageOptionTypes());
		dispatch(setCustomBackgroundFile(null));
		dispatch(setBackgroundFileKey(CUSTOM_BACKGROUND_OPTION_KEY));

		if (emptyStudioBackgrounds) {
			dispatch(deletePoseOptionSection(StudioPoseOptionTypes.Backgrounds));
		}

		if (emptyOwnPackageOptions) {
			dispatch(deletePackageOptionsSection(PackageOptionTypes.BuildYourOwn));
		}

		if (emptyStyleOptions) {
			dispatch(deletePoseOptionSection(StudioPoseOptionTypes.Style));
		}

		if (emptyPoseOptions) {
			dispatch(deletePoseOptionSection(StudioPoseOptionTypes.Pose));
		}

		if (emptyColorFlavorOptions) {
			dispatch(deletePoseOptionSection(StudioPoseOptionTypes.ColorFlavor));
		}
	}, []);
};
