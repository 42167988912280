import { Link, useNavigate } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import { FC, useState } from 'react';

import { SortPromotionsLabels } from 'api/models/requests/promotions/paginatePromotionsParams';
import { IPromotion } from 'api/models/responses/promotions/promotion';
import { UnitTypes } from 'api/models/responses/general/unitTypes';

import { usePromotionsBreadcrumbs } from 'pages/Promotions/hooks/usePromotionsBreadcrumbs';
import { usePaginatePromotions } from 'pages/Promotions/hooks/usePaginatePromotions';

import { ColumnLabelContainer } from 'components/Table/ColumnLabelContainer';
import { CellActionMenu } from 'components/Table/CellActionMenu';
import { Button } from 'components/FormControls/Button';
import { NoItemsFound } from 'components/NoItemsFound';
import { Loader } from 'components/Loader';
import { Table } from 'components/Table';
import { Tabs } from 'components/Tabs';

import { promotionsTabsList } from 'constants/promotions/promotionsTabsList';
import {
	PromotionActionMenu,
	promotionActionMenuOptions,
} from 'constants/promotions/promotionActionMenu';
import { tabs } from 'constants/general/tabs';
import { ROUTES } from 'constants/ROUTES';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useDefineDevice } from 'hooks/useDefineDevice';
import { useActionMenu } from 'hooks/useActionMenu';
import { usePagination } from 'hooks/usePagination';

import { deletePromotionAsync, setCopiedPromotionKey } from 'store/promotions';
import { normalizeDigit } from 'utils/ui/normalizeDigit';
import { GeneralTabs } from 'types/generalTabs';

export const Promotions: FC = () => {
	const [activeTab, setActiveTab] = useState(GeneralTabs.Active);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	usePromotionsBreadcrumbs();

	const {
		orderBy,
		promotions,
		setSortParams,
		orderDirection,
		isPromotionsPending,
		getPaginatedPromotions,
		isPromotionsPaginationPending,
	} = usePaginatePromotions(tabs[activeTab]);

	const { actionMenuId, setActionMenuId } = useActionMenu();
	const ref = usePagination(getPaginatedPromotions);

	const { isMobile } = useDefineDevice();

	const handleCreatePromotion = () => {
		navigate(ROUTES.PROMOTIONS.CREATE_PROMOTION);
	};

	const handleMenuItemClick = (
		promotionKey: number,
		menuItem: PromotionActionMenu
	) => {
		switch (menuItem) {
			case PromotionActionMenu.DuplicatePromotion:
				dispatch(setCopiedPromotionKey(promotionKey));
				navigate(ROUTES.PROMOTIONS.CREATE_PROMOTION);
				break;

			case PromotionActionMenu.DeletePermanently:
				void dispatch(deletePromotionAsync(promotionKey));
				break;

			default:
				break;
		}
	};

	const columns: Column<IPromotion>[] = [
		{
			Header: (
				<ColumnLabelContainer
					orderBy={orderBy}
					label="Promotion"
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={SortPromotionsLabels.Name}
				/>
			),
			accessor: 'name',
			Cell: ({ row }: CellProps<IPromotion>) => {
				const { name, promotionKey } = row.original;

				return (
					<Link
						title={name}
						className="card-table-link"
						to={`${ROUTES.PROMOTIONS.PROMOTIONS}/${promotionKey}`}
					>
						{name}
					</Link>
				);
			},
		},
		{
			Header: (
				<ColumnLabelContainer
					label="Value"
					orderBy={orderBy}
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={SortPromotionsLabels.Value}
				/>
			),
			accessor: 'value',
			Cell: ({ row }: CellProps<IPromotion>) => {
				const { value, unitType } = row.original;

				const processedValue =
					unitType === UnitTypes.Dollar
						? normalizeDigit({ value, isPrice: true, minimumFractionDigits: 2 })
						: `${value}%`;

				return <>{processedValue} off</>;
			},
		},
		{
			Header: (
				<ColumnLabelContainer
					label="Type"
					orderBy={orderBy}
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={SortPromotionsLabels.Type}
				/>
			),
			accessor: 'type',
		},
		{
			Header: (
				<ColumnLabelContainer
					label="# of uses"
					orderBy={orderBy}
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={SortPromotionsLabels.NumberOfUses}
				/>
			),
			accessor: 'numberOfUses',
			Cell: ({ row }: CellProps<IPromotion>) => {
				const { numberOfUses } = row.original;

				return (
					<Link
						to="/"
						className="card-table-link"
						title={numberOfUses.toString()}
					>
						{numberOfUses}
					</Link>
				);
			},
		},
		{
			id: 'actionMenu',
			Cell: ({ row }: CellProps<IPromotion>) => {
				const { promotionKey } = row.original;

				return (
					<CellActionMenu
						menuList={promotionActionMenuOptions}
						showActionMenu={actionMenuId === promotionKey}
						handleMenuItemClick={(menuItem) =>
							handleMenuItemClick(promotionKey, menuItem)
						}
						setActiveActionMenu={() => setActionMenuId(promotionKey)}
					/>
				);
			},
		},
	];

	const showPromotions = !isPromotionsPending && !!promotions.length;
	const showNoPromotionsFound = !isPromotionsPending && !promotions.length;

	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<Tabs
					activeTab={activeTab}
					setActiveTab={setActiveTab}
					tabsList={promotionsTabsList}
					className="price-header-controls"
				/>
				{!isMobile && (
					<Button
						value="+ New Promotion"
						handleClick={handleCreatePromotion}
						className="btn-primary promotion-header-btn"
					/>
				)}
			</div>
			{isPromotionsPending && <Loader />}
			{showPromotions && (
				<div className="org-project-search-container">
					<Table
						columns={columns}
						data={promotions}
						className="org-table table-action-menu"
					/>
					<div ref={ref} style={{ height: '1px' }} />
				</div>
			)}
			{isPromotionsPaginationPending && (
				<div className="loader-container">
					<div className="loader" />
				</div>
			)}
			{showNoPromotionsFound && <NoItemsFound title="promotions" />}
			{isMobile && (
				<Button
					value="+ New Promotion"
					handleClick={handleCreatePromotion}
					className="btn-primary promotion-header-btn"
				/>
			)}
		</div>
	);
};
