import { useLocation, useParams } from 'react-router-dom';
import { FC, useEffect, useState } from 'react';

import SubjectFormQuestionsService from 'api/services/SubjectFormQuestionsService';
import { IUpdateCampaign } from 'api/models/requests/marketing/updateCampaign';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import { IPreset } from 'api/models/responses/presets/presets';
import PresetsService from 'api/services/PresetsService';
import {
	ErrorTypes,
	IErrorResponse,
} from 'api/models/responses/general/errorResponse';

import { ISubjectFormQuestionUI } from 'components/SubjectFormQuestions/types';
import { Loader } from 'components/Loader';

import { getReferencedItemsAsync } from 'store/projects';
import { IBreadcrumbs } from 'store/breadcrumbs/types';

import { useJourneyPresetBreadcrumbs } from 'pages/JourneyPresets/hooks/useJourneyPresetBreadcrumbs';
import { getSubjectFormQuestionsUI } from 'utils/subjectFormQuestions/getSubjectFormQuestionsUI';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { presetsUrls } from 'constants/apiUrls/presetsUrls';

import presetDetailsImage from 'images/presetDetailsImage.svg';

import { JourneyPresetFormQuestions } from './components/JourneyPresetFormQuestions';
import { JourneyPresetMarketing } from './components/JourneyPresetMarketing';
import { JourneyPresetTimeline } from './components/JourneyPresetTimeline';
import { JourneyPresetHeader } from './components/JourneyPresetHeader';
import { JourneyPresetSales } from './components/JourneyPresetSales';
import {
	SaleErrors,
	PresetErrors,
	IPresetSaleUI,
	IUpdatePreset,
	IUpdatePresetCampaign,
} from './types';

const initialSaleErrors: SaleErrors = {
	projectGreeting: '',
	saleExpiresInDays: '',
	orderNotificationEmail: '',
	orderConfirmationMessage: '',
};

const initialPresetErrors: PresetErrors = {
	presetName: '',
	leadCampaignKey: '',
	buyerCampaignKey: '',
	presetDescription: '',
	smsSpecialMessage: '',
	accessCodeMessage: '',
	emailSpecialMessage: '',
	prospectCampaignKey: '',
};

export const JourneyPresetDetails: FC = () => {
	const [presetDetails, setPresetDetails] = useState<IPreset | null>(null);
	const [presetSalesUI, setPresetSalesUI] = useState<IPresetSaleUI[]>([]);
	const [subjectFormQuestionsUI, setSubjectFormQuestionsUI] = useState<
		ISubjectFormQuestionUI[]
	>([]);

	const [presetErrors, setPresetErrors] =
		useState<PresetErrors>(initialPresetErrors);

	const [isFormQuestionsPending, setIsFormQuestionsPending] = useState(false);
	const [isPresetSalesPending, setIsPresetSalesPending] = useState(false);
	const [isPresetPending, setIsPresetPending] = useState(false);

	const { journeyPresetId } = useParams();
	const { pathname } = useLocation();
	const dispatch = useAppDispatch();

	const journeyPresetDetailsBreadcrumbs: IBreadcrumbs = {
		isActive: true,
		path: pathname,
		title: presetDetails?.presetName || '',
	};

	useJourneyPresetBreadcrumbs(journeyPresetDetailsBreadcrumbs);

	const getPreset = async (presetKey: number) => {
		setIsPresetPending(true);

		try {
			const data = await PresetsService.getPreset(presetKey);

			setPresetDetails(data);
		} catch (error) {
			console.log(error);
		}

		setIsPresetPending(false);
	};

	const getPresetSales = async (presetKey: number) => {
		setIsPresetSalesPending(true);

		try {
			const data = await PresetsService.getPresetSales(presetKey);

			const processedSalesUI = data.map((sale) => ({
				...sale,
				errors: initialSaleErrors,
			}));

			setPresetSalesUI(processedSalesUI);
		} catch (error) {
			console.log(error);
		}

		setIsPresetSalesPending(false);
	};

	const getPresetSubjectFormQuestions = async (presetKey: number) => {
		setIsFormQuestionsPending(true);

		try {
			const data = await SubjectFormQuestionsService.getSubjectFormQuestions(
				presetsUrls.getPresetsFormQuestions(presetKey)
			);

			setSubjectFormQuestionsUI(getSubjectFormQuestionsUI(data));
		} catch (error) {
			console.log(error);
		}

		setIsFormQuestionsPending(false);
	};

	const updatePreset = async ({
		key,
		value,
		validationMessage,
	}: IUpdatePreset) => {
		if (!journeyPresetId) return;

		if (validationMessage) {
			return setPresetErrors((prevState) => ({
				...prevState,
				[key]: validationMessage,
			}));
		}

		const updatedField: IPatchBody = {
			value,
			path: key,
			op: 'replace',
		};

		const body: IPatchBody[] = [updatedField];

		try {
			const data = await PresetsService.updatePreset(+journeyPresetId, body);

			setPresetDetails(data);
		} catch (error) {
			const { type, errors } = error as IErrorResponse;

			if (
				type === ErrorTypes.BusinessError ||
				type === ErrorTypes.ValidationError
			) {
				setPresetErrors((prevState) => ({ ...prevState, ...errors }));
			}
		}
	};

	const updatePresetCampaign = async ({
		value,
		target,
	}: IUpdatePresetCampaign) => {
		if (!journeyPresetId) return;

		const body: IUpdateCampaign = {
			campaignKey: value,
			campaignTarget: target,
		};

		try {
			const data = await PresetsService.updatePresetCampaign(
				+journeyPresetId,
				body
			);

			setPresetDetails(data);
		} catch (error) {
			console.log(error);
		}
	};

	const clearPresetError = (key: keyof PresetErrors) => {
		setPresetErrors((prevState) => ({
			...prevState,
			[key]: '',
		}));
	};

	useEffect(() => {
		if (!journeyPresetId) return;

		const journeyPresetIdNumber = +journeyPresetId;

		void getPreset(journeyPresetIdNumber);
		void getPresetSales(journeyPresetIdNumber);
		void getPresetSubjectFormQuestions(journeyPresetIdNumber);
	}, [journeyPresetId]);

	useEffect(() => {
		if (!presetDetails) return;

		const { journey, projectType } = presetDetails;

		if (!journey || !projectType) return;

		void dispatch(getReferencedItemsAsync({ journey, projectType }));
	}, [presetDetails?.journey]);

	const showLoader =
		isFormQuestionsPending || isPresetSalesPending || isPresetPending;

	if (showLoader) {
		return <Loader isFullHeight />;
	}

	if (!presetDetails) return null;

	return (
		<div className="preset-form">
			<div className="preset-details-back">
				<JourneyPresetHeader
					errors={presetErrors}
					updatePreset={updatePreset}
					clearPresetError={clearPresetError}
					presetName={presetDetails.presetName}
					description={presetDetails.presetDescription}
				/>
				<img
					alt="preset"
					src={presetDetailsImage}
					className="preset-header-img"
				/>
				<span className="preset-placeholder" />
				<div className="preset">
					<JourneyPresetTimeline
						updatePreset={updatePreset}
						journey={presetDetails.journey}
						projectType={presetDetails.projectType}
					/>
					<JourneyPresetSales
						presetSalesUI={presetSalesUI}
						setPresetSalesUI={setPresetSalesUI}
					/>
					<JourneyPresetMarketing
						errors={presetErrors}
						updatePreset={updatePreset}
						presetDetails={presetDetails}
						clearPresetError={clearPresetError}
						updateCampaign={updatePresetCampaign}
					/>
					<JourneyPresetFormQuestions
						presetKey={presetDetails.presetKey}
						subjectFormQuestions={subjectFormQuestionsUI}
						setSubjectFormQuestions={setSubjectFormQuestionsUI}
					/>
				</div>
			</div>
		</div>
	);
};
