import { Dispatch } from '@reduxjs/toolkit';

import StudioPhotoSettingsService from 'api/services/StudioPhotoSettingsService';
import { IStudioMarketing } from 'api/models/responses/studio/studioMarketing';
import { ICreateStudioBody } from 'api/models/requests/studio/createStudio';
import StudioMarketingService from 'api/services/StudioMarketingService';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import StudioService from 'api/services/StudioService';

import {
	setStudio,
	setAllStudios,
	setStudioMarketing,
	setStudioPhotoSettings,
} from './studioSlice';

export const createStudioAsync =
	(createStudioBody: ICreateStudioBody) => async (dispatch: Dispatch) => {
		const studio = await StudioService.createStudio(createStudioBody);

		dispatch(setStudio(studio));
	};

export const getStudioAsync = () => async (dispatch: Dispatch) => {
	try {
		const studio = await StudioService.getStudio();

		dispatch(setStudio(studio));
	} catch (error) {
		console.log(error);
	}
};

export const getAllStudiosAsync = () => async (dispatch: Dispatch) => {
	try {
		const studios = await StudioService.getAllStudios();

		dispatch(setAllStudios(studios));
	} catch (error) {
		console.log(error);
	}
};

export const getStudioMarketingAsync = () => async (dispatch: Dispatch) => {
	try {
		const data = await StudioMarketingService.getStudioMarketing();

		dispatch(setStudioMarketing(data));
	} catch (error) {
		console.log(error);
	}
};

export const getStudioPhotoSettingsAsync = () => async (dispatch: Dispatch) => {
	try {
		const data = await StudioPhotoSettingsService.getStudioPhotoSettings();

		dispatch(setStudioPhotoSettings(data));
	} catch (error) {
		console.log(error);
	}
};

export const updateStudioMarketingAsync =
	(path: keyof IStudioMarketing, value: string) =>
	async (dispatch: Dispatch) => {
		try {
			const updatedField: IPatchBody = {
				path,
				value,
				op: 'replace',
			};

			const body: IPatchBody[] = [updatedField];

			const data = await StudioMarketingService.updateStudioMarketing(body);

			dispatch(setStudioMarketing(data));
		} catch (error) {
			console.log(error);
		}
	};

export const createPhoneNumberAsync = () => async (dispatch: Dispatch) => {
	try {
		const data = await StudioMarketingService.createPhoneNumber();

		dispatch(setStudioMarketing(data));
	} catch (error) {
		console.log(error);
	}
};
