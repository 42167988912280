import { useState } from 'react';

import { validateForUniquenessSubjectFormQuestions } from 'utils/subjectFormQuestions/validateForUniquenessSubjectFormQuestions';
import { getInitialSubjectFormQuestion } from 'utils/subjectFormQuestions/getInitialSubjectFormQuestion';
import { isValidSubjectFormQuestions } from 'utils/subjectFormQuestions/isValidSubjectFormQuestions';
import { changeEntitiesSequence } from 'utils/dragAndDrop/changeEntitiesSequence';
import {
	validateOptions,
	validateFormQuestion,
	validateDataFieldType,
} from 'utils/validations/general/formQuestionsValidation';

import { ISubjectFormQuestionUniq } from 'api/models/responses/projects/subjectFormQuestion';
import SubjectFormQuestionsService from 'api/services/SubjectFormQuestionsService';
import { IPatchBody } from 'api/models/requests/general/patchBody';

import { subjectFormQuestionInitialError } from 'constants/projects/subjectFormQuestions/subjectFormQuestionInitialError';
import { ISubjectFormQuestionUI } from 'components/SubjectFormQuestions/types';
import { IClearErrorParams, IHandleUpdateParams } from 'pages/Projects/types';
import { IChangeSequencePayload } from 'hooks/useDragAndDrop';

interface IResult {
	isReordered: boolean;
	disabledFieldId: string;
	handleDeleteSubjectFormQuestion: (
		getUrl: (key: number) => string,
		localKey: string,
		subjectFormQuestionKey?: number
	) => Promise<void>;
	handleUpdateSubjectFromQuestion: (
		getUrl: (key: number) => string,
		updateParams: IHandleUpdateParams
	) => Promise<void>;
	handleChangeSubjectFormQuestionsSequence: (
		changeSequencePayload: IChangeSequencePayload
	) => void;
	handleCreateSubjectFormQuestion: () => void;
	reorderSubjectFormQuestions: (url: string) => Promise<void>;
	handleSaveSubjectFormQuestion: (url: string) => Promise<void>;
	clearSubjectFormQuestionFieldError: (params: IClearErrorParams) => void;
}

export const useSubjectFormQuestionCRUD = (
	subjectFormQuestions: ISubjectFormQuestionUI[],
	setSubjectFormQuestions: (
		subjectFormQuestions: ISubjectFormQuestionUI[]
	) => void
): IResult => {
	const [disabledFieldId, setDisabledFieldId] = useState('');
	const [isReordered, setIsReordered] = useState(false);

	const getSubjectFormQuestionUI = (
		localKey: string,
		subjectFormQuestion: ISubjectFormQuestionUniq
	): ISubjectFormQuestionUI[] =>
		subjectFormQuestions.map((subjectFormQuestionParam) =>
			subjectFormQuestionParam.localKey === localKey
				? {
						...subjectFormQuestion,
						localKey,
						errors: subjectFormQuestionInitialError,
				  }
				: subjectFormQuestionParam
		);

	const handleCreateSubjectFormQuestion = () => {
		const newFormQuestion = getInitialSubjectFormQuestion(
			subjectFormQuestions.length
		);

		setSubjectFormQuestions([...subjectFormQuestions, newFormQuestion]);
	};

	const handleSaveSubjectFormQuestion = async (url: string) => {
		try {
			const lastSubjectFormQuestion =
				subjectFormQuestions[subjectFormQuestions.length - 1];

			const optionsErrorMessage = validateOptions(
				lastSubjectFormQuestion.options
			);
			const formQuestionErrorMessage = validateFormQuestion(
				lastSubjectFormQuestion.formQuestion
			);
			const dataFieldErrorTypeMessage = validateDataFieldType(
				lastSubjectFormQuestion.dataFieldType
			);

			const updatedSubjectFormQuestion: ISubjectFormQuestionUI = {
				...lastSubjectFormQuestion,
				errors: {
					options: optionsErrorMessage,
					formQuestion:
						formQuestionErrorMessage ||
						lastSubjectFormQuestion.errors.formQuestion,
					dataFieldType:
						dataFieldErrorTypeMessage ||
						lastSubjectFormQuestion.errors.dataFieldType,
				},
			};

			const updatedSubjectFormQuestions = subjectFormQuestions.map(
				(subjectFormQuestion) =>
					subjectFormQuestion.localKey === updatedSubjectFormQuestion.localKey
						? updatedSubjectFormQuestion
						: subjectFormQuestion
			);

			const isValid = isValidSubjectFormQuestions(updatedSubjectFormQuestions);

			if (!isValid) {
				setSubjectFormQuestions(updatedSubjectFormQuestions);
				return;
			}

			const { localKey, errors, ...rest } = lastSubjectFormQuestion;

			const data = await SubjectFormQuestionsService.createSubjectFormQuestion(
				url,
				rest as ISubjectFormQuestionUniq
			);

			const subjectFormQuestionsUI = getSubjectFormQuestionUI(localKey, data);

			setSubjectFormQuestions(subjectFormQuestionsUI);
		} catch (error) {
			console.log(error);
		}
	};

	const handleUpdateSubjectFromQuestion = async (
		getUrl: (key: number) => string,
		updateParams: IHandleUpdateParams
	) => {
		try {
			const {
				value,
				localKey,
				fieldKey,
				validationMessage,
				subjectFormQuestionKey,
			} = updateParams;

			const updatedSubjectFormQuestions = subjectFormQuestions.map(
				(subjectFormQuestion) => {
					if (subjectFormQuestion.localKey !== localKey) {
						return subjectFormQuestion;
					}

					return {
						...subjectFormQuestion,
						[fieldKey]: value,
						errors: {
							...subjectFormQuestion.errors,
							[fieldKey]: validationMessage ?? '',
						},
					};
				}
			);

			const validatedSubjectFormQuestions =
				validateForUniquenessSubjectFormQuestions(updatedSubjectFormQuestions);

			setSubjectFormQuestions(validatedSubjectFormQuestions);

			const isValid = isValidSubjectFormQuestions(
				validatedSubjectFormQuestions
			);

			if (!subjectFormQuestionKey || !isValid) return;

			const updatedSubjectFormQuestionFields: IPatchBody = {
				value,
				op: 'replace',
				path: fieldKey,
			};

			const body: IPatchBody[] = [updatedSubjectFormQuestionFields];

			setDisabledFieldId(`${localKey}-${fieldKey}`);

			await SubjectFormQuestionsService.updateSubjectFormQuestion(
				getUrl(subjectFormQuestionKey),
				body
			);
		} catch (error) {
			console.log(error);
		}

		setDisabledFieldId('');
	};

	const handleDeleteSubjectFormQuestion = async (
		getUrl: (key: number) => string,
		localKey: string,
		subjectFormQuestionKey?: number
	) => {
		try {
			const filteredSubjectFormQuestions = subjectFormQuestions.filter(
				(subjectFormQuestion) => subjectFormQuestion.localKey !== localKey
			);

			const validatedSubjectFormQuestions =
				validateForUniquenessSubjectFormQuestions(filteredSubjectFormQuestions);

			if (subjectFormQuestionKey) {
				await SubjectFormQuestionsService.deleteSubjectFormQuestion(
					getUrl(subjectFormQuestionKey)
				);
			}

			setSubjectFormQuestions(validatedSubjectFormQuestions);
		} catch (error) {
			console.log(error);
		}
	};

	const reorderSubjectFormQuestions = async (url: string) => {
		try {
			const requestBody = subjectFormQuestions.reduce<Record<number, number>>(
				(acc, { subjectFormQuestionKey, sequence }) =>
					subjectFormQuestionKey
						? {
								...acc,
								[subjectFormQuestionKey]: sequence,
						  }
						: acc,
				{}
			);

			await SubjectFormQuestionsService.reorderSubjectFormQuestions(
				url,
				requestBody
			);

			setIsReordered(false);
		} catch (error) {
			console.log(error);
		}
	};

	const handleChangeSubjectFormQuestionsSequence = (
		changeSequencePayload: IChangeSequencePayload
	) => {
		const updatedSubjectFormQuestions = changeEntitiesSequence({
			...changeSequencePayload,
			fieldKey: 'localKey',
			entities: subjectFormQuestions,
		});

		setSubjectFormQuestions(updatedSubjectFormQuestions);
		setIsReordered(true);
	};

	const clearSubjectFormQuestionFieldError = (params: IClearErrorParams) => {
		const updatedSubjectFormQuestions = subjectFormQuestions.map(
			(subjectFormQuestion) => {
				if (subjectFormQuestion.localKey === params.localKey) {
					return {
						...subjectFormQuestion,
						errors: {
							...subjectFormQuestion.errors,
							[params.fieldKey]: '',
						},
					};
				}

				return subjectFormQuestion;
			}
		);

		setSubjectFormQuestions(updatedSubjectFormQuestions);
	};

	return {
		isReordered,
		disabledFieldId,
		reorderSubjectFormQuestions,
		handleSaveSubjectFormQuestion,
		handleCreateSubjectFormQuestion,
		handleUpdateSubjectFromQuestion,
		handleDeleteSubjectFormQuestion,
		clearSubjectFormQuestionFieldError,
		handleChangeSubjectFormQuestionsSequence,
	};
};
