import { IBaseOptions } from 'api/models/responses/priceLists/studio/priceListOptions/baseOptions';
import { IPriceListStudio } from 'api/models/responses/priceLists/studio/priceListStudio';
import { ICreatePriceList } from 'api/models/requests/priceLists/createPriceList';
import { IPatchBody } from 'api/models/requests/general/patchBody';

import { priceListsUrls } from 'constants/apiUrls/priceListsUrls';
import { api } from 'config/api';

class PriceListsStudioService {
	async getPriceListStudio(priceListKey: number): Promise<IPriceListStudio> {
		const { data } = await api.get<IPriceListStudio>(
			priceListsUrls.studioFulfilled.getPriceListStudioFulfilledUrl(
				priceListKey
			)
		);

		return data;
	}

	async createPriceListStudio(
		body: ICreatePriceList
	): Promise<IPriceListStudio> {
		const { data } = await api.post<IPriceListStudio>(
			priceListsUrls.studioFulfilled.createPriceListStudioFulfilled,
			body
		);

		return data;
	}

	async updatePriceListStudio(
		priceListKey: number,
		body: IPatchBody[]
	): Promise<IPriceListStudio> {
		const { data } = await api.patch<IPriceListStudio>(
			priceListsUrls.studioFulfilled.getPriceListStudioFulfilledUrl(
				priceListKey
			),
			body
		);

		return data;
	}

	async copyPriceListStudio(
		priceListKey: number,
		priceListName: string
	): Promise<IPriceListStudio> {
		const { data } = await api.post<IPriceListStudio>(
			priceListsUrls.studioFulfilled.copyPriceListStudioFulfilled(priceListKey),
			{
				name: priceListName,
			}
		);

		return data;
	}

	async deletePriceListStudio(priceListKey: number): Promise<string> {
		const { data } = await api.delete<string>(
			priceListsUrls.studioFulfilled.getPriceListStudioFulfilledUrl(
				priceListKey
			)
		);

		return data;
	}

	async getPriceListStudioBaseOptions(
		priceListKey: number
	): Promise<IBaseOptions> {
		const { data } = await api.get<IBaseOptions>(
			priceListsUrls.studioFulfilled.getBaseOptions(priceListKey)
		);

		return data;
	}
}

export default new PriceListsStudioService();
