import { FC, useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useToastify } from 'hooks/useToastify';

import { toastifyRequiredFields } from 'constants/general/toast/toastifyRequiredFields';
import { isValidSubjectFormQuestionsSelector } from 'store/projects';
import { IPresetSale } from 'api/models/responses/presets/presets';
import { SaleErrors } from 'components/Sales/Sale/types';

import presetDetailsImage from 'images/presetDetailsImage.svg';

import { JourneyPresetFormQuestions } from './components/JourneyPresetFormQuestions';
import { JourneyPresetMarketing } from './components/JourneyPresetMarketing';
import { JourneyPresetControls } from './components/JourneyPresetControls';
import { JourneyPresetTimeline } from './components/JourneyPresetTimeline';
import { JourneyPresetHeader } from './components/JourneyPresetHeader';
import { JourneyPresetSales } from './components/JourneyPresetSales';

import { IJourneyPresetValues } from '../types';

export interface IJourneyPresetFromProps {
	presetSales: IPresetSale[];
}

export const JourneyPresetForm: FC<IJourneyPresetFromProps> = ({
	presetSales,
}) => {
	const { errors, isSubmitting } = useFormikContext<IJourneyPresetValues>();

	const formQuestionsRef = useRef<HTMLDivElement>(null);

	const isValidSubjectFormQuestions = useAppSelector(
		isValidSubjectFormQuestionsSelector
	);

	const { showErrorsToastify } = useToastify();

	const doesErrorExist = !!Object.values(errors).length;

	useEffect(() => {
		if (!isSubmitting) return;

		const firstErrorEntries = Object.entries(errors)[0];

		if (firstErrorEntries) {
			const [key, message] = firstErrorEntries;

			if (key === 'presetSales') {
				const saleIndex = (message as SaleErrors[]).findIndex(
					(value) => !!value
				);

				const $fields = document.querySelectorAll('#presetSale');

				const $field = $fields[saleIndex];

				$field.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
			} else {
				const $field = document.getElementById(key);

				if ($field) {
					$field.scrollIntoView({
						behavior: 'smooth',
						block: 'center',
					});
				}
			}

			showErrorsToastify(toastifyRequiredFields);
		}
	}, [errors, isSubmitting]);

	useEffect(() => {
		if (isValidSubjectFormQuestions || !isSubmitting) return;

		if (!doesErrorExist && !isValidSubjectFormQuestions) {
			formQuestionsRef?.current?.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});

			showErrorsToastify(toastifyRequiredFields);
		}
	}, [isSubmitting, doesErrorExist, isValidSubjectFormQuestions]);

	return (
		<form className="preset-form">
			<div className="preset-details-back">
				<JourneyPresetHeader />
				<img
					alt="preset header"
					src={presetDetailsImage}
					className="preset-header-img"
				/>
				<span className="preset-placeholder" />
			</div>
			<div className="preset">
				<JourneyPresetTimeline />
				<JourneyPresetSales presetSales={presetSales} />
				<JourneyPresetMarketing />
				<JourneyPresetFormQuestions parentRef={formQuestionsRef} />
				<JourneyPresetControls />
			</div>
		</form>
	);
};
