import { ImagoPoseOptionTypes } from 'api/models/responses/priceLists/imago/priceListOptions/poseOptionTypes';
import { ISelectOption } from 'types/ui/select';

import { imagoPoseOptionTypesUI } from '../poseOptionTypesUI';

export const imagoPoseOptionTypesSelectOptions: ISelectOption<ImagoPoseOptionTypes>[] =
	Object.values(ImagoPoseOptionTypes)
		.filter((value) => value !== ImagoPoseOptionTypes.None)
		.map((value) => {
			const { singleLabel } = imagoPoseOptionTypesUI[value];

			return {
				value,
				label: singleLabel,
			};
		});
