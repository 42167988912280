import { IOwnPackageOption } from 'api/models/responses/priceLists/studio/priceListOptions/ownPackageOption';
import { ICreateOwnPackageOption } from 'api/models/requests/priceLists/studio/priceListOptions/createOwnPackageOption';
import { IPatchBody } from 'api/models/requests/general/patchBody';

import { multipartHeaders } from 'constants/images/multipartHeaders';
import { priceListsUrls } from 'constants/apiUrls/priceListsUrls';

import { api } from 'config/api';

class OwnPackageOptionsService {
	async getOwnPackageOptions(
		priceListKey: number
	): Promise<IOwnPackageOption[]> {
		const { data } = await api.get<IOwnPackageOption[]>(
			priceListsUrls.studioFulfilled.ownPackageOptions.getUrlByPriceListKey(
				priceListKey
			)
		);

		return data;
	}

	async createOwnPackageOption(
		priceListKey: number,
		{
			name,
			price,
			points,
			sequence,
			description,
			referenceCode,
			previewImageFile,
		}: ICreateOwnPackageOption
	): Promise<IOwnPackageOption> {
		const formData = new FormData();

		formData.set('Name', name);
		formData.set('Price', price.toString());
		formData.set('Description', description);
		formData.set('Points', points.toString());
		formData.set('ReferenceCode', referenceCode);
		formData.set('Sequence', sequence.toString());
		formData.set('PreviewImage', previewImageFile ?? '');

		const { data } = await api.post<IOwnPackageOption>(
			priceListsUrls.studioFulfilled.ownPackageOptions.getUrlByPriceListKey(
				priceListKey
			),
			formData
		);

		return data;
	}

	async updateOwnPackageOption(
		ownPackageOptionKey: number,
		body: IPatchBody[]
	): Promise<IOwnPackageOption> {
		const { data } = await api.patch<IOwnPackageOption>(
			priceListsUrls.studioFulfilled.ownPackageOptions.getUrlByOwnPackageOptionKey(
				ownPackageOptionKey
			),
			body
		);

		return data;
	}

	async deleteOwnPackageOption(ownPackageOptionKey: number): Promise<void> {
		await api.delete<string>(
			priceListsUrls.studioFulfilled.ownPackageOptions.getUrlByOwnPackageOptionKey(
				ownPackageOptionKey
			)
		);
	}

	async reorderOwnPackageOptions(
		priceListKey: number,
		body: Record<number, number>
	): Promise<void> {
		await api.put<string>(
			priceListsUrls.studioFulfilled.ownPackageOptions.reorderOwnPackageOptions(
				priceListKey
			),
			body
		);
	}

	async uploadOwnPackageOptionPreviewImage(
		ownPackageOptionKey: number,
		imageFile: File
	): Promise<string> {
		const formData = new FormData();

		formData.set('imageFile', imageFile);

		const { data } = await api.post<string>(
			priceListsUrls.studioFulfilled.ownPackageOptions.previewImageOwnPackageOption(
				ownPackageOptionKey
			),
			formData,
			{
				...multipartHeaders,
			}
		);

		return data;
	}

	async deleteOwnPackageOptionPreviewImage(
		ownPackageOptionKey: number
	): Promise<void> {
		await api.delete<string>(
			priceListsUrls.studioFulfilled.ownPackageOptions.previewImageOwnPackageOption(
				ownPackageOptionKey
			)
		);
	}
}

export default new OwnPackageOptionsService();
