import { FC } from 'react';

import { useAsyncOptimizedCheckbox } from 'hooks/formControls/useAsyncOptimizedCheckbox';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import { Checkbox } from 'components/FormControls/Checkbox';

import { IPriceListOptionsProps } from '../../../..';

interface IPresaleOptionsProps
	extends Pick<IPriceListOptionsProps, 'updatePriceListStudio'> {
	isAddBuddyPoseAtPresale?: boolean;
}

export const PresaleOptions: FC<IPresaleOptionsProps> = ({
	updatePriceListStudio,
	isAddBuddyPoseAtPresale,
}) => {
	const updateBuddyPoseAtPresale = async (value: boolean) => {
		if (!updatePriceListStudio) return;

		try {
			const updatedField: IPatchBody = {
				value,
				op: 'replace',
				path: 'isAddBuddyPoseAtPresale',
			};

			const body: IPatchBody[] = [updatedField];

			await updatePriceListStudio(body);
		} catch (error) {
			console.log(error);
		}
	};

	const { checked, isPending, handleChange } = useAsyncOptimizedCheckbox(
		isAddBuddyPoseAtPresale,
		updateBuddyPoseAtPresale
	);

	return (
		<div className="price-options multi">
			<h4 className="price-options-title">Presale Options</h4>
			<Checkbox
				checked={checked}
				disabled={isPending}
				id="isAddBuddyPoseAtPresale"
				handleChange={handleChange}
				label="Add Buddy Pose Prompt at Checkout"
			/>
		</div>
	);
};
