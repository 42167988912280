import { Formik } from 'formik';
import { FC } from 'react';

import { IUpdateStudioPhotoSettings } from 'api/models/requests/studioPhotoSettings';
import StudioPhotoSettingsService from 'api/services/StudioPhotoSettingsService';
import { WatermarkTypes } from 'api/models/responses/studio/studioPhotoSettings';
import {
	ErrorTypes,
	IErrorResponse,
} from 'api/models/responses/general/errorResponse';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useToastify } from 'hooks/useToastify';

import { setStudioPhotoSettings } from 'store/studio';

import { EditWatermarkForm } from './EditWatermarkForm';
import { IWatermarkError } from '../WatermarkSection';

export interface IEditWatermarkValues extends IUpdateStudioPhotoSettings {
	availableWatermark: boolean;
}

export interface IEditWatermarkProps {
	customWatermarkUrl: string;
	defaultWatermarkUrl: string;
	hideUpdateModal: () => void;
	initialDisabledWatermark: boolean;
	initialWatermarkType: WatermarkTypes;
	setUpdateWatermarkTimeLimitError: (error: IWatermarkError) => void;
}

export const EditWatermark: FC<IEditWatermarkProps> = ({
	hideUpdateModal,
	customWatermarkUrl,
	defaultWatermarkUrl,
	initialWatermarkType,
	initialDisabledWatermark,
	setUpdateWatermarkTimeLimitError,
}) => {
	const dispatch = useAppDispatch();

	const { showSuccessToastify } = useToastify();

	const initialValues: IEditWatermarkValues = {
		imageFile: null,
		isCustomWatermarkDeleted: false,
		watermarkType: initialWatermarkType,
		availableWatermark: !initialDisabledWatermark,
	};

	const onSubmit = async ({
		imageFile,
		watermarkType,
		availableWatermark,
		isCustomWatermarkDeleted,
	}: IEditWatermarkValues) => {
		try {
			const body: IUpdateStudioPhotoSettings = {
				isCustomWatermarkDeleted: availableWatermark
					? isCustomWatermarkDeleted
					: true,
				imageFile: availableWatermark ? imageFile : null,
				watermarkType: availableWatermark ? watermarkType : WatermarkTypes.No,
			};

			const data = await StudioPhotoSettingsService.updateStudioPhotoSettings(
				body
			);

			dispatch(setStudioPhotoSettings(data));

			showSuccessToastify({ title: 'Watermark Changed' });
		} catch (error) {
			const { type, errors } = error as IErrorResponse;

			const studioPhotoSettingsModel = errors.studioPhotoSettingsModel;

			if (type === ErrorTypes.BusinessError && studioPhotoSettingsModel) {
				setUpdateWatermarkTimeLimitError({
					message: studioPhotoSettingsModel[0],
				});
			}
		} finally {
			hideUpdateModal();
		}
	};

	return (
		<div className="media-watermark-modal">
			<Formik onSubmit={onSubmit} initialValues={initialValues}>
				<EditWatermarkForm
					customWatermarkUrl={customWatermarkUrl}
					defaultWatermarkUrl={defaultWatermarkUrl}
				/>
			</Formik>
		</div>
	);
};
