import { createSelector } from 'reselect';

import { packageOptionTypesSelectOptions } from 'constants/priceLists/studio/selectOptions/packageOptionTypesSelectOptions';
import { PackageOptionTypes } from 'constants/priceLists/studio/packageOptionTypes';

import { RootState } from 'store/store';

export const priceListPackageOptionsStateSelector = (state: RootState) =>
	state.studioPriceListPackageOptions;

export const groupedPackageOptionsSelector = createSelector(
	priceListPackageOptionsStateSelector,
	(state) => state.groupedPackageOptions
);

export const addedPackageOptionTypesSelector = createSelector(
	priceListPackageOptionsStateSelector,
	(state) => state.addedPackageOptionTypes
);

export const showOwnPackageOptionsSelector = createSelector(
	[groupedPackageOptionsSelector, addedPackageOptionTypesSelector],
	(groupedPackageOptions, addedPackageOptionTypes) =>
		!!groupedPackageOptions.BuildYourOwn.length ||
		addedPackageOptionTypes.BuildYourOwn
);

export const emptyOwnPackageOptionsSelector = createSelector(
	[addedPackageOptionTypesSelector, groupedPackageOptionsSelector],
	(addedPackageOptionTypes, groupedPackageOptions) => {
		const length = groupedPackageOptions.BuildYourOwn.length;

		const unsavedOption =
			!groupedPackageOptions.BuildYourOwn[length - 1]
				?.priceListStudioFulfilledOwnPackageOptionKey;

		const unsavedSingleOption = unsavedOption && length === 1;

		return (
			(!length || unsavedSingleOption) && addedPackageOptionTypes.BuildYourOwn
		);
	}
);

export const filteredPackageOptionTypesSelectOptionsSelector = createSelector(
	[showOwnPackageOptionsSelector],
	(showOwnPackageOptions) =>
		packageOptionTypesSelectOptions.filter(({ value }) => {
			const buildYourOwnExists =
				value === PackageOptionTypes.BuildYourOwn && showOwnPackageOptions;

			if (buildYourOwnExists) {
				return false;
			}

			return true;
		})
);

export const showBuildYourOwnPackageCheckboxSelector = createSelector(
	groupedPackageOptionsSelector,
	(groupedPackageOptions) => !!groupedPackageOptions.BuildYourOwn.length
);
