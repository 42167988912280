import { ICreatePackageBody } from 'api/models/requests/priceLists/studio/priceListPackages/createPackageBody';
import { IPackageFormQuestion } from 'api/models/responses/priceLists/studio/packageFormQuestion';
import { IGeneralFormQuestion } from 'api/models/responses/general/generalFormQuestion';
import { IPackage } from 'api/models/responses/priceLists/studio/priceListPackages';
import { IPatchBody } from 'api/models/requests/general/patchBody';

import { multipartHeaders } from 'constants/images/multipartHeaders';
import { priceListsUrls } from 'constants/apiUrls/priceListsUrls';

import { api } from 'config/api';

class PackagesService {
	async getPackages(priceListKey: number): Promise<IPackage[]> {
		const { data } = await api.get<IPackage[]>(
			priceListsUrls.studioFulfilled.packages.getPackageUrlByPriceListKey(
				priceListKey
			)
		);

		return data;
	}

	async createPackage(
		priceListKey: number,
		{
			name,
			sequence,
			retailPrice,
			description,
			availableFor,
			referenceCode,
			previewImageFile,
			requiredPoseOptions,
			isWaiveShippingCharge,
			ownPackagePointsAmount,
			isIncludeImagesDownload,
			isBuildOwnPackageEnabled,
			isBackgroundOptionRequired,
		}: ICreatePackageBody
	): Promise<IPackage> {
		const formData = new FormData();

		formData.set('Name', name);
		formData.set(
			'IsBuildOwnPackageEnabled',
			isBuildOwnPackageEnabled.toString()
		);
		formData.set(
			'IsBackgroundOptionRequired',
			isBackgroundOptionRequired.toString()
		);
		formData.set('Description', description);
		formData.set('AvailableFor', availableFor);
		formData.set('ReferenceCode', referenceCode);
		formData.set('Sequence', sequence.toString());
		formData.set('RetailPrice', retailPrice.toString());
		formData.set('PreviewImage', previewImageFile ?? '');
		formData.set('RequiredPoseOptions', requiredPoseOptions);
		formData.set('IsWaiveShippingCharge', isWaiveShippingCharge.toString());
		formData.set('OwnPackagePointsAmount', ownPackagePointsAmount.toString());
		formData.set('IsIncludeImagesDownload', isIncludeImagesDownload.toString());

		const { data } = await api.post<IPackage>(
			priceListsUrls.studioFulfilled.packages.getPackageUrlByPriceListKey(
				priceListKey
			),
			formData
		);

		return data;
	}

	async updatePackage(
		packageKey: number,
		body: IPatchBody[]
	): Promise<IPackage> {
		const { data } = await api.patch<IPackage>(
			priceListsUrls.studioFulfilled.packages.getPackageUrlByPackageKey(
				packageKey
			),
			body
		);

		return data;
	}

	async deletePackage(packageKey: number): Promise<void> {
		await api.delete<string>(
			priceListsUrls.studioFulfilled.packages.getPackageUrlByPackageKey(
				packageKey
			)
		);
	}

	async reorderPackages(
		priceListKey: number,
		body: Record<number, number>
	): Promise<void> {
		await api.put<string>(
			priceListsUrls.studioFulfilled.packages.packagesReorder(priceListKey),
			body
		);
	}

	async uploadPackageImage(
		packageKey: number,
		imageFile: File
	): Promise<string> {
		const formData = new FormData();

		formData.append('imageFile', imageFile);

		const { data } = await api.post<string>(
			priceListsUrls.studioFulfilled.packages.getPackagePreviewImageUrl(
				packageKey
			),
			formData,
			{ ...multipartHeaders }
		);

		return data;
	}

	async deletePackageImage(packageKey: number): Promise<void> {
		await api.delete<string>(
			priceListsUrls.studioFulfilled.packages.getPackagePreviewImageUrl(
				packageKey
			)
		);
	}

	async createPackageFormQuestion(
		packageKey: number,
		body: IGeneralFormQuestion
	): Promise<IPackageFormQuestion> {
		const { data } = await api.post<IPackageFormQuestion>(
			priceListsUrls.studioFulfilled.packages.createPackageFormQuestion(
				packageKey
			),
			body
		);

		return data;
	}

	async updatePackageFormQuestion(
		packageFormQuestionKey: number,
		body: IPatchBody[]
	): Promise<IPackageFormQuestion> {
		const { data } = await api.patch<IPackageFormQuestion>(
			priceListsUrls.studioFulfilled.packages.getPackageFormQuestionsUrl(
				packageFormQuestionKey
			),
			body
		);

		return data;
	}

	async deletePackageFormQuestion(
		packageFormQuestionKey: number
	): Promise<void> {
		await api.delete<string>(
			priceListsUrls.studioFulfilled.packages.getPackageFormQuestionsUrl(
				packageFormQuestionKey
			)
		);
	}

	async reorderPackageFormQuestions(
		packageKey: number,
		body: Record<number, number>
	): Promise<void> {
		await api.put<string>(
			priceListsUrls.studioFulfilled.packages.packageFormQuestionsReorder(
				packageKey
			),
			body
		);
	}
}

export default new PackagesService();
