import { Button } from 'components/FormControls/Button';
import { InputGrid } from 'components/FormControls/InputGrid';

export const CloseAccount = () => {
	return (
		<div className="modal-body accing-modal">
			<span className="accing-modal-title">
				Confirm by typing “DELETE” below
			</span>
			<InputGrid
				placeholder="DELETE"
				required={false}
				className="accing-modal"
			/>
			<div className="input-checkbox-container">
				<input id="check" type="checkbox" className="input-checkbox" />
				<label htmlFor="check" className="input-checkbox-label">
					Yes, I want to delete all information and close my imago account.
				</label>
			</div>
			<div className="accing-modal-btns">
				<Button className="btn-primary" value="Keep Account Open" />
				<Button className="btn-secondary" value="Close Account" />
			</div>
		</div>
	);
};
