import { IAdditionalChargeOption } from 'api/models/responses/priceLists/studio/priceListOptions/additionalChargeOption';
import { ICreateAdditionalChargeOption } from 'api/models/requests/priceLists/studio/priceListOptions/createAdditionalChargeOption';
import { IPatchBody } from 'api/models/requests/general/patchBody';

import { priceListsUrls } from 'constants/apiUrls/priceListsUrls';
import { api } from 'config/api';

class AdditionalChargeOptionsService {
	async createAdditionalChargeOption(
		priceListKey: number,
		body: ICreateAdditionalChargeOption
	): Promise<IAdditionalChargeOption> {
		const { data } = await api.post<IAdditionalChargeOption>(
			priceListsUrls.studioFulfilled.additionalChargeOptions.createAdditionalChargeOption(
				priceListKey
			),
			body
		);

		return data;
	}

	async updateAdditionalChargeOption(
		priceListKey: number,
		additionalChargeOptionKey: number,
		body: IPatchBody[]
	): Promise<IAdditionalChargeOption> {
		const { data } = await api.patch<IAdditionalChargeOption>(
			priceListsUrls.studioFulfilled.additionalChargeOptions.additionalChargeOptionUrl(
				priceListKey,
				additionalChargeOptionKey
			),
			body
		);

		return data;
	}

	async deleteAdditionalChargeOption(
		priceListKey: number,
		additionalChargeOptionKey: number
	): Promise<void> {
		await api.delete<string>(
			priceListsUrls.studioFulfilled.additionalChargeOptions.additionalChargeOptionUrl(
				priceListKey,
				additionalChargeOptionKey
			)
		);
	}

	async reorderAdditionalChargeOptions(
		priceListKey: number,
		body: Record<number, number>
	): Promise<void> {
		await api.put<string>(
			priceListsUrls.studioFulfilled.additionalChargeOptions.reorderAdditionalChargeOptions(
				priceListKey
			),
			body
		);
	}
}

export default new AdditionalChargeOptionsService();
