import { toast } from 'react-toastify';
import { useCallback } from 'react';

import { splitCamelCaseString } from 'utils/general/splitCamelCaseString';
import { IToastError, IToastPayload } from 'types/ui/toastify';
import { ToastifyContent } from 'components/ToastifyContent';

interface IResult {
	showErrorsToastify: (params: IToastError) => void;
	showErrorToastify: (params: IToastPayload) => void;
	showSuccessToastify: (params: IToastPayload) => void;
}

export const useToastify = (): IResult => {
	const showErrorsToastify = useCallback(({ type, errors }: IToastError) => {
		Object.values(errors).forEach((messages) =>
			toast.error(
				<ToastifyContent
					info={messages[0] || ''}
					title={splitCamelCaseString(type)}
				/>,
				{
					icon: false,
				}
			)
		);
	}, []);

	const showErrorToastify = useCallback(({ title, message }: IToastPayload) => {
		toast.error(<ToastifyContent title={title} info={message} />);
	}, []);

	const showSuccessToastify = useCallback(
		({ title, message }: IToastPayload) => {
			toast.success(<ToastifyContent title={title} info={message} />);
		},
		[]
	);

	return { showErrorsToastify, showSuccessToastify, showErrorToastify };
};
