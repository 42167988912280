import { ISearchPriceLists } from 'api/models/requests/priceLists/searchPriceLists';
import { PaginatedPriceLists } from 'api/models/responses/priceLists/priceList';

import { priceListsUrls } from 'constants/apiUrls/priceListsUrls';
import { api } from 'config/api';

class PriceListsService {
	async searchPriceLists({
		skip,
		take,
		orderBy,
		isActive,
		searchValue,
		orderDirection,
	}: ISearchPriceLists): Promise<PaginatedPriceLists> {
		const { data } = await api.get<PaginatedPriceLists>(
			priceListsUrls.searchPriceLists,
			{
				params: {
					Skip: skip,
					Take: take,
					OrderBy: orderBy,
					IsActive: isActive,
					SearchValue: searchValue,
					OrderDirection: orderDirection,
				},
			}
		);

		return data;
	}
}

export default new PriceListsService();
