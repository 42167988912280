import { Dispatch } from '@reduxjs/toolkit';

import ImageGalleryService from 'api/services/ImageGalleryService';

import {
	setUploadedImage,
	setGalleryImages,
	deleteGalleryImage,
	setIsImageGalleryPending,
} from './imageGallerySlice';

export const getGalleryImagesAsync = () => async (dispatch: Dispatch) => {
	dispatch(setIsImageGalleryPending(true));

	try {
		const data = await ImageGalleryService.getGalleryImages();

		dispatch(setGalleryImages(data));
	} catch (error) {
		console.log(error);
	}

	dispatch(setIsImageGalleryPending(false));
};

export const uploadImageAsync =
	(imageFile: File) => async (dispatch: Dispatch) => {
		const data = await ImageGalleryService.uploadImage(imageFile);

		dispatch(setUploadedImage(data));
	};

export const deleteGalleryImageAsync =
	(galleryImageKey: number) => async (dispatch: Dispatch) => {
		dispatch(deleteGalleryImage(galleryImageKey));

		try {
			await ImageGalleryService.deleteGalleryImage(galleryImageKey);
		} catch (error) {
			console.log(error);
		}
	};
