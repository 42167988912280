import { createSelector } from 'reselect';

import { WatermarkTypes } from 'api/models/responses/studio/studioPhotoSettings';
import { RootState } from 'store/store';

export const studioStateSelector = (state: RootState) => state.studio;

export const studioSelector = createSelector(
	studioStateSelector,
	({ studio }) => studio
);

export const allStudiosSelector = createSelector(
	studioStateSelector,
	({ allStudios }) => allStudios
);

export const studioMarketingSelector = createSelector(
	studioStateSelector,
	({ studioMarketing }) => studioMarketing
);

export const studioPhotoSettingsSelector = createSelector(
	studioStateSelector,
	({ studioPhotoSettings }) => studioPhotoSettings
);

export const watermarkTypeSelector = createSelector(
	studioPhotoSettingsSelector,
	(studioPhotoSettings) =>
		studioPhotoSettings?.watermarkType || WatermarkTypes.No
);

export const disabledWatermarkSelector = createSelector(
	watermarkTypeSelector,
	(watermarkType) => watermarkType === WatermarkTypes.No
);

export const customWatermarkImageGuidByTypeSelector = createSelector(
	studioPhotoSettingsSelector,
	(studioPhotoSettings) => ({
		[WatermarkTypes.No]: '',
		[WatermarkTypes.Single]:
			studioPhotoSettings?.customSingleWatermarkImageGuid || '',
		[WatermarkTypes.Repeat]:
			studioPhotoSettings?.customRepeatWatermarkImageGuid || '',
	})
);

export const defaultWatermarkImageGuidByTypeSelector = createSelector(
	studioPhotoSettingsSelector,
	(studioPhotoSettings) => ({
		[WatermarkTypes.No]:
			studioPhotoSettings?.defaultSingleWatermarkImageGuid || '',
		[WatermarkTypes.Single]:
			studioPhotoSettings?.defaultSingleWatermarkImageGuid || '',
		[WatermarkTypes.Repeat]:
			studioPhotoSettings?.defaultRepeatWatermarkImageGuid || '',
	})
);

export const studioKeySelector = createSelector(
	studioSelector,
	(studio) => studio?.studioKey || 0
);

export const studioNetworkDomainSelector = createSelector(
	studioSelector,
	(studio) => studio?.networkDomain || ''
);

export const timezoneSelector = createSelector(
	studioSelector,
	(studio) => studio?.timeZone || ''
);
