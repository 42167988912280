import { FC } from 'react';

import { IPatchBody } from 'api/models/requests/general/patchBody';
import PresetsService from 'api/services/PresetsService';

import { getPriceListsSelectOptions } from 'utils/priceLists/getPriceListsSelectOptions';
import { salesUI } from 'constants/projects/salesUI';
import { ISelectOption } from 'types/ui/select';

import { IClearSaleError, IPresetSaleUI, IUpdatePresetSale } from '../../types';
import { JourneyPresetSale } from './JourneyPresetSale';
import { SaleTypes } from 'api/models/responses/projects/sales';

interface IPresetSalesProps {
	presetSalesUI: IPresetSaleUI[];
	setPresetSalesUI: (presetSalesUI: IPresetSaleUI[]) => void;
}

export const JourneyPresetSales: FC<IPresetSalesProps> = ({
	presetSalesUI,
	setPresetSalesUI,
}) => {
	const updatePresetSale = async ({
		key,
		value,
		saleKey,
		validationMessage,
	}: IUpdatePresetSale) => {
		if (validationMessage) {
			const updatedPresetSales = presetSalesUI.map((sale) =>
				sale.presetSaleKey === saleKey
					? {
							...sale,
							[key]: value,
							errors: {
								...sale.errors,
								[key]: validationMessage,
							},
					  }
					: sale
			);

			setPresetSalesUI(updatedPresetSales);
			return;
		}

		const updatedField: IPatchBody = {
			value,
			path: key,
			op: 'replace',
		};

		const body: IPatchBody[] = [updatedField];

		try {
			const data = await PresetsService.updatePresetSale(saleKey, body);

			const updatedPresetSales = presetSalesUI.map((sale) =>
				sale.presetSaleKey === saleKey
					? {
							...sale,
							[key]: value,
					  }
					: sale
			);

			setPresetSalesUI(updatedPresetSales);
		} catch (error) {
			console.log(error);
		}
	};

	const clearSaleError = ({ key, saleKey }: IClearSaleError) => {
		const updatedPresetSales = presetSalesUI.map((sale) =>
			sale.presetSaleKey === saleKey
				? {
						...sale,
						errors: {
							...sale.errors,
							[key]: '',
						},
				  }
				: sale
		);

		setPresetSalesUI(updatedPresetSales);
	};

	const SalesList = presetSalesUI.map((sale) => {
		const priceListsSelectOptions: ISelectOption<string>[] =
			getPriceListsSelectOptions(sale.allowedPriceLists);

		const saleUI = salesUI[sale.saleType];
		const disabledSaleExpiresInDays =
			sale.saleType === SaleTypes.JourneyALatePresale;

		return (
			<JourneyPresetSale
				saleUI={saleUI}
				presetSaleUI={sale}
				key={sale.presetSaleKey}
				updateSale={updatePresetSale}
				clearPresetSaleError={clearSaleError}
				priceListsSelectOptions={priceListsSelectOptions}
				disabledSaleExpiresInDays={disabledSaleExpiresInDays}
			/>
		);
	});

	return <>{SalesList}</>;
};
