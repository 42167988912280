import { ITab } from 'types/tab';

import { ImagoPriceListTabs } from './imagoPriceListTabs';

export const imagoPriceListTabsList: ITab<ImagoPriceListTabs>[] = [
	{
		id: ImagoPriceListTabs.PriceListOptions,
		title: 'Price List Options',
	},
	{
		id: ImagoPriceListTabs.Packages,
		title: 'Packages',
	},
	{
		id: ImagoPriceListTabs.Prints,
		title: 'Prints',
	},
	{
		id: ImagoPriceListTabs.GiftAccessories,
		title: 'Gifts+Accessories',
	},
	{
		id: ImagoPriceListTabs.Downloads,
		title: 'Downloads',
	},
	{
		id: ImagoPriceListTabs.Free,
		title: 'Free',
	},
	{
		id: ImagoPriceListTabs.Retouching,
		title: 'Retouching',
	},
];
