import { useRef } from 'react';
import { Formik } from 'formik';

import { IBillingInfoBody } from 'api/models/requests/studioBilling/billingInfo';
import StudioBillingService from 'api/services/StudioBillingService';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useToggleSection } from 'hooks/useToggleSection';

import { ToggleSection } from 'components/ToggleSection';
import { Button } from 'components/FormControls/Button';
import { Modal, ModalRef } from 'components/Modal';

import { billingInfoSelector, setStudioBilling } from 'store/studioBilling';

import { UpdateW9Info } from '../UpdateW9Info';

export const WInformation = () => {
	const billingInfo = useAppSelector(billingInfoSelector);

	const updateWInfoRef = useRef<ModalRef>(null);

	const dispatch = useAppDispatch();

	const { isOpen, handleToggle } = useToggleSection(true);

	const showWInfoModal = () => {
		updateWInfoRef?.current?.open();
	};

	const hideWInfoModal = () => {
		updateWInfoRef?.current?.close();
	};

	const initialValues: IBillingInfoBody = {
		billingFirstName: '',
		billingLastName: '',
		billingBusinessName: '',
		billingEntityType: '',
		billingW9Initials: '',
		billingPhone: '',
		billingPhoneExt: '',
		billingFax: '',
		billingStreet: '',
		billingCity: '',
		billingState: '',
		billingPostalCode: '',
		billingEmail: '',
		studioKey: 0,
		ssn: '',
		ein: '',
	};

	const onSubmit = async (w9BillingBody: IBillingInfoBody) => {
		try {
			const data = await StudioBillingService.updateBillingInfo(w9BillingBody);

			dispatch(setStudioBilling(data));
			hideWInfoModal();
		} catch (error) {
			console.log(error, 'error');
		}
	};

	return (
		<div className="accing-toggle-section">
			<ToggleSection
				title="W9 Information"
				isOpen={isOpen}
				className="accing-toggle-header"
				handleToggle={handleToggle}
				header={
					<Button
						className="btn-secondary accing-btn"
						value="Update W9 Info"
						handleClick={showWInfoModal}
					/>
				}
			>
				<div className="accing-wrapper">
					<div className="accing-list-wrapper">
						<ul className="accing-cols">
							<li className="accing-item">
								Name:
								<span>
									{billingInfo?.billingFirstName} {billingInfo?.billingLastName}
								</span>
							</li>
							<li className="accing-item">
								Business Name: <span>{billingInfo?.billingBusinessName}</span>
							</li>
							<li className="accing-item">
								Address:
								<span>
									{billingInfo?.billingStreet}, {billingInfo?.billingCity},
									{billingInfo?.billingState} {billingInfo?.billingPostalCode}
								</span>
							</li>
							<li className="accing-item">
								Phone: <span>{billingInfo?.billingPhone}</span>
							</li>
							<li className="accing-item">
								Email: <span>{billingInfo?.billingEmail}</span>
							</li>
							<li className="accing-item">
								Entity Type: <span>{billingInfo?.billingEntityType}</span>
							</li>
							<li className="accing-item">
								Tax ID Type: <span>SSN</span>
							</li>
							<li className="accing-item">
								Last 4 #s: <span>{billingInfo?.tinLastFour}</span>
							</li>
						</ul>
					</div>
				</div>
			</ToggleSection>
			<Modal
				title="Update Account W-9 Details"
				ref={updateWInfoRef}
				className="accing-w9-modal"
			>
				<Formik onSubmit={onSubmit} initialValues={initialValues}>
					<UpdateW9Info closeModal={hideWInfoModal} />
				</Formik>
			</Modal>
		</div>
	);
};
