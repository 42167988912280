import { useFormikContext } from 'formik';
import { FC } from 'react';

import { InputGrid } from 'components/FormControls/InputGrid';
import { TextArea } from 'components/FormControls/TextArea';

import { IJourneyPresetValues } from '../../../types';

export const JourneyPresetHeader: FC = () => {
	const { values, errors, touched, handleChange } =
		useFormikContext<IJourneyPresetValues>();

	return (
		<div className="preset-header-form">
			<InputGrid
				required
				id="presetName"
				label="Preset Name"
				value={values.presetName}
				error={errors.presetName}
				placeholder="Preset name"
				handleChange={handleChange}
				touched={touched.presetName}
				className="autorow preset-header-input"
			/>
			{/* TODO: Add className for text area */}
			<TextArea
				required
				label="Description"
				id="presetDescription"
				placeholder="Description"
				handleChange={handleChange}
				value={values.presetDescription}
				error={errors.presetDescription}
				touched={touched.presetDescription}
			/>
		</div>
	);
};
