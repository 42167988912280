import { CellProps, Column } from 'react-table';
import { Link } from 'react-router-dom';
import { FC } from 'react';

import { Button } from 'components/FormControls/Button';
import { Table } from 'components/Table';

import { IProjectItem } from 'api/models/responses/projects/projectItem';
import { normalizeDate } from 'utils/ui/normalizeDate';
import { ROUTES } from 'constants/ROUTES';

interface IDeleteOrganizationErrorProps {
	hideModal: () => void;
	activeChildItems: IProjectItem[];
}

export const DeleteOrganizationReject: FC<IDeleteOrganizationErrorProps> = ({
	hideModal,
	activeChildItems,
}) => {
	const columns: Column<IProjectItem>[] = [
		{
			Header: <div className="tr-arrow">Name</div>,
			accessor: 'projectName',
			Cell: ({ row }: CellProps<IProjectItem>) => {
				const { projectName, projectKey } = row.original;

				const path = `${ROUTES.PROJECTS.PROJECTS}/${projectKey}`;

				return (
					<Link to={path} title={projectName} className="card-table-link">
						{projectName}
					</Link>
				);
			},
		},
		{
			Header: 'Expiration Date',
			accessor: 'expireDate',
			Cell: ({
				value,
			}: CellProps<IProjectItem, IProjectItem['expireDate']>) => (
				<>{normalizeDate(value)}</>
			),
		},
	];

	return (
		<div className="org-delete-modal">
			<div className="price-table">
				<Table<IProjectItem>
					columns={columns}
					data={activeChildItems}
					className="org-table table-action-menu"
				/>
			</div>
			<Button
				value="OK"
				handleClick={hideModal}
				className="org-modal-btn btn-primary"
			/>
		</div>
	);
};
