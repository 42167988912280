import { FC } from 'react';

import { ToggleSection } from 'components/ToggleSection';
import { Button } from 'components/FormControls/Button';
import { Loader } from 'components/Loader';

import { useToggleSection } from 'hooks/useToggleSection';
import { useDefineDevice } from 'hooks/useDefineDevice';

import { ProjectDetails } from './ProjectDetails';
import { IDetailsGeneralProps } from '../..';

export const ProjectDetailsContainer: FC<IDetailsGeneralProps> = ({
	isPending,
	projectDetails,
	setProjectDetails,
}) => {
	const { isOpen, handleToggle } = useToggleSection(true);
	const { isMobile } = useDefineDevice();

	if (isMobile) return null;

	const showProjectDetails = !isPending && projectDetails;

	return (
		<ToggleSection
			title="Details"
			isOpen={isOpen}
			className="preset-details-header"
			handleToggle={handleToggle}
			header={
				<Button
					value="View Project Page"
					className="btn-secondary org-details-btn"
				/>
			}
		>
			{showProjectDetails ? (
				<ProjectDetails
					projectDetails={projectDetails}
					setProjectDetails={setProjectDetails}
				/>
			) : (
				<Loader />
			)}
		</ToggleSection>
	);
};
