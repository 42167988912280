import { StudioPoseOptionTypes } from 'api/models/responses/priceLists/studio/priceListOptions/poseOption';
import { CatalogProductCategoryTypes } from 'api/models/responses/catalogProducts/catalogProducts';

export const priceListsUrls = {
	searchPriceLists: 'api/price-lists',

	defaultBackgroundsUrl: 'api/price-lists/default-backgrounds',

	studioFulfilled: {
		createPriceListStudioFulfilled: 'api/price-lists/studio-fulfilled',

		copyPriceListStudioFulfilled: (priceListKey: number) =>
			`api/price-lists/studio-fulfilled/copy/${priceListKey}`,

		getBaseOptions: (priceListKey: number) =>
			`api/price-lists/studio-fulfilled/${priceListKey}/base-options`,

		getPriceListStudioFulfilledUrl: (priceListKey: number) =>
			`api/price-lists/studio-fulfilled/${priceListKey}`,

		backgroundOptions: {
			createBackgroundOption: (priceListKey: number) =>
				`api/price-lists/studio-fulfilled/${priceListKey}/background-option/studio`,

			createDefaultBackgroundOption: (priceListKey: number) =>
				`api/price-lists/studio-fulfilled/${priceListKey}/background-option/imago`,

			deleteBackgroundOptions: (priceListKey: number) =>
				`api/price-lists/studio-fulfilled/${priceListKey}/background-option`,

			getBackgroundOptionUrl: (backgroundOptionKey: number) =>
				`api/price-lists/studio-fulfilled/background-option/${backgroundOptionKey}`,

			reorderBackgroundOptions: (priceListKey: number) =>
				`api/price-lists/studio-fulfilled/${priceListKey}/reorder`,
		},

		ownPackageOptions: {
			getUrlByPriceListKey: (priceListKey: number) =>
				`api/price-lists/studio-fulfilled/${priceListKey}/own-package-options`,

			getUrlByOwnPackageOptionKey: (ownPackageOptionKey: number) =>
				`api/price-lists/studio-fulfilled/own-package-options/${ownPackageOptionKey}`,

			reorderOwnPackageOptions: (priceListKey: number) =>
				`api/price-lists/studio-fulfilled/${priceListKey}/own-package-options/reorder`,

			previewImageOwnPackageOption: (ownPackageOptionKey: number) =>
				`api/price-lists/studio-fulfilled/own-package-options/${ownPackageOptionKey}/preview-image`,
		},

		additionalChargeOptions: {
			createAdditionalChargeOption: (priceListKey: number) =>
				`api/price-lists/studio-fulfilled/${priceListKey}/additional-charge-options`,

			additionalChargeOptionUrl: (
				priceListKey: number,
				additionalChargeOptionKey: number
			) =>
				`api/price-lists/studio-fulfilled/${priceListKey}/additional-charge-options/${additionalChargeOptionKey}`,

			reorderAdditionalChargeOptions: (priceListKey: number) =>
				`api/price-lists/studio-fulfilled/${priceListKey}/additional-charge-options/reorder`,
		},

		shippingOptions: {
			createShippingOption: (priceListKey: number) =>
				`api/price-lists/studio-fulfilled/${priceListKey}/shipping-options`,

			shippingOptionUrl: (shippingOptionKey: number) =>
				`api/price-lists/studio-fulfilled/shipping-options/${shippingOptionKey}`,

			reorderShippingOptions: (priceListKey: number) =>
				`api/price-lists/studio-fulfilled/${priceListKey}/shipping-options/reorder`,

			setDefaultShippingOption: (
				priceListKey: number,
				shippingOptionKey: number
			) =>
				`api/price-lists/studio-fulfilled/${priceListKey}/shipping-options/${shippingOptionKey}/default`,
		},

		poseOptions: {
			createPoseOption: (priceListKey: number) =>
				`api/price-lists/studio-fulfilled/${priceListKey}/pose-options`,

			updatePoseOption: (poseOptionKey: number) =>
				`api/price-lists/studio-fulfilled/pose-options/${poseOptionKey}`,

			deletePoseOptions: (
				priceListKey: number,
				poseOptionType: StudioPoseOptionTypes
			) =>
				`api/price-lists/studio-fulfilled/${priceListKey}/pose-options/type/${poseOptionType}`,

			deletePoseOption: (priceListKey: number, poseOptionKey: number) =>
				`api/price-lists/studio-fulfilled/${priceListKey}/pose-options/${poseOptionKey}`,

			reorderPoseOptions: (priceListKey: number) =>
				`api/price-lists/studio-fulfilled/${priceListKey}/pose-options/reorder`,

			poseOptionPreviewImageUrl: (poseOptionKey: number) =>
				`api/price-lists/studio-fulfilled/pose-options/${poseOptionKey}/preview-image`,
		},

		packages: {
			getPackageUrlByPriceListKey: (priceListKey: number) =>
				`api/price-lists/studio-fulfilled/${priceListKey}/packages`,

			getPackageUrlByPackageKey: (packageKey: number) =>
				`api/price-lists/studio-fulfilled/packages/${packageKey}`,

			getPackagePreviewImageUrl: (packageKey: number) =>
				`api/price-lists/studio-fulfilled/packages/${packageKey}/preview-image`,

			packagesReorder: (priceListKey: number) =>
				`api/price-lists/studio-fulfilled/${priceListKey}/packages/reorder`,

			createPackageFormQuestion: (packageKey: number) =>
				`api/price-lists/studio-fulfilled/packages/${packageKey}/form-questions`,

			getPackageFormQuestionsUrl: (packageFormQuestionKey: number) =>
				`api/price-lists/studio-fulfilled/packages/form-questions/${packageFormQuestionKey}`,

			packageFormQuestionsReorder: (packageKey: number) =>
				`api/price-lists/studio-fulfilled/packages/${packageKey}/form-questions/reorder`,
		},

		alaCarte: {
			getAlaCarteUrlByPriceListKey: (priceListKey: number) =>
				`api/price-lists/studio-fulfilled/${priceListKey}/a-la-cartes`,

			getAlaCarteUrlByAlaCarteKey: (alaCarteKey: number) =>
				`api/price-lists/studio-fulfilled/a-la-cartes/${alaCarteKey}`,

			reorderAlaCartes: (priceListKey: number) =>
				`api/price-lists/studio-fulfilled/${priceListKey}/a-la-cartes/reorder`,

			getAlaCartePreviewImageUrl: (alaCarteKey: number) =>
				`api/price-lists/studio-fulfilled/a-la-cartes/${alaCarteKey}/preview-image`,

			createAlaCarteFormQuestion: (alaCarteKey: number) =>
				`api/price-lists/studio-fulfilled/a-la-cartes/${alaCarteKey}/form-questions`,

			getAlaCarteFormQuestionsUrl: (alaCarteFormQuestionKey: number) =>
				`api/price-lists/studio-fulfilled/a-la-cartes/form-questions/${alaCarteFormQuestionKey}`,

			reorderAlaCarteFormQuestions: (alaCarteKey: number) =>
				`api/price-lists/studio-fulfilled/a-la-cartes/${alaCarteKey}/form-questions/reorder`,
		},
	},

	imagoFulfilled: {
		createPriceListImagoFulfilled: 'api/price-lists/imago-fulfilled',

		getPriceListImagoFulfilledUrl: (priceListKey: number) =>
			`api/price-lists/imago-fulfilled/${priceListKey}`,

		copyPriceListImagoFulfilled: (priceListKey: number) =>
			`api/price-lists/imago-fulfilled/copy/${priceListKey}`,

		backgroundOptions: {
			getBackgroundOptions: (priceListKey: number) =>
				`api/price-lists/imago-fulfilled/${priceListKey}/backgrounds`,

			createBackgroundOption: (priceListKey: number) =>
				`api/price-lists/imago-fulfilled/${priceListKey}/backgrounds/studio`,

			createDefaultBackgroundOption: (priceListKey: number) =>
				`api/price-lists/imago-fulfilled/${priceListKey}/backgrounds/imago`,

			getBackgroundOptionUrl: (backgroundOptionKey: number) =>
				`api/price-lists/imago-fulfilled/backgrounds/${backgroundOptionKey}`,

			reorderBackgroundOptions: (priceListKey: number) =>
				`api/price-lists/imago-fulfilled/${priceListKey}/reorder`,
		},

		products: {
			createProduct: (priceListKey: number) =>
				`api/price-lists/Imago-fulfilled/${priceListKey}/products`,

			getProductUrl: (productKey: number) =>
				`api/price-lists/Imago-fulfilled/products/${productKey}`,

			updateCatalogProduct: (productKey: number) =>
				`api/price-lists/Imago-fulfilled/product/${productKey}/catalog-product`,

			getProducts: (
				priceListKey: number,
				category: CatalogProductCategoryTypes
			) =>
				`api/price-lists/Imago-fulfilled/${priceListKey}/products/category/${category}`,

			getSpecificProducts: (priceListKey: number) =>
				`api/price-lists/Imago-fulfilled/${priceListKey}/products/specific-category/gift-and-accessories`,

			getProductImageUrl: (productKey: number) =>
				`api/price-lists/Imago-fulfilled/products/${productKey}/preview-image`,

			reorderProducts: (priceListKey: number) =>
				`api/price-lists/Imago-fulfilled/${priceListKey}/products/reorder`,
		},
	},
};
