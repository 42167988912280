import { FC, ReactElement, useState } from 'react';

import { SearchPhotoByParameters } from 'api/models/requests/photos/searchByParameters';
import { ISearchPhotosParams } from 'api/models/requests/photos/searchPhotos';

import { ImagesSectionTabs } from 'constants/projects/imagesSection/imagesSectionTabs';
import { useToggleSection } from 'hooks/useToggleSection';
import { ToggleSection } from 'components/ToggleSection';

import { ImagesSectionTab } from './components/ImagesSectionTab';
import { PhotosSearch } from './components/PhotosSearch';
import { usePhotos } from './hooks/usePhotos';
import { useAlbums } from './hooks/useAlbums';
import { Photos } from './components/Photos';
import { Albums } from './components/Albums';

interface IImagesContainerProps {
	projectKey: number;
	isPngUploadAllowed?: boolean;
}

const initialSearchPhotosParams: ISearchPhotosParams = {
	searchValue: '',
	searchParameter: SearchPhotoByParameters.ParticipantName,
};

const imagesSectionTabs = Object.values(ImagesSectionTabs);

export const ImagesContainer: FC<IImagesContainerProps> = ({
	projectKey,
	isPngUploadAllowed,
}) => {
	const [currentTab, setCurrentTab] = useState<ImagesSectionTabs>(
		ImagesSectionTabs.Images
	);

	const [searchPhotosParams, setSearchPhotosParams] =
		useState<ISearchPhotosParams>(initialSearchPhotosParams);

	const { isOpen, setIsOpen, handleToggle } = useToggleSection(false);

	const resetSearchPhotosParams = () => {
		setSearchPhotosParams(initialSearchPhotosParams);
	};

	const photosProps = usePhotos({
		projectKey,
		isCall: isOpen,
		searchPhotosParams,
	});

	const albumsProps = useAlbums(projectKey, isOpen);

	const TabsList = imagesSectionTabs.map((tab) => (
		<ImagesSectionTab
			key={tab}
			value={tab}
			isActive={currentTab === tab}
			handleClick={() => setCurrentTab(tab)}
		/>
	));

	const ImagesTab = currentTab === ImagesSectionTabs.Images;

	const TabsPages: Record<ImagesSectionTabs, ReactElement> = {
		[ImagesSectionTabs.Images]: (
			<Photos {...photosProps} projectKey={projectKey} />
		),
		[ImagesSectionTabs.Groups]: (
			<Albums
				{...albumsProps}
				projectKey={projectKey}
				isPngUploadAllowed={isPngUploadAllowed}
				isDeletedPhotos={photosProps.isDeletedPhotos}
				setUpdatePhotos={photosProps.setUpdatePhotos}
				resetSearchPhotosParams={resetSearchPhotosParams}
			/>
		),
	};

	return (
		<ToggleSection
			title="Images"
			isOpen={isOpen}
			handleToggle={handleToggle}
			className="preset-details-header images-section-header"
			header={
				ImagesTab && (
					<PhotosSearch
						setIsOpen={setIsOpen}
						searchPhotosParams={searchPhotosParams}
						setUpdatePhotos={photosProps.setUpdatePhotos}
						setSearchPhotosParams={setSearchPhotosParams}
						resetSearchPhotosParams={resetSearchPhotosParams}
					/>
				)
			}
		>
			<ul className="project-images-list">{TabsList}</ul>
			{TabsPages[currentTab]}
		</ToggleSection>
	);
};
