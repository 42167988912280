import { useMsal } from '@azure/msal-react';
import cn from 'classnames';
import { FC } from 'react';

import { clearSelectedSubscriptionLevelKey } from 'store/subscriptions';
import { isOpenSelector, setIsOpen } from 'store/aside';
import { clearStudio } from 'store/studio';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';

import { Navigation } from './components/Navigation';
import { FindOrder } from './components/FindOrder';
import { Accounts } from './components/Accounts';
import { SendLink } from './components/SendLink';

export const Aside: FC = () => {
	const isOpen = useAppSelector(isOpenSelector);

	const dispatch = useAppDispatch();

	const { instance } = useMsal();

	const hideAside = () => dispatch(setIsOpen(false));

	const logout = async () => {
		try {
			await instance.logoutRedirect();

			dispatch(clearStudio());
			dispatch(clearSelectedSubscriptionLevelKey());
		} catch (e) {
			console.log(e);
		}
	};

	const handleLogout = () => {
		void logout();
	};

	return (
		<aside
			className={cn('aside', {
				'is-open': isOpen,
			})}
		>
			<button onClick={hideAside} className="asside-collapse">
				Collapse Menu
			</button>
			<Accounts handleLogout={handleLogout} />
			<FindOrder />
			<SendLink />
			<Navigation handleLogout={handleLogout} />
		</aside>
	);
};
