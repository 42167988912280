import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StudioPoseOptionTypes } from 'api/models/responses/priceLists/studio/priceListOptions/poseOption';
import { IPoseOptionUI } from 'pages/PriceLists/types/studio/priceListOptions/poseOptions';

import {
	IPoseOptionsState,
	GroupedPoseOptions,
	AddedPoseOptionTypes,
	ISetGroupedPoseOptionsPayload,
} from '.';

const initialGroupedPoseOptions: GroupedPoseOptions = {
	[StudioPoseOptionTypes.None]: [],
	[StudioPoseOptionTypes.Pose]: [],
	[StudioPoseOptionTypes.Style]: [],
	[StudioPoseOptionTypes.ColorFlavor]: [],
	[StudioPoseOptionTypes.Backgrounds]: [],
};

const initialAddedPoseOptionTypes: AddedPoseOptionTypes = {
	[StudioPoseOptionTypes.None]: false,
	[StudioPoseOptionTypes.Pose]: false,
	[StudioPoseOptionTypes.Style]: false,
	[StudioPoseOptionTypes.ColorFlavor]: false,
	[StudioPoseOptionTypes.Backgrounds]: false,
};

const initialState: IPoseOptionsState = {
	groupedPoseOptions: initialGroupedPoseOptions,
	addedPoseOptionTypes: initialAddedPoseOptionTypes,
};

const priceListPoseOptionsSlice = createSlice({
	name: 'studioPriceListPoseOptions',
	initialState,
	reducers: {
		setPoseOptions: (state, { payload }: PayloadAction<IPoseOptionUI[]>) => {
			const groupedPoseOptions = payload.reduce((acc, poseOption) => {
				const poseOptionType = poseOption.poseOptionType;

				return {
					...acc,
					[poseOptionType]: [...acc[poseOptionType], poseOption],
				};
			}, initialGroupedPoseOptions);

			state.groupedPoseOptions = groupedPoseOptions;
		},

		setGroupedPoseOptions: (
			state,
			{ payload }: PayloadAction<ISetGroupedPoseOptionsPayload>
		) => {
			state.groupedPoseOptions[payload.poseOptionType] = payload.poseOptions;
		},

		addPoseOptionType: (
			state,
			{ payload }: PayloadAction<StudioPoseOptionTypes>
		) => {
			state.addedPoseOptionTypes[payload] = true;
		},

		deletePoseOptionSection: (
			state,
			{ payload }: PayloadAction<StudioPoseOptionTypes>
		) => {
			state.groupedPoseOptions[payload] = [];
			state.addedPoseOptionTypes[payload] = false;
		},

		resetAddedPoseOptionTypes: (state) => {
			state.addedPoseOptionTypes = initialAddedPoseOptionTypes;
		},
	},
});

export const priceListPoseOptionsReducer = priceListPoseOptionsSlice.reducer;
export const {
	setPoseOptions,
	addPoseOptionType,
	setGroupedPoseOptions,
	deletePoseOptionSection,
	resetAddedPoseOptionTypes,
} = priceListPoseOptionsSlice.actions;
