import { FC, useEffect } from 'react';

import { studioStatisticPriceKeys } from 'constants/dashboard/studioStatisticPriceKeys';
import { studioStatisticKeys } from 'constants/dashboard/studioStatisticKeys';
import { DASHBOARD } from 'constants/navigation';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useDefineDevice } from 'hooks/useDefineDevice';

import { studioStatisticSelector } from 'store/dashboard';
import { setBreadcrumbs } from 'store/breadcrumbs';

import NavigationService from 'utils/NavigationService';

import { DateRangeSelect } from './components/DateRangeSelect';
import { LatestProjects } from './components/LatestProjects';
import { OrderWaiting } from './components/OrderWaiting';
import { PanelData } from '../../components/PanelData';
import { ComingSoon } from './components/ComingSoon';
import { DidYouKnow } from './components/DidYouKnow';
import { NewOrders } from './components/NewOrders';
import { CreateNew } from './components/CreateNew';
import { Schedule } from './components/Schedule';

export const Dashboard: FC = () => {
	const studioStatistic = useAppSelector(studioStatisticSelector);

	const dispatch = useAppDispatch();
	const { isMobile } = useDefineDevice();

	useEffect(() => {
		const dashboardNavigation =
			NavigationService.getParentNavigation(DASHBOARD);

		if (!dashboardNavigation) return;

		const dashboardBreadcrumbs = {
			isActive: true,
			path: dashboardNavigation.path,
			title: dashboardNavigation.title,
		};

		const breadcrumbs = [dashboardBreadcrumbs];

		dispatch(setBreadcrumbs(breadcrumbs));
	}, [dispatch]);

	return (
		<div className="dashboard">
			<PanelData
				statistic={studioStatistic}
				statisticKeys={studioStatisticKeys}
				statisticPriceKeys={studioStatisticPriceKeys}
			>
				<DateRangeSelect />
			</PanelData>
			<div className="dashboard-thumb">
				{!isMobile && <Schedule />}
				<ComingSoon />
				<OrderWaiting />
				<NewOrders />
				<LatestProjects />
				<CreateNew />
				<DidYouKnow />
			</div>
		</div>
	);
};
