import { FC } from 'react';

import { useAsyncOptimizedCheckbox } from 'hooks/formControls/useAsyncOptimizedCheckbox';
import { useAppSelector } from 'hooks/redux/useAppSelector';

import { Checkbox } from 'components/FormControls/Checkbox';
import { Input } from 'components/FormControls/Input';

import { showWaiveShippingChargeCheckboxSelector } from 'store/priceLists/studio/priceListBaseOptions';
import { PoseOptionsCheckBoxes } from 'pages/PriceLists/components/PoseOptionsCheckBoxes';
import { IAlaCarte } from 'api/models/responses/priceLists/studio/priceListAlaCarte';

import { UpdateAlaCarteHandler } from '../..';

interface IAlaCarteOptionsProps
	extends Pick<
		IAlaCarte,
		| 'requiredPoseOptions'
		| 'isWaiveShippingCharge'
		| 'isIncludeImagesDownload'
		| 'isBackgroundOptionRequired'
	> {
	sequence: number;
	alaCarteKey?: number;
	updateAlaCarte: UpdateAlaCarteHandler;
}

export const AlaCarteOptions: FC<IAlaCarteOptionsProps> = ({
	sequence,
	alaCarteKey,
	updateAlaCarte,
	requiredPoseOptions,
	isWaiveShippingCharge,
	isIncludeImagesDownload,
	isBackgroundOptionRequired,
}) => {
	const showWaiveShippingChargeCheckbox = useAppSelector(
		showWaiveShippingChargeCheckboxSelector
	);

	const updateIsIncludeImagesDownload = async (value: boolean) => {
		await updateAlaCarte({
			value,
			sequence,
			alaCarteKey,
			fieldKey: 'isIncludeImagesDownload',
		});
	};

	const includeImagesDownload = useAsyncOptimizedCheckbox(
		isIncludeImagesDownload,
		updateIsIncludeImagesDownload
	);

	const updateIsWaveShippingCharge = async (value: boolean) => {
		await updateAlaCarte({
			value,
			sequence,
			alaCarteKey,
			fieldKey: 'isWaiveShippingCharge',
		});
	};

	const waveShippingCharge = useAsyncOptimizedCheckbox(
		isWaiveShippingCharge,
		updateIsWaveShippingCharge
	);

	const updateIsBackgroundOptionRequired = async (value: boolean) => {
		await updateAlaCarte({
			value,
			sequence,
			alaCarteKey,
			fieldKey: 'isBackgroundOptionRequired',
		});
	};

	const updateRequiredPoseOptions = async (value: string) => {
		await updateAlaCarte({
			value,
			sequence,
			alaCarteKey,
			fieldKey: 'requiredPoseOptions',
		});
	};

	return (
		<div className="price-carte-options">
			<span className="price-textarea-label">
				Package Options
				<div className="price-textarea-container">
					{showWaiveShippingChargeCheckbox && (
						<Checkbox
							label="Waive Shipping Charge"
							checked={waveShippingCharge.checked}
							disabled={waveShippingCharge.isPending}
							id={`waive-shipping-charge-${sequence}`}
							handleChange={waveShippingCharge.handleChange}
						/>
					)}
					<Checkbox
						label="Includes Download"
						id={`include-download-${sequence}`}
						checked={includeImagesDownload.checked}
						disabled={includeImagesDownload.isPending}
						handleChange={includeImagesDownload.handleChange}
					/>
				</div>
			</span>
			<span className="price-textarea-label">
				Pose Options
				<div className="price-textarea-container">
					<PoseOptionsCheckBoxes
						sequence={sequence}
						requiredPoseOptions={requiredPoseOptions}
						updateRequiredPoseOptions={updateRequiredPoseOptions}
						isBackgroundOptionRequired={isBackgroundOptionRequired}
						updateIsBackgroundOptionRequired={updateIsBackgroundOptionRequired}
					/>
				</div>
				<div className="price-pose-select">
					<Input value="1" disabled />
					<span>Max # of poses</span>
				</div>
			</span>
		</div>
	);
};
