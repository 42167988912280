import { GeneralTabs } from 'types/generalTabs';
import { ITab } from 'types/tab';

export const promotionsTabsList: ITab<GeneralTabs>[] = [
	{
		id: GeneralTabs.Active,
		title: 'Active Promotions',
	},
	{
		id: GeneralTabs.Archived,
		title: 'Archived Promotions',
	},
];
