import { FC, ChangeEvent } from 'react';
import { Formik, Form } from 'formik';
import { object } from 'yup';

import { IBankAccountBody } from 'api/models/requests/studioBilling/bankAccount';
import StudioBillingService from 'api/services/StudioBillingService';

import { InputGrid } from 'components/FormControls/InputGrid';
import { Button } from 'components/FormControls/Button';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';

import { setBankAccUpdate } from 'store/studioBilling';
import { studioKeySelector } from 'store/studio';

import { bankACHValidationSchema } from 'pages/RegistrationSteps/SetupAccount/validations';
import { validateStringNumber } from 'utils/validations/general/validateStringNumber';
import { ButtonTypes } from 'types/ui/buttonTypes';

interface ICloseModal {
	closeModal: () => void;
}

const fields = [
	{
		id: 'bankRoutingNumber',
		label: 'Routing #',
		placeholder: 'Placeholder',
	},
	{
		id: 'confirmBankRoutingNumber',
		label: 'Confirm Routing #',
		placeholder: 'Placeholder',
	},
	{
		id: 'bankAccountNumber',
		label: 'Account #',
		placeholder: 'Placeholder',
	},
	{
		id: 'confirmBankAccountNumber',
		label: 'Confirm Account #',
		placeholder: 'Placeholder',
	},
];

interface IBankAccount
	extends Record<string, string>,
		Omit<IBankAccountBody, 'studioKey'> {
	confirmBankRoutingNumber: string;
	confirmBankAccountNumber: string;
}

export const UpdateBankAccount: FC<ICloseModal> = ({ closeModal }) => {
	const initialValues: IBankAccount = {
		bankRoutingNumber: '',
		bankAccountNumber: '',
		bankAccountName: '',
		confirmBankRoutingNumber: '',
		confirmBankAccountNumber: '',
	};

	const studioKey = useAppSelector(studioKeySelector);

	const dispatch = useAppDispatch();

	const onSubmit = async (bankAccountBody: IBankAccount) => {
		const { confirmBankAccountNumber, confirmBankRoutingNumber, ...rest } =
			bankAccountBody;

		try {
			const data = await StudioBillingService.updateBankACHInformation({
				...rest,
				studioKey,
			});

			if (!data) return;

			dispatch(setBankAccUpdate(data));

			closeModal();
		} catch (error) {
			console.log(error, 'error');
		}
	};

	const validationSchema = object().shape(bankACHValidationSchema);

	const handleValidateNumber = (
		e: ChangeEvent<HTMLInputElement>,
		formikHandleChange: (e: ChangeEvent<HTMLInputElement>) => void
	) => {
		const isValidNumber = validateStringNumber(e.target.value);

		if (!isValidNumber) return;

		formikHandleChange(e);
	};

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={validationSchema}
		>
			{({ handleChange, values, errors, touched }) => (
				<Form className="modal-body accing-modal">
					<div className="accing-modal-form">
						<div className="accing-modal-input">
							<InputGrid
								id="bankAccountName"
								placeholder="Placeholder"
								handleChange={handleChange}
								label="Account Holder’s Name"
								error={errors.bankAccountName}
								value={values.bankAccountName}
								touched={!!touched.bankAccountName}
							/>
						</div>
						{fields.map(({ id, label, placeholder }) => (
							<InputGrid
								id={id}
								key={id}
								label={label}
								error={errors[id]}
								value={values[id]}
								touched={!!touched[id]}
								placeholder={placeholder}
								handleChange={(e) => handleValidateNumber(e, handleChange)}
							/>
						))}
					</div>
					<div className="accing-modal-btns">
						<Button
							className="btn-primary"
							value="Update Account On File"
							type={ButtonTypes.SUBMIT}
						/>
						<Button
							className="btn-secondary"
							value="Back"
							handleClick={closeModal}
						/>
					</div>
				</Form>
			)}
		</Formik>
	);
};
