import { IBankAccountUpdateResponse } from 'api/models/responses/studioBilling/bankAccountUpdate';
import { IBankAccountResponse } from 'api/models/responses/studioBilling/bankAccount';
import { IBillingInfoBody } from 'api/models/requests/studioBilling/billingInfo';
import { IBankAccountBody } from 'api/models/requests/studioBilling/bankAccount';
import { IBillingInfo } from 'api/models/responses/studioBilling/billingInfo';
import { ICardInfo } from 'api/models/responses/studioBilling/cardInfo';
import { ICardInfoBody } from 'api/models/requests/studio/createStudio';

import { studioBillingUrls } from 'constants/apiUrls/studioBillingUrls';
import { api } from 'config/api';

class StudioBillingService {
	async createBankACHInformation(
		bankACHBody: IBankAccountBody
	): Promise<IBankAccountResponse> {
		const { data } = await api.post<IBankAccountResponse>(
			studioBillingUrls.createBankACHInformation,
			bankACHBody
		);

		return data;
	}

	async getBankACHInfo(): Promise<IBankAccountResponse> {
		const { data } = await api.get<IBankAccountResponse>(
			studioBillingUrls.createBankACHInformation
		);

		return data;
	}

	async updateBankACHInformation(
		bankACHBody: IBankAccountBody
	): Promise<IBankAccountUpdateResponse | null> {
		const { data } = await api.put<IBankAccountUpdateResponse>(
			studioBillingUrls.createBankACHInformation,
			bankACHBody
		);

		return data;
	}

	async getBillingInfo(): Promise<IBillingInfo> {
		const { data } = await api.get<IBillingInfo>(
			studioBillingUrls.billingInfoUrl
		);

		return data;
	}

	async updateBillingInfo(
		w9BillingBody: IBillingInfoBody
	): Promise<IBillingInfo> {
		const { data } = await api.put<IBillingInfo>(
			studioBillingUrls.billingInfoUrl,
			w9BillingBody
		);

		return data;
	}

	async getCardInfo(): Promise<ICardInfo> {
		const { data } = await api.get<ICardInfo>(studioBillingUrls.cardUrl);

		return data;
	}

	async updateCardInfo(body: ICardInfoBody): Promise<ICardInfo> {
		const { data } = await api.put<ICardInfo>(studioBillingUrls.cardUrl, body);

		return data;
	}
}

export default new StudioBillingService();
