import cn from 'classnames';
import { FC } from 'react';

interface IAccountProps {
	isActive: boolean;
	studioKey: number;
	studioName: string;
	selectStudio: (studioKey: number) => Promise<void>;
}

export const Account: FC<IAccountProps> = ({
	isActive,
	studioKey,
	studioName,
	selectStudio,
}) => {
	const handleSelectStudio = () => {
		void selectStudio(studioKey);
	};

	return (
		<li className="aside-menu-item" onClick={handleSelectStudio}>
			<span
				className={cn('aside-menu-link', {
					bold: isActive,
				})}
			>
				{studioName}
			</span>
		</li>
	);
};
