import { object, string } from 'yup';

import { requiredFieldMessage } from 'constants/general/validation/generalMessages';
import {
	nameValidationMessage,
	organizationNameRegEx,
} from 'constants/organizations/validation';

export const createStudioScheme = object({
	studioName: string()
		.required(requiredFieldMessage)
		.matches(organizationNameRegEx, nameValidationMessage),
	phone: string().required(requiredFieldMessage),
	street: string().required(requiredFieldMessage),
	city: string().required(requiredFieldMessage),
	zip: string().required(requiredFieldMessage),
	cardNumber: string().required(requiredFieldMessage),
	expiration: string().required(requiredFieldMessage),
	verificationCode: string().required(requiredFieldMessage),
	billingZip: string().required(requiredFieldMessage),
	cardType: string().required(requiredFieldMessage),
	state: string().required(requiredFieldMessage),
	networkDomain: string().required(requiredFieldMessage),
});
