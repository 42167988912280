import { ChangeEvent, FC, useRef } from 'react';
import { useFormikContext } from 'formik';
import cn from 'classnames';

import { validateStringNumber } from 'utils/validations/general/validateStringNumber';
import { usePasswordInput } from 'hooks/formControls/usePasswordInput';
import { VALID_FIELDS_LENGTH } from 'constants/auth/validation';
import { TableInput } from 'components/TableInput';

import { W9EntityType } from './components/W9EntityType';
import { W9Header } from './components/W9Header';
import { W9States } from './components/W9States';

import { IGetPaidFormValues } from '../../types';

type PartialInputRefT = HTMLInputElement | null;

export const W9Info: FC = () => {
	const secondPartPhoneRef = useRef<PartialInputRefT>(null);
	const thirdPartPhoneRef = useRef<PartialInputRefT>(null);

	const secondPartSsnRef = useRef<PartialInputRefT>(null);
	const thirdPartSsnRef = useRef<PartialInputRefT>(null);

	const secondPartEinRef = useRef<PartialInputRefT>(null);

	const { values, errors, touched, handleChange, setFieldValue } =
		useFormikContext<IGetPaidFormValues>();

	const ssnVisibility = usePasswordInput();
	const einVisibility = usePasswordInput();

	const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
		const secondPartPhoneInput = secondPartPhoneRef.current;
		const thirdPartPhoneInput = thirdPartPhoneRef.current;

		if (!secondPartPhoneInput || !thirdPartPhoneInput) return;

		const {
			target: { id, value },
		} = e;

		const isValidPhone = validateStringNumber(value);

		if (!isValidPhone) return;

		const isFirstPartPhoneField = id === 'firstPartPhone';
		const isSecondPartPhoneField = id === 'secondPartPhone';
		const isThirdPartPhoneField = id === 'thirdPartPhone';

		const invalidFieldsLength =
			((isFirstPartPhoneField || isSecondPartPhoneField) && value.length > 3) ||
			(isThirdPartPhoneField && value.length > 4);

		if (invalidFieldsLength) return;

		if (isFirstPartPhoneField && value.length === 3) {
			secondPartPhoneInput.focus();
		}

		if (isSecondPartPhoneField && value.length === 3) {
			thirdPartPhoneInput.focus();
		}

		const currentBillingPhoneValue = values.billingPhone;

		void setFieldValue('billingPhone', {
			...currentBillingPhoneValue,
			[id]: value,
		});
	};

	const handleSsnChange = (e: ChangeEvent<HTMLInputElement>) => {
		const secondPartSsnInput = secondPartSsnRef.current;
		const thirdPartSsnInput = thirdPartSsnRef.current;

		if (!secondPartSsnInput || !thirdPartSsnInput) return;

		const {
			target: { id, value },
		} = e;

		const isValidSsn = validateStringNumber(value);

		if (!isValidSsn) return;

		const isFirstPartSsnField = id === 'firstPartSsn';
		const isSecondPartSsnField = id === 'secondPartSsn';
		const isThirdPartSsnField = id === 'thirdPartSsn';

		const invalidFieldsLength =
			(isFirstPartSsnField && value.length > 3) ||
			(isSecondPartSsnField && value.length > 2) ||
			(isThirdPartSsnField && value.length > 4);

		if (invalidFieldsLength) return;

		if (isFirstPartSsnField && value.length === 3) {
			secondPartSsnInput.focus();
		}

		if (isSecondPartSsnField && value.length === 2) {
			thirdPartSsnInput.focus();
		}

		const currentTinValue = values.tin;
		const currentSsnValue = values.tin.ssn;

		void setFieldValue('tin', {
			...currentTinValue,
			ssn: {
				...currentSsnValue,
				[id]: value,
			},
		});
	};

	const handleEinChange = (e: ChangeEvent<HTMLInputElement>) => {
		const secondPartEinInput = secondPartEinRef.current;

		if (!secondPartEinInput) return;

		const {
			target: { id, value },
		} = e;

		const isValidEin = validateStringNumber(value);

		if (!isValidEin) return;

		const isFirstPartEinField = id === 'firstPartEin';
		const isSecondPartEinField = id === 'secondPartEin';

		const invalidFieldsLength =
			(isFirstPartEinField && value.length > 2) ||
			(isSecondPartEinField && value.length > 7);

		if (invalidFieldsLength) return;

		if (isFirstPartEinField && value.length === 2) {
			secondPartEinInput.focus();
		}

		const currentTinValue = values.tin;
		const currentEinValue = values.tin.ein;

		void setFieldValue('tin', {
			...currentTinValue,
			ein: {
				...currentEinValue,
				[id]: value,
			},
		});
	};

	const handleZipChange = (e: ChangeEvent<HTMLInputElement>) => {
		const isValidZip = validateStringNumber(
			e.target.value,
			VALID_FIELDS_LENGTH.ZIP
		);

		if (!isValidZip) return;

		handleChange(e);
	};

	const billingPhoneError =
		errors.billingPhone?.firstPartPhone ||
		errors.billingPhone?.secondPartPhone ||
		errors.billingPhone?.thirdPartPhone;

	const billingPhoneTouched =
		touched.billingPhone?.firstPartPhone ||
		touched.billingPhone?.secondPartPhone ||
		touched.billingPhone?.thirdPartPhone;

	const showBillingPhoneError = billingPhoneError && billingPhoneTouched;

	const tinError = errors.tin?.ssn?.firstPartSsn;

	const tin = touched.tin;

	const tinTouched =
		tin?.ssn?.firstPartSsn ||
		tin?.ssn?.secondPartSsn ||
		tin?.ssn?.thirdPartSsn ||
		tin?.ein?.firstPartEin ||
		tin?.ein?.secondPartEin;

	const showTinError = tinError && tinTouched;

	return (
		<form className="acc-table-container">
			<table className="acc-table" cellSpacing={0} cellPadding={2} border={0}>
				<tbody className="acc-tbody">
					<W9Header />
					<tr className="acc-tr">
						<td className="acc-td" colSpan={6}>
							<div className="acc-names">
								<TableInput
									required
									label="First Name:"
									id="billingFirstName"
									handleChange={handleChange}
									value={values.billingFirstName}
									touched={!!touched.billingFirstName}
									error={errors.billingFirstName}
								/>
								<TableInput
									required
									label="Last Name:"
									id="billingLastName"
									handleChange={handleChange}
									value={values.billingLastName}
									touched={!!touched.billingLastName}
									error={errors.billingLastName}
								/>
							</div>
						</td>
					</tr>
					<tr className="acc-tr">
						<td className="acc-td" colSpan={6}>
							<TableInput
								required
								label="Business Name:"
								id="billingBusinessName"
								className="acc-form-fixed"
								handleChange={handleChange}
								value={values.billingBusinessName}
								touched={!!touched.billingBusinessName}
								error={errors.billingBusinessName}
							/>
						</td>
					</tr>
					<tr className="acc-tr">
						<td className="acc-td" colSpan={6}>
							<TableInput
								required
								label="Address:"
								id="billingStreet"
								className="acc-form-fixed"
								handleChange={handleChange}
								value={values.billingStreet}
								touched={!!touched.billingStreet}
								error={errors.billingStreet}
							/>
						</td>
					</tr>
					<tr className="acc-tr">
						<td className="acc-td" colSpan={3}>
							<TableInput
								required
								label="City:"
								id="billingCity"
								value={values.billingCity}
								className="acc-form-column"
								handleChange={handleChange}
								touched={!!touched.billingCity}
								error={errors.billingCity}
							/>
						</td>
						<W9States />
						<td className="acc-td" colSpan={1}>
							<TableInput
								required
								label="Zip:"
								className="acc-zip"
								id="billingPostalCode"
								handleChange={handleZipChange}
								value={values.billingPostalCode}
								touched={!!touched.billingPostalCode}
								error={errors.billingPostalCode}
							/>
						</td>
					</tr>
					<tr className="acc-tr">
						<td className="acc-td acc-td-multi" colSpan={2}>
							<div className="acc-form-multi">
								<div
									id="billingPhone"
									className={cn('acc-form', {
										'acc-error': showBillingPhoneError,
									})}
								>
									<label htmlFor="phone" className="acc-label acc-phone">
										Phone:
										<input
											type="text"
											id="firstPartPhone"
											className="acc-input"
											onChange={handlePhoneChange}
											value={values.billingPhone.firstPartPhone}
										/>
										<span>-</span>
										<input
											type="text"
											id="secondPartPhone"
											className="acc-input"
											ref={secondPartPhoneRef}
											onChange={handlePhoneChange}
											value={values.billingPhone.secondPartPhone}
										/>
										<span>-</span>
										<input
											type="text"
											id="thirdPartPhone"
											className="acc-input"
											ref={thirdPartPhoneRef}
											onChange={handlePhoneChange}
											value={values.billingPhone.thirdPartPhone}
										/>
									</label>
									<span className="acc-form-required">*</span>
									{showBillingPhoneError && (
										<span className="acc-error-message">
											{billingPhoneError}
										</span>
									)}
								</div>
								<TableInput
									label="Ext:"
									required={false}
									className="acc-ext"
									id="billingPhoneExt"
									handleChange={handleChange}
									value={values.billingPhoneExt}
									touched={!!touched.billingPhoneExt}
									error={errors.billingPhoneExt}
								/>
							</div>
						</td>
						<td className="acc-td" colSpan={1}>
							<TableInput
								label="Fax:"
								id="billingFax"
								required={false}
								value={values.billingFax}
								handleChange={handleChange}
								touched={!!touched.billingFax}
								error={errors.billingFax}
							/>
						</td>
						<td className="acc-td" colSpan={3}>
							<TableInput
								required
								label="Email:"
								id="billingEmail"
								value={values.billingEmail}
								handleChange={handleChange}
								touched={!!touched.billingEmail}
								error={errors.billingEmail}
							/>
						</td>
					</tr>
				</tbody>
			</table>
			<W9EntityType />
			<table className="acc-table" cellSpacing={0} cellPadding={2} border={0}>
				<tbody className="acc-tbody">
					<tr className="acc-tr">
						<th className="acc-th acc-part" colSpan={6}>
							<span className="acc-part-num">Part I</span>
							<span className="acc-part-title">
								Taxpayer Identification Number (TIN)
							</span>
						</th>
					</tr>
					<tr className="acc-tr">
						<td className="acc-td-clear">
							<p className="acc-paragraph">
								Enter your TIN in the appropriate box. For individuals, this is
								your social security number (SSN). However, for a resident
								alien, sole proprietor, or disregarded entity, see the Part I
								instruction on page 2.For other entities, it is your employer
								identification number (EIN).
								<br />
								If you do not have a number, see How to get a TIN on page 2.
							</p>
							<p className="acc-paragraph">
								Note:If the account is in more than one name, see the chart on
								page 2 for guidelines on whose number to enter.
							</p>
						</td>
						<td className="acc-td-clear">
							<div id="tin" className="acc-form-container">
								<label htmlFor="securityNum" className="acc-label">
									Social security number
								</label>
								<div
									className={cn('acc-string-field acc-security', {
										'acc-error': showTinError,
									})}
								>
									<div className="acc-form-row">
										<input
											id="firstPartSsn"
											className="acc-input"
											onChange={handleSsnChange}
											type={ssnVisibility.inputType}
											value={values.tin.ssn.firstPartSsn}
										/>
										<span>-</span>
										<input
											id="secondPartSsn"
											className="acc-input"
											ref={secondPartSsnRef}
											onChange={handleSsnChange}
											type={ssnVisibility.inputType}
											value={values.tin.ssn.secondPartSsn}
										/>
										<span>-</span>
										<div className="acc-toggle-section">
											<input
												id="thirdPartSsn"
												className="acc-input"
												ref={thirdPartSsnRef}
												onChange={handleSsnChange}
												type={ssnVisibility.inputType}
												value={values.tin.ssn.thirdPartSsn}
											/>
											<span
												className="acc-toggle-btn"
												onClick={ssnVisibility.handleShowValue}
											>
												{ssnVisibility.toggleValue}
											</span>
										</div>
										<span className="acc-form-required">*</span>
									</div>
								</div>
								<span className="acc-divider">OR</span>
								<div>
									<label htmlFor="indentifNum" className="acc-label">
										Employer identification number
									</label>
									<div
										className={cn('acc-string-field acc-employer', {
											'acc-error': showTinError,
										})}
									>
										<div className="acc-form-row">
											<input
												id="firstPartEin"
												className="acc-input"
												onChange={handleEinChange}
												type={einVisibility.inputType}
												value={values.tin.ein.firstPartEin}
											/>
											<span>-</span>
											<div className="acc-toggle-section">
												<input
													id="secondPartEin"
													className="acc-input"
													ref={secondPartEinRef}
													onChange={handleEinChange}
													type={einVisibility.inputType}
													value={values.tin.ein.secondPartEin}
												/>
												<span
													className="acc-toggle-btn"
													onClick={einVisibility.handleShowValue}
												>
													{einVisibility.toggleValue}
												</span>
											</div>
											<span className="acc-form-required">*</span>
										</div>
										{showTinError && (
											<span className="acc-error-message">{tinError}</span>
										)}
									</div>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<table className="acc-table" cellSpacing={0} cellPadding={2} border={0}>
				<tbody className="acc-tbody">
					<tr className="acc-tr">
						<th className="acc-th acc-part" colSpan={3}>
							<span className="acc-part-num">Part II</span>
							<span className="acc-part-title">Certification</span>
						</th>
					</tr>
					<tr className="acc-tr">
						<td className="acc-td-clear">
							<span className="acc-list-title">
								Under penalties of perjury, I certify that:
							</span>
							<ul className="acc-list">
								<li className="acc-list-item">
									The number shown on this form is my correct taxpayer
									identification number (or I am waiting for a number to be
									issued to me)
								</li>
								<li className="acc-list-item">
									I am not subject to backup withholding because: (a)I am exempt
									from backup withholding, or (b)I have not been notified by the
									Internal Revenue Services (IRS) that I am subject to backup
									withholding as a result of a failure to report all interest or
									dividends, or (c)the IRS has notified me that I am no longer
									subject to backup withholding, and I am a U.S. citizen or
									other U.S. person (as defined on page 2).
								</li>
								<li className="acc-list-item">
									I nor any principals in my firm are presently debarred,
									suspended, proposed for debarment, declared ineligible or
									voluntarily excluded from participation in covered
									transactions by any federal department or agency.
								</li>
								<li className="acc-list-item">
									I am the same person (or payee’s agent) accessing the system
									and submitting this form as identified on the Substitute Form
									W-9.
								</li>
							</ul>
							<p className="acc-list-info">
								Certification instructions: You must cross out item 2 above if
								you have been notified by the IRS that you are currently subject
								to backup withholding because you have failed to report all
								interest and dividends on your tax return. For real estate
								ransactions, item 2 does not apply.
							</p>
						</td>
					</tr>
					<tr className="acc-tr">
						<td>
							<div className="acc-initial">
								<TableInput
									required
									label="Initial Here:"
									id="billingW9Initials"
									handleChange={handleChange}
									value={values.billingW9Initials}
									touched={!!touched.billingW9Initials}
									error={errors.billingW9Initials}
								/>
								<span className="acc-initial-notif">
									By initialing here you agree that you agree with the
									Certifications in Part II
								</span>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</form>
	);
};
