import { ICreateNewOrganizationBody } from 'api/models/requests/organizations/createNewOrganization';
import { IOrganizationStatistic } from 'api/models/responses/organizations/organizationStatistic';
import { IArchivedOrganization } from 'api/models/responses/organizations/archivedOrganization';
import { IOrganizationDetails } from 'api/models/responses/organizations/organizationDetails';
import { IActiveOrganization } from 'api/models/responses/organizations/activeOrganization';
import { IPatchBody } from 'api/models/requests/general/patchBody';

import { organizationsUrls } from 'constants/apiUrls/organizationsUrls';
import { multipartHeaders } from 'constants/images/multipartHeaders';
import { api } from 'config/api';

class OrganizationService {
	async getOrganizationStatistic(
		orgKey: number
	): Promise<IOrganizationStatistic> {
		const { data } = await api.get<IOrganizationStatistic>(
			organizationsUrls.getOrganizationStatistic(orgKey)
		);

		return data;
	}

	async getActiveOrganizations(): Promise<IActiveOrganization[]> {
		const { data } = await api.get<IActiveOrganization[]>(
			organizationsUrls.getActiveOrganizations
		);

		return data;
	}

	async getArchivedOrganizations(): Promise<IArchivedOrganization[]> {
		const { data } = await api.get<IArchivedOrganization[]>(
			organizationsUrls.getArchivedOrganizations
		);

		return data;
	}

	async getOrganizationDetails(orgKey: number): Promise<IOrganizationDetails> {
		const { data } = await api.get<IOrganizationDetails>(
			organizationsUrls.organizationUrlIncludingKey(orgKey)
		);

		return data;
	}

	async fullOrganizationUpdate(
		orgKey: number,
		body: Partial<IOrganizationDetails>
	): Promise<IOrganizationDetails> {
		const { data } = await api.put<IOrganizationDetails>(
			organizationsUrls.organizationUrlIncludingKey(orgKey),
			body
		);

		return data;
	}

	async partialOrganizationUpdate(
		orgKey: number,
		body: IPatchBody[]
	): Promise<IOrganizationDetails> {
		const { data } = await api.patch<IOrganizationDetails>(
			organizationsUrls.organizationUrlIncludingKey(orgKey),
			body
		);

		return data;
	}

	async deleteOrganization(orgKey: number): Promise<string> {
		const { data } = await api.delete<string>(
			organizationsUrls.organizationUrlIncludingKey(orgKey)
		);

		return data;
	}

	async createNewOrganization(
		createOrganizationBody: ICreateNewOrganizationBody
	): Promise<IOrganizationDetails> {
		const { data } = await api.post<IOrganizationDetails>(
			organizationsUrls.createNewOrganization,
			createOrganizationBody
		);

		return data;
	}

	async setCoverImage(orgKey: number, file: File): Promise<string> {
		const formData = new FormData();

		formData.set('imageFile', file);

		const { data } = await api.post<string>(
			organizationsUrls.coverImageUrl(orgKey),
			formData,
			{
				...multipartHeaders,
			}
		);

		return data;
	}

	async setSearchResultImage(orgKey: number, file: File): Promise<string> {
		const formData = new FormData();

		formData.set('imageFile', file);

		const { data } = await api.post<string>(
			organizationsUrls.searchResultsImageUrl(orgKey),
			formData,
			{
				...multipartHeaders,
			}
		);

		return data;
	}

	async deleteCoverImage(orgKey: number): Promise<void> {
		await api.delete<boolean>(organizationsUrls.coverImageUrl(orgKey));
	}

	async deleteSearchResultImage(orgKey: number): Promise<void> {
		await api.delete<boolean>(organizationsUrls.searchResultsImageUrl(orgKey));
	}
}

export default new OrganizationService();
