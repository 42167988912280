import { StudioPoseOptionTypes } from 'api/models/responses/priceLists/studio/priceListOptions/poseOption';
import { ISelectOption } from 'types/ui/select';

import { studioPoseOptionTypesUI } from '../poseOptionTypesUI';

export const studioPoseOptionTypesSelectOptions: ISelectOption<StudioPoseOptionTypes>[] =
	Object.values(StudioPoseOptionTypes)
		.filter((value) => value !== StudioPoseOptionTypes.None)
		.map((value) => {
			const { singleLabel } = studioPoseOptionTypesUI[value];

			return {
				value,
				label: singleLabel,
			};
		});
