import { createSelector } from 'reselect';

import { imagoBackgroundsSelector } from 'store/priceLists/priceListBackgrounds';
import { RootState } from 'store/store';

import { imagoPoseOptionTypesSelectOptions } from 'constants/priceLists/imago/selectOptions/poseOptionTypesSelectOptions';
import { ImagoPoseOptionTypes } from 'api/models/responses/priceLists/imago/priceListOptions/poseOptionTypes';

export const priceListPoseOptionsSelector = (state: RootState) =>
	state.imagoPriceListPoseOptions;

export const addedPoseOptionTypesSelector = createSelector(
	priceListPoseOptionsSelector,
	({ addedPoseOptionTypes }) => addedPoseOptionTypes
);

export const emptyImagoBackgroundsSelector = createSelector(
	[addedPoseOptionTypesSelector, imagoBackgroundsSelector],
	(addedPoseOptionTypes, imagoBackgrounds) =>
		!imagoBackgrounds.length && addedPoseOptionTypes.Backgrounds
);

export const showImagoBackgroundOptionsSelector = createSelector(
	[imagoBackgroundsSelector, addedPoseOptionTypesSelector],
	(imagoBackgrounds, addedPoseOptionTypes) =>
		!!imagoBackgrounds.length || addedPoseOptionTypes.Backgrounds
);

export const filteredPoseOptionTypesSelectOptionsSelector = createSelector(
	[showImagoBackgroundOptionsSelector],
	(showImagoBackgroundOptions) =>
		imagoPoseOptionTypesSelectOptions.filter(({ value }) => {
			const backgroundsOptionsExist =
				value === ImagoPoseOptionTypes.Backgrounds &&
				showImagoBackgroundOptions;

			if (backgroundsOptionsExist) {
				return false;
			}

			return true;
		})
);
