import { IMarketingStatistic } from 'api/models/responses/dashboard/marketingStatistic';
import { IStudioStatistic } from 'api/models/responses/dashboard/studioStatistic';
import { ISendLinkBody } from 'api/models/requests/dashboard/sendLink';

import { dashboardUrls } from 'constants/apiUrls/dashboardUrls';
import { api } from 'config/api';

class DashboardService {
	async getMarketingStatistic(
		dateFrom: string,
		dateTo: string
	): Promise<IMarketingStatistic[]> {
		const { data } = await api.get<IMarketingStatistic[]>(
			dashboardUrls.marketingStatistic,
			{
				params: {
					dateFrom,
					dateTo,
				},
			}
		);

		return data;
	}

	async getStudioStatistic(
		dateFrom: string,
		dateTo: string
	): Promise<IStudioStatistic> {
		const { data } = await api.get<IStudioStatistic>(dashboardUrls.studio, {
			params: {
				dateFrom,
				dateTo,
			},
		});

		return data;
	}

	async sendLink(body: ISendLinkBody): Promise<void> {
		const { data } = await api.post<void>(dashboardUrls.sendLink, body);

		return data;
	}
}

export default new DashboardService();
