import { IPriceListSale } from '../priceLists/priceListSale';

export enum SaleTypes {
	JourneyAPresale = 'JourneyAPresale',
	JourneyALatePresale = 'JourneyALatePresale',
	JourneyAGallerySale = 'JourneyAGallerySale',
	JourneyBProofSale = 'JourneyBProofSale',
	JourneyBGallerySale = 'JourneyBGallerySale',
	JourneyCGallerySale = 'JourneyCGallerySale',
	JourneyDPresale = 'JourneyDPresale',
	JourneyDGallerySale = 'JourneyDGallerySale',
	JourneyEPresale = 'JourneyEPresale',
	JourneyELatePresale = 'JourneyELatePresale',
	JourneyEGallerySale = 'JourneyEGallerySale',
}

export interface ISale {
	saleKey: number;
	saleType: SaleTypes;
	projectGreeting: string;
	isSaleAvailable: boolean;
	saleEndDate: string | null;
	isKioskModeEnabled: boolean;
	saleStartDate: string | null;
	orderNotificationEmail: string;
	orderConfirmationMessage: string;
	allowedPriceLists: IPriceListSale[];
	priceListResponse: IPriceListSale | null;
}
