import { FC } from 'react';

import { presetDetailsImages } from 'constants/projects/presetDetailsImages';
import { selectedJourneyPresetSelector } from 'store/journeyPresets';
import presetHeaderImage from 'images/projects/preset-header.png';
import { useAppSelector } from 'hooks/redux/useAppSelector';

export const PresetDetails: FC = () => {
	const selectedJourneyPreset = useAppSelector(selectedJourneyPresetSelector);

	if (!selectedJourneyPreset) return null;

	const presetDetailsImage = presetDetailsImages[selectedJourneyPreset.journey];

	return (
		<div className="preset-form">
			<div className="preset-back">
				<img
					alt="preset"
					src={presetHeaderImage}
					className="preset-header-img"
				/>
				<span className="preset-placeholder" />
			</div>
			<div className="preset-scroll">
				<img
					alt="preset details"
					src={presetDetailsImage}
					className="preset-details-img"
				/>
			</div>
		</div>
	);
};
