import { IGiftAndAccessoriesProduct } from 'api/models/responses/priceLists/imago/priceListGiftAndAccessories';
import { ICreateProduct } from 'api/models/requests/priceLists/imago/priceListProducts/createProduct';
import { CatalogProductCategoryTypes } from 'api/models/responses/catalogProducts/catalogProducts';
import { IProduct } from 'api/models/responses/priceLists/imago/priceListProducts';
import { IPatchBody } from 'api/models/requests/general/patchBody';

import { multipartHeaders } from 'constants/images/multipartHeaders';
import { priceListsUrls } from 'constants/apiUrls/priceListsUrls';

import { api } from 'config/api';

class PriceListProductsService {
	async getProducts(
		priceListKey: number,
		catalogProductCategoryType: CatalogProductCategoryTypes
	): Promise<IProduct[]> {
		const { data } = await api.get<IProduct[]>(
			priceListsUrls.imagoFulfilled.products.getProducts(
				priceListKey,
				catalogProductCategoryType
			)
		);

		return data;
	}

	async getSpecificProducts(
		priceListKey: number
	): Promise<IGiftAndAccessoriesProduct[]> {
		const { data } = await api.get<IGiftAndAccessoriesProduct[]>(
			priceListsUrls.imagoFulfilled.products.getSpecificProducts(priceListKey)
		);

		return data;
	}

	async createProduct(
		priceListKey: number,
		{
			name,
			sequence,
			retailPrice,
			availableFor,
			previewImageFile,
			catalogProductKey,
			previewDescription,
		}: ICreateProduct
	): Promise<IProduct> {
		const formData = new FormData();

		formData.set('Name', name);
		formData.set('AvailableFor', availableFor);
		formData.set('Sequence', sequence.toString());
		formData.set('RetailPrice', retailPrice.toString());
		formData.set('PreviewImage', previewImageFile ?? '');
		formData.set('PreviewDescription', previewDescription);
		formData.set('CatalogProductKey', catalogProductKey.toString());

		const { data } = await api.post<IProduct>(
			priceListsUrls.imagoFulfilled.products.createProduct(priceListKey),
			formData
		);

		return data;
	}

	async updateProduct(
		productKey: number,
		body: IPatchBody[]
	): Promise<IProduct> {
		const { data } = await api.patch<IProduct>(
			priceListsUrls.imagoFulfilled.products.getProductUrl(productKey),
			body
		);

		return data;
	}

	async deleteProduct(productKey: number): Promise<void> {
		await api.delete<string>(
			priceListsUrls.imagoFulfilled.products.getProductUrl(productKey)
		);
	}

	async updateCatalogProduct(
		productKey: number,
		catalogProductKey: number
	): Promise<IProduct> {
		const { data } = await api.put<IProduct>(
			priceListsUrls.imagoFulfilled.products.updateCatalogProduct(productKey),
			{ catalogProductKey }
		);

		return data;
	}

	async uploadProductImage(
		productKey: number,
		imageFile: File
	): Promise<string> {
		const formData = new FormData();

		formData.append('imageFile', imageFile);

		const { data } = await api.post<string>(
			priceListsUrls.imagoFulfilled.products.getProductImageUrl(productKey),
			formData,
			{ ...multipartHeaders }
		);

		return data;
	}

	async deleteProductImage(productKey: number): Promise<void> {
		await api.delete<string>(
			priceListsUrls.imagoFulfilled.products.getProductImageUrl(productKey)
		);
	}

	async reorderProducts(
		priceListKey: number,
		body: Record<number, number>
	): Promise<void> {
		await api.put<string>(
			priceListsUrls.imagoFulfilled.products.reorderProducts(priceListKey),
			body
		);
	}
}

export default new PriceListProductsService();
