import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { initialOwnPackageOptionErrors } from 'constants/priceLists/studio/initialOwnPackageOptionErrors';
import { PackageOptionTypes } from 'constants/priceLists/studio/packageOptionTypes';

import { IOwnPackageOptionUI } from 'pages/PriceLists/types/studio/priceListOptions/ownPackageOptions';
import { MultiPoseOwnPackageOptions } from 'pages/PriceLists/components/MultiPoseOwnPackageOptions';
import { AddOptionTypeModalBody } from 'pages/PriceLists/components/AddOptionTypeModalBody';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useToggleSection } from 'hooks/useToggleSection';

import { ToggleSection } from 'components/ToggleSection';
import { Button } from 'components/FormControls/Button';
import { Modal, ModalRef } from 'components/Modal';
import { Loader } from 'components/Loader';

import OwnPackageOptionsService from 'api/services/PriceListsService/studio/OwnPackageOptionsService';
import {
	addPackageOptionType,
	setGroupedPackageOptions,
	groupedPackageOptionsSelector,
	showOwnPackageOptionsSelector,
	filteredPackageOptionTypesSelectOptionsSelector,
} from 'store/priceLists/studio/priceListPackageOptions';

import { OwnPackageOptions } from './OwnPackageOptions';

interface IPackageOptionsContainerProps {
	className: string;
	priceListKey?: number;
	isPriceListPending: boolean;
}

export const PackageOptionsContainer: FC<IPackageOptionsContainerProps> = ({
	className,
	priceListKey,
	isPriceListPending,
}) => {
	const [isPending, setIsPending] = useState(true);

	const groupedPackageOptions = useAppSelector(groupedPackageOptionsSelector);
	const showOwnPackageOptions = useAppSelector(showOwnPackageOptionsSelector);
	const filteredPackageOptionTypesSelectOptions = useAppSelector(
		filteredPackageOptionTypesSelectOptionsSelector
	);

	const addPackageOptionModalRef = useRef<ModalRef>(null);

	const { isOpen, handleToggle } = useToggleSection(false);

	const dispatch = useAppDispatch();

	const showAddPackageOptionModal = () => {
		addPackageOptionModalRef?.current?.open();
	};

	const hideAddPackageOptionModal = () => {
		addPackageOptionModalRef?.current?.close();
	};

	const addOptionType = (packageOptionType: PackageOptionTypes) => {
		dispatch(addPackageOptionType(packageOptionType));

		hideAddPackageOptionModal();
	};

	const getOwnPackageOptions = useCallback(async () => {
		if (!priceListKey) return;

		setIsPending(true);

		try {
			const data = await OwnPackageOptionsService.getOwnPackageOptions(
				priceListKey
			);

			const processedOwnPackageOptions: IOwnPackageOptionUI[] = data.map(
				(ownPackageOption) => ({
					...ownPackageOption,
					errors: initialOwnPackageOptionErrors,
				})
			);

			dispatch(
				setGroupedPackageOptions({
					options: processedOwnPackageOptions,
					optionType: PackageOptionTypes.BuildYourOwn,
				})
			);
		} catch (error) {
			console.log(error);
		}

		setIsPending(false);
	}, [priceListKey]);

	useEffect(() => {
		void getOwnPackageOptions();
	}, [getOwnPackageOptions]);

	const disableAddPackageOptionBtn = showOwnPackageOptions;

	const showLoader = isPending || isPriceListPending;

	return (
		<>
			<ToggleSection
				isOpen={isOpen}
				className={className}
				title="Package Options"
				handleToggle={handleToggle}
			>
				{showLoader ? (
					<Loader />
				) : (
					<div className="price-container">
						{showOwnPackageOptions && (
							<OwnPackageOptions
								priceListKey={priceListKey}
								ownPackageOptionsUI={groupedPackageOptions.BuildYourOwn}
							/>
						)}
						<MultiPoseOwnPackageOptions />
						<Button
							disabled={disableAddPackageOptionBtn}
							handleClick={showAddPackageOptionModal}
							className="price-add-type btn-secondary"
							value="+ Add Another Type of Package Option"
						/>
					</div>
				)}
			</ToggleSection>
			<Modal ref={addPackageOptionModalRef} title="Add Type of Package Option">
				<AddOptionTypeModalBody<PackageOptionTypes>
					selectLabel="Package Option"
					addOptionType={addOptionType}
					btnValue="Add This Package Option"
					selectPlaceholder="Select Package Option Type"
					selectOptions={filteredPackageOptionTypesSelectOptions}
				/>
			</Modal>
		</>
	);
};
