import { FormQuestionDataFieldType } from 'api/models/responses/general/generalFormQuestion';
import { ISubjectFormQuestionUI } from 'components/SubjectFormQuestions/types';
import { IProject } from 'api/models/responses/projects/projectDetails';

export enum Steps {
	STEP1 = 'STEP1',
	STEP2 = 'STEP2',
}

export interface IStepControl {
	setStep: (step: Steps) => void;
}

export interface TestContextExtended {
	parent: unknown;
}

export interface IHandleUpdateParams {
	localKey: string;
	validationMessage?: string;
	subjectFormQuestionKey?: number;
	fieldKey: keyof Partial<ISubjectFormQuestionUI>;
	value: string | boolean | FormQuestionDataFieldType;
}

export type IClearErrorParams = Omit<
	IHandleUpdateParams,
	'validationMessage' | 'value'
>;

export interface IProjectUpdate {
	projectDetails: IProject;
	setProjectDetails: (projectDetails: IProject) => void;
}

export interface IUpdateTimeline {
	isUpdateTimeline?: boolean;
	setIsUpdateTimeline: (isUpdate: boolean) => void;
}
