import { object, string } from 'yup';

import { requiredFieldMessage } from 'constants/general/validation/generalMessages';
import {
	campaignNameRegEx,
	campaignNameErrorMessage,
} from 'constants/campaigns/validation';

export const copyCampaignValidationSchema = object().shape({
	name: string()
		.required(requiredFieldMessage)
		.matches(campaignNameRegEx, campaignNameErrorMessage),
});
