import { ICreateProjectFromPresetBody } from 'api/models/requests/projects/createProjectFromPreset';
import { UnitedOrgDetailsProjectSortLabels } from 'api/models/requests/projects/projectsParams';
import { IProjectCreationFormParams } from 'api/models/requests/projects/projectCreationForm';
import { ICreateBlankProjectBody } from 'api/models/requests/projects/createBlankProject';
import { IProjectCreationForm } from 'api/models/responses/projects/projectCreationForm';
import { IActiveStudioProject } from 'api/models/responses/projects/activeStudioProject';
import { ISearchProjectParams } from 'api/models/requests/projects/searchProjectParams';
import { IPaginationResponse } from 'api/models/responses/general/paginationResponse';
import { IUpdateProjectSale } from 'api/models/requests/projects/updateProjectSale';
import { ICreateProspectsBody } from 'api/models/requests/projects/createProspects';
import { IProjectStatistic } from 'api/models/responses/projects/projectStatistic';
import { ICreateProspects } from 'api/models/responses/projects/createProspects';
import { IValidateNameUrl } from 'api/models/requests/projects/validateNameUrl';
import { ProjectJourneys } from 'api/models/requests/projects/projectJourneys';
import { ILatestProject } from 'api/models/responses/projects/latestProjects';
import { IProjectTimeline } from 'api/models/responses/projects/timeline';
import { CampaignsTarget } from 'api/models/responses/campaigns/campaign';
import { IProject } from 'api/models/responses/projects/projectDetails';
import { IComingSoon } from 'api/models/responses/projects/comingSoon';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import { ISale } from 'api/models/responses/projects/sales';
import {
	ISearchedProject,
	IOrganizationProject,
} from 'api/models/responses/projects/organizationProjects';
import {
	ISelectCampaign,
	ICampaignDetails,
	ICampaignStatistic,
} from 'api/models/responses/projects/marketing';

import { multipartHeaders } from 'constants/images/multipartHeaders';
import { projectsUrls } from 'constants/apiUrls/projectsUrls';

import { ISortParams } from 'types/sortParams';
import { api } from 'config/api';

class ProjectsService {
	async getProjectStatistic(projectKey: number): Promise<IProjectStatistic> {
		const { data } = await api.get<IProjectStatistic>(
			projectsUrls.getProjectStatistic(projectKey)
		);

		return data;
	}

	async getProjectDetails(projectKey: number): Promise<IProject> {
		const { data } = await api.get<IProject>(
			projectsUrls.projectUrlIncludingKey(projectKey)
		);

		return data;
	}

	async getActiveStudioProjects(): Promise<IActiveStudioProject[]> {
		const { data } = await api.get<IActiveStudioProject[]>(
			projectsUrls.activeStudioProjects
		);

		return data;
	}

	async partialProjectUpdate(
		projectKey: number,
		body: IPatchBody[]
	): Promise<IProject> {
		const { data } = await api.patch<IProject>(
			projectsUrls.projectUrlIncludingKey(projectKey),
			body
		);

		return data;
	}

	async deleteProject(projectKey: number): Promise<void> {
		await api.delete<string>(projectsUrls.projectUrlIncludingKey(projectKey));
	}

	async getComingSoonAsync(): Promise<IComingSoon[]> {
		const { data } = await api.get<IComingSoon[]>(projectsUrls.comingSoon);

		return data;
	}

	async getLatestProjects(): Promise<ILatestProject[]> {
		const { data } = await api.get<ILatestProject[]>(projectsUrls.latest);

		return data;
	}

	async getActiveOrganizationProjects(
		orgKey: number,
		params: ISortParams<UnitedOrgDetailsProjectSortLabels>
	): Promise<IPaginationResponse<IOrganizationProject>> {
		const { data } = await api.get<IPaginationResponse<IOrganizationProject>>(
			projectsUrls.activeForOrganization(orgKey),
			{
				params,
			}
		);

		return data;
	}

	async getExpiredOrganizationProjects(
		orgKey: number,
		params: ISortParams<UnitedOrgDetailsProjectSortLabels>
	): Promise<IPaginationResponse<IOrganizationProject>> {
		const { data } = await api.get<IPaginationResponse<IOrganizationProject>>(
			projectsUrls.expiredForOrganization(orgKey),
			{
				params,
			}
		);

		return data;
	}

	async searchProject(
		params: ISearchProjectParams
	): Promise<IPaginationResponse<ISearchedProject>> {
		const { data } = await api.get<IPaginationResponse<ISearchedProject>>(
			projectsUrls.searchProject,
			{
				params,
			}
		);

		return data;
	}

	async validateNameUrl({
		projectUrl,
		projectName,
		estimatedNumberOfSubjects,
	}: IValidateNameUrl): Promise<boolean> {
		const { data } = await api.get<boolean>(projectsUrls.validateNameUrl, {
			params: {
				ProjectUrl: projectUrl,
				ProjectName: projectName,
				EstimatedNumberOfSubjects: estimatedNumberOfSubjects,
			},
		});

		return data;
	}

	async getReferencedItems({
		journey,
		projectType,
	}: IProjectCreationFormParams): Promise<IProjectCreationForm> {
		const { data } = await api.get<IProjectCreationForm>(
			projectsUrls.getProjectCreateFormData(journey),
			{
				params: {
					ProjectType: projectType,
				},
			}
		);

		return data;
	}

	async createBlankProject(body: ICreateBlankProjectBody): Promise<IProject> {
		const { data } = await api.post<IProject>(
			projectsUrls.createBlankProject,
			body
		);

		return data;
	}

	async createProjectFromPreset(
		presetKey: number,
		body: ICreateProjectFromPresetBody
	): Promise<IProject> {
		const { data } = await api.post<IProject>(
			projectsUrls.createProjectFromPreset(presetKey),
			body
		);

		return data;
	}

	async setCoverImage(projectKey: number, file: File): Promise<string> {
		const formData = new FormData();

		formData.set('imageFile', file);

		const { data } = await api.post<string>(
			projectsUrls.coverImageUrl(projectKey),
			formData,
			{
				...multipartHeaders,
			}
		);

		return data;
	}

	async deleteCoverImage(projectKey: number): Promise<void> {
		await api.delete<boolean>(projectsUrls.coverImageUrl(projectKey));
	}

	async getMarketingCampaignStatistics(
		projectKey: number
	): Promise<ICampaignStatistic[]> {
		const { data } = await api.get<ICampaignStatistic[]>(
			projectsUrls.getMarketingCampaignStatistic(projectKey)
		);

		return data;
	}

	async getMarketingCampaignDetails(
		projectKey: number
	): Promise<ICampaignDetails[]> {
		const { data } = await api.get<ICampaignDetails[]>(
			projectsUrls.getMarketingCampaignDetails(projectKey)
		);

		return data;
	}

	async getProjectTimeline(projectKey: number): Promise<IProjectTimeline> {
		const { data } = await api.get<IProjectTimeline>(
			projectsUrls.getProjectTimeline(projectKey)
		);

		return data;
	}

	async getProjectSale(saleKey: number): Promise<ISale> {
		const { data } = await api.get<ISale>(
			projectsUrls.getProjectSaleUrl(saleKey)
		);

		return data;
	}

	async updateProjectSale(
		saleKey: number,
		body: IUpdateProjectSale
	): Promise<void> {
		await api.put<string>(projectsUrls.getProjectSaleUrl(saleKey), body);
	}

	async getMarketingCampaigns(
		campaignTarget: CampaignsTarget,
		journey: ProjectJourneys
	): Promise<ISelectCampaign[]> {
		const { data } = await api.get<ISelectCampaign[]>(
			projectsUrls.getMarketingCampaigns(campaignTarget, journey)
		);

		return data;
	}

	async createProspects(
		projectKey: number,
		{ file, emailColumnName, phoneNumberColumnName }: ICreateProspectsBody
	): Promise<ICreateProspects> {
		const formData = new FormData();

		formData.set('file', file);
		formData.set('EmailColumnName', emailColumnName);
		formData.set('PhoneNumberColumnName', phoneNumberColumnName);

		const { data } = await api.post<ICreateProspects>(
			projectsUrls.createProspects(projectKey),
			formData
		);

		return data;
	}
}

export default new ProjectsService();
