export const organizationNameRegEx =
	/^[a-zA-Z0-9][a-zA-Z0-9\s'‘’`.&-]*[a-zA-Z0-9'‘’`.&-]$/;

export const nameValidationMessage =
	'Name must be 3 - 100 letters, numbers, and spaces, and must begin with a letter or number.';

export const organizationUrlRegEx = /^[a-zA-Z0-9][a-zA-Z0-9-]*$/;

export const orgNameLengthLimit = 75;
export const orgUrlLengthLimit = 255;

export const orgNameMaxLengthMessage =
	'Organization Name max length is 75 chars.';
export const orgUrlMaxLengthMessage = 'Invalid url. Max length is 255 chars.';

export const orgNameRegExMessage =
	'Please change your organization name to only use the following symbols: a-z, A-Z, 0-9, (space), ‘(apostrophe), - (dash), .(dot), & (ampersand).';
export const orgUrlRegExMessage =
	"Invalid url. Allows only [a-z], [A-Z] chars, - (dash) and digits [0-9]. Can't start with dash.";
