import { FC } from 'react';

import { allStudiosSelector, studioKeySelector } from 'store/studio';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import StudioService from 'api/services/StudioService';

import { Account } from './Account';

interface IAccountsProps {
	handleLogout: () => void;
}

export const Accounts: FC<IAccountsProps> = ({ handleLogout }) => {
	const allStudios = useAppSelector(allStudiosSelector);
	const studioKey = useAppSelector(studioKeySelector);

	const selectStudio = async (key: number) => {
		try {
			await StudioService.switchStudio(key);

			handleLogout();
		} catch (error) {
			console.log(error);
		}
	};

	const StudiosList = allStudios.map(({ studioKey: key, studioName }) => (
		<Account
			key={key}
			studioKey={key}
			studioName={studioName}
			selectStudio={selectStudio}
			isActive={studioKey === key}
		/>
	));

	return (
		<div className="aside-section">
			<h3 className="aside-section-title">ACCOUNTS</h3>
			<div className="aside-content">
				<ul className="aside-menu">{StudiosList}</ul>
			</div>
		</div>
	);
};
