import { Formik, FormikHelpers } from 'formik';
import { FC, useEffect } from 'react';

import { ISendLinkBody } from 'api/models/requests/dashboard/sendLink';
import DashboardService from 'api/services/DashboardService';

import { phoneErrorMessage } from 'constants/general/validation/phoneE164USValidation';
import { getActiveStudioProjectsAsync } from 'store/projects';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import {
	isValidPhone,
	convertUSPhoneToE164Format,
} from 'utils/validations/general/validatePhone';

import { sendLinkValidationSchema } from './validation';
import { SendLinkForm } from './SendLinkForm';

export interface ISendLinkValues
	extends Omit<
		ISendLinkBody,
		'projectKey' | 'emailAddress' | 'mobilePhoneNumber'
	> {
	emailAddress: string;
	projectKey: number | null;
	mobilePhoneNumber: string;
}

export const SendLink: FC = () => {
	const dispatch = useAppDispatch();

	const initialValues: ISendLinkValues = {
		passcode: '',
		projectKey: null,
		emailAddress: '',
		mobilePhoneNumber: '',
		addToProspectCampaign: false,
	};

	const onSubmit = async (
		values: ISendLinkValues,
		{ setErrors }: FormikHelpers<ISendLinkValues>
	) => {
		const { projectKey, emailAddress, mobilePhoneNumber } = values;

		if (!projectKey) return;

		if (mobilePhoneNumber) {
			const convertedPhoneNumber =
				convertUSPhoneToE164Format(mobilePhoneNumber);

			const isValid = isValidPhone(convertedPhoneNumber);

			if (!isValid) {
				return setErrors({
					mobilePhoneNumber: phoneErrorMessage,
				});
			}
		}

		const body: ISendLinkBody = {
			...values,
			projectKey,
			mobilePhoneNumber:
				emailAddress && !mobilePhoneNumber ? null : mobilePhoneNumber,
			emailAddress: mobilePhoneNumber && !emailAddress ? null : emailAddress,
		};

		try {
			await DashboardService.sendLink(body);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		void dispatch(getActiveStudioProjectsAsync());
	}, []);

	return (
		<div className="aside-section aside-mob-section">
			<h3 className="aside-section-title">SEND A LINK</h3>
			<Formik
				onSubmit={onSubmit}
				initialValues={initialValues}
				validationSchema={sendLinkValidationSchema}
			>
				<SendLinkForm />
			</Formik>
		</div>
	);
};
