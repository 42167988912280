import { date, object, string } from 'yup';

import { getInvalidKeyWordsMessage } from 'utils/broadcast/getInvalidKeyWordsMessage';
import { getMaxLengthMessage } from 'utils/validations/general/getMaxLengthMessage';
import { parseKeyWordsFromString } from 'utils/broadcast/parseKeyWordsFromString';
import { validateKeyWords } from 'utils/broadcast/validateKeyWords';

import {
	requiredFieldMessage,
	invalidCharactersMessage,
} from 'constants/general/validation/generalMessages';
import {
	broadcastNameRegEx,
	emailBodyMaxLength,
	maxEmailSubjectLength,
	broadcastNameMaxLength,
	emailBodyMaxLengthError,
} from 'constants/broadcasts/validation';

export const getBroadcastValidationSchema = (validateSpecialDate: boolean) =>
	object().shape({
		broadcastName: string()
			.required(requiredFieldMessage)
			.max(broadcastNameMaxLength, getMaxLengthMessage(broadcastNameMaxLength))
			.matches(broadcastNameRegEx, invalidCharactersMessage),

		days: string().required(requiredFieldMessage),

		specialDate: validateSpecialDate
			? date().default(null).required(requiredFieldMessage)
			: date().default(null).notRequired(),

		emailSubject: string()
			.required(requiredFieldMessage)
			.max(maxEmailSubjectLength, getMaxLengthMessage(maxEmailSubjectLength))
			.test('', '', (value, context) => {
				const parsedKeyWords = parseKeyWordsFromString(value);

				const { isValidKeyWords, invalidKeyWords } =
					validateKeyWords(parsedKeyWords);

				if (!isValidKeyWords) {
					return context.createError({
						message: getInvalidKeyWordsMessage(invalidKeyWords),
					});
				}

				return true;
			}),

		emailMessage: string()
			.required(requiredFieldMessage)
			.max(emailBodyMaxLength, emailBodyMaxLengthError)
			.test('', '', (value, context) => {
				const parsedKeyWords = parseKeyWordsFromString(value);

				const { isValidKeyWords, invalidKeyWords } =
					validateKeyWords(parsedKeyWords);

				if (!isValidKeyWords) {
					return context.createError({
						message: getInvalidKeyWordsMessage(invalidKeyWords),
					});
				}

				return true;
			}),

		textMessage: string()
			.required(requiredFieldMessage)
			.test('', '', (value, context) => {
				const parsedKeyWords = parseKeyWordsFromString(value);

				const { isValidKeyWords, invalidKeyWords } =
					validateKeyWords(parsedKeyWords);

				if (!isValidKeyWords) {
					return context.createError({
						message: getInvalidKeyWordsMessage(invalidKeyWords),
					});
				}

				return true;
			}),
	});
