import { IPaginatedPresetsParams } from 'api/models/requests/presets/paginatedPresetsParams';
import { IPaginationResponse } from 'api/models/responses/general/paginationResponse';
import { IUpdateCampaign } from 'api/models/requests/marketing/updateCampaign';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import {
	ICreatePreset,
	ICreatePresetFromProjectBody,
} from 'api/models/requests/presets/createPresets';
import {
	IPreset,
	IBasePreset,
	IPresetSale,
} from 'api/models/responses/presets/presets';

import { presetsUrls } from 'constants/apiUrls/presetsUrls';
import { api } from 'config/api';

class PresetsService {
	async getPresets({
		skip,
		take,
		orderBy,
		journey,
		projectType,
		orderDirection,
	}: IPaginatedPresetsParams): Promise<IPaginationResponse<IBasePreset>> {
		const { data } = await api.get<IPaginationResponse<IBasePreset>>(
			presetsUrls.presetsUrl,
			{
				params: {
					Skip: skip,
					Take: take,
					OrderBy: orderBy,
					Journey: journey ?? '',
					projectType: projectType ?? '',
					OrderDirection: orderDirection,
				},
			}
		);

		return data;
	}

	async getPreset(presetKey: number): Promise<IPreset> {
		const { data } = await api.get<IPreset>(
			presetsUrls.getPresetsUrlIncludingKey(presetKey)
		);

		return data;
	}

	async createPreset(body: ICreatePreset): Promise<IPreset> {
		const { data } = await api.post<IPreset>(presetsUrls.presetsUrl, body);

		return data;
	}

	async deletePreset(presetKey: number): Promise<void> {
		await api.delete<string>(presetsUrls.getPresetsUrlIncludingKey(presetKey));
	}

	async updatePreset(presetKey: number, body: IPatchBody[]): Promise<IPreset> {
		const { data } = await api.patch<IPreset>(
			presetsUrls.getPresetsUrlIncludingKey(presetKey),
			body
		);

		return data;
	}

	async createPresetFromProject(
		projectKey: number,
		body: ICreatePresetFromProjectBody
	): Promise<IPreset> {
		const { data } = await api.post<IPreset>(
			presetsUrls.createPresetFromProject(projectKey),
			body
		);

		return data;
	}

	async updatePresetCampaign(
		presetKey: number,
		body: IUpdateCampaign
	): Promise<IPreset> {
		const { data } = await api.put<IPreset>(
			presetsUrls.updatePresetCampaign(presetKey),
			body
		);

		return data;
	}

	async getPresetSales(presetKey: number): Promise<IPresetSale[]> {
		const { data } = await api.get<IPresetSale[]>(
			presetsUrls.presetSales(presetKey)
		);

		return data;
	}

	async updatePresetSale(
		presetKey: number,
		body: IPatchBody[]
	): Promise<IPresetSale> {
		const { data } = await api.patch<IPresetSale>(
			presetsUrls.getPresetSaleUrl(presetKey),
			body
		);

		return data;
	}
}

export default new PresetsService();
