import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Formik, FormikHelpers } from 'formik';
import Editor from 'ckeditor5-custom-build';
import { lazy } from 'yup';

import { ISendSpecialBroadcastBody } from 'api/models/requests/broadcast/sendSpecialBroadcast';
import { IBroadcastInfo } from 'api/models/responses/broadcasts/broadcastInfo';
import BroadcastService from 'api/services/BroadcastService';
import {
	ErrorTypes,
	IErrorResponse,
} from 'api/models/responses/general/errorResponse';

import { validateSendToEmails } from 'utils/validations/broadcasts/validateSendToEmails';
import { validateSendToPhones } from 'utils/validations/broadcasts/validateSendToPhones';
import { inHourTimeline } from 'utils/dates/inHourTimeline';

import { MarketingKeywords } from 'components/Broadcast/MarketingKeywords';
import {
	endDayHour,
	startDayHour,
} from 'constants/general/dates/dayHoursLimit';

import { TextMessageSection } from './components/TextMessageSection';
import { ISendBroadcastProps, ISendBroadcastValues } from './types';
import { BroadcastControls } from './components/BroadcastControls';
import { BroadcastHeader } from './components/BroadcastHeader';
import { getSendSpecialBroadcastScheme } from './validation';
import { EmailSection } from './components/EmailSection';
import { AlsoInclude } from './components/AlsoInclude';

export const SendBroadcast: FC<ISendBroadcastProps> = ({
	projectKey,
	hideSendBroadcastModal,
	setSentToNumberOfPeople,
	showSendSpecialBroadcastInfo,
	showSpecialBroadcastRejectModal,
}) => {
	const [broadcastInfo, setBroadcastInfo] = useState<IBroadcastInfo | null>(
		null
	);
	const [isPending, setIsPending] = useState(false);

	const emailEditorRef = useRef<CKEditor<Editor>>(null);

	const initialValues: ISendBroadcastValues = {
		textMessage: '',
		emailMessage: '',
		emailSubject: '',
		sendByEmail: true,
		sendToPhoneNumber: '',
		sendToEmailAddress: '',
		leads: !!broadcastInfo?.hasAnyLeads,
		buyers: !!broadcastInfo?.hasAnyBuyers,
		prospects: !!broadcastInfo?.hasAnyProspects,
		sendByPhone:
			!!broadcastInfo?.hasVerifiedPhoneNumber &&
			inHourTimeline(startDayHour, endDayHour),
	};

	const getBroadcastInfo = useCallback(async () => {
		if (!projectKey) return;

		setIsPending(true);

		try {
			const data = await BroadcastService.getBroadcastInfo(projectKey);

			setBroadcastInfo(data);
		} catch (error) {
			console.log(error);
		}

		setIsPending(false);
	}, [projectKey]);

	const onSubmit = async (
		{
			leads,
			buyers,
			prospects,
			textMessage,
			sendByEmail,
			sendByPhone,
			emailSubject,
			emailMessage,
			sendToPhoneNumber,
			sendToEmailAddress,
		}: ISendBroadcastValues,
		{ setErrors }: FormikHelpers<ISendBroadcastValues>
	) => {
		if (!projectKey) return;

		const sendToEmailAddressError = validateSendToEmails(sendToEmailAddress);
		const sendToPhoneNumberError = validateSendToPhones(sendToPhoneNumber);

		if (sendToPhoneNumberError || sendToEmailAddressError) {
			return setErrors({
				sendToPhoneNumber: sendToPhoneNumberError,
				sendToEmailAddress: sendToEmailAddressError,
			});
		}

		const sendSpecialBroadcastBody: ISendSpecialBroadcastBody = {
			leads,
			buyers,
			prospects,
			projectKey,
			sendByEmail,
			sendByPhone,
			textMessage,
			emailSubject,
			emailMessage,
			sendToPhoneNumber,
			sendToEmailAddress,
		};

		try {
			const data = await BroadcastService.sendSpecialBroadcast(
				sendSpecialBroadcastBody
			);

			setSentToNumberOfPeople(data.sentToNumberOfPeople);
			hideSendBroadcastModal();
			showSendSpecialBroadcastInfo();
		} catch (error) {
			const { type, errors } = error as IErrorResponse;

			if (type === ErrorTypes.BusinessError) {
				const isMarketingError = errors.isMarketingEnabled;

				if (!isMarketingError) return;

				showSpecialBroadcastRejectModal();
			}
		}
	};

	const validationSchema = lazy((values: ISendBroadcastValues) =>
		getSendSpecialBroadcastScheme(values.sendByEmail, values.sendByPhone)
	);

	useEffect(() => {
		void getBroadcastInfo();
	}, [getBroadcastInfo]);

	return (
		<Formik
			enableReinitialize
			onSubmit={onSubmit}
			initialValues={initialValues}
			validationSchema={validationSchema}
		>
			<div className="broadcast">
				<BroadcastHeader isPending={isPending} broadcastInfo={broadcastInfo} />
				<EmailSection
					editorRef={emailEditorRef}
					broadcastInfo={broadcastInfo}
				/>
				<TextMessageSection broadcastInfo={broadcastInfo} />
				<MarketingKeywords initialIsOpen />
				<AlsoInclude broadcastInfo={broadcastInfo} />
				<BroadcastControls
					editorRef={emailEditorRef}
					broadcastInfo={broadcastInfo}
					hideSendBroadcastModal={hideSendBroadcastModal}
				/>
			</div>
		</Formik>
	);
};
