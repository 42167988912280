import { Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';
import { parseTwoDigitYear } from 'moment';
import { FC, useState } from 'react';

import { toastifyRequiredFields } from 'constants/general/toast/toastifyRequiredFields';
import { requiredFieldMessage } from 'constants/general/validation/generalMessages';
import { ROUTES } from 'constants/ROUTES';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useToastify } from 'hooks/useToastify';

import { ButtonTypes } from 'types/ui/buttonTypes';
import { Payments } from 'types/ui/payments';

import {
	ErrorTypes,
	IErrorResponse,
} from 'api/models/responses/general/errorResponse';
import { splitCamelCaseString } from 'utils/general/splitCamelCaseString';
import { createStudioAsync } from 'store/studio';

import { createStudioScheme } from './validations';

import { BillingInformation } from './components/BillingInformation';
import { StudioInformation } from './components/StudioInformation';
import { SideWrapper } from '../components/SideWrapper';
import { StepHeader } from '../components/StepHeader';
import { Loader } from 'components/Loader';
import { Button } from 'components/FormControls/Button';

export interface ICreateStudioForm {
	zip: string;
	city: string;
	state: string;
	phone: string;
	street: string;
	studioName: string;
	cardNumber: string;
	expiration: string;
	billingZip: string;
	networkDomain: string;
	verificationCode: string;
	cardType: Payments | null;
}

export const CreateStudio: FC = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { showErrorsToastify } = useToastify();

	const [loading, setLoading] = useState(false);

	const initialValues: ICreateStudioForm = {
		studioName: '',
		phone: '',
		street: '',
		city: '',
		zip: '',
		cardNumber: '',
		expiration: '',
		verificationCode: '',
		billingZip: '',
		cardType: Payments.VISA,
		state: '',
		networkDomain: '',
	};

	const onSubmit = async (
		values: ICreateStudioForm,
		{ setErrors }: FormikHelpers<ICreateStudioForm>
	) => {
		setLoading(true);
		try {
			const {
				zip,
				city,
				state,
				phone,
				street,
				cardType,
				studioName,
				billingZip,
				expiration,
				cardNumber,
				networkDomain,
				verificationCode,
			} = values;

			if (!cardType) return;

			const studioContactInfo = {
				zip,
				city,
				state,
				phone,
				street,
				website: networkDomain,
			};

			const [expireMonth, expireYear] = expiration.split('/');

			const fullYear = parseTwoDigitYear(expireYear);

			const billingCardInfo = {
				cardType,
				cardNumber,
				billingZip,
				verificationCode,
				expireYear: fullYear,
				expireMonth: +expireMonth,
			};

			const createStudioBody = {
				studioName,
				networkDomain,
				billingCardInfo,
				studioContactInfo,
			};

			await dispatch(createStudioAsync(createStudioBody));

			navigate(ROUTES.STUDIO_STEPS.SELECT_PLAN);
		} catch (error) {
			const { type, errors } = error as IErrorResponse;

			if (type !== ErrorTypes.ValidationError) {
				const updatedToastifyError = {
					type: splitCamelCaseString(type),
					errors,
				};

				showErrorsToastify(updatedToastifyError);
			}

			const updatedErrorMessages = Object.entries(errors).reduce<
				FormikErrors<ICreateStudioForm>
			>((acc, [key, messages]) => {
				const splittedKey = key.split('.');

				const parsedKey = splittedKey[splittedKey.length - 1];

				const doesKeyExist = parsedKey in values;

				const parsedMessage = messages[0] || '';

				if (parsedKey === 'expireYear' || parsedKey === 'expireMonth') {
					return {
						...acc,
						expiration: parsedMessage,
					};
				}

				if (!doesKeyExist) return acc;

				return {
					...acc,
					[parsedKey]: parsedMessage,
				};
			}, {});

			setErrors(updatedErrorMessages);
		} finally {
			setLoading(false);
		}
	};

	const validateHandler = async (
		validateForm: FormikHelpers<ICreateStudioForm>['validateForm']
	) => {
		const errors = await validateForm();

		const isError = Object.values(errors).some(
			(errorMessage) => errorMessage === requiredFieldMessage
		);

		if (isError) {
			showErrorsToastify(toastifyRequiredFields);
		}

		if (loading) return <Loader isFullHeight={true} />;
	};

	return (
		<div className="studio-container">
			<Formik
				onSubmit={onSubmit}
				initialValues={initialValues}
				validationSchema={createStudioScheme}
			>
				{({ validateForm }) => (
					<Form className="panel">
						<StepHeader title="Create a New Studio" stepCount="Step 1 of 3" />
						<div className="form-wrapper">
							<h2 className="studio-title">Studio Information</h2>
							<StudioInformation />
							<h2 className="studio-title">Billing Information</h2>
							<BillingInformation />
						</div>
						<Button
							value="Create Studio"
							type={ButtonTypes.SUBMIT}
							className="studio-btn btn-primary"
							handleClick={() => {
								void validateHandler(validateForm);
							}}
						/>
					</Form>
				)}
			</Formik>
			<SideWrapper />
		</div>
	);
};
