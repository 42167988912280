import { createSelector } from 'reselect';

import { RootState } from 'store/store';

const baseOptionsStateSelector = (state: RootState) =>
	state.studioPriceListBaseOptions;

export const shippingOptionsSelector = createSelector(
	baseOptionsStateSelector,
	(state) => state.shippingOptions
);

export const additionalChargeOptionsSelector = createSelector(
	baseOptionsStateSelector,
	(state) => state.additionalChargeOptions
);

export const isPendingBaseOptionsSelector = createSelector(
	baseOptionsStateSelector,
	(state) => state.isPendingBaseOptions
);

export const showWaiveShippingChargeCheckboxSelector = createSelector(
	shippingOptionsSelector,
	(options) =>
		options.length && options.some(({ shippingPrice }) => shippingPrice)
);
