import { Dispatch } from '@reduxjs/toolkit';

import SubscriptionsService from 'api/services/SubscriptionsService';

import {
	setSubscription,
	setSubscriptions,
	setSubscriptionLevelKey,
} from './subscriptionsSlice';

export const getSubscriptionsAsync = () => async (dispatch: Dispatch) => {
	try {
		const data = await SubscriptionsService.getSubscriptionsList();

		dispatch(setSubscriptions(data));
	} catch (error) {
		console.log(error);
	}
};

export const getSubscriptionAsync = () => async (dispatch: Dispatch) => {
	try {
		const data = await SubscriptionsService.getSubscription();

		if (!data) return;

		dispatch(setSubscription(data));
	} catch (error) {
		console.log(error);
	}
};

export const setSubscriptionAsync =
	(studioKey: number, activeSubscriptionLevelKey: number) =>
	async (dispatch: Dispatch) => {
		await SubscriptionsService.setStudioSubscription(
			activeSubscriptionLevelKey,
			studioKey
		);

		dispatch(setSubscriptionLevelKey(activeSubscriptionLevelKey));
	};
