import { useMsal } from '@azure/msal-react';
import { FC, useEffect } from 'react';

import { Confirm } from 'components/Modal/components/Confirm';
import { ToggleSection } from 'components/ToggleSection';
import { Button } from 'components/FormControls/Button';
import { Modal } from 'components/Modal';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useModalControls } from 'hooks/useModalControls';
import { useToggleSection } from 'hooks/useToggleSection';
import { useToastify } from 'hooks/useToastify';

import { studioKeySelector } from 'store/studio';
import {
	subscriptionSelector,
	setSubscriptionAsync,
	getSubscriptionsAsync,
	setShowUpgradePlanModal,
	setActiveSubscriptionKey,
	subscriptionLevelKeySelector,
	showUpgradePlanModalSelector,
	activeSubscriptionKeySelector,
} from 'store/subscriptions';

import { IErrorResponse } from 'api/models/responses/general/errorResponse';
import { normalizeDigit } from 'utils/ui/normalizeDigit';

import { UpgradeYourPlan } from '../UpgradeYourPlan';

export const PlanInformation: FC = () => {
	const subscriptionLevelKey = useAppSelector(subscriptionLevelKeySelector);
	const subscription = useAppSelector(subscriptionSelector);
	const studioKey = useAppSelector(studioKeySelector);
	const showUpgradePlanModalInitially = useAppSelector(
		showUpgradePlanModalSelector
	);
	const activeSubscriptionLevelKey = useAppSelector(
		activeSubscriptionKeySelector
	);

	const dispatch = useAppDispatch();
	const { instance } = useMsal();

	const { isOpen, handleToggle } = useToggleSection(true);
	const { showErrorsToastify } = useToastify();

	const {
		modalRef: upgradePlanModalRef,
		showModal: showUpgradePlanModal,
		hideModal: hideUpgradePlanModal,
	} = useModalControls();

	const {
		modalRef: confirmUpgradePlanModalRef,
		showModal: showConfirmUpgradePlanModal,
		hideModal: hideConfirmUpgradePlanModal,
	} = useModalControls();

	const showUpgradePlanModalAndSetupInitialKey = () => {
		showUpgradePlanModal();
		dispatch(setActiveSubscriptionKey(subscriptionLevelKey));
	};

	const choosePlan = async () => {
		try {
			await dispatch(
				setSubscriptionAsync(studioKey, activeSubscriptionLevelKey)
			);

			await instance.logoutRedirect();
		} catch (error) {
			const { type, errors } = error as IErrorResponse;

			const toastifyError = {
				type,
				errors,
			};

			showErrorsToastify(toastifyError);
		}
	};

	useEffect(() => {
		void dispatch(getSubscriptionsAsync());
	}, []);

	useEffect(() => {
		if (!showUpgradePlanModalInitially) return;

		showUpgradePlanModalAndSetupInitialKey();
		dispatch(setShowUpgradePlanModal(false));
	}, [showUpgradePlanModalInitially]);

	const normalizedImagesCount = normalizeDigit({
		value: subscription?.maxImageCount ?? 0,
	});

	return (
		<div className="accing-toggle-section">
			<ToggleSection
				isOpen={isOpen}
				title="Plan Information"
				handleToggle={handleToggle}
				className="accing-toggle-header"
			>
				<div className="accing-wrapper">
					<div className="accing-list-wrapper">
						<ul className="accing-list">
							<li className="accing-item">
								Plan:
								<span>
									<b>{subscription?.name}</b>
								</span>
							</li>
							<li className="accing-item">
								Subscription: <span>${subscription?.subscriptionLevelKey}</span>
							</li>
							<li className="accing-item">
								Studio Fee: <span>{subscription?.exceededMaxStorageFee}%</span>
							</li>
							<li className="accing-item">
								Auto Fee:
								<span>{subscription?.autoFulfillServiceFeePercent}%</span>
							</li>
							<li className="accing-item">
								Images:
								<span>{normalizedImagesCount}</span>
							</li>
						</ul>
						<Button
							value="Upgrade Now"
							className="btn-primary accing-btn"
							handleClick={showUpgradePlanModalAndSetupInitialKey}
						/>
					</div>
				</div>
			</ToggleSection>
			<Modal
				ref={upgradePlanModalRef}
				title="Upgrade Your Plan"
				subtitle="Choose a plan that best suits your business needs"
			>
				<UpgradeYourPlan
					hideUpgradePlanModal={hideUpgradePlanModal}
					showConfirmUpgradePlanModal={showConfirmUpgradePlanModal}
				/>
			</Modal>
			<Modal
				ref={confirmUpgradePlanModalRef}
				title="You are about to change your account plan"
				subtitle="In order to complete the change to your plan, we must first log you out. Click the confirm button below to complete the upgrade to your plan. After you may login and enjoy the benefits of your new upgrade."
			>
				<Confirm
					handleConfirm={choosePlan}
					confirmBtnValue="Confirm & Logout"
					hideConfirmModal={hideConfirmUpgradePlanModal}
				/>
			</Modal>
		</div>
	);
};
