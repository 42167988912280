import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IGalleryImage } from 'api/models/responses/imageGallery';

import { IImageGalleryState } from '.';

const initialState: IImageGalleryState = {
	galleryImages: [],
	isImageGalleryPending: false,
	isUploadImagesPending: false,
};

const imageGallerySlice = createSlice({
	name: 'imageGallery',
	initialState,
	reducers: {
		setGalleryImages: (state, { payload }: PayloadAction<IGalleryImage[]>) => {
			state.galleryImages = payload;
		},

		deleteGalleryImage: (state, { payload }: PayloadAction<number>) => {
			state.galleryImages = state.galleryImages.filter(
				({ galleryImageKey }) => galleryImageKey !== payload
			);
		},

		setUploadedImage: (state, { payload }: PayloadAction<IGalleryImage>) => {
			state.galleryImages = [payload, ...state.galleryImages];
		},

		setIsImageGalleryPending: (state, { payload }: PayloadAction<boolean>) => {
			state.isImageGalleryPending = payload;
		},

		setIsUploadImagesPending: (state, { payload }: PayloadAction<boolean>) => {
			state.isUploadImagesPending = payload;
		},
	},
});

export const imageGalleryReducer = imageGallerySlice.reducer;
export const {
	setUploadedImage,
	setGalleryImages,
	deleteGalleryImage,
	setIsImageGalleryPending,
	setIsUploadImagesPending,
} = imageGallerySlice.actions;
