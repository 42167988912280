import { ActionMeta, SingleValue } from 'react-select';
import { useFormikContext } from 'formik';
import { FC } from 'react';

import { projectTypesSelectOptions } from 'constants/projects/selectOptions/projectTypesSelectOptions';
import { journeyTimelines } from 'constants/projects/timeline/journeyTimelines';

import { JourneyTimeline } from 'components/Journey/JourneyTimeline';
import { SelectComponent } from 'components/FormControls/Select';
import { InputGrid } from 'components/FormControls/InputGrid';

import { ProjectTypes } from 'api/models/requests/projects/projectTypes';
import { getJourneyType } from 'utils/journeyTimeline/getJourneyType';
import { ISelectOption } from 'types/ui/select';

import { IJourneyPresetValues } from '../../../types';

export const JourneyPresetTimeline: FC = () => {
	const { values, errors, touched, setFieldValue } =
		useFormikContext<IJourneyPresetValues>();

	const handleChangeProjectType = (
		option: SingleValue<ISelectOption<ProjectTypes>>,
		actionMeta?: ActionMeta<ISelectOption<ProjectTypes>>
	) => {
		if (!option || !actionMeta?.name) return;

		void setFieldValue(actionMeta.name, option.value);
	};

	const journeyType = getJourneyType(journeyTimelines[values.journey]);

	const {
		journeyText,
		journeyTitle,
		journeySubText,
		journeyBlankProjectImage: journeyImage,
	} = journeyTimelines[values.journey];

	return (
		<>
			{/* TODO: add styles for placing project type and journey in row */}
			<div className="preset-section-wrapper preset-wrapper-header pres-details">
				<SelectComponent
					name="projectType"
					label="Project Type"
					error={errors.projectType}
					value={values.projectType}
					className="select-required"
					touched={touched.projectType}
					onChange={handleChangeProjectType}
					selectOptions={projectTypesSelectOptions}
				/>
				<InputGrid
					disabled
					readOnly
					value={journeyType}
					label="Journey Type"
					className="autorow preset-header-input"
				/>
			</div>
			<div className="preset-journey">
				<JourneyTimeline
					journeyText={journeyText}
					journeyTitle={journeyTitle}
					journeyImage={journeyImage}
					journeySubText={journeySubText}
				/>
			</div>
		</>
	);
};
