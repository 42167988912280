import { FC, useState } from 'react';

import { CatalogProductCategoryTypes } from 'api/models/responses/catalogProducts/catalogProducts';
import { IPatchBody } from 'api/models/requests/general/patchBody';

import { useCreatePriceListOptionBtn } from 'pages/PriceLists/hooks/useCreatePriceListOptionBtn';
import { PriceListControls } from 'pages/PriceLists/components/PriceListControls';
import { PriceTable } from 'pages/PriceLists/components/PriceTable';

import { ImagoPriceListTabs } from 'constants/priceLists/tabs/imagoPriceListTabs';
import { imagoTableHeaders } from 'constants/priceLists/imago/imagoTableHeaders';

import { Checkbox } from 'components/FormControls/Checkbox';
import { Button } from 'components/FormControls/Button';
import { NoItemsFound } from 'components/NoItemsFound';
import { Loader } from 'components/Loader';

import { useAsyncOptimizedCheckbox } from 'hooks/formControls/useAsyncOptimizedCheckbox';

import { useImagoProducts } from '../../hooks/useImagoProducts';
import { Product } from '../../components/Product';
import { IPriceListOptionsProps } from '../..';

const headers = imagoTableHeaders(true);

interface IPriceListPackagesProps extends IPriceListOptionsProps {
	isPackageSelectionRequired?: boolean;
	updatePriceListImago: (body: IPatchBody[]) => Promise<void>;
}

export const PriceListPackages: FC<IPriceListPackagesProps> = ({
	priceListKey,
	setActiveTab,
	isPriceListPending,
	updatePriceListImago,
	isPackageSelectionRequired,
}) => {
	const [showCreatedPackage, setShowCreatedPackage] = useState(false);

	const {
		productsUI,
		updateProduct,
		deleteProduct,
		lastProductUI,
		previewImageFile,
		handleAddProduct,
		isCatalogPending,
		isProductsPending,
		handleSaveProduct,
		clearProductError,
		uploadProductImage,
		deleteProductImage,
		updateCatalogProduct,
		changeProductSequence,
		catalogProductsSelectOptions,
	} = useImagoProducts({
		priceListKey,
		setShowCreatedPackage,
		catalogProductCategoryType: CatalogProductCategoryTypes.Packages,
	});

	const updateIsRequiredPackageFirst = async (value: boolean) => {
		if (!updatePriceListImago) return;

		const updatedField: IPatchBody = {
			value,
			op: 'replace',
			path: 'isPackageSelectionRequired',
		};

		const body: IPatchBody[] = [updatedField];

		await updatePriceListImago(body);
	};

	const {
		checked,
		handleChange,
		isPending: isPendingRequiredPackage,
	} = useAsyncOptimizedCheckbox(
		isPackageSelectionRequired,
		updateIsRequiredPackageFirst
	);

	const handleBack = () => {
		setActiveTab(ImagoPriceListTabs.PriceListOptions);
	};

	const handleNext = () => {
		setActiveTab(ImagoPriceListTabs.Prints);
	};

	const PackagesList = productsUI.map((productUI, index) => {
		const lastPackage = index === productsUI.length - 1;

		return (
			<Product
				productUI={productUI}
				updateProduct={updateProduct}
				deleteProduct={deleteProduct}
				previewImageFile={previewImageFile}
				clearProductError={clearProductError}
				uploadProductImage={uploadProductImage}
				deleteProductImage={deleteProductImage}
				productSelectPlaceholder="Select Package"
				updateCatalogProduct={updateCatalogProduct}
				setShowCreatedPackage={setShowCreatedPackage}
				changeProductSequence={changeProductSequence}
				catalogProductsSelectOptions={catalogProductsSelectOptions}
				showCreatedPackage={lastPackage ? showCreatedPackage : false}
				key={productUI?.priceListImagoFulfilledProductKey || productUI.sequence}
			/>
		);
	});

	const showLoader =
		isCatalogPending || isProductsPending || isPriceListPending;

	const showSaveBtn =
		!!productsUI.length && !lastProductUI?.priceListImagoFulfilledProductKey;

	const { btnValue, btnClassName, btnClickHandler } =
		useCreatePriceListOptionBtn({
			showSaveBtn,
			optionName: 'Package',
			handleAdd: handleAddProduct,
			handleSave: handleSaveProduct,
		});

	return (
		<>
			<div className="package-carte-title">
				<span className="price-package-title">Packages</span>
				<Checkbox
					checked={checked}
					handleChange={handleChange}
					label="Require Package First"
					id="isPackageSelectionRequired"
					disabled={isPendingRequiredPackage}
				/>
			</div>
			<div className="price-container price-imago-container">
				{showLoader ? (
					<Loader />
				) : (
					<>
						{productsUI.length ? (
							<PriceTable className="price-imago-package" headers={headers}>
								{PackagesList}
							</PriceTable>
						) : (
							<NoItemsFound title="packages" />
						)}
						<Button
							value={btnValue}
							handleClick={btnClickHandler}
							className={`price-add-back ${btnClassName}`}
						/>
					</>
				)}
			</div>
			<PriceListControls handleBack={handleBack} handleNext={handleNext} />
		</>
	);
};
