import { IStudioPhotoSettings } from 'api/models/responses/studio/studioPhotoSettings';
import { IUpdateStudioPhotoSettings } from 'api/models/requests/studioPhotoSettings';

import { studioPhotoSettingsUrls } from 'constants/apiUrls/studioPhotoSettingsUrls';
import { multipartHeaders } from 'constants/images/multipartHeaders';

import { api } from 'config/api';

class StudioPhotoSettingsService {
	async getStudioPhotoSettings(): Promise<IStudioPhotoSettings> {
		const { data } = await api.get<IStudioPhotoSettings>(
			studioPhotoSettingsUrls.studioPhotoSettingsUrl
		);

		return data;
	}

	async updateStudioPhotoSettings({
		imageFile,
		watermarkType,
		isCustomWatermarkDeleted,
	}: IUpdateStudioPhotoSettings): Promise<IStudioPhotoSettings> {
		const formData = new FormData();

		formData.set('Image', imageFile ?? '');
		formData.set(
			'IsCustomWatermarkDeleted',
			isCustomWatermarkDeleted.toString()
		);
		formData.set('WatermarkType', watermarkType);

		const { data } = await api.put<IStudioPhotoSettings>(
			studioPhotoSettingsUrls.studioPhotoSettingsUrl,
			formData,
			{
				...multipartHeaders,
			}
		);

		return data;
	}
}

export default new StudioPhotoSettingsService();
