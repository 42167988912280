import { DataOrder } from 'constants/general/dataOrder';

import { PriceListsSortLabels } from './priceListsSortLabels';

export const priceListsSortOptions: Record<PriceListsSortLabels, DataOrder> = {
	[PriceListsSortLabels.Name]: DataOrder.ASC,
	[PriceListsSortLabels.Fulfillment]: DataOrder.ASC,
	[PriceListsSortLabels.ProductsCount]: DataOrder.DESC,
	[PriceListsSortLabels.LastModifyDate]: DataOrder.DESC,
	[PriceListsSortLabels.ActiveProjectsCount]: DataOrder.DESC,
};
