import { IDefaultBackground } from 'api/models/responses/priceLists/defaultBackground';
import { priceListsUrls } from 'constants/apiUrls/priceListsUrls';
import { api } from 'config/api';

class DefaultBackgroundsService {
	async getDefaultBackgrounds(): Promise<IDefaultBackground[]> {
		const { data } = await api.get<IDefaultBackground[]>(
			priceListsUrls.defaultBackgroundsUrl
		);

		return data;
	}
}

export default new DefaultBackgroundsService();
