import cn from 'classnames';

import { ITab } from 'types/tab';

interface ITabsProps<Tabs> {
	activeTab: Tabs;
	className: string;
	disabledTabs?: Tabs[];
	tabsList: ITab<Tabs>[];
	setActiveTab: (activeTab: Tabs) => void;
}

export const Tabs = <Tabs extends string>({
	tabsList,
	activeTab,
	className,
	disabledTabs,
	setActiveTab,
}: ITabsProps<Tabs>) => {
	const TabsList = tabsList.map(({ id, title, disabled }) => {
		const disabledTab = disabled || disabledTabs?.includes(id);

		return (
			<li
				key={id}
				onClick={() => !disabledTab && setActiveTab(id)}
				className={cn('price-header-item', {
					'is-disabled': disabledTab,
					'is-active': activeTab === id,
				})}
			>
				{title}
			</li>
		);
	});

	return <ul className={className}>{TabsList}</ul>;
};
