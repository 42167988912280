import { SingleValue } from 'react-select';
import { useFormikContext } from 'formik';
import { FC } from 'react';

import { SelectComponent } from 'components/FormControls/Select';
import { Checkbox } from 'components/FormControls/Checkbox';
import { Button } from 'components/FormControls/Button';
import { Input } from 'components/FormControls/Input';

import { activeStudioProjectsSelectOptionsSelector } from 'store/projects';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { ISelectOption } from 'types/ui/select';

import { ISendLinkValues } from '..';

interface IField {
	placeholder: string;
	id: keyof Omit<ISendLinkValues, 'projectKey' | 'addToProspectCampaign'>;
}

const inputFields: IField[] = [
	{
		id: 'passcode',
		placeholder: 'Passcode (if not already known)',
	},
	{
		id: 'emailAddress',
		placeholder: 'Email Address',
	},
	{
		id: 'mobilePhoneNumber',
		placeholder: 'Mobile Phone Number',
	},
];

export const SendLinkForm: FC = () => {
	const { values, errors, touched, handleChange, handleSubmit, setFieldValue } =
		useFormikContext<ISendLinkValues>();

	const activeStudioProjectsSelectOptions = useAppSelector(
		activeStudioProjectsSelectOptionsSelector
	);

	const handleChangeProjectKey = (option: SingleValue<ISelectOption>) => {
		if (!option) return;

		void setFieldValue('projectKey', option.value);
	};

	const InputFieldsList = inputFields.map(({ id, placeholder }) => {
		const error = errors[id];
		const touchedField = touched[id];

		const showError = error && touchedField;

		return (
			<div key={id}>
				<Input
					id={id}
					value={values[id]}
					placeholder={placeholder}
					handleChange={handleChange}
				/>
				{showError && <span className="grid-error">{error}</span>}
			</div>
		);
	});

	const showProjectKeyError = errors.projectKey && touched.projectKey;

	return (
		<div className="aside-content">
			<div className="select-error-container">
				<SelectComponent
					value={values.projectKey}
					onChange={handleChangeProjectKey}
					selectPlaceholder="Select Project*"
					selectOptions={activeStudioProjectsSelectOptions}
				/>
				{showProjectKeyError && (
					<span className="grid-error">{errors.projectKey}</span>
				)}
			</div>
			{InputFieldsList}
			<Checkbox
				id="addToProspectCampaign"
				handleChange={handleChange}
				label="Add to Prospect Campaign"
				checked={values.addToProspectCampaign}
			/>
			<Button
				value="Share"
				className="btn-secondary"
				handleClick={handleSubmit}
			/>
		</div>
	);
};
