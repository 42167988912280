import { FC, useState, useEffect, useCallback, ReactElement } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import PriceListsImagoService from 'api/services/PriceListsService/imago/PriceListsImagoService';
import { IPriceListImago } from 'api/models/responses/priceLists/imago/priceListImago';
import { IPatchBody } from 'api/models/requests/general/patchBody';

import { usePriceListsBreadcrumbs } from 'pages/PriceLists/hooks/usePriceListsBreadcrumbs';
import { PriceListHeader } from 'pages/PriceLists/components/PriceListHeader';

import { imagoPriceListTabsList } from 'constants/priceLists/tabs/imagoPriceListTabsList';
import { ImagoPriceListTabs } from 'constants/priceLists/tabs/imagoPriceListTabs';
import { PriceListFulfillment } from 'constants/priceLists/priceListFulfillment';

import { getDefaultBackgroundsAsync } from 'store/priceLists/priceListBackgrounds';
import { IBreadcrumbs } from 'store/breadcrumbs';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { Tabs } from 'components/Tabs';

import { PriceListGiftAndAccessories } from './pages/PriceListGiftAndAccessories';
import { PriceListRetouching } from './pages/PriceListRetouching';
import { PriceListDownloads } from './pages/PriceListDownloads';
import { PriceListPackages } from './pages/PriceListPackages';
import { PriceListOptions } from './pages/PriceListOptions';
import { PriceListPrints } from './pages/PriceListPrints';
import { PriceListFree } from './pages/PriceListFree';

export interface IPriceListOptionsProps {
	priceListKey?: number;
	isPriceListPending: boolean;
	setActiveTab: (tab: ImagoPriceListTabs) => void;
}

export const PriceListImago: FC = () => {
	const [priceListImago, setPriceListImago] = useState<IPriceListImago | null>(
		null
	);

	const [isPriceListPending, setIsPriceListPending] = useState(true);

	const [activeTab, setActiveTab] = useState<ImagoPriceListTabs>(
		ImagoPriceListTabs.PriceListOptions
	);

	const { priceListId } = useParams();
	const { pathname } = useLocation();
	const dispatch = useAppDispatch();

	const priceListStudioBreadcrumb: IBreadcrumbs = {
		path: pathname,
		isActive: true,
		title: priceListImago?.name || '',
	};

	usePriceListsBreadcrumbs(priceListStudioBreadcrumb);

	const getPriceListImagoAsync = useCallback(async () => {
		try {
			if (!priceListId) return;

			const data = await PriceListsImagoService.getPriceListImago(+priceListId);

			setPriceListImago(data);
		} catch (error) {
			console.log(error);
		}

		setIsPriceListPending(false);
	}, [priceListId]);

	const updatePriceListImago = async (body: IPatchBody[]) => {
		if (!priceListId) return;

		const data = await PriceListsImagoService.updatePriceListImago(
			+priceListId,
			body
		);

		setPriceListImago(data);
	};

	useEffect(() => {
		void dispatch(getDefaultBackgroundsAsync());
	}, []);

	useEffect(() => {
		void getPriceListImagoAsync();
	}, []);

	const generalProps = {
		setActiveTab,
		isPriceListPending,
		priceListKey: priceListImago?.priceListImagoFulfilledKey,
	};

	const PriceListsTabsPages: Record<ImagoPriceListTabs, ReactElement> = {
		[ImagoPriceListTabs.PriceListOptions]: (
			<PriceListOptions
				{...generalProps}
				updatePriceListImago={updatePriceListImago}
				isFreeShippingEnabled={priceListImago?.isFreeShippingEnabled}
				freeShippingOnOrdersAbove={priceListImago?.freeShippingOnOrdersAbove}
			/>
		),
		[ImagoPriceListTabs.Packages]: (
			<PriceListPackages
				{...generalProps}
				updatePriceListImago={updatePriceListImago}
				isPackageSelectionRequired={priceListImago?.isPackageSelectionRequired}
			/>
		),
		[ImagoPriceListTabs.Prints]: <PriceListPrints {...generalProps} />,
		[ImagoPriceListTabs.GiftAccessories]: (
			<PriceListGiftAndAccessories {...generalProps} />
		),
		[ImagoPriceListTabs.Downloads]: <PriceListDownloads {...generalProps} />,
		[ImagoPriceListTabs.Free]: <PriceListFree {...generalProps} />,
		[ImagoPriceListTabs.Retouching]: <PriceListRetouching {...generalProps} />,
	};

	return (
		<div className="price">
			<PriceListHeader
				name={priceListImago?.name}
				updatePriceList={updatePriceListImago}
				fulfillment={PriceListFulfillment.Imago}
			/>
			<div className="org-container">
				<div className="price-header">
					<Tabs
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						className="price-header-list"
						tabsList={imagoPriceListTabsList}
					/>
				</div>
			</div>
			{PriceListsTabsPages[activeTab]}
		</div>
	);
};
