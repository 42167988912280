import { nameValidationMessage } from 'constants/organizations/validation';
import { requiredFieldMessage } from 'constants/general/validation/generalMessages';
import { organizationNameRegEx } from 'constants/organizations/validation';

export const validatePresetName = (presetName: string): string => {
	if (!presetName) {
		return requiredFieldMessage;
	}

	if (organizationNameRegEx.test(presetName)) {
		return nameValidationMessage;
	}

	return '';
};
