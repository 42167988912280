import { Dispatch } from '@reduxjs/toolkit';

import { initialAdditionalChargeOptionErrors } from 'constants/priceLists/studio/initialAdditionalChargeOptionErrors';
import { initialBackgroundOptionErrors } from 'constants/priceLists/studio/initialBackgroundOptionErrors';
import { initialShippingOptionErrors } from 'constants/priceLists/studio/initialShippingOptionErrors';
import { initialPoseOptionErrors } from 'constants/priceLists/studio/initialPoseOptionErrors';

import PriceListsStudioService from 'api/services/PriceListsService/studio/PriceListsStudioService';
import { getProcessedPriceListOptions } from 'utils/priceLists/getProcessedPriceListOptions';

import { setStudioBackgrounds } from '../../priceListBackgrounds';
import { setPoseOptions } from '../priceListPoseOptions';
import {
	setShippingOptions,
	setIsPendingBaseOptions,
	setAdditionalChargeOptions,
} from '.';

export const getPriceListBaseOptionsAsync =
	(priceListKey: number) => async (dispatch: Dispatch) => {
		try {
			const data = await PriceListsStudioService.getPriceListStudioBaseOptions(
				priceListKey
			);

			const processedBackgroundOptions = getProcessedPriceListOptions(
				data.priceListStudioFulfilledBackgroundOptions,
				initialBackgroundOptionErrors
			);

			const processedAdditionalChargeOptions = getProcessedPriceListOptions(
				data.priceListStudioFulfilledAdditionalChargeOptions,
				initialAdditionalChargeOptionErrors
			);

			const processedShippingOptions = getProcessedPriceListOptions(
				data.priceListStudioFulfilledShippingOptions,
				initialShippingOptionErrors
			);

			const processedPoseOptions = getProcessedPriceListOptions(
				data.priceListStudioFulfilledPoseOptions,
				initialPoseOptionErrors
			);

			dispatch(setPoseOptions(processedPoseOptions));
			dispatch(setShippingOptions(processedShippingOptions));
			dispatch(setStudioBackgrounds(processedBackgroundOptions));
			dispatch(setAdditionalChargeOptions(processedAdditionalChargeOptions));
		} catch (error) {
			console.log(error);
		}

		dispatch(setIsPendingBaseOptions(false));
	};
