import { useFormikContext } from 'formik';
import { ChangeEvent, FC } from 'react';
import cn from 'classnames';

import { statesSelectOptions } from 'constants/general/states/statesSelectOptions';
import { IGetPaidFormValues } from 'pages/RegistrationSteps/SetupAccount/types';

export const W9States: FC = () => {
	const { values, errors, touched, setFieldValue } =
		useFormikContext<IGetPaidFormValues>();

	const SelectOptionsList = statesSelectOptions.map(({ label, value }) => (
		<option key={value} value={value} label={label} />
	));

	const handleSelectOption = (e: ChangeEvent<HTMLSelectElement>) => {
		void setFieldValue('billingState', e.target.value);
	};

	const stateError = errors.billingState;
	const stateTouched = touched.billingState;

	const showStateError = stateError && stateTouched;

	return (
		<td className="acc-td" colSpan={2}>
			<div
				className={cn('acc-form-select', {
					'acc-error': showStateError,
				})}
			>
				<label htmlFor="select" className="acc-select-label">
					State:
					<select
						name="select"
						id="billingState"
						className="acc-select"
						value={values.billingState || '-Select-'}
						onChange={handleSelectOption}
					>
						<option hidden disabled value="-Select-">
							-Select-
						</option>
						{SelectOptionsList}
					</select>
					<span className="acc-form-required">*</span>
				</label>
				{showStateError && (
					<span className="acc-error-message">{stateError}</span>
				)}
			</div>
		</td>
	);
};
