import React, { ReactNode } from 'react';
import cn from 'classnames';

export interface IHeaderConfig {
	name: string;
	left?: boolean;
	suptitle?: string;
	subtitle?: string;
	required?: boolean;
}

interface PriceTableProps {
	children: ReactNode;
	headers: IHeaderConfig[];
	className?: string;
}

export const PriceTable: React.FC<PriceTableProps> = ({
	headers,
	children,
	className,
}) => (
	<table className={`price-option-table ${className || ''}`}>
		<thead className="price-option-thead">
			<tr className="price-option-tr">
				<th className="price-option-th"></th>
				{headers.map(({ name, required, suptitle, subtitle, left }) => (
					<th
						key={name}
						className={cn('price-option-th', {
							'price-table-left': left,
						})}
					>
						{suptitle && <span className="price-option-sup">{suptitle}</span>}
						{name}
						{suptitle && <sup className="price-option-sup-icon">?</sup>}
						{required && <b>*</b>}
						{subtitle && <span className="price-option-sub">{subtitle}</span>}
					</th>
				))}
				<th className="price-option-th"></th>
			</tr>
		</thead>
		{children}
	</table>
);
