import { IGiftAndAccessoriesCatalogProduct } from 'api/models/responses/catalogProducts/giftAndAccessoriesCatalogProduct';
import {
	ICatalogProduct,
	CatalogProductCategoryTypes,
} from 'api/models/responses/catalogProducts/catalogProducts';

import { catalogProductsUrls } from 'constants/apiUrls/catalogProductsUrls';
import { api } from 'config/api';

class CatalogProductsService {
	async getCatalogProducts(
		catalogCategoryType: CatalogProductCategoryTypes
	): Promise<ICatalogProduct[]> {
		const { data } = await api.get<ICatalogProduct[]>(
			catalogProductsUrls.getCatalogProducts(catalogCategoryType)
		);

		return data;
	}

	async getSpecificCatalogProducts(): Promise<
		IGiftAndAccessoriesCatalogProduct[]
	> {
		const { data } = await api.get<IGiftAndAccessoriesCatalogProduct[]>(
			catalogProductsUrls.getSpecificCatalogProducts
		);

		return data;
	}
}

export default new CatalogProductsService();
