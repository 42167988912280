import { FC } from 'react';

interface ILoaderProps {
	isFullHeight?: boolean;
	isFillContent?: boolean;
	className?: string;
}

export const Loader: FC<ILoaderProps> = ({
	isFullHeight,
	isFillContent,
	className,
}) => (
	<div
		className={`page-loader-container ${
			isFullHeight ? 'is-page' : 'is-section'
		} ${isFillContent ? 'is-content' : ''}`}
	>
		<div className={`loader ${className ?? ''}`} />
	</div>
);

Loader.defaultProps = {
	isFullHeight: false,
	isFillContent: false,
};
