import { FC, useEffect, useState } from 'react';

import PriceListsStudioService from 'api/services/PriceListsService/studio/PriceListsStudioService';
import PriceListsImagoService from 'api/services/PriceListsService/imago/PriceListsImagoService';
import {
	IRejectDeletePriceList,
	IActivePriceListChildItems,
} from 'api/models/responses/priceLists/rejectDeletePriceList';

import { priceListsTabsList } from 'constants/priceLists/tabs/priceListsTabsList';
import { PriceListsSortLabels } from 'constants/priceLists/priceListsSortLabels';
import { PriceListFulfillment } from 'constants/priceLists/priceListFulfillment';
import { PriceListsActionMenu } from 'constants/priceLists/priceListsActionMenu';
import { DataOrder } from 'constants/general/dataOrder';
import { tabs } from 'constants/general/tabs';
import {
	upgradePlanTitle,
	upgradePlanSubtitle,
} from 'constants/subscriptions/upgradePlan';

import { ConfirmDelete } from 'components/Modal/components/ConfirmDelete';
import { UpgradePlan } from 'components/Modal/components/UpgradePlan';
import { Agreement } from 'components/Modal/components/Agreement';
import { Button } from 'components/FormControls/Button';
import { NoItemsFound } from 'components/NoItemsFound';
import { Loader } from 'components/Loader';
import { Search } from 'components/Search';
import { Modal } from 'components/Modal';
import { Tabs } from 'components/Tabs';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useModalControls } from 'hooks/useModalControls';

import { usePriceListsBreadcrumbs } from 'pages/PriceLists/hooks/usePriceListsBreadcrumbs';
import { deletePriceList, isPriceListsPendingSelector } from 'store/priceLists';
import { GeneralTabs } from 'types/generalTabs';

import { RejectDeletePriceList } from './components/RejectDeletePriceList';
import { CreateNewPriceList } from './components/CreateNewPriceList';
import { useSearchPriceLists } from './hooks/useSearchPriceLists';
import { PriceListsList } from './components/PriceListsList';

export interface IActionMenuPayload {
	key: number;
	name: string;
	menuItem: PriceListsActionMenu;
	fulfillment: PriceListFulfillment;
}

export interface ILimitErrorMessage {
	message: string;
}

const initialActiveChildItems: IActivePriceListChildItems = {
	presets: [],
	projects: [],
};

export const PriceLists: FC = () => {
	const [activeTab, setActiveTab] = useState(GeneralTabs.Active);

	const [priceListKey, setPriceListKey] = useState<number | null>(null);
	const [priceListName, setPriceListName] = useState<string | null>(null);
	const [priceListFulfillment, setPriceListFulfillment] =
		useState<PriceListFulfillment | null>(null);

	const [limitErrorMessage, setLimitErrorMessage] =
		useState<ILimitErrorMessage>({ message: '' });

	const [activeChildItems, setActiveChildItems] =
		useState<IActivePriceListChildItems>(initialActiveChildItems);

	const isPriceListsPending = useAppSelector(isPriceListsPendingSelector);

	const dispatch = useAppDispatch();

	const {
		modalRef: createModalRef,
		showModal: showCreateModal,
		hideModal: hideCreateModal,
	} = useModalControls();

	const {
		modalRef: confirmDeleteModalRef,
		showModal: showConfirmDeleteModal,
		hideModal: hideConfirmDeleteModal,
	} = useModalControls();

	const {
		modalRef: successDeleteModalRef,
		showModal: showSuccessDeleteModal,
		hideModal: hideSuccessDeleteModal,
	} = useModalControls();

	const {
		modalRef: rejectDeleteModalRef,
		showModal: showRejectDeleteModal,
		hideModal: hideRejectDeleteModal,
	} = useModalControls();

	const {
		modalRef: copyModalRef,
		showModal: showCopyModal,
		hideModal: hideCopyModal,
	} = useModalControls();

	const {
		modalRef: successCopyModalRef,
		showModal: showSuccessCopyModal,
		hideModal: hideSuccessCopyModal,
	} = useModalControls();

	const {
		modalRef: rejectCreateModalRef,
		showModal: showRejectCreateModal,
		hideModal: hideRejectCreateModal,
	} = useModalControls();

	const {
		modalRef: upgradePlanModalRef,
		showModal: showUpgradePlanModal,
		hideModal: hideUpgradePlanModal,
	} = useModalControls();

	const {
		orderBy,
		results,
		setOrderBy,
		handleSearch,
		setSortParams,
		orderDirection,
		setOrderDirection,
		setPaginatedEntity,
	} = useSearchPriceLists(tabs[activeTab]);

	usePriceListsBreadcrumbs();

	const deletePriceListAsync = async () => {
		if (!priceListKey || !priceListFulfillment) return;

		try {
			if (priceListFulfillment === PriceListFulfillment.Studio) {
				await PriceListsStudioService.deletePriceListStudio(priceListKey);
			}

			if (priceListFulfillment === PriceListFulfillment.Imago) {
				await PriceListsImagoService.deletePriceListImago(priceListKey);
			}

			dispatch(deletePriceList(priceListKey));

			showSuccessDeleteModal();
			hideConfirmDeleteModal();
		} catch (error) {
			const { isDeleted, activeChildItems: activeChildItemsRes } =
				error as IRejectDeletePriceList;

			if (!isDeleted) {
				setActiveChildItems(activeChildItemsRes[0]);
				showRejectDeleteModal();
				hideConfirmDeleteModal();
			}
		}
	};

	const handleActionMenuClick = ({
		key,
		name,
		menuItem,
		fulfillment,
	}: IActionMenuPayload) => {
		setPriceListKey(key);
		setPriceListName(name);
		setPriceListFulfillment(fulfillment);

		switch (menuItem) {
			case PriceListsActionMenu.DuplicatePriceList:
				showCopyModal();
				break;

			case PriceListsActionMenu.DeletePermanently:
				showConfirmDeleteModal();
				break;

			default:
				break;
		}
	};

	const sortPriceListsAfterCopy = () => {
		if (!setOrderBy || !setOrderDirection) return;

		setOrderDirection(DataOrder.DESC);
		setOrderBy(PriceListsSortLabels.LastModifyDate);
	};

	const handleHideRejectCreateModal = () => {
		hideRejectCreateModal();
		showUpgradePlanModal();
	};

	useEffect(() => {
		if (!limitErrorMessage.message) return;

		showRejectCreateModal();
	}, [limitErrorMessage]);

	const processedPriceListName = priceListName ?? '';

	const modals = [
		{
			ref: createModalRef,
			title: 'Create New Price List',
			children: (
				<CreateNewPriceList
					hideCreateModal={hideCreateModal}
					setLimitErrorMessage={setLimitErrorMessage}
				/>
			),
		},
		{
			ref: confirmDeleteModalRef,
			title: 'Delete Price List',
			subtitle: `Are you sure you would like to delete this price list ${processedPriceListName}? This cannot be undone.`,
			children: (
				<ConfirmDelete
					handlePermanentlyDelete={deletePriceListAsync}
					hideConfirmDeleteModal={hideConfirmDeleteModal}
				/>
			),
		},
		{
			ref: successDeleteModalRef,
			title: 'Price List Deleted',
			subtitle: `The price list ${processedPriceListName} has been deleted`,
			children: <Agreement handleAgreement={hideSuccessDeleteModal} />,
		},
		{
			ref: rejectDeleteModalRef,
			title: 'Cannot Delete Price List',
			subtitle:
				'This price list cannot be deleted. The following projects and/or presets are use this price list. You can delete this price list once it has been replaced on the following projects and/or presets. Or after they have been deleted.',
			children: (
				<RejectDeletePriceList
					hideModal={hideRejectDeleteModal}
					activeChildItems={activeChildItems}
				/>
			),
		},
		{
			ref: copyModalRef,
			title: 'Duplicate Price List',
			children: (
				<CreateNewPriceList
					isCopy
					priceListKey={priceListKey}
					hideCopyModal={hideCopyModal}
					setPriceListName={setPriceListName}
					initialFulfillment={priceListFulfillment}
					showSuccessCopyModal={showSuccessCopyModal}
					setLimitErrorMessage={setLimitErrorMessage}
					sortPriceListsAfterCopy={sortPriceListsAfterCopy}
				/>
			),
		},
		{
			ref: successCopyModalRef,
			title: 'Price List Duplicated',
			subtitle: `The new price list ${processedPriceListName} is being created and will appear on your active price lists when ready.`,
			children: <Agreement handleAgreement={hideSuccessCopyModal} />,
		},
		{
			ref: upgradePlanModalRef,
			title: upgradePlanTitle,
			subtitle: upgradePlanSubtitle,
			children: <UpgradePlan hideModal={hideUpgradePlanModal} />,
		},
		{
			ref: rejectCreateModalRef,
			title: 'Price List limit',
			subtitle: limitErrorMessage.message,
			children: <Agreement handleAgreement={handleHideRejectCreateModal} />,
		},
	];

	const Modals = modals.map(({ ref, title, subtitle, children }) => (
		<Modal key={title} ref={ref} title={title} subtitle={subtitle}>
			{children}
		</Modal>
	));

	const showPriceListTable = !!results.length && !isPriceListsPending;
	const priceListNotFound = !results.length && !isPriceListsPending;

	return (
		<>
			<div className="price">
				<div className="org-search-container">
					<Search
						title="Find a Price List"
						handleSearch={handleSearch}
						placeholder="Find a Price List"
					/>
				</div>
				<div className="org-container">
					<div className="price-header">
						<Tabs
							activeTab={activeTab}
							setActiveTab={setActiveTab}
							tabsList={priceListsTabsList}
							className="price-header-controls"
						/>
						<Button
							value="+ New Price List"
							handleClick={showCreateModal}
							className="btn-primary price-btn"
						/>
					</div>
					{showPriceListTable && (
						<PriceListsList
							orderBy={orderBy}
							results={results}
							setSortParams={setSortParams}
							orderDirection={orderDirection}
							setPaginatedEntity={setPaginatedEntity}
							handleActionMenuClick={handleActionMenuClick}
						/>
					)}
					{isPriceListsPending && <Loader isFullHeight />}
					{priceListNotFound && <NoItemsFound title="price lists" />}
				</div>
			</div>
			{Modals}
		</>
	);
};
