import {
	E164USReplaceRegEx,
	E164USPhoneNumberMatchRegEx,
} from 'constants/general/validation/phoneE164USValidation';

export const convertUSPhoneToE164Format = (phone: string) => {
	if (!phone) return '';

	if (phone.startsWith('+38')) {
		return phone;
	}

	const phoneReplaced = phone.replace(E164USReplaceRegEx, '');

	switch (phoneReplaced.length) {
		case 10:
			return `+1${phoneReplaced}`;

		case 11:
			return `+${phoneReplaced}`;

		default:
			return phone;
	}
};

export const isValidPhone = (phone: string) => {
	if (!phone) return false;

	if (phone.startsWith('+38')) return true;

	return E164USPhoneNumberMatchRegEx.test(phone);
};
