import { FC, SyntheticEvent } from 'react';
import cn from 'classnames';

import { IInputProps } from 'types/ui/inputProps';

import { LazyInput } from '../LazyInput';

interface IGridProps extends Omit<IInputProps, 'value'> {
	row?: boolean;
	error?: string;
	isLazy?: boolean;
	touched?: boolean;
	supLabel?: string;
	subLabel?: string;
	value?: string | null;
	defaultValue?: string | null;
	clearError?: (id: string) => void;
	handleLazyChange?: (e: SyntheticEvent<HTMLInputElement>) => void;
}

export const InputGrid: FC<IGridProps> = ({
	id,
	row,
	type,
	name,
	label,
	value,
	error,
	title,
	isLazy,
	touched,
	supLabel,
	subLabel,
	required,
	disabled,
	readOnly,
	className,
	handleBlur,
	clearError,
	placeholder,
	defaultValue,
	handleChange,
	handleKeyDown,
	handleLazyChange,
}) => {
	const generalInputProps = {
		id,
		name,
		type,
		label,
		title,
		readOnly,
		disabled,
		placeholder,
		className: 'input-grid',
	};

	const showError = error && touched;

	return (
		<div
			className={cn('input-form-grid', {
				[`input-form-${className || ''}`]: className,
				'input-form-error': showError,
			})}
		>
			<div className="grid-header">
				<label htmlFor={id} className="label-grid">
					{label}
					{required && <i className="grid-required">*</i>}
				</label>
				{!row && supLabel && <span className="suplabel-grid">{supLabel}</span>}
			</div>
			<div className="input-form-content">
				<span className="suplabel-grid">{row && supLabel}</span>
				{isLazy ? (
					<LazyInput
						{...generalInputProps}
						clearError={clearError}
						handleChange={handleLazyChange}
						defaultValue={defaultValue || ''}
						readOnly={readOnly}
					/>
				) : (
					<input
						{...generalInputProps}
						value={value ?? ''}
						onBlur={handleBlur}
						onChange={handleChange}
						onKeyDown={handleKeyDown}
					/>
				)}
				{subLabel && <span className="sublabel-grid">{subLabel}</span>}
				{showError && <span className="grid-error">{error}</span>}
			</div>
		</div>
	);
};

InputGrid.defaultProps = {
	row: false,
	supLabel: '',
	subLabel: '',
	type: 'text',
	isLazy: false,
	required: true,
	readOnly: false,
};
