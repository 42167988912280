import { FC } from 'react';

import { StepSubjectHeader } from 'components/StepSubjectHeader';
import { IStepControl } from 'pages/Projects/types';

import { PresetDetails } from './components/PresetDetails';
import { Form } from './components/Form';

export interface IStep1Props extends IStepControl {
	getPaginatedJourneyPresets: () => void;
}

export const CreateProjectFromPresetStep1: FC<IStep1Props> = ({
	setStep,
	getPaginatedJourneyPresets,
}) => (
	<div className="project-thumb project-preset-step">
		<StepSubjectHeader step="Step 1 of 2" title="Select a Preset" />
		<div className="project-blank-main">
			<Form
				setStep={setStep}
				getPaginatedJourneyPresets={getPaginatedJourneyPresets}
			/>
			<PresetDetails />
		</div>
	</div>
);
