import { INewOrder } from 'api/models/responses/orders/newOrder';
import { ordersUrls } from 'constants/apiUrls/ordersUrls';
import { api } from 'config/api';

class OrderService {
	async getNewOrders(): Promise<INewOrder[]> {
		const { data } = await api.get<INewOrder[]>(ordersUrls.newOrders);

		return data;
	}
}

export default new OrderService();
