import { createSelector } from 'reselect';

import { studioPoseOptionTypesSelectOptions } from 'constants/priceLists/studio/selectOptions/poseOptionTypesSelectOptions';
import { StudioPoseOptionTypes } from 'api/models/responses/priceLists/studio/priceListOptions/poseOption';
import { RootState } from 'store/store';

import { studioBackgroundsSelector } from '../../priceListBackgrounds';

export const poseOptionsStateSelector = (state: RootState) =>
	state.studioPriceListPoseOptions;

export const groupedPoseOptionsSelector = createSelector(
	poseOptionsStateSelector,
	({ groupedPoseOptions }) => groupedPoseOptions
);

export const addedPoseOptionTypesSelector = createSelector(
	poseOptionsStateSelector,
	({ addedPoseOptionTypes }) => addedPoseOptionTypes
);

export const emptyStudioBackgroundsSelector = createSelector(
	[addedPoseOptionTypesSelector, studioBackgroundsSelector],
	(addedPoseOptionTypes, studioBackgrounds) => {
		const length = studioBackgrounds.length;

		const unsavedOption =
			!studioBackgrounds[length - 1]
				?.priceListStudioFulfilledBackgroundOptionKey;

		const unsavedSingleOption = unsavedOption && length === 1;

		return (!length || unsavedSingleOption) && addedPoseOptionTypes.Backgrounds;
	}
);

export const emptyStyleOptionsSelector = createSelector(
	[addedPoseOptionTypesSelector, groupedPoseOptionsSelector],
	(addedPoseOptionTypes, groupedPoseOptions) => {
		const length = groupedPoseOptions.Style.length;

		const unsavedOption =
			!groupedPoseOptions.Style[length - 1]
				?.priceListStudioFulfilledPoseOptionKey;

		const unsavedSingleOption = unsavedOption && length === 1;

		return (!length || unsavedSingleOption) && addedPoseOptionTypes.Style;
	}
);

export const emptyPoseOptionsSelector = createSelector(
	[addedPoseOptionTypesSelector, groupedPoseOptionsSelector],
	(addedPoseOptionTypes, groupedPoseOptions) => {
		const length = groupedPoseOptions.Pose.length;

		const unsavedOption =
			!groupedPoseOptions.Pose[length - 1]
				?.priceListStudioFulfilledPoseOptionKey;

		const unsavedSingleOption = unsavedOption && length === 1;

		return (!length || unsavedSingleOption) && addedPoseOptionTypes.Pose;
	}
);

export const emptyColorFlavorOptionsSelector = createSelector(
	[addedPoseOptionTypesSelector, groupedPoseOptionsSelector],
	(addedPoseOptionTypes, groupedPoseOptions) => {
		const length = groupedPoseOptions.ColorFlavor.length;

		const unsavedOption =
			!groupedPoseOptions.ColorFlavor[length - 1]
				?.priceListStudioFulfilledPoseOptionKey;

		const unsavedSingleOption = unsavedOption && length === 1;

		return (!length || unsavedSingleOption) && addedPoseOptionTypes.ColorFlavor;
	}
);

export const showStudioBackgroundOptionsSelector = createSelector(
	[studioBackgroundsSelector, addedPoseOptionTypesSelector],
	(studioBackgrounds, addedPoseOptionTypes) =>
		!!studioBackgrounds.length || addedPoseOptionTypes.Backgrounds
);

export const showStyleOptionsSelector = createSelector(
	[groupedPoseOptionsSelector, addedPoseOptionTypesSelector],
	(groupedPoseOptions, addedPoseOptionTypes) =>
		!!groupedPoseOptions.Style.length || addedPoseOptionTypes.Style
);

export const showPoseOptionsSelector = createSelector(
	[groupedPoseOptionsSelector, addedPoseOptionTypesSelector],
	(groupedPoseOptions, addedPoseOptionTypes) =>
		!!groupedPoseOptions.Pose.length || addedPoseOptionTypes.Pose
);

export const showColorFavorOptionsSelector = createSelector(
	[groupedPoseOptionsSelector, addedPoseOptionTypesSelector],
	(groupedPoseOptions, addedPoseOptionTypes) =>
		!!groupedPoseOptions.ColorFlavor.length || addedPoseOptionTypes.ColorFlavor
);

export const filteredPoseOptionTypesSelectOptionsSelector = createSelector(
	[
		showPoseOptionsSelector,
		showStyleOptionsSelector,
		showColorFavorOptionsSelector,
		showStudioBackgroundOptionsSelector,
	],
	(
		showPoseOptions,
		showStyleOptions,
		showColorFavorOptions,
		showStudioBackgroundOptions
	) =>
		studioPoseOptionTypesSelectOptions.filter(({ value }) => {
			const poseOptionsExists =
				value === StudioPoseOptionTypes.Pose && showPoseOptions;

			const styleOptionsExists =
				value === StudioPoseOptionTypes.Style && showStyleOptions;

			const colorFavorOptionsExists =
				value === StudioPoseOptionTypes.ColorFlavor && showColorFavorOptions;

			const backgroundsOptionsExists =
				value === StudioPoseOptionTypes.Backgrounds &&
				showStudioBackgroundOptions;

			if (
				poseOptionsExists ||
				styleOptionsExists ||
				colorFavorOptionsExists ||
				backgroundsOptionsExists
			)
				return false;

			return true;
		})
);

export const showPoseCheckboxSelector = createSelector(
	groupedPoseOptionsSelector,
	(groupedPoseOptions) => !!groupedPoseOptions.Pose.length
);

export const showStyleCheckboxSelector = createSelector(
	groupedPoseOptionsSelector,
	(groupedPoseOptions) => !!groupedPoseOptions.Style.length
);

export const showColorFlavorCheckboxSelector = createSelector(
	groupedPoseOptionsSelector,
	(groupedPoseOptions) => !!groupedPoseOptions.ColorFlavor.length
);

export const initialRequiredPoseOptionsSelector = createSelector(
	[
		showPoseCheckboxSelector,
		showStyleCheckboxSelector,
		showColorFlavorCheckboxSelector,
	],
	(showPoseCheckbox, showStyleCheckbox, showColorFlavorCheckbox) => {
		if (!showPoseCheckbox && !showStyleCheckbox && !showColorFlavorCheckbox) {
			return `${StudioPoseOptionTypes.None}`;
		}

		const requiredPoseOptionsArr = [];

		if (showPoseCheckbox) {
			requiredPoseOptionsArr.push(StudioPoseOptionTypes.Pose);
		}

		if (showStyleCheckbox) {
			requiredPoseOptionsArr.push(StudioPoseOptionTypes.Style);
		}

		if (showColorFlavorCheckbox) {
			requiredPoseOptionsArr.push(StudioPoseOptionTypes.ColorFlavor);
		}

		if (requiredPoseOptionsArr.length === 1) {
			return requiredPoseOptionsArr[0];
		}

		return requiredPoseOptionsArr.join(', ');
	}
);
