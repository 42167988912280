import { ICreatePoseOption } from 'api/models/requests/priceLists/studio/priceListOptions/createPoseOption';
import {
	IPoseOption,
	StudioPoseOptionTypes,
} from 'api/models/responses/priceLists/studio/priceListOptions/poseOption';
import { IPatchBody } from 'api/models/requests/general/patchBody';

import { multipartHeaders } from 'constants/images/multipartHeaders';
import { priceListsUrls } from 'constants/apiUrls/priceListsUrls';

import { api } from 'config/api';

class PoseOptionsService {
	async createPoseOption(
		priceListKey: number,
		{
			name,
			sequence,
			retailPrice,
			referenceCode,
			poseOptionType,
			previewImageFile,
		}: ICreatePoseOption
	): Promise<IPoseOption> {
		const formData = new FormData();

		formData.set('Name', name);
		formData.set('ReferenceCode', referenceCode);
		formData.set('Sequence', sequence.toString());
		formData.set('PoseOptionType', poseOptionType);
		formData.set('RetailPrice', retailPrice.toString());
		formData.set('PreviewImage', previewImageFile ?? '');

		const { data } = await api.post<IPoseOption>(
			priceListsUrls.studioFulfilled.poseOptions.createPoseOption(priceListKey),
			formData
		);

		return data;
	}

	async updatePoseOption(
		poseOptionKey: number,
		body: IPatchBody[]
	): Promise<IPoseOption> {
		const { data } = await api.patch<IPoseOption>(
			priceListsUrls.studioFulfilled.poseOptions.updatePoseOption(
				poseOptionKey
			),
			body
		);

		return data;
	}

	async deletePoseOptions(
		priceListKey: number,
		poseOptionType: StudioPoseOptionTypes
	): Promise<void> {
		await api.delete<string>(
			priceListsUrls.studioFulfilled.poseOptions.deletePoseOptions(
				priceListKey,
				poseOptionType
			)
		);
	}

	async deletePoseOption(
		priceListKey: number,
		poseOptionKey: number
	): Promise<void> {
		await api.delete<string>(
			priceListsUrls.studioFulfilled.poseOptions.deletePoseOption(
				priceListKey,
				poseOptionKey
			)
		);
	}

	async reorderPoseOptions(
		priceListKey: number,
		body: Record<number, number>
	): Promise<void> {
		await api.put<string>(
			priceListsUrls.studioFulfilled.poseOptions.reorderPoseOptions(
				priceListKey
			),
			body
		);
	}

	async uploadPoseOptionPreviewImage(
		poseOptionKey: number,
		imageFile: File
	): Promise<string> {
		const formData = new FormData();

		formData.append('imageFile', imageFile);

		const { data } = await api.post<string>(
			priceListsUrls.studioFulfilled.poseOptions.poseOptionPreviewImageUrl(
				poseOptionKey
			),
			formData,
			{
				...multipartHeaders,
			}
		);

		return data;
	}

	async deletePoseOptionPreviewImage(poseOptionKey: number): Promise<void> {
		await api.delete<string>(
			priceListsUrls.studioFulfilled.poseOptions.poseOptionPreviewImageUrl(
				poseOptionKey
			)
		);
	}
}

export default new PoseOptionsService();
