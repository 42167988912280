import { ImagoPoseOptionTypes } from 'api/models/responses/priceLists/imago/priceListOptions/poseOptionTypes';

interface IPoseOptionTypesUI {
	singleLabel: string;
	multiLabel: string;
}

export const imagoPoseOptionTypesUI: Record<
	ImagoPoseOptionTypes,
	IPoseOptionTypesUI
> = {
	[ImagoPoseOptionTypes.None]: {
		singleLabel: 'None',
		multiLabel: 'None',
	},
	[ImagoPoseOptionTypes.Backgrounds]: {
		singleLabel: 'Background',
		multiLabel: 'Backgrounds',
	},
};
