import moment, { parseTwoDigitYear, utc } from 'moment';
import { useEffect, useRef } from 'react';
import { Formik } from 'formik';

import { ICardInfoBody } from 'api/models/requests/studio/createStudio';
import StudioBillingService from 'api/services/StudioBillingService';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useToggleSection } from 'hooks/useToggleSection';

import { ToggleSection } from 'components/ToggleSection';
import { Button } from 'components/FormControls/Button';
import { Modal, ModalRef } from 'components/Modal';

import { updateCardScheme } from 'pages/Accounting/validations';
import { Payments } from 'types/ui/payments';
import {
	setCardInfo,
	cardInfoSelector,
	getCardInfoAsync,
} from 'store/studioBilling';

import { IUpdateCardForm, UpdateCardDetails } from '../UpdateCardDetails';

export const BillingInformation = () => {
	const cardInfo = useAppSelector(cardInfoSelector);

	const dispatch = useAppDispatch();

	const { isOpen, handleToggle } = useToggleSection(true);

	const updateCardRef = useRef<ModalRef>(null);

	const showUpdateCardModal = () => {
		updateCardRef?.current?.open();
	};

	const hideUpdateCardModal = () => {
		updateCardRef?.current?.close();
	};

	const expireDate =
		cardInfo?.expires &&
		moment(utc(cardInfo?.expires).toDate()).format('MM/DD');

	//------------
	const initialValues: IUpdateCardForm = {
		cardType: Payments.VISA,
		cardNumber: '',
		expiration: '',
		verificationCode: '',
		billingZip: '',
	};

	const onSubmit = async (values: IUpdateCardForm) => {
		const { cardType, cardNumber, expiration, verificationCode, billingZip } =
			values;

		const [expireMonth, expireYear] = expiration.split('/');

		const fullYear = parseTwoDigitYear(expireYear);

		const billingCardInfo: ICardInfoBody = {
			cardType,
			cardNumber,
			billingZip,
			verificationCode,
			expireYear: fullYear,
			expireMonth: +expireMonth,
		};

		try {
			const data = await StudioBillingService.updateCardInfo(billingCardInfo);

			if (!data) return;

			dispatch(setCardInfo(data));
			hideUpdateCardModal();
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		void dispatch(getCardInfoAsync());
	}, []);

	return (
		<div className="accing-toggle-section">
			<ToggleSection
				title="Billing Information"
				isOpen={isOpen}
				className="accing-toggle-header"
				handleToggle={handleToggle}
			>
				<div className="accing-wrapper">
					<span className="accing-title">
						Will use for any fees and balances for this studio.
					</span>
					<div className="accing-list-wrapper">
						<ul className="accing-list">
							<li className="accing-item">
								Card Type: <span>{cardInfo?.cardType}</span>
							</li>
							<li className="accing-item">
								Card Number: <span>**** **** **** {cardInfo?.lastFour}</span>
							</li>
							<li className="accing-item">
								Expiration Date: <span>{expireDate}</span>
							</li>
						</ul>
						<Button
							className="btn-secondary accing-btn"
							value="Update Credit Card"
							handleClick={showUpdateCardModal}
						/>
					</div>
				</div>
			</ToggleSection>
			<Modal
				title="Update Card Details"
				subtitle="Will use this card for any fees and balances for this studio."
				ref={updateCardRef}
			>
				<Formik
					onSubmit={onSubmit}
					initialValues={initialValues}
					validationSchema={updateCardScheme}
				>
					<UpdateCardDetails closeModal={hideUpdateCardModal} />
				</Formik>
			</Modal>
		</div>
	);
};
