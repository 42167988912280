import { FC, SyntheticEvent, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { imagesBlobStorageUrl } from 'constants/images/blobUrls';
import {
	imageUploadTypes,
	imageUploadTypesErrorMessage,
} from 'constants/images/validation/imageUploadTypes';
import {
	BYTES_IN_ONE_MB,
	IMAGE_UPLOAD_LIMIT_BYTES,
} from 'constants/images/validation/imageUploadLimit';

import { IErrorResponse } from 'api/models/responses/general/errorResponse';

import { ICoverImageProps } from '..';

export const CoverImage: FC<Omit<ICoverImageProps, 'label'>> = ({
	handleUploadImage,
	handleDeleteImage,
	coverImagePreviewRelativePath,
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const uploadImage = async (acceptedFiles: File[]) => {
		const [file] = acceptedFiles;

		if (!acceptedFiles.length) {
			return setErrorMessage(imageUploadTypesErrorMessage);
		}

		if (file.size > IMAGE_UPLOAD_LIMIT_BYTES) {
			return setErrorMessage(
				`Image size more than available limit ${
					IMAGE_UPLOAD_LIMIT_BYTES * BYTES_IN_ONE_MB
				}mb`
			);
		}

		setIsLoading(true);

		try {
			await handleUploadImage(file);

			setErrorMessage('');
		} catch (error) {
			const { errors } = error as IErrorResponse;

			const imageFileErrorMessage = errors.imageFile[0];

			if (imageFileErrorMessage) {
				setErrorMessage(imageFileErrorMessage);
			}
		}

		setIsLoading(false);
	};

	const onDrop = (acceptedFiles: File[]) => {
		void uploadImage(acceptedFiles);
	};

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		multiple: false,
		accept: imageUploadTypes,
	});

	const deleteImage = (e: SyntheticEvent<HTMLButtonElement>) => {
		e.stopPropagation();

		void handleDeleteImage();
	};

	if (!coverImagePreviewRelativePath) {
		return isLoading ? (
			<div className="loader-container">
				<div className="loader" />
			</div>
		) : (
			<>
				<span className="org-cover-mock" {...getRootProps()}>
					<input {...getInputProps()} />
				</span>
				{errorMessage && (
					<span className="org-cover-error">{errorMessage}</span>
				)}
			</>
		);
	}

	return (
		<>
			<img
				alt="cover image"
				className="org-cover-img"
				src={`${imagesBlobStorageUrl}${coverImagePreviewRelativePath}`}
			/>
			<button className="org-cover-btn" onClick={deleteImage}>
				<i className="org-cover-icon icon-delete" />
			</button>
		</>
	);
};
