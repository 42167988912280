import { SingleValue } from 'react-select';
import { useFormikContext } from 'formik';
import { ChangeEvent, FC } from 'react';

import { promoUnitTypesSelectOptions } from 'constants/promotions/selectOptions/promoUnitTypesSelectOptions';
import { promoApplyToSelectOptions } from 'constants/promotions/selectOptions/promoApplyToSelectOptions';
import { promoTypesSelectOptions } from 'constants/promotions/selectOptions/promoTypesSelectOptions';

import { PromoApplyToTypes } from 'api/models/responses/promotions/promotionDetails';
import { PromotionTypes } from 'api/models/responses/promotions/promotion';
import { UnitTypes } from 'api/models/responses/general/unitTypes';

import { SelectComponent } from 'components/FormControls/Select';
import { InputGrid } from 'components/FormControls/InputGrid';
import { ReactDatePicker } from 'components/ReactDatePicker';

import { normalizePrice } from 'utils/ui/normalizePrice';
import { ISelectOption } from 'types/ui/select';

import { IPromotionValues } from '../..';

export const PromotionForm: FC = () => {
	const { values, errors, touched, handleChange, setFieldValue } =
		useFormikContext<IPromotionValues>();

	const handleChangeUnitTypes = (
		option: SingleValue<ISelectOption<UnitTypes>>
	) => {
		if (!option) return;

		void setFieldValue('unitType', option.value);
	};

	const handleChangePromoType = (
		option: SingleValue<ISelectOption<PromotionTypes>>
	) => {
		if (!option) return;

		void setFieldValue('type', option.value);
	};

	const handleChangeApplyTo = (
		option: SingleValue<ISelectOption<PromoApplyToTypes>>
	) => {
		if (!option) return;

		void setFieldValue('applyTo', option.value);
	};

	const handleChangeIntegerValue = (e: ChangeEvent<HTMLInputElement>) => {
		const { id, value } = e.target;

		if (value.includes('.')) return;

		if (!Number.isInteger(+value)) return;

		void setFieldValue(id, value);
	};

	const handleChangeExpirationDate = (date: Date) => {
		void setFieldValue('expirationDate', date);
	};

	const handleChangeDecimalPriceValue = (e: ChangeEvent<HTMLInputElement>) => {
		const { id, value } = e.target;

		if (isNaN(+value)) return;

		void setFieldValue(id, value);
	};

	const handleBlurDecimalPriceValue = (e: ChangeEvent<HTMLInputElement>) => {
		const { id, value } = e.target;

		void setFieldValue(id, normalizePrice(value));
	};

	const handleBlurIntegerValue = (e: ChangeEvent<HTMLInputElement>) => {
		const { id, value } = e.target;

		if (!value) {
			void setFieldValue(id, '0');
		}
	};

	const showExpirationDateError =
		touched.expirationDate && errors.expirationDate;

	const isPercentUnitType = values.unitType === UnitTypes.Percent;

	const basedOnUnitTypeProps = {
		handleBlur: isPercentUnitType
			? handleBlurIntegerValue
			: handleBlurDecimalPriceValue,
		handleChange: isPercentUnitType
			? handleChangeIntegerValue
			: handleChangeDecimalPriceValue,
		id: isPercentUnitType ? 'percentValue' : 'priceValue',
		error: isPercentUnitType ? errors.percentValue : errors.priceValue,
		value: isPercentUnitType ? values.percentValue : values.priceValue,
		touched: isPercentUnitType ? touched.percentValue : touched.priceValue,
	};

	return (
		<div className="promotion-details-form">
			<InputGrid
				row
				id="name"
				label="Name"
				required={false}
				placeholder="Name"
				className="autorow"
				error={errors.name}
				value={values.name}
				touched={touched.name}
				handleChange={handleChange}
			/>
			<div className="promotion-multi-form">
				<label htmlFor={basedOnUnitTypeProps.id} className="promotion-label">
					Max Value
				</label>
				<div className="promotion-details-multi">
					<InputGrid
						{...basedOnUnitTypeProps}
						row
						required={false}
						className="autorow"
					/>
					<SelectComponent
						value={values.unitType}
						error={errors.unitType}
						touched={touched.unitType}
						onChange={handleChangeUnitTypes}
						selectOptions={promoUnitTypesSelectOptions}
					/>
				</div>
			</div>
			<div className="promotion-select-form">
				<SelectComponent
					label="Promo Type"
					value={values.type}
					error={errors.type}
					touched={touched.type}
					onChange={handleChangePromoType}
					selectOptions={promoTypesSelectOptions}
				/>
			</div>
			<InputGrid
				row
				id="promoCodes"
				required={false}
				className="autorow"
				label="Valid Code(s)"
				error={errors.promoCodes}
				value={values.promoCodes}
				handleChange={handleChange}
				touched={touched.promoCodes}
				placeholder="Enter code (If multiple separate by comma)"
			/>
			<InputGrid
				row
				id="maxUses"
				required={false}
				label="Max Uses"
				className="autorow"
				value={values.maxUses}
				error={errors.maxUses}
				touched={touched.maxUses}
				handleChange={handleChangeIntegerValue}
				placeholder="Leave blank for unlimited"
			/>
			<div className="promotion-select-form">
				<SelectComponent
					label="Apply to"
					error={errors.applyTo}
					value={values.applyTo}
					touched={touched.applyTo}
					selectPlaceholder="Apply to"
					onChange={handleChangeApplyTo}
					selectOptions={promoApplyToSelectOptions}
				/>
			</div>
			<InputGrid
				row
				required={false}
				id="minOrderValue"
				className="autorow"
				label="Min Order Value $"
				value={values.minOrderValue}
				error={errors.minOrderValue}
				touched={touched.minOrderValue}
				handleBlur={handleBlurDecimalPriceValue}
				handleChange={handleChangeDecimalPriceValue}
			/>
			<div>
				<div className="promotion-select-form">
					<label htmlFor="expirationDate" className="label">
						Expiration Date
					</label>
					<ReactDatePicker
						id="expirationDate"
						selected={values.expirationDate}
						onChange={handleChangeExpirationDate}
					/>
					{showExpirationDateError && (
						<span className="input-error">{errors.expirationDate}</span>
					)}
				</div>
			</div>
		</div>
	);
};
